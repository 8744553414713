import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Form, Accordion } from "react-bootstrap";
import Card from "../components/Card";
import { useSwagger } from "../context/SwaggerContext";

const INDIVIDUAL_CATALOG = "c70146ec-d8f6-48e4-b359-b686a1da7fa0";

export const AddEditBuildingPartVariant = ({ buildingPartV, variantID, onClose }) => {
    const _buildingGuid = useParams()['buildingGuid'];
    const { login } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();
    const initialBPV = buildingPartV ? {
        id: buildingPartV?.id,
        standard_reference: buildingPartV?.standard_reference?.id,
        bpc: buildingPartV?.standard_reference?.catalog || "c70146ec-d8f6-48e4-b359-b686a1da7fa0",
        type: buildingPartV?.type,
        U_thermal_bridge: buildingPartV?.U_thermal_bridge,
        U_typical: buildingPartV?.U_typical,
        filter_1: buildingPartV?.standard_reference?.filter_1,
        filter_2: buildingPartV?.standard_reference?.filter_2,
        valid_from: buildingPartV?.standard_reference?.valid_from,
        valid_to: buildingPartV?.standard_reference?.valid_to,
        comment : buildingPartV?.comment
    } : {};

    const [bpv, setBPV] = useState(initialBPV);
    const [bpc, setBPC] = useState();
    const [type, setType] = useState();
    const [bpt, setBPT] = useState(undefined);

    const [thickness, setThickness] = useState(0)
    const [lambda, setLambda] = useState(0)
    const client = useSwagger();


    const getBPC = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].getBuildingPartCatalog({ org_uuid: login.currentOrganisation.id });

            if (response.status >= 200 && response.status < 300) {
                let responseEP = response.status === 204 ? [] : response.obj;

                if (buildingPartV === undefined) setBPV(response.status === 204 ? responseEP : (prevState) => ({ ...prevState, bpc: responseEP?.[0]?.id }));

                setBPC([...responseEP, {
                    created_at: new Date(),
                    description: "",
                    id: "c70146ec-d8f6-48e4-b359-b686a1da7fa0",
                    name: t('individually'),
                    organisation: null,
                    updated_at: new Date(),
                }]);
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, bpv, onClose]);


    const getTypes = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["buildingparttypes"].buildingparttypes_retrieve();

            if (response.status >= 200 && response.status < 300) {
                let responseEP = response.status === 204 ? [] : response.obj;

                if (buildingPartV === undefined) setBPV(response.status === 204 ? responseEP : (prevState) => ({ ...prevState, type: String(response.obj?.[0]?.value)}));

                const labelMap = {
                    Floor: "floor",
                    OuterWall: "outerwall",
                    Ceiling: "ceiling",
                    Window: "window",
                    OuterDoor: "outerdoor",
                    Roof: "roof",
                    InnerWall: "innerwall",
                    InnerDoor: "innerdoor",
                    InnerCeiling: "innerceiling",
                    Shutterbox: "rollershutterbox",
                    "Virtual Group": "virtualGroup",
                    "Ground Soil" : "groundSoil",
                    "OuterWall Soil": "outerWallSoil",
                };

                const updatedItems = responseEP.map(item => {
                    const newLabel = labelMap[item.label];
                    return newLabel ? { ...item, label: newLabel } : item;
                });

                // setType(responseEP);
                setType(updatedItems);

            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, bpv, onClose]);

    const getBPTypology = useCallback(async () => {
        if (bpv?.['bpc'] === "c70146ec-d8f6-48e4-b359-b686a1da7fa0") return

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["buildingpartcatalog"].getBuildingpartCatalogTypology({ org_uuid: login.currentOrganisation.id, buildingpart_catalog_uuid: bpv?.["bpc"], type: bpv?.["type"] });

            if (response.status >= 200 && response.status < 300) {
                let responseEP = response.status === 204 ? [] : response.obj;
                if (buildingPartV === undefined) setBPV((prevState) => ({ ...prevState, standard_reference: response.obj?.[0]?.id }));
                setBPT(responseEP);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, bpv, onClose, bpc, type]);

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;
        setBPV(prevState => ({
            ...prevState,
            [id]: value
        }));
    }, []);

    const calculateThickness = useCallback(() => {
        if (!bpv.hasOwnProperty('U_typical')) {
            addToast(t("error"), "Bevor Sie dier Dämmung berechnen wollen, tragen Sie bitte ein U Wert", "error")
            return
        }
        if (lambda === 0) {
            addToast(t("error"), "Lambda darf nicht null sein", "error")
            return
        }
        if (thickness === 0) {
            addToast(t("error"), "Dicke darf nicht null sein", "error")
            return
        }
        let value = Math.pow(1 / bpv?.U_typical + (thickness * 10) / lambda, -1)
        setBPV(prevState => ({
            ...prevState,
            U_typical: String(value)
        }))
    }, [thickness, lambda, bpv, addToast])

    useEffect(() => {
        getBPC();
        getTypes();
    }, []);

    useEffect(() => {
        // if ((bpc === undefined && bpv?.standard_reference?.id === undefined) || type === undefined) return;
        if (bpc === undefined || type === undefined) return;
        getBPTypology();
    }, [bpv?.bpc, bpv?.type, bpc, type]);

    let filtered = bpv?.type != undefined ? bpt?.filter((b) => b.type == bpv.type) : bpt;
    // console.log("filtered t", filtered);
    let filtered_1 = bpv?.filter_1 ? filtered?.filter((b) => b.filter_1 == bpv.filter_1) : filtered;
    // console.log("filtered 1", filtered_1);
    let filtered_2 = bpv?.filter_2 ? filtered_1?.filter((b) => b.filter_2 == bpv.filter_2) : filtered_1;
    //   console.log("filtered 2", filtered_2);
    let filtered_3 = Number(bpv?.valid_from) > 0 ? filtered_2?.filter((b) => b.valid_from == bpv.valid_from) : filtered_2;
    //   console.log("filtered 3", filtered_3);
    let filtered_4 = Number(bpv?.valid_to) > 0 ? filtered_3?.filter((b) => b.valid_to == bpv.valid_to) : filtered_3;
    // console.log("filtered 4", filtered_4);

    let filter_1_options = [...new Set(filtered?.filter((e) => e?.filter_1 != "").map((e) => e.filter_1))];
    let filter_2_options = [...new Set(filtered_1?.filter((e) => e?.filter_2 != "").map((e) => e.filter_2))];
    let filter_3_options = [...new Set(filtered_2?.filter((e) => e?.valid_from != "").map((e) => e.valid_from))];
    let filter_4_options = [...new Set(filtered_3?.filter((e) => e?.valid_to != "").map((e) => e.valid_to))];

    
    let reference = undefined;
    filtered_4?.map(b => {
        if (b.id == bpv['standard_reference']) {
            reference = b;
        }
    });


    useEffect(() => {
        if (bpv.filter_1 && buildingPartV?.standard_reference?.filter_1 != bpv?.filter_1) {
            setBPV((prevState) => ({
                ...prevState,
                filter_2: filter_2_options[0],
                valid_from: filter_3_options[0],
                valid_to: filter_4_options[0],
            }));
        }
    }, [bpv.filter_1]);

    useEffect(() => {
        if (bpv.filter_2 && buildingPartV?.standard_reference?.filter_2 != bpv?.filter_2) {
            setBPV((prevState) => ({
                ...prevState,
                valid_from: filter_3_options[0],
                valid_to: filter_4_options[0],
            }));
        }
    }, [bpv.filter_2]);

    useEffect(() => {
        if (bpv.valid_from) {
            setBPV((prevState) => ({
                ...prevState,
                valid_to: filter_4_options[0],
            }));
        }
    }, [bpv.valid_from]);

    useEffect(() => {
        if (bpv.type && bpv.type != initialBPV.type) {
            setBPV((prevShp) => ({
                ...prevShp,
                filter_1: "",
                filter_2: "",
                valid_from: "",
                valid_to: "",
            }));
        }
    }, [bpv.type]);

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();
        let bp = {...bpv};
        if (buildingPartV && buildingPartV?.standard_reference?.id === bpv?.standard_reference && bpv?.filter_1 != buildingPartV?.standard_reference?.filter_1 || bpv?.filter_2 != buildingPartV?.standard_reference?.filter_2) {
            if (filtered_4.length)
                bp.standard_reference = filtered_4[0].id
        }

        if (bp['U_typical'] && typeof bp['U_typical'] === 'string') bp['U_typical'] = parseFloat(bp['U_typical']);
        if (bp['U_thermal_bridge'] && typeof bp['U_thermal_bridge'] === 'string') bp['U_thermal_bridge'] = parseFloat(bp['U_thermal_bridge']);
        if (bp.bpc === "c70146ec-d8f6-48e4-b359-b686a1da7fa0") delete bp.standard_reference
        if (bp.bpc !== "c70146ec-d8f6-48e4-b359-b686a1da7fa0") {
            delete bp.U_typical
        }
        if (bp.bpc !== INDIVIDUAL_CATALOG) {
            bp.comment = ""
        }
        delete bp.bpc;

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(bp);
                return req;
            };

            const response = bpv.id ?
                await client.apis["building"].building_variants_buildingparts_partial_update({ building_uuid: _buildingGuid, variant_uuid: variantID, variant_bp_uuid: bpv.id }) :
                await client.apis["building"].building_variants_buildingparts_create({ building_uuid: _buildingGuid, variant_uuid: variantID });

            if (response.status >= 200 && response.status < 300) {
                onClose();
                addToast(t("success"), bpv?.id ? t("patchBuildingPartVariant") : t("postBuildingPartVariant"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;
            addToast(t("error"), statusCode === 409 ? t('conflictBpVariant') : t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, bpv, onClose, filtered_4]);

    const get_filter_1_and_2 = (type) => {
        let label_filter_1 = type == 9 ? t("insulation") : t("material");
        let label_filter_2 = type == 3 ? t("glazing") : t("structure");

        return (
            <>
                <Form.Group className="mb-3">
                    <Form.Label>{label_filter_1}</Form.Label>
                    <Form.Select
                        id="filter_1"
                        value={bpv["filter_1"] || ""}
                        onChange={(e) => changeHandler(e)}
                        disabled={filter_1_options.length < 1}
                    >
                        {filter_1_options.map((filter_1) => (
                            <option key={`bpt-key-${filter_1}`} value={filter_1}>
                                {filter_1}
                            </option>
                        ))}
                        <option key={`bpt-key-none`} value={""}>
                            {"keine Auswahl"}
                        </option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>{label_filter_2}</Form.Label>
                    <Form.Select
                        id="filter_2"
                        value={bpv["filter_2"] || ""}
                        onChange={(e) => changeHandler(e)}
                        disabled={filter_2_options.length < 1}
                    >
                        {filter_2_options.map((filter_2) => (
                            <option key={`bpt-key-${filter_2}`} value={filter_2}>
                                {filter_2}
                            </option>
                        ))}
                        <option key={`bpt-key-none`} value={""}>
                            {"keine Auswahl"}
                        </option>
                    </Form.Select>
                </Form.Group>
            </>
        );
    };

    const get_individual_submenu = () => {
        return (
            <>
                {bpv["bpc"] !== INDIVIDUAL_CATALOG && (
                    <>
                        {get_filter_1_and_2(bpv?.type)}

                        <Form.Group className="mb-3">
                            <Form.Label>{t("valid_from")}</Form.Label>
                            <Form.Select
                                id="valid_from"
                                value={bpv["valid_from"] || ""}
                                onChange={(e) => changeHandler(e)}
                                disabled={filter_3_options.length < 1}
                            >
                                {filter_3_options.map((valid_from) => (
                                    <option key={`bpt-key-${valid_from}`} value={valid_from}>
                                        {valid_from}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("valid_to")}</Form.Label>
                            <Form.Select
                                id="valid_to"
                                value={bpv["valid_to"] || ""}
                                onChange={(e) => changeHandler(e)}
                                disabled={filter_4_options.length < 1}
                            >
                                {filter_4_options.map((valid_to) => (
                                    <option key={`bpt-key-${valid_to}`} value={valid_to}>
                                        {valid_to}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("buildingPart")}</Form.Label>
                            <Form.Select
                                id="standard_reference"
                                value={bpv?.standard_reference}
                                onChange={(e) => changeHandler(e)}
                                disabled={filtered_4?.length < 1}
                            >
                                    {filtered_4?.map((b) => (
                                        <option key={`bpt-key-${b.id}`} value={b.id}>
                                            {b.name}
                                        </option>
                                    ))}
                            </Form.Select>
                        </Form.Group>

                        {bpv["bpc"] !== INDIVIDUAL_CATALOG &&
                            <Card {...{ heading: t('dataset'), className: 'mb-3' }}>
                                <p>{t('description') + " : "}</p><p>{reference?.name || filtered_4?.length >= 1 && filtered_4[0].name || ""}</p>
                                <p>{t('uValue') + " : " + (reference?.u_value_m2 || filtered_4?.length >= 1 && filtered_4[0].u_value_m2 || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} W/m²/K</p>
                            </Card>
                        }
                    </>
                )}
            </>
        );
    };

    const get_individualCatalog_submenu = () => {
        return (
            <>
                {bpv["bpc"] === INDIVIDUAL_CATALOG && (
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('uTypical')} (W/m²K)</Form.Label>
                            <Form.Control id="U_typical" type="number" step="any" placeholder="" value={bpv['U_typical'] || ''} onChange={(e) => changeHandler(e)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>U {t('thermalBridge')}</Form.Label>
                            <Form.Control id="U_thermal_bridge" type="number" step="any" placeholder="" value={bpv['U_thermal_bridge'] || ''} onChange={(e) => changeHandler(e)} />
                        </Form.Group>

                        {/* <Form.Group className="mb-3">
                            <Form.Label>{t("thickness")} (cm)</Form.Label>
                            <Form.Control id="thickness" type="number" step="any" placeholder="" value={thickness} onChange={(e) => setThickness(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Lambda (WLG) (W/mK * 10^-3)</Form.Label>
                            <Form.Control id="Lambda" type="number" step="any" placeholder="" value={lambda} onChange={(e) => setLambda(e.target.value)} />
                        </Form.Group> */}

                        <Form.Group className="mb-3">
                            <Form.Label>{t("comment")}</Form.Label>
                            <Form.Control
                            as="textarea"
                            id="comment"
                            placeholder=""
                            value={bpv?.comment || ""}
                            onChange={(e) => changeHandler(e)}
                            rows={2} 
                            />
                        </Form.Group>

                        {/* <Button variant="outline-primary" className='w-100 mb-3' onClick={() => calculateThickness()}>{t('calc')}</Button>  */}
                    </>
                )}
            </>
        );
    };

    const get_insulation_menu = () => {
        return <>
            {bpv["bpc"] === INDIVIDUAL_CATALOG && (
            <>
                <Accordion defaultActiveKey={null} className="mb-3">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{t("additionalInformations")}</Accordion.Header>
                    <Accordion.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("thickness")} (cm)</Form.Label>
                            <Form.Control id="thickness" type="number" step="any" placeholder="" value={thickness} onChange={(e) => setThickness(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Lambda (WLG) (W/mK * 10^-3)</Form.Label>
                            <Form.Control id="Lambda" type="number" step="any" placeholder="" value={lambda} onChange={(e) => setLambda(e.target.value)} />
                        </Form.Group>

                        <Button variant="outline-primary" className='w-100 mb-3' onClick={() => calculateThickness()}>{t('calc')}</Button> 
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
            </>
            )}
        </>
    }

    return <Form onSubmit={(e) => onSubmit(e)}>
        <Form.Group className='mb-3'>
            <Form.Label>{t('Buildingpartcataloges')}</Form.Label>
            <Form.Select id="bpc" value={bpv['bpc'] || ''} onChange={(e) => changeHandler(e)}>
                {bpc?.map(b => <option key={`bpc-key-${b.id}`} value={b.id}>{b.name}</option>)}
            </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('type')}</Form.Label>
            <Form.Select id="type" value={bpv['type'] || ''} onChange={(e) => changeHandler(e)}>
                {type?.filter(b => b.value !== 100).map(b => (
                    <option key={`type-key-${b.value}`} value={b.value}>
                        {t(b.label)}
                    </option>
                ))}
            </Form.Select>
        </Form.Group>

        {get_individual_submenu()}

        {bpv["bpc"] !== INDIVIDUAL_CATALOG && (
            <Form.Group className="mb-3">
                <Form.Label>U {t('thermalBridge')}</Form.Label>
                <Form.Control id="U_thermal_bridge" type="number" step="any" placeholder="" value={bpv['U_thermal_bridge'] || ''} onChange={(e) => changeHandler(e)} />
            </Form.Group>
        )}

        {get_individualCatalog_submenu()}
        {get_insulation_menu()}

        <Button variant="outline-primary" className='w-100' type='submit'>{t('id' in bpv ? 'edit' : 'add')}</Button>
    </Form>;
};