import React from 'react'
import { useState, useEffect, useCallback, useRef } from "react";
import { useAuth } from "../Context";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Accordion, Button, Form, Nav, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useToast } from '../NotificationsContent';
import { useSwagger } from "../context/SwaggerContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faExclamation, faTrash } from "@fortawesome/free-solid-svg-icons";
import SecurityActionModal from "../components/SecurityActionModal";
import Loading from '../components/Loading';


const AddEditCounterReadingOrConsumption = ({counter, variantID, buildingID, onClose, editCounterReadingOrConsumption}) => {
    let datasourceType = counter?.counter_datasource_type;
    let counterReading = datasourceType === 1000 ? true : false

    const _buildingGuid = useParams()['buildingGuid'] || buildingID;
    const { addToast } = useToast();
    const { login } = useAuth();
    const client = useSwagger();
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const currentDateTime = new Date().toLocaleString("sv-SE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false
    }).replace(" ", "T").slice(0, 16);

    const formatDateTime = (dateTime) => {
        return new Date(dateTime).toLocaleString("sv-SE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false
        }).replace(" ", "T").slice(0, 16);
    };

    const formatDateTimeUTC = (dateTime) => {
        const date = new Date(dateTime);
        return `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}T${String(date.getUTCHours()).padStart(2, '0')}:${String(date.getUTCMinutes()).padStart(2, '0')}`;
    };

    const initialData = {
        counter: editCounterReadingOrConsumption && counterReading? {to_datetime: formatDateTime(editCounterReadingOrConsumption.to_datetime), counter_value: editCounterReadingOrConsumption.counter_value} : {to_datetime: currentDateTime, counter_value: ""},
        consumption: editCounterReadingOrConsumption && !counterReading ? {to_datetime: formatDateTime(editCounterReadingOrConsumption.to_datetime), from_datetime: formatDateTime(editCounterReadingOrConsumption.from_datetime), counter_value: editCounterReadingOrConsumption.value_raw } : {to_datetime: currentDateTime, counter_value: "", from_datetime: ""}
    };

    const [entry, setEntry] = useState( counterReading ? initialData.counter : initialData.consumption );

    const [meterReplacement, setMeterReplacement] = useState(editCounterReadingOrConsumption ? editCounterReadingOrConsumption?.exchange !== null : false)

    const [meterReplacementData, setMeterReplacementData] = useState(() => ({
        counter_exchange_end_value: editCounterReadingOrConsumption?.exchange?.counter_exchange_end_value || "",
        counter_exchange_start_value: editCounterReadingOrConsumption?.exchange?.counter_exchange_start_value || "",
        counter_exchange_datetime: formatDateTimeUTC(editCounterReadingOrConsumption?.exchange?.counter_exchange_datetime) || ""
    }));
    
    const onSubmit = useCallback(async(e) => {
        e.preventDefault();
        if (!client || isSubmitting) return;

        setIsSubmitting(true)

        let result = entry

        if(meterReplacement) {
            if (Number(result.counter_value) < Number(meterReplacementData.counter_exchange_start_value)) {
                addToast(t("error"), t("meterReplacementError"), "error");
                setIsSubmitting(false)
                return
            } else {
                result = { ...result, exchange_counter: { ...meterReplacementData } };
            }
        }

        result.to_datetime = result.to_datetime.replace("T", " ");

        if(!counterReading) {
            result.from_datetime = result.from_datetime.replace("T", " ")
            result.value_raw = result.counter_value
            delete result.counter_value
        }

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(result);
                return req;
            };

            const response = editCounterReadingOrConsumption ? await client.apis["building"].building_variants_datasource_consumptions_partial_update({ building_uuid: _buildingGuid, variant_uuid: variantID, datasource_uuid: counter.id, id: editCounterReadingOrConsumption.id }) : await client.apis["building"].building_variants_datasource_consumptions_create({ building_uuid: _buildingGuid, variant_uuid: variantID, datasource_uuid: counter.id });
            
            if (response.status >= 200 && response.status < 300) {
                addToast(t("building"), editCounterReadingOrConsumption ? (counterReading ? t("editCounterReadingSuccess") : t("editConsumptionSuccess")) : (counterReading? t("addCounterReadingSuccess") : t("addConsumptionSuccess")) , "success");
                onClose();
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;
            if (statusCode === 409) {
                addToast(t("error"), t("overlappingDates"), "error");
                
            } else {
                addToast(t("error"), t("responseError"), "error");
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            setIsSubmitting(false)
        }
    }, [client, isSubmitting, entry, meterReplacement, counterReading, meterReplacementData, addToast, t, editCounterReadingOrConsumption, _buildingGuid, variantID, counter, login.Authorization, onClose])

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;
        setEntry(prevState => ({
            ...prevState,
            [id]: value
        }));
    }, []);

    const changeHandlerReplacement = useCallback((e) => {
        const { id, value } = e.target;
        setMeterReplacementData(prevState => ({
            ...prevState,
            [id]: value
        }));
    }, []);

    const getCounterReadingForm = () => {
        return <>
            <Form.Group className='mb-3'>
                <Form.Label>{t('date')}</Form.Label>
                <Form.Control id='to_datetime' required className="displayDate" type='datetime-local' value={entry['to_datetime'] || ''} onChange={(e) => changeHandler(e)} />
            </Form.Group>
    
            <Form.Group className='mb-3'>
                <Form.Label>{t('counterReading')}</Form.Label>
                <Form.Control id='counter_value' required type='number' value={entry['counter_value'] || ''} onChange={(e) => changeHandler(e)} />
            </Form.Group>
        </>
    }

    const getConsumptionForm = () => {
        return <>
            <Form.Group className='mb-3'>
                <Form.Label>{t('from_1')}</Form.Label>
                <Form.Control id='from_datetime' required className="displayDate" type='datetime-local' value={entry['from_datetime'] || ''} onChange={(e) => changeHandler(e)} />
            </Form.Group>

            <Form.Group className='mb-3'>
                <Form.Label>{t('to_1')}</Form.Label>
                <Form.Control id='to_datetime' required className="displayDate" type='datetime-local' value={entry['to_datetime'] || ''} onChange={(e) => changeHandler(e)} />
            </Form.Group>

            <Form.Group className='mb-3'>
                <Form.Label>{t('consumption')}</Form.Label>
                <Form.Control id='counter_value' required  type='number' value={entry['counter_value'] || ''} onChange={(e) => changeHandler(e)} />
            </Form.Group>
        </>
    }

    const meterReplacementForm = () => {
        return <>
            <Form.Check
                className="mb-3"
                type="checkbox"
                id="meterReplacement"
                label={t('meterReplacement')}
            >
                <Form.Check.Input
                    checked={meterReplacement}
                    onChange={(e) => {setMeterReplacement(!meterReplacement)}}
                />
                <Form.Check.Label>
                    {`${t('meterReplacement')} ? `}
                </Form.Check.Label>
            </Form.Check>

            <Accordion defaultActiveKey={null} className="mb-3">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{t("meterReplacement")}</Accordion.Header>
                    <Accordion.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("oldValMeterReading")}</Form.Label>
                            <Form.Control id="counter_exchange_end_value" type="number" step="any" placeholder="" value={meterReplacementData.counter_exchange_end_value} onChange={(e) => changeHandlerReplacement(e)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("newValMeterReading")}</Form.Label>
                            <Form.Control id="counter_exchange_start_value" type="number" step="any" placeholder="" value={meterReplacementData.counter_exchange_start_value} onChange={(e) => changeHandlerReplacement(e)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>{t('date')}</Form.Label>
                            <Form.Control id="counter_exchange_datetime" className="displayDate" type='datetime-local' value={meterReplacementData.counter_exchange_datetime} onChange={(e) => changeHandlerReplacement(e)} />
                        </Form.Group>

                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    }

    return (
        <>
            {(counter || editCounterReadingOrConsumption) && (
                <Form onSubmit={(e) => onSubmit(e)}>
                    {counterReading ? (
                        <>
                            {getCounterReadingForm()}
                            {meterReplacementForm()}
                        </>
                    ) : (
                        getConsumptionForm()
                    )}
                    <Button variant="outline-primary" disabled={isSubmitting} className='w-100' type='submit'>{ t('save') }</Button>
                </Form>
            )}

            {isSubmitting &&
                <Loading />
            }
        </>
    )
}

export default AddEditCounterReadingOrConsumption
