import { useState } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import { faAdd, faCalculator, faHeadset } from "@fortawesome/free-solid-svg-icons";
import Card from "../components/Card";
import { DemographicDiagramm, SankeyDiagram } from "../components/AmCharts";
import useBuildingHook from "../hooks/useBuildingHook";
import { usePermissions } from "../context/PermissionsContext";

export const BuildingEnergyFlow = () => {
    const _buildingGuid = useParams()['buildingGuid'];
    const { login, doSetLogin } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();
    const { allowEdit } = usePermissions();
    useBuildingHook()
    
    const mainFunctions = [
        {label: t('calculation'), onClick: () => console.log(true), key: 'calculation', icon: faCalculator},
        {label: "Live", onClick: () => console.log(true), key: 'live', icon: faHeadset}
    ]

    return <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">
            <Card {...{ heading: `${t('energyFlow')} ${t('inThe')} ${t('building')}: ${login?.currentBuilding?.name}`, ...(allowEdit && { mainFunctions }), className: "mb-3" }} >
                <div style={{ height: "500px "}}>
                    <SankeyDiagram {...{ chartID: "randomis2"}} />
                </div>
            </Card>
            <Card {...{ heading: `${t('energyFlow')} ${t('inThe')} ${t('building')}: ${login?.currentBuilding?.name}`, ...(allowEdit && { mainFunctions }) }} >
                <div style={{ height: "500px "}}>
                    <DemographicDiagramm {...{ chartID: "randomi3s2"}} />
                </div>
            </Card>
        </div>
    </MainLayout>
}

export default BuildingEnergyFlow;