import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../Context";
import { useTranslation } from "react-i18next";
import { Loader } from "../style/Loader";
import { Alert } from "react-bootstrap";
import { useSwagger } from "../context/SwaggerContext";
import { useToast } from "../NotificationsContent";

export const BoldSign = () => {
    const [state, setState] = useState(undefined)
    const { login } = useAuth();
    const { t } = useTranslation();
    const _orgGuid = useParams()['orgGuid'];
    const _docuID = useParams()['docuID'];
    const { addToast } = useToast();
    const client = useSwagger();

    const checkFrameContracts = useCallback(async () => {
    //     fetch(`https://tech.sigmaheat.de/org/${_orgGuid}/user/${login.id}/sign/${_docuID}/check`, {
    //         headers: {
    //             'Authorization': login.Authorization,
    //             "Content-Type": "application/json"
    //         },
    //         method: "GET"
    //     })
    //     .then((response) => {
    //         if (!response.ok) throw new Error(JSON.stringify({ title: t("error"), message: t("responseError") }))
    //         if (response.status === 204) setState("204")
    //         if (response.status === 200) setState("200")
    //     })
    //     .catch((error) => {
    //         setState("error")
    //     });
    // }, [login.Authorization, _docuID])

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["org"].org_sign_retrieve({org_uuid: _orgGuid, frc_id: _docuID});

            if (response.status === 204) setState("204");
            if (response.status === 200) setState("200");

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            setState("error");
            addToast(t("error"), t("responseError"), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, _docuID]);

    useEffect(() => {
        checkFrameContracts()
    }, [])

    return <div className="d-flex align-items-center justify-content-center vh-100">
        {state === undefined ? (
            <Loader />
        ) : (state === "204") ? (
            <Alert variant='info'>{t("docNotSigned")}</Alert>
        ) : (
            <Alert variant='success'>{t("docSigned")}</Alert>
        )}
    </div>
}

export default BoldSign;