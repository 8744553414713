import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form, ProgressBar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faArrowUpFromBracket, faTrash } from '@fortawesome/free-solid-svg-icons'
import LightGallery from 'lightgallery/react'
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-thumbnail.css'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import { Loader } from '../style/Loader'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Card from './Card'
import ContentModal from "./ContentModal"
import { usePermissions } from '../context/PermissionsContext'

const ImageUpload = forwardRef(({ show, setShow, imageUpload }, ref) => {
    const { t } = useTranslation();
    const [progress, setProgress] = useState(0);
    const [loadedImages, setLoadedImages] = useState([]);
    const [editedImages, setEditedImages] = useState([]);
    const [showDescription, setShowDescription] = useState(false);
    const [loader, setLoader] = useState(false);
    const [uploading, setUploading] = useState(false);
    const dndRef = useRef(null);
    const uploadRef = useRef(null);
    const contentRef = useRef(null);
    const inputRef = useRef(null);
    const MySwal = withReactContent(Swal)

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        // const content = contentRef.current;
        const files = e?.dataTransfer?.files || e.target.files;
        setLoader(true);

        const processFile = (fileIndex) => {
            if (fileIndex >= files.length) {
                setLoader(false)
                return
            }

            const file = files[fileIndex];
            const reader = new FileReader();

            reader.addEventListener('load', (e) => {
                if (file.type.includes('image/')) {
                    setLoadedImages((prevImages) => [...prevImages, { file: file, description: '', reader: reader.result, checked: false }]);
                    setEditedImages((prevImages) => [...prevImages, { file: file, description: '', reader: reader.result, checked: false }]);
                }

                processFile(fileIndex + 1);
            });

            reader.readAsDataURL(file);
        };

        processFile(0);
        dndRef.current.classList.remove('drag');
    }, [])

    const handleDragover = (e) => {
        dndRef.current.classList.add('drag');
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
    }

    const handleDragleave = () => {
        dndRef.current.classList.remove('drag');
    }

    const handleFileDialog = (e) => {
        if (e.target !== dndRef.current && e.target !== document.querySelector('.modal__gallery')) return;
        inputRef.current.click();
    }

    const handleDescriptionChange = (e) => {
        const updatedDescription = e.target.value;

        setEditedImages((prevItems) => {
            const updatedItems = [...prevItems];

            updatedItems[showDescription] = {
                ...updatedItems[showDescription],
                description: updatedDescription,
            };

            return updatedItems;
        });
    };

    const deleteImage = () => {
        const updatedArray = loadedImages.filter((_, index) => index !== showDescription);
        setLoadedImages(updatedArray);
        setEditedImages(updatedArray);
        setShowDescription(false);
    };

    const hideModal = () => {
        setShow(false)
        setShowDescription(false)
        setLoadedImages([])
        setEditedImages([])
        setProgress(0)
    }

    const startUploading = () => {
        if (!Boolean(loadedImages.length)) {
            MySwal.fire(t('imageFiles'), t('imageFilesText'), 'info')
        } else if (!uploading) {
            setUploading(true);
            imageUpload(loadedImages);
        } else {
            MySwal.fire(t('uploading'), t('uploadingText'), 'info')
        }
    }

    useImperativeHandle(ref, () => ({
        closeImageInformation() {
            MySwal.fire({
                title: t('uploaded'),
                text: t('uploadedText'),
                icon: 'success'
            }).then(result => {
                hideModal()
                setUploading(false)
            })
        },
        updateProgress(step, index) {
            setProgress(step)

            setLoadedImages((prevItems) => {
                const updatedItems = [...prevItems];

                updatedItems[index] = {
                    ...updatedItems[index],
                    checked: true,
                };

                return updatedItems;
            });
        }
    }));

    useEffect(() => {
        const currentRef = dndRef.current;
        const inputCurrentRef = inputRef.current;
        if (currentRef === null) return;
        currentRef.addEventListener('click', handleFileDialog);
        inputCurrentRef.addEventListener('change', handleDrop);
        currentRef.addEventListener('drop', handleDrop);
        currentRef.addEventListener('dragover', handleDragover);
        currentRef.addEventListener('dragleave', handleDragleave);
        return () => {
            inputCurrentRef.removeEventListener('change', handleDrop);
            currentRef.removeEventListener('click', handleFileDialog);
            currentRef.removeEventListener('drop', handleDrop);
            currentRef.removeEventListener('dragover', handleDragover);
            currentRef.removeEventListener('dragleave', handleDragleave);
        }
    }, [show, handleDrop])

    useEffect(() => {
        uploadRef.current.style.display = !Boolean(loadedImages.length) ? 'flex' : 'none';
        contentRef.current.style.display = !Boolean(loadedImages.length) ? 'block' : 'grid';
    }, [loadedImages])

    return (
        <div className={`position-relative overflow-hidden${showDescription !== false ? ' toast__open' : ''}`}>
            <section className='upload__wrapper mb-3' ref={dndRef}>
                <input type='file' multiple accept='image/*' ref={inputRef} />
                <div className='upload__message' ref={uploadRef}>
                    <FontAwesomeIcon icon={faArrowUpFromBracket} size='2xl' />
                    <p>{t('selectorDnD')}</p>
                </div>

                {loader && <Loader />}

                <div className='modal__gallery' ref={contentRef}>
                    {!loader && Boolean(loadedImages.length) && loadedImages.map((img, index) => {
                        return (
                            <a key={`image-key-${index}`} className='gallery__item' href={img.reader} target='_blank' rel='noreferrer' onClick={(e) => { e.preventDefault(); setShowDescription(index) }}>
                                <div className='round__checkbox'>
                                    <input type='checkbox' id={`indicator-id-${img.file.name}`} checked={img.checked} readOnly />
                                    <label htmlFor={`indicator-id-${img.file.name}`}></label>
                                </div>
                                <img src={img.reader} alt='' />
                            </a>
                        )
                    })}
                </div>
            </section>

            <div className='d-flex justify-content-between align-items-center gap-3'>
                <ProgressBar now={progress} label={`${progress}%`} className='w-100' />
                <Button variant="outline-primary" onClick={() => startUploading()}>{t('upload')}</Button>
            </div>

            <div className='toast__backdrop' onClick={(e) => { if (e.target === document.querySelector('.toast__backdrop')) setShowDescription(false) }}>
                <Form className='image__information p-3' onSubmit={(e) => { e.preventDefault(); setLoadedImages(editedImages); setShowDescription(false); }}>
                    <Form.Group className='mb-3'>
                        <Form.Label>{t('imageDescription')}</Form.Label>
                        <Form.Control className='image__information__textarea' as='textarea' rows={8} value={editedImages[showDescription]?.description} onChange={(e) => handleDescriptionChange(e)} />
                    </Form.Group>

                    <div className='d-flex justify-content-between'>
                        <Button variant='outline-danger' onClick={() => deleteImage()}>
                            <FontAwesomeIcon icon={faTrash} size='sm' />
                        </Button>
                        <Button variant="outline-primary" onClick={() => { setShowDescription(false); setEditedImages(loadedImages) }}>Close</Button>
                        <Button variant="outline-primary" type='submit'>{t('confirm')}</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
})

const Gallery = forwardRef(({ title, loader, images, imageUpload, loadImages, loadedImage, sort, setSort }, ref) => {
    const [show, setShow] = useState(false);
    const imageRef = useRef(null);
    const observer = useRef(null);
    const LICENCE = process.env.REACT_APP_LIGHTGALLERY;
    const { allowEdit } = usePermissions();

    const { t } = useTranslation();

    const mainFunctions = [
        {label: t('uploadImages'), onClick: () => setShow(true), key: 'uploadImages', icon: faAdd}
    ]

    const handleIntersection = useCallback((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const imageId = entry.target.dataset.imageId;
                const imageIndex = images.findIndex((item) => item.id === imageId)

                if (imageIndex === images.length - 1) {
                    const startIndex = images.length;
                    const endIndex = startIndex + 6;
                    loadImages(startIndex, endIndex);
                }

                observer.current.unobserve(entry.target);
            }
        });
    }, [images, loadImages]);

    useImperativeHandle(ref, (step, index) => ({
        closeModal() {
            imageRef.current.closeImageInformation()
        },
        updateProgress(step, index) {
            imageRef.current.updateProgress(step, index);
        },
        updateImageItem(index) {

        }
    }));

    useEffect(() => {
        observer.current = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        });

        return () => observer.current.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [images, loadedImage, handleIntersection])

    useEffect(() => {
        if (!(!Boolean(images.length) || loader)) observer.current.observe(document.querySelector(`[data-image-id="${images[images.length - 1].id}"]`));
    }, [images, loader, loadedImage])

    return (
        <>
            <Card {...{ heading: title, ...(allowEdit && { mainFunctions }) }}>
                {images?.length === 0 && loader && <Loader />}
                {!loader && !Boolean(images?.length) && <p className='m-0'>{t('noImagesMessage')}</p>}
                <LightGallery
                    elementClassNames='gallery__grid'
                    speed={500}
                    plugins={[lgThumbnail, lgZoom]}
                    licenseKey={LICENCE}
                >
                    { // eslint-disable-next-line array-callback-return
                        Boolean(images.length) && images.map(im => <a key={im.id} className='gallery__item' href={im.src} target='_blank' rel='noreferrer' download={im.title}>
                            <img src={im.src} alt={im.title} data-image-id={im.id} />
                        </a>)}
                </LightGallery>
            </Card>

            <ContentModal {...{ show, onHide: () => setShow(false), title: t('uploadImages'), content: <ImageUpload {...{ show, setShow: setShow, imageUpload }} ref={imageRef} />, size: 'lg' }} />
        </>
    )
})

export default Gallery