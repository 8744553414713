import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row, Nav, Tab } from "react-bootstrap";
import RoofDormer from "./RoofDormer";

export const RoofSlopeDefault = ({ width, kneestock, diagonal, dormers, sync, noofdeductionareas, onWidthChange, onKneestockChange, onDiagonalChange, onNoOfDoomerChange, onDormerChange, onSyncChange, onSyncAllDormers, onNoofdeductionareasChange, dedOne, dedTwo, buildingParts, onDeductionAreaChange }) => {
    const MIN = 0;
    const MAX = 4;
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState('tab1');

    const tabCount = Math.min(dormers?.noOfDoomer, MAX);

    const navItems = [];
    const tabPanes = [];

    for (let i = 1; i <= tabCount; i++) {
        const eventKey = `tab${i}`;
        navItems.push(
            <Nav.Item key={eventKey}>
                <Nav.Link eventKey={eventKey}>{t("dormer")} {i}</Nav.Link>
            </Nav.Item>
        );
        tabPanes.push(
            <Tab.Pane eventKey={eventKey} key={eventKey}>
                <RoofDormer {...{
                    width: dormers.dormers[eventKey].formValue.width,
                    height: dormers.dormers[eventKey].formValue.height,
                    ClosedSidesNo: dormers.dormers[eventKey].formValue.ClosedSidesNo,
                    onWidthChange: (value) => {onDormerChange(eventKey, 'width', parseFloat(value)); if (sync) onSyncAllDormers()},
                    onHeightChange: (value) => {onDormerChange(eventKey, 'height', parseFloat(value)); if (sync) onSyncAllDormers()},
                    onClosedSidesNoChange: (value) => {onDormerChange(eventKey, 'ClosedSidesNo', parseFloat(value)); if (sync) onSyncAllDormers()}
                    }}
                />
            </Tab.Pane>
        );
    }

    useEffect(() => {
        const currentTabIndex = parseInt(activeTab.replace('tab', ''), 10);
        if (tabCount > 0 && (currentTabIndex > tabCount || currentTabIndex === 0)) setActiveTab(`tab${tabCount}`);
    }, [tabCount, activeTab]);

    const handleCheckboxChange = (e) => {
        onSyncChange(e.target.checked);
        if (!e.target.checked) return
        onSyncAllDormers();
    };

    return <>
        <Row>
            <Col className="mb-3">
                <Form.Group className="d-flex flex-column justify-content-end h-100" controlId="width">
                    <Form.Label>{t("width")}</Form.Label>
                    <Form.Control type="number" value={width} onChange={(e) => onWidthChange(e.target.value)} />
                </Form.Group>
            </Col>
            <Col className="mb-3">
                <Form.Group className="d-flex flex-column justify-content-end h-100" controlId="kneestock">
                    <Form.Label>{t("kneestock")}</Form.Label>
                    <Form.Control type="number" value={kneestock} onChange={(e) => onKneestockChange(e.target.value)} />
                </Form.Group>
            </Col>
            <Col className="mb-3">
                <Form.Group className="d-flex flex-column justify-content-end h-100" controlId="diagonal">
                    <Form.Label>{t("diagonal")}</Form.Label>
                    <Form.Control type="number" value={diagonal} onChange={(e) => onDiagonalChange(e.target.value)} />
                </Form.Group>
            </Col>
        </Row>

        <Form.Group className="mb-3" controlId="noofdeductionareas">
            <Form.Label>{t("noofdeductionareas")}</Form.Label>
            <Form.Control type="number" value={noofdeductionareas} min={MIN} max={2} onChange={(e) => onNoofdeductionareasChange(e.target.value)} />
        </Form.Group>

        {noofdeductionareas !== 0 && (
            Array.from({ length: parseInt(noofdeductionareas) }, (_, i) => {
            return <Form.Group className="mb-3">
                <Form.Label>Deduction Area {i + 1}</Form.Label>
                <Form.Select value={i === 0 ? dedOne : dedTwo} onChange={(e) => onDeductionAreaChange(e.target.value, i === 0 ? 'dedOne' : 'dedTwo')} required>
                    {buildingParts?.map(bp => <option key={`bp-${bp.id}-${i}`} value={bp.id}>{bp.name}</option>)}
                </Form.Select>
            </Form.Group>
        }))}

        <Form.Group className="mb-3" controlId="dormerNo">
            <Form.Label>{t("dormerNo")}</Form.Label>
            <Form.Control type="number" value={dormers?.noOfDoomer} min={MIN} max={MAX} onChange={(e) => onNoOfDoomerChange(e.target.value)} />
        </Form.Group>

        <Form.Check
            className="mb-3"
            type="checkbox" 
            id="syncChanges"
            label={t("syncChanges")}
            checked={sync}
            onChange={handleCheckboxChange}
        />

        {dormers?.noOfDoomer > 0 && <Tab.Container key={`tab-${activeTab}`} defaultActiveKey={activeTab} onSelect={(k) => setActiveTab(k)} className="mb-3">
            <Nav variant="pills" justify>
                {navItems}
            </Nav>
            <Tab.Content>
                {tabPanes}
            </Tab.Content>
        </Tab.Container>}
    </>
}


export const RoofSlope = ({ heightOne, heightTwo, distance, dormers, sync, noofdeductionareas, onHeightOneChange, onHeightTwoChange, onDistanceChange, onNoOfDoomerChange, onDormerChange, onSyncChange, onSyncAllDormers, onNoofdeductionareasChange }) => {
    const MIN = 0;
    const MAX = 4;
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState('tab1');

    const tabCount = Math.min(dormers?.noOfDoomer, MAX);

    const navItems = [];
    const tabPanes = [];

    for (let i = 1; i <= tabCount; i++) {
        const eventKey = `tab${i}`;
        navItems.push(
            <Nav.Item key={eventKey}>
                <Nav.Link eventKey={eventKey}>{t("dormer")} {i}</Nav.Link>
            </Nav.Item>
        );
        tabPanes.push(
            <Tab.Pane eventKey={eventKey} key={eventKey}>
                <RoofDormer {...{
                    width: dormers.dormers[eventKey].formValue.width,
                    height: dormers.dormers[eventKey].formValue.height,
                    ClosedSidesNo: dormers.dormers[eventKey].formValue.ClosedSidesNo,
                    onWidthChange: (value) => {onDormerChange(eventKey, 'width', parseFloat(value)); if (sync) onSyncAllDormers()},
                    onHeightChange: (value) => {onDormerChange(eventKey, 'height', parseFloat(value)); if (sync) onSyncAllDormers()},
                    onClosedSidesNoChange: (value) => {onDormerChange(eventKey, 'ClosedSidesNo', parseFloat(value)); if (sync) onSyncAllDormers()}
                    }}
                />
            </Tab.Pane>
        );
    }

    useEffect(() => {
        const currentTabIndex = parseInt(activeTab.replace('tab', ''), 10);
        if (tabCount > 0 && (currentTabIndex > tabCount || currentTabIndex === 0)) setActiveTab(`tab${tabCount}`);
    }, [tabCount, activeTab]);

    const handleCheckboxChange = (e) => {
        onSyncChange(e.target.checked);
        if (!e.target.checked) return
        onSyncAllDormers();
    };

    return <>
        <Row>
            <Col className="mb-3">
                <Form.Group className="d-flex flex-column justify-content-end h-100" controlId="heightOne">
                    <Form.Label>{t("height")} 1</Form.Label>
                    <Form.Control type="number" value={heightOne} onChange={(e) => onHeightOneChange(e.target.value)} />
                </Form.Group>
            </Col>
            <Col className="mb-3">
                <Form.Group className="d-flex flex-column justify-content-end h-100" controlId="heightTwo">
                    <Form.Label>{t("height")} 2</Form.Label>
                    <Form.Control type="number" value={heightTwo} onChange={(e) => onHeightTwoChange(e.target.value)} />
                </Form.Group>
            </Col>
            <Col className="mb-3">
                <Form.Group className="d-flex flex-column justify-content-end h-100" controlId="distance">
                    <Form.Label>{t("distance")}</Form.Label>
                    <Form.Control type="number" value={distance} onChange={(e) => onDistanceChange(e.target.value)} />
                </Form.Group>
            </Col>
        </Row>

        <Form.Group className="mb-3" controlId="noofdeductionareas">
            <Form.Label>{t("noofdeductionareas")}</Form.Label>
            <Form.Control type="number" value={noofdeductionareas} min={MIN} max={2} onChange={(e) => onNoofdeductionareasChange(e.target.value)} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="dormerNo">
            <Form.Label>{t("dormerNo")}</Form.Label>
            <Form.Control type="number" value={dormers?.noOfDoomer} min={MIN} max={MAX} onChange={(e) => onNoOfDoomerChange(e.target.value)} />
        </Form.Group>

        <Form.Check
            className="mb-3"
            type="checkbox" 
            id="syncChanges"
            label={t("syncChanges")}
            checked={sync}
            onChange={handleCheckboxChange}
        />

        {dormers?.noOfDoomer > 0 && <Tab.Container key={`tab-${activeTab}`} defaultActiveKey={activeTab} onSelect={(k) => setActiveTab(k)} className="mb-3">
            <Nav variant="pills" justify>
                {navItems}
            </Nav>
            <Tab.Content>
                {tabPanes}
            </Tab.Content>
        </Tab.Container>}
    </>
}