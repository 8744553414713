import React from "react"
import { Placeholder } from 'react-bootstrap'

export const DivPlaceholder = () => {

    return <Placeholder as="div" animation="glow">
        <Placeholder xs={12} />
    </Placeholder>
}

export default DivPlaceholder;