import { useState, useEffect, useCallback, useRef } from "react"
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ContentModal from "../components/ContentModal";
import ESProductsWizard from "./ESProductsWizard";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import { Button, Placeholder, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faFileSignature, faList, faUniversalAccess, faCheck } from "@fortawesome/free-solid-svg-icons";
import PaymentMethod from "./PaymentMethod";
import Collapse from "../components/Collapse";
import InvoicesTable from "./InvoicesTable";
import { timeReadableFormat } from "../util/helpers";
import { useSwagger } from "../context/SwaggerContext";

const Contract = ({ contract }) => {
    const iframeRef = useRef(null);

    useEffect(() => {
        if (contract === "show") return
        iframeRef.current.src = contract?.docsign_url;
    }, [contract, iframeRef])

    return <>
        {contract === "show" && <Placeholder as={"div"} animation="glow">
            <Placeholder style={{ width: '100%', height: '500px' }} />
        </Placeholder>}

        {contract !== "show" && <iframe ref={iframeRef} style={{ width: '100%', height: '500px' }} />}
    </>
}

export const FrameContracts = ({ _orgGuid }) => {
    const { login } = useAuth();
    const { addToast } = useToast();
    const { t } = useTranslation();

    const [contracts, setContracts] = useState(undefined);
    const [contract, setContract] = useState(undefined);

    const client = useSwagger();

    const contractsTableStructure = [
        {
            col: '#',
            type: 'label'
        }, {
            col: "Product",
            type: 'label'
        }, {
            col: t("createdAt"),
            type: 'label'
        }, {
            col: t("updatedAt"),
            type: 'label'
        }, {
            col: t("sign"),
            type: 'buttons'
        }
    ]

    const getFrameContracts = useCallback(async () => {
    //     fetch(`https://tech.sigmaheat.de/org/${_orgGuid}/offers/`, {
    //         headers: {
    //             'Authorization': login.Authorization,
    //             "Content-Type": "application/json"
    //         },
    //         method: "GET",
    //     })
    //     .then((response) => {
    //         if (!response.ok) throw new Error(t("responseNetwork"))
    //         return response.json();
    //     })
    //     .then((content) => setContracts(content))
    //     .catch((error) => {
    //         addToast(t("error"), error.message, "error")
    //     });
    // }, [login.Authorization])

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["org"].org_offers_retrieve({org_uuid: _orgGuid});

            if (response.status >= 200 && response.status < 300) {
                setContracts(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseNetwork"), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization])


    const signFrameContracts = useCallback(async (contact_id) => {
        setContract("show")
    //     fetch(`https://tech.sigmaheat.de/org/${_orgGuid}/sign/${contact_id}/`, {
    //         headers: {
    //             'Authorization': login.Authorization,
    //             "Content-Type": "application/json"
    //         },
    //         method: "GET"
    //     })
    //     .then((response) => {
    //         if (response.status === 429) throw new Error(JSON.stringify({ title: t("httpStatus.fourtwonine.title"), message: t("httpStatus.fourtwonine.message") }))
    //         if (!response.ok) throw new Error(JSON.stringify({ title: t("error"), message: t("responseError") }))
    //         return response.json();
    //     })
    //     .then((content) => setContract(content))
    //     .catch((error) => {
    //         const parsedError = JSON.parse(error.message);
    //         addToast(parsedError.title, parsedError.message, "error")
    //     });
    // }, [login.Authorization])

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["org"].org_sign_retrieve({org_uuid: _orgGuid, frc_id: contact_id});

            if (response.status >= 200 && response.status < 300) {
                setContract(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;
            if (statusCode === 429) {
                addToast(t("httpStatus.fourtwonine.title"), t("httpStatus.fourtwonine.message"), "error");
            } else {
                addToast(t("error"), t("responseError"), "error");
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization])


    const checkFrameContracts = useCallback(async(contact_id) => {

    //     fetch(`https://tech.sigmaheat.de/org/${_orgGuid}/user/${login.id}/sign/${contact_id}/check`, {
    //         headers: {
    //             'Authorization': login.Authorization,
    //             "Content-Type": "application/json"
    //         },
    //         method: "GET"
    //     })
    //     .then((response) => {
    //         if (!response.ok) throw new Error(JSON.stringify({ title: t("error"), message: t("responseError") }))
    //         if (response.status === 204) addToast(t("success"), "Contract is not signed", "success")
    //         if (response.status === 200) addToast(t("success"), "Contract is signed", "success")
    //     })
    //     .catch((error) => {
    //         const parsedError = JSON.parse(error.message);
    //         addToast(parsedError.title, parsedError.message, "error")
    //     });
    // }, [login.Authorization])

    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
        client.requestInterceptor = (req) => {
            req.headers["Content-Type"] = "application/json";
            req.headers["Authorization"] = login.Authorization;
            return req;
        };

        const response = await client.apis["org"].org_sign_retrieve({org_uuid: _orgGuid, frc_id: contact_id});

        if (response.status === 204) addToast(t("success"), "Contract is not signed", "success")
        if (response.status === 200) addToast(t("success"), "Contract is signed", "success")

        client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
        addToast(t("error"), t("responseError"), "error");

        client.http.requestInterceptor = originalRequestInterceptor;
    }
}, [client, login.Authorization]);


    useEffect(() => {
        getFrameContracts()
    }, [])

    return <>
        {(contracts === undefined) ? (
            <TablePlaceholder {...{structure: contractsTableStructure}} />
        ) : (!Boolean(contracts?.length)) ? (
            <p className="m-0">{t("noFrameContracts")}</p>
        ) : (
            <Table responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{t("product")}</th>
                        <th>{t("createdAt")}</th>
                        <th>{t("updatedAt")}</th>
                        <th>{t("sign")}</th>
                        <th>{t("check")}</th>
                    </tr>
                </thead>
                <tbody>
                    {contracts?.map((row, i) => (
                        <tr key={`contract-id-${row.product}`}>
                            <td>{i + 1}</td>
                            <td>{row.product_name}</td>
                            <td>{timeReadableFormat(row.created_at)}</td>
                            <td>{timeReadableFormat(row.updated_at)}</td>
                            <td>
                                <Button variant="outline-primary" className="p-10" onClick={() => signFrameContracts(row.id)} disabled={row.signed}>
                                    <FontAwesomeIcon icon={faFileSignature} />
                                </Button>
                            </td>
                            <td>
                                <Button variant="outline-primary" className="p-10" onClick={() => checkFrameContracts(row.id)} disabled={row.signed}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )}
        <ContentModal {...{ show: contract !== undefined, onHide: () => setContract(undefined), title: t("signFrameContract"), topMost: true, size: 'lg', content: <Contract {...{ contract }} /> }} />
    </>
}

export const ESTableRows = ({ row, setSelectedBuilding, setShw, orgGuid }) => {
    const [display, setDisplay] = useState(false)

    return <>
        <tr>
            <td>
                <Collapse {...{ boolean: display, onClick: () => setDisplay(!display) }} />
            </td>
            <td>{row.building_name}</td>
            <td>{row.building_address}</td>
            <td>{row.product_title}</td>
            <td>{row.status}</td>
            <td>{timeReadableFormat(row.active_until)}</td>
            <td>
                <Button variant="outline-primary" className="p-10" onClick={() => {setSelectedBuilding(row); setShw("paymentMethod")}}>
                    <FontAwesomeIcon icon={faCreditCard} />
                </Button>
            </td>
        </tr>
        {display && <tr>
            <td colSpan='100%'>
                <InvoicesTable {...{ stripe_subscription_id: row.stripe_subscription_id, orgGuid }}/>
            </td>
        </tr>}
    </>
}

export const CompanyESProducts = ({ shw, setShw }) => {
    const _orgGuid = useParams()['orgGuid'];
    const { login } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();
    const [buildingswProduct, setBuildingswProduct] = useState(undefined)
    const [selectedBuilding, setSelectedBuilding] = useState(undefined)
    const [wizard, setWizard] = useState({
        page: 0,
        type: ""
    })

    const client = useSwagger();

    const buildingsTableStructure = [
        {
            col: t("buildingName"),
            type: 'label'
        }, {
            col: t("buildingAddress"),
            type: 'label'
        }, {
            col: t("product"),
            type: 'label'
        }, {
            col: t("status"),
            type: 'label'
        }, {
            col: t("activeTill"),
            type: 'label'
        }
    ]

    const getBuildingsWithProducts = useCallback(async () => {
    //     fetch(`https://tech.sigmaheat.de/org/${_orgGuid}/getbuildingswproduct`, {
    //         headers: {
    //             'Authorization': login.Authorization,
    //             "Content-Type": "application/json"
    //         }
    //     })
    //     .then((response) => {
    //         if (!response.ok) throw new Error(t("responseNetwork"))
    //         if (response.status === 204) return [];
    //         return response.json();
    //     })
    //     .then((content) => {
    //         setBuildingswProduct(content)})
    //     .catch((error) => {
    //         addToast(t("error"), error.message, "error")
    //     });
    // }, [login.Authorization])

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["org"].getBuildingProduct({org_uuid: _orgGuid});

            if (response.status === 204) {
                setBuildingswProduct([])
                return
            }


            if (response.status >= 200 && response.status < 300) {
                setBuildingswProduct(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.error(error);
            addToast(t("error"), t("responseNetwork"), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization])

    const wizardTitle = useCallback(() => {
        if (wizard.page === 0) return t("availableESProducts")
        if (wizard.page === 1) return t("selectBuildingsForES")
        if (wizard.page === 2) return t("paymentSummary")
        if (wizard.page === 3) return t("assignPaymentMethods")
        if (wizard.page === 4) return t("frameContract")
    }, [wizard])

    useEffect(() => {
        getBuildingsWithProducts()
    }, [])

    return <>
        {(buildingswProduct === undefined) ? (
            <TablePlaceholder {...{structure: buildingsTableStructure}} />
        ) : (!Boolean(buildingswProduct?.length)) ? (
            <p className="m-0">{t("noESProductAssigned")}</p>
        ) : (
            <Table responsive>
                <thead>
                    <tr>
                        <th>
                            <div className='d-flex' style={{ width: '16px', height: '16px' }}>
                                <FontAwesomeIcon icon={faList} size='sm' />
                            </div>
                        </th>
                        <th>{t("buildingName")}</th>
                        <th>{t("buildingAddress")}</th>
                        <th>{t("product")}</th>
                        <th>{t("status")}</th>
                        <th>{t("activeTill")}</th>
                        <th>{t("changePaymentMethod")}</th>
                    </tr>
                </thead>
                <tbody>
                    {buildingswProduct?.map(row => (<ESTableRows key={`es-product-key-${row.id}`} {...{ row, setSelectedBuilding, setShw }} />))}
                </tbody>
            </Table>
        )}

        <ContentModal {...{ show: shw === "esPackages", onHide: () => { setShw(false); setWizard({ page: 0, type: "" }) }, title: wizardTitle(), content: <ESProductsWizard {...{ wizard, setWizard, onClose: () => { setShw(false); setWizard({ page: 0, type: "" }) }, getBuildingsWithProducts }} />, size: "xl" }} />
        <ContentModal {...{ show: shw === "paymentMethod", onHide: () => { setShw(false); setSelectedBuilding(undefined) }, title: t("changePaymentMethod"), content: <PaymentMethod {...{ selectedBuilding, onHide: () => {setShw(false); setSelectedBuilding(undefined)} }} />, footer: false }} />
        <ContentModal {...{ show: shw === "frameContracts", onHide: () => setShw(false), title: t("frameContracts"), content: <FrameContracts {...{ _orgGuid }} />, footer: false, size: 'lg' }} />
    </>
}

export default CompanyESProducts;