// import generator from "generate-password";
import { useState } from "react";
import { useSwagger } from "../context/SwaggerContext"
import localStorageVersion from "./constants/LocalStorageVersion";

export function generatePassword() {
  return null;
  /* return generator.generate({
        length: 15,
        lowercase: true,
        uppercase: true,
        numbers: true,
        symbols: false
    });
    */
}

const style = getComputedStyle(document.body);
export const chartColors = (() => {
  let colors = [
    style.getPropertyValue("--bs-primary").trim(),
    style.getPropertyValue("--bs-secondary").trim(),
    style.getPropertyValue("--bs-tertiary").trim(),
  ];

  colors.forEach((value, index) => {
    if (value.includes("#")) {
      function hexToRgbA(hex) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
          c = hex.substring(1).split("");
          if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
          }
          c = "0x" + c.join("");
          return (
            "rgba(" +
            [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
            ",1)"
          );
        }
        throw new Error("Bad Hex");
      }

      colors[index] = hexToRgbA(value);
    } else if (value.includes("rgb") && !value.includes("rgba")) {
      colors[index] = value.replace("rgb", "rgba");
      colors[index] = colors[index]
        .slice(0, colors[index].length - 1)
        .concat(", 1)");
    }
  });

  return colors;
})();

export function getBreakpoint() {
  for (let item of document.getElementById("bootstrap-detect-breakpoint")
    .children) {
    if (getComputedStyle(item, null).display === "block")
      return item.getAttribute("bp");
  }
}

export function addressToString(address) {
  let string = `${address.street && address.street + ", "}${
    address.zip && address.zip
  } ${address.town && address.town}`;
  return string;
}

export function numberToString(x) {
  if (Number.isNaN(Number.parseFloat(x))) return x;
  return Number.parseFloat(x).toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
}

export function quantityToUnit(quantity) {
  switch (quantity) {
    case "temperature":
      return "°C";
    case "energy":
      return "kWh";
    case "power":
      return "kW";
    case "time":
      return "h";
    case "flow":
      return "m³/h";
    case "volume":
      return "m³";
    case "voltage":
      return "V";
    case "current":
      return "A";
    default:
      return "";
  }
}

export function quantityName(quantity) {
  switch (quantity) {
    case "temperature":
      return "Temperatur [℃]";
    case "energy":
      return "Energie [kWh]";
    case "power":
      return "Leistung [kW]";
    case "time":
      return "Zeit [h]";
    case "flow":
      return "Fluss [m³/h]";
    case "volume":
      return "Volumen [m³]";
    case "voltage":
      return "Elektrische Spannung [V]";
    case "current":
      return "Stromstärke [A]";
    case "dimensionless":
      return "Dimensionslos";
    case "other":
      return "Andere";
    default:
      return "";
  }
}

export function utcToLocal(date) {
  return new Date(
    Date.parse(date) - new Date(date).getTimezoneOffset() * 60000
  );
}

export function localToUTC(date) {
  return new Date(
    Date.parse(date) + new Date(date).getTimezoneOffset() * 60000
  );
}

export function dateToString(date) {
  //TODO this should be done with proper formatting strings perhaps
  return date.toLocaleString().replace(",", "");
}

export function dateToDateString(date) {
  return date.getDate() + "." + date.getMonth() + "." + date.getFullYear();
}

export function exportMeasurementsToCSVDownload(jsonData) {
  let dataStr = ConvertToCSV(jsonData);
  exportToCSVDownload("data", dataStr);
}

export function CSVtoJSONConverter(rawString) {
  let arr = rawString.split("\n");
  var jsonObj = [];
  var headers = arr[0].split(";");
  for (var i = 1; i < arr.length; i++) {
    var data = arr[i].split(";");
    var obj = {};
    for (var j = 0; j < data.length; j++) {
      obj[headers[j].trim()] = data[j].trim();
    }
    if (!(data.length === 1 && data[0].length === 0)) jsonObj.push(obj);
  }
  return jsonObj;
}

export function exportToCSVDownload(name = "", dataStr = "") {
  let dataUri =
    "data:application/csv;charset=utf-8," + encodeURIComponent(dataStr);
  let exportFileDefaultName = name + ".csv";
  let linkElement = document.createElement("a");
  linkElement.setAttribute("href", dataUri);
  linkElement.setAttribute("download", exportFileDefaultName);
  linkElement.click();
}

export function clamp(val, min, max) {
  return val > max ? max : val < min ? min : val;
}

// rotates x,y around a center cx, cy
export function rotatePoint(cx, cy, x, y, angle) {
  var radians = (Math.PI / 180) * angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = cos * (x - cx) - sin * (y - cy) + cx,
    ny = cos * (y - cy) + sin * (x - cx) + cy;
  return [nx, ny];
}

function ConvertToCSV(objArray) {
  var seperator = ";";
  var str_timestamp = "t";
  var str_value = "avg";

  var data = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";

  //Header
  var line = "Timestamp";
  var timestamps = {};

  for (var k = 0; k < data.length; k++) {
    var index = Object.keys(data)[k];
    if (line !== "") line += seperator;
    line += data[index]["measurement"]["name"];
    var entries = data[index]["entries"];

    for (var i = 0; i < entries.length; i++) {
      if (timestamps[entries[i][str_timestamp]] == null) {
        timestamps[entries[i][str_timestamp]] = generateEmptyArray(k);
      }
      timestamps[entries[i][str_timestamp]].push(entries[i][str_value]);
    }
    timestamps = fillNulls(timestamps, k);
  }
  str += line + "\r\n";
  for (const [key, val] of Object.entries(timestamps)) {
    var dat = new Date(
      Date.parse(key) - new Date().getTimezoneOffset() * 60000
    );
    line +=
      dat.getFullYear() +
      "/" +
      String(dat.getMonth() + 1) +
      "/" +
      dat.getDate() +
      " " +
      String(dat.getHours()).padStart(2, "0") +
      ":" +
      String(dat.getMinutes()).padStart(2, "0") +
      ":" +
      String(dat.getSeconds()).padStart(2, "0") +
      seperator;
    for (var ele in val)
      if (val[ele] != null) {
        line += new Intl.NumberFormat("de-DE").format(val[ele]) + seperator;
      } else {
        line += "" + seperator;
      }
    str += line + "\r\n";
  }
  return str;
}

function generateEmptyArray(count) {
  var r = [];
  for (var j = 0; j < count; j++) {
    r.push(null);
  }
  return r;
}

function fillNulls(timestamps, len) {
  // eslint-disable-next-line no-unused-vars
  for (const [_, val] of Object.entries(timestamps)) {
    if (val.length !== len + 1) val.push(null);
  }
  return timestamps;
}

export const useForceUpdate = () => {
  // eslint-disable-next-line no-unused-vars
  const [x, set] = useState(0);
  return () => set((x) => x + 1);
};

export function chartTimeUnit(interval) {
  let timeUnit = "hour";
  let count = 1;
  if (interval === "minutely" || interval === "quarterhourly") {
    timeUnit = "minute";
    if (interval === "quarterhourly") count = 15;
  } else if (interval === "daily") {
    timeUnit = "day";
  } else if (interval === "yearly") {
    timeUnit = "year";
  }
  return { timeUnit: timeUnit, count: count };
}

export function isUUID(uuid) {
  let s = "" + uuid;

  s = s.match(
    "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
  );
  return s !== null;
}
// eslint-disable-next-line no-unused-vars
// const checkTokenValidation = (token) => {
//   fetch("https://tech.sigmaheat.de/login", {
//     headers: {
//       "Content-Type": "application/json",
//     },
//     method: "POST",
//     body: JSON.stringify(token),
//   })
//     .then((response) => {
//       if (response.status === 401 || response.status === 404) {
//         return false;
//       }
//       return response.json();
//     })
//     .then((data) => {
//       console.log(data);
//       return true;
//     })
//     .catch((error) => {
//       setTimeout(() => console.clear());
//       return false;
//     });
// };

//
const checkAuth = async (login, client) => {
  console.log("login: ", login);
  // const response = await fetch("http://localhost:8000/checkauth/", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: login?.Authorization,
  //   },
  // });

  // return !response.ok ? false : true
    if (!client) return;
    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["checkauth"].checkauth_retrieve();
      return !response.ok ? false : true
    } catch {
       return false
    } finally {
      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }

/**
 * Check if a profile json string (returned by GET or POST v2/login) is valid.
 * If invalid, return null. If valid, return the object.
 * @param profile
 * @returns {null|{validUntil}|{token}|any}
 */
// export function checkProfile(profile) {
//   console.log("checkAuth:", checkAuth(profile))
//   if (!profile) return null;
//   if (profile["TwoFA"]) return profile;
//   try {
//     if (!isUUID(profile.id) || !profile.Authorization || !profile.valid_until)
//       return null;
//     if (Date.parse(profile.valid_until) < Date.parse(new Date())) return null;
//     // if (!checkTokenValidation(profile.Authorization)) return null;
//     if (!checkAuth(profile)) return null;
//     return profile;
//   } catch (e) {
//     console.error("Could not parse", profile);
//     return null;
//   }
// }

// export async function checkProfile(profile) {
//   if (!profile) return null;

//   if (profile["TwoFA"]) return profile;

//   try {
//     if (!isUUID(profile.id) || !profile.Authorization || !profile.valid_until) return null;

//     console.log("AUTHHHH");

//     if (Date.parse(profile.valid_until) < Date.now()) return null;
//     const authResult = await checkAuth(profile);
//     console.log("AUTHHHH2", authResult);
//     if (!authResult) return null;
//     return profile;
//   } catch (e) {
//     console.error("Could not parse", profile, e);
//     return null;
//   }
// }

export async function checkProfile(profile, client) {
  if (!profile) return null;

  if (profile["TwoFA"]) return profile;
  if (profile["accepted"] === false) return profile;

  try {
    if (!isUUID(profile.id) || !profile.Authorization || !profile.valid_until) return null;

    if (Date.parse(profile.valid_until) < Date.now()) return null;
    const authResult = await checkAuth(profile, client);
    if (!authResult) return null;
    return profile;
  } catch (e) {
    console.error("Could not parse", profile, e);
    return null;
  }
}

/**
 * If Local Storage contains data from the current app version, do nothing.
 * Otherwise, delete all data in Local Storage.
 */
export function verifyLocalStorage() {
  const APP_VERSION = localStorageVersion;
  if (localStorage.APP_VERSION !== APP_VERSION) {
    localStorage.clear();
    localStorage.setItem("APP_VERSION", APP_VERSION);
  }
}

// export function getSavedProfile() {
//   verifyLocalStorage();
//   const str_json = localStorage.getItem("Profile");
//   let profile;
//   try {
//     profile = checkProfile(JSON.parse(str_json));
//   } catch (e) {
//     console.error("Error parsing Profile", str_json);
//     profile = null;
//   }

//   //If exported value is null, whatever is in Profile is invalid, so it must be deleted.
//   //Note that overwriting null with null is harmless.
//   console.log("localStorage[Profile]=", profile);
//   if (!profile) localStorage.setItem("Profile", null);
//   return profile;
// }

// export async function getSavedProfile() {
//   verifyLocalStorage();
//   const str_json = localStorage.getItem("Profile");
//   let profile = false;

//   try {
//     // Parse the JSON string from localStorage
//     const parsedProfile = JSON.parse(str_json);
//     // Wait for the checkProfile function to resolve if it's asynchronous
//     profile = await checkProfile(parsedProfile);
//   } catch (e) {
//     console.error("Error parsing Profile", str_json, e);
//     profile = null;
//   }

//   // Log the value of profile to see if it's valid
//   // console.log("localStorage[Profile]=", profile);

//   // If the profile is null, the data is invalid, so reset it in localStorage
//   if (!profile) localStorage.setItem("Profile", null);

//   // Return the validated or nullified profile
//   return profile;
// }

export async function getSavedProfile(client) {
  verifyLocalStorage();
  const str_json = localStorage.getItem("Profile");
  let profile = false;

  try {
    // Parse the JSON string from localStorage
    const parsedProfile = JSON.parse(str_json);
    // Wait for the checkProfile function to resolve if it's asynchronous
    profile = await checkProfile(parsedProfile, client);
  } catch (e) {
    console.error("Error parsing Profile", str_json, e);
    profile = null;
  }

  // Log the value of profile to see if it's valid
  // console.log("localStorage[Profile]=", profile);

  // If the profile is null, the data is invalid, so reset it in localStorage
  if (!profile) localStorage.setItem("Profile", null);

  // Return the validated or nullified profile
  return profile;
}

export const generateRandomId = (length) => {
  let id = "";
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  const randomLetterIndex = Math.floor(Math.random() * 52);
  id += characters.charAt(randomLetterIndex);

  for (let i = 1; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters.charAt(randomIndex);
  }

  return id;
};

export const generateDistinctColors = (numColors) => {
  const colors = [];
  const goldenRatio = 0.618033988749895;
  for (let i = 0; i < numColors; i++) {
    const hue = (i * goldenRatio) % 1;
    const saturation = 0.75;
    const lightness = 0.5;
    const color = hslToHex(hue, saturation, lightness);
    colors.push(color);
  }
  return colors;
};

const hslToHex = (h, s, l) => {
  const rgb = hslToRgb(h, s, l);
  const [r, g, b] = rgb;
  const toHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

const hslToRgb = (h, s, l) => {
  let r, g, b;

  if (s === 0) {
    r = g = b = l;
  } else {
    const hueToRgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hueToRgb(p, q, h + 1 / 3);
    g = hueToRgb(p, q, h);
    b = hueToRgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};

export const findNearestObject = (array, targetDate) => {
  var nearestObject = null;
  var nearestDifference = Infinity;

  for (var i = 0; i < array.length; i++) {
    var obj = array[i];
    var difference = Math.abs(obj.date - targetDate);

    if (difference < nearestDifference) {
      nearestDifference = difference;
      nearestObject = obj;
    }
  }

  return nearestObject;
};

export const getMinMax = (arrays) => {
  let overallMin = Infinity;
  let overallMax = -Infinity;

  for (const arr of arrays) {
    for (const obj of arr) {
      for (const key of Object.keys(obj)) {
        if (key !== "date") {
          const value = obj[key];
          overallMin = Math.min(overallMin, value);
          overallMax = Math.max(overallMax, value);
        }
      }
    }
  }

  return { min: overallMin, max: overallMax };
};


export const formatDateWithoutTime = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  
  return `${year}-${month}-${day}`;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

function findLineByLeastSquares(obj) {
  var values_x = [];
  var values_y = [];

  for (var i = 0; i < obj.length; i++) {
    values_x.push(obj[i].x);
    values_y.push(obj[i].y);
  }

  var sum_x = 0;
  var sum_y = 0;
  var sum_xy = 0;
  var sum_xx = 0;
  var count = 0;

  var x = 0;
  var y = 0;
  var values_length = values_x.length;

  if (values_length !== values_y.length) {
    throw new Error(
      "The parameters values_x and values_y need to have same size!"
    );
  }

  if (values_length === 0) {
    return [[], []];
  }

  for (var j = 0; j < values_length; j++) {
    x = values_x[j];
    y = values_y[j];
    sum_x += x;
    sum_y += y;
    sum_xx += x * x;
    sum_xy += x * y;
    count++;
  }

  var m = (count * sum_xy - sum_x * sum_y) / (count * sum_xx - sum_x * sum_x);
  var b = sum_y / count - (m * sum_x) / count;

  var result_values = [];
  // for -12
  result_values.push({
    x: -12,
    y: -12 * m + b,
  });
  for (var k = 0; k < values_length; k++) {
    x = values_x[k];
    y = x * m + b;
    result_values.push({
      x: x,
      y: y,
    });
  }

  var result_func = [];
  result_func.push({
    a: m,
    b: b,
  });

  return {
    data: result_values,
    func: result_func,
  };
}

export const calculateLLS = (plotMeasurementData) => {
  var xAxis = plotMeasurementData[0].data;
  var yAxis = plotMeasurementData[1].data;
  if (xAxis == null || yAxis == null || xAxis.length <= 1 || yAxis.length <= 1)
    return {
      data: [],
      line: {
        data: [],
        func: [],
      },
      axes: [plotMeasurementData[0].name, plotMeasurementData[1].name],
    };

  const xAxisFiltered = xAxis.filter((elem) =>
    yAxis.some((d) => elem.date === d.date)
  );
  const yAxisFiltered = yAxis.filter((elem) =>
    xAxisFiltered.some((d) => elem.date === d.date)
  );

  if (xAxisFiltered === null || yAxisFiltered === null)
    return {
      data: [],
      line: {
        data: [],
        func: [],
      },
      axes: [plotMeasurementData[0].name, plotMeasurementData[1].name],
    };

  let xKey = Object.keys(xAxis[0]);
  let yKey = Object.keys(yAxis[0]);

  let lls = [];
  for (let i = 0; i < xAxisFiltered.length; i++) {
    lls.push({
      x: xAxisFiltered[i][xKey[1]],
      y: yAxisFiltered[i][yKey[1]],
    });
  }

  // lls Array sortieren
  lls.sort((a, b) => (a.x > b.x ? 1 : b.x > a.x ? -1 : 0));

  let line = [];
  line = findLineByLeastSquares(lls);
  return {
    data: lls,
    line: line,
    axes: [plotMeasurementData[0].name, plotMeasurementData[1].name],
  };
};

export const extractInitials = (fullName) => {
  const names = fullName.split(" ");
  const firstNameInitial = names[0][0];
  let lastNameInitial = "";
  if (names.length > 1) lastNameInitial = names[names.length - 1][0];
  return (firstNameInitial === undefined ? " " : firstNameInitial?.toUpperCase()) + (lastNameInitial === undefined ? " " : lastNameInitial?.toUpperCase());
};

export const currencyToSymbol = (currency) => {
  if (currency === "eur") return "€";
  if (currency === "usd") return "$";
};

const padZero = (str, len) => {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
};

export const specificColorGenerator = (fullname, bg) => {
  const FN = fullname.toUpperCase();

  let hash = 0;
  for (let i = 0; i < FN.length; i++) {
    const charCode = FN.charCodeAt(i);
    hash = (hash << 5) - hash + charCode;
    hash |= 0;
  }

  const positiveHash = Math.abs(hash);
  const hexColor =
    "#" +
    (positiveHash & 0x00ffffff).toString(16).toUpperCase().padStart(6, "0");

  let hex = hexColor;

  if (hex.indexOf("#") === 0) hex = hex.slice(1);

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) throw new Error("Invalid HEX color.");
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);

  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);

  return bg ? hexColor : "#" + padZero(r) + padZero(g) + padZero(b);
};

export const timeReadableFormat = (time) => {
  const timestamp = new Date(time);
  const readableTime = timestamp.toLocaleString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  return readableTime;
};

export const getCookie = (cookieName) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(cookieName + "=")) return cookie;
  }
  return null;
};

export const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2);
};

export const arrayObjectsToCSV = (arrayOfArrays) => {
  let csvContent = "";
  const headers = Object.keys(arrayOfArrays[0][0]);
  csvContent += headers.join(",") + "\n";

  arrayOfArrays.forEach((subArray) => {
    subArray.forEach((obj) => {
      const row = headers
        .map((header) => {
          const value = `${obj[header]}`.replace(/"/g, '""');
          return `"${value}"`;
        })
        .join(",");
      csvContent += row + "\n";
    });
  });

  return csvContent;
};

export const downloadCSV = (csvContent, filename = "data.csv") => {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


export const deleteCookie = (name, path, domain) => {
  if (path === undefined) path = '/';
  if (domain === undefined) domain = window.location.hostname;
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=' + path + '; domain=' + domain + ';';
}

// export const setCookie = (name, value, days, domain) => {
//   let expires = "";
//   if (days) {
//     const date = new Date();

//     // berechnet das Ablaufdatum des Cookies
//     // date.getTime() gibt die aktuelle Zeit
//     // Werte werden addiert, um das zukünftige Ablaufdatum zu berechnen
//     date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

//     // wandelt das Date-Objekt in eine UTC-String-Darstellung um und speichert es in der expires-Variable im Format, das für Cookies erforderlich ist
//     expires = "; expires=" + date.toUTCString();
//   }
//   const cookieString = name + "=" + (value || "") + expires + "; path=/; domain=" + domain;
//   // const cookieString = `${name}=${value || ""}${expires}; path=/`;
//   document.cookie = cookieString;
// }

export const setCookie = (name, values, days, domain) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }

  const cookieValue = JSON.stringify(values);
  const cookieString = name + "=" + encodeURIComponent(cookieValue) + expires + "; path=/; domain=" + domain;
  document.cookie = cookieString;
}


export const validateIBAN = (value) => {
  if (!value.startsWith('DE')) {
    return false;
  }

  const rearrangedIban = value.slice(4) + value.slice(0, 4);

  let numericIban = '';
  for (let char of rearrangedIban) {
    if (isNaN(char)) {
      numericIban += (char.charCodeAt(0) - 55).toString();
    } else {
      numericIban += char;
    }
  }

  let remainder = 0;
  for (let i = 0; i < numericIban.length; i += 9) {
    const part = numericIban.slice(i, i + 9);
    remainder = parseInt(remainder + part, 10) % 97;
  }

  return remainder === 1;
}