import React from 'react'
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const InfoLevel = ({ onClose, floor, ...props }) => {
    const { t } = useTranslation();

    const excludedKeys = ['id', 'building'];

    const floorEntries = floor ? Object.entries(floor).filter(([key, value]) => !excludedKeys.includes(key)) : [];

    const getDisplayKey = (key) => {
        switch (key) {
            case 'floor':
                return t('level');
            case 'wide':
                return t('width') + ' (m)';
            case 'length':
                return t('length') + ' (m)';
            case 'area':
                return t('area') + ' (m²)';
            case 'room_temperature':
                return t('room_temperature') + ' (°C)';
            case 'avg_room_temperature':
                return t('avg') + ' ' + t('room_temperature') + ' (°C)';
            case 'avg_air_exchange_rate':
                return t('avg') + ' ' + t('airExchangeRate') + ' (1/h)';
            case 'transmission_losses':
                return t('transmissionLoss') + ' (W)';
            case 'ventilation_losses':
                return t('din276Main.long.ventilationLoss') + ' (W)';
            case 'thermal_power':
                return t('thermalPower') + ' (kW)';
            case 'heating_load':
                return t('heatingLoad') + ' (kW)';
            case 'created_at':
                return 'created';
            case 'updated_at':
                return 'lastUpdate';
            case 'utilization_unit':
                return 'unit';
            case 'calculated_area':
                return 'calculatedArea';
            default:
                return key;
        }
    };

    const renderValue = (key, value) => {
        if (value === null || value === undefined) {
            return t('noValues');
        }

        if (typeof value === 'number') {
            value = parseFloat(value.toFixed(2));
        }

        if (key === 'thermal_power') {
            if (value) {
                const newValue = value / 1000;
                return newValue; 
            } else {
                return value
            }
        }

        if (key === 'updated_at' || key === 'created_at') {
            const date = new Date(value);
            return date.toLocaleString(); 
        }

        return value;
    };

    console.log("floor", floor)
    return (
        <div>
            <Form>
                {floorEntries.map(([key, value]) => {
                    return (
                        <Form.Group className='mb-2' key={key}>
                            <Form.Label style={{ fontWeight: "bold" }}>{t(getDisplayKey(key, t))}</Form.Label>
                            <Form.Control type='text' readOnly disabled value={renderValue(key, value)} />
                        </Form.Group>
                    )
                })}
            </Form>
        </div>
    )
}

export default InfoLevel