import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../Context";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BuildingProfile from "../dashboard/Widgets/BuildingProfile";
import Events from "../dashboard/Widgets/Events";
import EnergySaving from "../dashboard/Widgets/EnergySaving";
import Consumption from "../dashboard/Widgets/Consumption";
import Climate from "../dashboard/Widgets/Climate";
import BuildingsMetrics from "../dashboard/Widgets/BuildingsMetrics";
import CO2 from "../dashboard/Widgets/CO2";
import Goal from "../dashboard/Widgets/Goal";
import Map from "../dashboard/Widgets/Map";
import { faMaximize, faHardDrive, faGear, faClipboard, faDoorClosed, faFileInvoice, faHotel, faHouse, faImage, faLockOpen, faNoteSticky, faPerson, faShoppingCart, faCarBattery, faRectangleList, faSitemap, faFile, faMoneyBill, faHandHoldingDollar, faToolbox } from "@fortawesome/free-solid-svg-icons";
import MainLayout from "../components/MainLayout";
import '../dashboard/style.css'
import Background from "../assets/background_3_3.png";
import { DIN276_main } from "./AddEditPlant"
import { useSwagger } from "../context/SwaggerContext";
import Academy from "../dashboard/Widgets/Academy";
import useBuildingHook from "../hooks/useBuildingHook";

export const GetSideBarArray = () => {
    // eslint-disable-next-line no-unused-vars
    const { login, _, reload } = useAuth();
    const _buildingGuid = useParams()['buildingGuid'];
    const [sidebarItems, setSidebarItems] = useState([]);
    const { t, i18n } = useTranslation();
    const din276_main = DIN276_main();
    const client = useSwagger();

    const loadSidebar = useCallback((plants) => {
        // let plantsItems = [
        //     ...plants.map(p => ({
        //         type: "Link",
        //         label: (p.din276_type !== 0) ? din276_main[p.din276_type].long : p.name,
        //         icon: din276_main[p.din276_type].icon,
        //         to: `/building/${_buildingGuid}/plant/${p.id}`
        //     }))
        // ];

        setSidebarItems([
            {
                type: 'Link',
                label: t("overview"),
                icon: faHouse,
                to: `/building/${_buildingGuid}/dashboard`
            },
            {
                type: 'Link',
                label: t("devices"),
                icon: faHardDrive,
                to: `/building/${_buildingGuid}/devices`
            },
            {
                type: 'Link',
                label: t("counters"),
                icon: faToolbox,
                to: `/building/${_buildingGuid}/counters`
            },
            {
                type: 'Link',
                label: t("energyFlow"),
                icon: faCarBattery,
                to: `/building/${_buildingGuid}/energyflow`
            },
            // {
            //     type: 'Link',
            //     label: t("energyReports"),
            //     icon: faNoteSticky,
            //     to: `/building/${_buildingGuid}/reports`
            // },
            {
                type: 'Link',
                label: t("buildingLogBook"),
                icon: faClipboard,
                to: `/building/${_buildingGuid}/log`
            },
            {
                type: 'Divider'
            },
            {
                type: 'Link',
                label: t("variants"),
                icon: faRectangleList,
                to: `/building/${_buildingGuid}/variants`
            },
            {
                type: 'Link',
                label: t("usageUnits"),
                icon: faDoorClosed,
                to: `/building/${_buildingGuid}/units`
            },
            {
                type: 'Link',
                label: t("buildingParts"),
                icon: faHotel,
                to: `/building/${_buildingGuid}/level`
            },
            {
                type: 'Link',
                label: t("heatparts"),
                icon: faSitemap,
                to: `/building/${_buildingGuid}/heatparts`
            },
            // {
            //     type: 'Collapsable',
            //     label: t("subsidies"),
            //     // icon: faMoneyBill,
            //     array: [
            //         {
            //             type: 'Link',
            //             label: `${t('subsidiesCatalog')}`,
            //             icon: faMoneyBill,
            //             to: `/building/${_buildingGuid}/subsidiescatalog`,
            //         }, {
            //             type: 'Link',
            //             label: t('requestedSubsidies'),
            //             icon: faHandHoldingDollar,
            //             to: `/building/${_buildingGuid}/actions`,
            //         },
            //     ]
            // },
            {
                type: 'Link',
                label: `${t('subsidiesCatalog')}`,
                icon: faMoneyBill,
                to: `/building/${_buildingGuid}/subsidiescatalog`,
            }, {
                type: 'Link',
                label: t('building_actions'),
                icon: faHandHoldingDollar,
                to: `/building/${_buildingGuid}/actions`,
            },
            {
                type: 'Divider'
            },
            // {
            //     type: 'Collapsable',
            //     label: t("plants"),
            //     array: plantsItems
            // },
            {
                type: 'Link',
                label: t("contactPerson"),
                icon: faPerson,
                to: `/building/${_buildingGuid}/contacts`
            },
            {
                type: 'Link',
                label: t("documents"),
                icon: faFile,
                to: `/building/${_buildingGuid}/documents`
            },
            {
                type: 'Link',
                label: t("gallery"),
                icon: faImage,
                to: `/building/${_buildingGuid}/pictures`
            },
            {
                type: 'Divider'
            },
            // {
            //     type: 'Link',
            //     label: t("release"),
            //     icon: faLockOpen,
            //     to: `/building/${_buildingGuid}/grants`
            // },
            // {
            //     type: 'Link',
            //     label: t("buyProducts"),
            //     icon: faShoppingCart,
            //     to: `/building/${_buildingGuid}/addons`
            // },
            // {
            //     type: 'Link',
            //     label: t("bills"),
            //     icon: faFileInvoice,
            //     to: `/building/${_buildingGuid}/invoices`
            // },
            {
                type: 'Link',
                label: t("settings"),
                icon: faGear,
                to: `/building/${_buildingGuid}/settings`
            },
        ])
    }, [setSidebarItems, _buildingGuid, t, din276_main]);

    // const loadPlants = useCallback(async() => {
    //     // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/plant/`, {
    //     //     headers: {
    //     //         'Authorization': login.Authorization,
    //     //         "Content-Type": "application/json"
    //     //     }
    //     // })
    //     //     .then((response) => {
    //     //         if (response.status === 204) return [];
    //     //         if (response.status >= 200 && response.status < 300) return response.json();
    //     //         return [];
    //     //     })
    //     //     .then((data) => {
    //     //         loadSidebar(data)
    //     //     })
    //     //     .catch(error => {
    //     //         console.log('Error:', error);
    //     //     });

    //     if (!client) return;

    //     const originalRequestInterceptor = client.http.requestInterceptor;

    //     try {
    //         client.requestInterceptor = (req) => {
    //             req.headers["Content-Type"] = "application/json";
    //             req.headers["Authorization"] = login.Authorization;
    //             return req;
    //         };

    //         const response = await client.apis["building"].getPlant({
    //             building_uuid: _buildingGuid,
    //         });

    //         if (response.status === 204) {
    //             loadSidebar([]);
    //             return;
    //         }

    //         if (response.status >= 200 && response.status < 300) {
    //             loadSidebar(response.obj);
    //         }

    //         client.http.requestInterceptor = originalRequestInterceptor;
    //     } catch (error) {
    //         console.log("Error:", error);
    //         client.http.requestInterceptor = originalRequestInterceptor;
    //     }
    // }, [client, _buildingGuid, login.Authorization, loadSidebar])

    useEffect(() => {
        // if (_buildingGuid) loadPlants();
        loadSidebar()
    }, [_buildingGuid, i18n.language, reload])

    return _buildingGuid ? sidebarItems : [];
}

export const BuildingDashboard = () => {
    const { t } = useTranslation();
    useBuildingHook()

    return <MainLayout {...{ background: Background }}>
        <div className="main__content sidebar__padding">
            <div className="grid__column">
                <BuildingProfile />
                {/* <Events {...{ icon: faMaximize, heading: 'Climate Target For 2030', descriptiton: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliau' }} /> */}
                {/* <Goal /> */}
            </div>

            <div className="grid__column">
                <Consumption />
                {/* <Climate {...{ direction: 45, color: '#decdef' }} /> */}
            </div>

            <div className="grid__column">
                <Map />
                {/* <CO2 /> */}
                {/* <EnergySaving /> */}
                {/* <BuildingsMetrics /> */}
            </div>
        </div>
    </MainLayout>
}

export default BuildingDashboard;