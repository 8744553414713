import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import Collapse from "../components/Collapse";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faList, faShoppingCart, faAdd, faFileContract } from "@fortawesome/free-solid-svg-icons";
import { useCallback } from "react";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import Card from "../components/Card";
import ContentModal from "../components/ContentModal";
import { ESTableRows } from "../companyProfile/CompanyESProducts";

import AddESProduct from "./AddESProduct";
import PaymentMethod from "../companyProfile/PaymentMethod";
import { FrameContracts } from "../companyProfile/CompanyESProducts";
import { useSwagger } from "../context/SwaggerContext";

export default function BuildingAddons() {
    const { t } = useTranslation()
    const _buildingGuid = useParams()['buildingGuid'];
    const { login } = useAuth();
    const client = useSwagger();
    const { addToast } = useToast()
    const [products, setProducts] = useState(undefined)
    const [building, setBuilding] = useState(undefined)
    const [building_has_products, setBuilding_has_Products] = useState(undefined);
    const [selectedBuilding, setSelectedBuilding] = useState(undefined)
    const [shw, setShw] = useState(false)
    const [wizard, setWizard] = useState({
        page: 0,
        type: ""
    })

    const buildingsTableStructure = [
        {
            col: t("buildingName"),
            type: 'label'
        }, {
            col: t("buildingAddress"),
            type: 'label'
        }, {
            col: t("product"),
            type: 'label'
        }, {
            col: t("status"),
            type: 'label'
        }, {
            col: t("activeTill"),
            type: 'label'
        }
    ]

    const mainFunctionsESSoftware = [
        { label: t('add'), onClick: () => setShw("esPackages"), key: 'addESSoftwarePackages', icon: faAdd },
        { label: t('frameContracts'), onClick: () => setShw("frameContracts"), key: 'frameContracts', icon: faFileContract }
    ]

    const wizardTitle = useCallback(() => {
        if (wizard.page === 0) return t("availableESProducts")
        if (wizard.page === 1) return t("paymentSummary")
        if (wizard.page === 2) return t("assignPaymentMethods")
        if (wizard.page === 3) return t("frameContract")
    }, [wizard])

    const fetchBuilding = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "GET"
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t("responseNetwork"))
        //     return response.json();
        // })
        // .then(data => setBuilding(data))
        // .catch((error) => {
        //     addToast(t("error"), error.message, "error")
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_retrieve({ building_uuid: _buildingGuid });

            if (response.status >= 200 && response.status < 300) {
                setBuilding(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseNetwork"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [_buildingGuid, addToast, client, login.Authorization, t]);

    const getBuildingsWithProducts = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/esproducts`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "GET"
        // })
        // .then((response) => {
        //     console.log(response)
        //     if (!response.ok) throw new Error(t("responseNetwork"))
        //     if (response.status === 204) return []
        //     return response.json();
        // })
        // .then(content => setBuilding_has_Products(content))
        // .catch((error) => {
        //     addToast(t("error"), error.message, "error")
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_esproducts_retrieve({ building_uuid: _buildingGuid });

            if (response.status === 204) {
                setBuilding_has_Products([]);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                setBuilding_has_Products(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseNetwork"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [_buildingGuid, addToast, client, login.Authorization, t]);

    useEffect(() => {
        fetchBuilding()
    }, [fetchBuilding])

    useEffect(() => {
        if (building === undefined) return
        getBuildingsWithProducts()
    }, [building, getBuildingsWithProducts])

    useEffect(() => {
        if (wizard.page === 1) getBuildingsWithProducts()
    }, [wizard])

    return <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">
            <Card {...{ heading: t("esProducts"), mainFunctions: mainFunctionsESSoftware }}>
                {(building_has_products === undefined) ? (
                    <TablePlaceholder {...{structure: buildingsTableStructure}} />
                ) : (!Boolean(building_has_products?.length)) ? (
                    <p className="m-0">{t("noESProductAssigned")}</p>
                ) : (
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    <div className='d-flex' style={{ width: '16px', height: '16px' }}>
                                        <FontAwesomeIcon icon={faList} size='sm' />
                                    </div>
                                </th>
                                <th>{t("buildingName")}</th>
                                <th>{t("buildingAddress")}</th>
                                <th>{t("product")}</th>
                                <th>{t("status")}</th>
                                <th>{t("activeTill")}</th>
                                <th>{t("changePaymentMethod")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {building_has_products?.map(row => (<ESTableRows key={`es-product-key-${row.id}`} {...{ row, setSelectedBuilding, setShw, orgGuid: building?.organisation }} />))}
                        </tbody>
                    </Table>
                )}
            </Card>

            <ContentModal {...{ show: shw === "esPackages", onHide: () => {setShw(false); setWizard({ page: 0, type: "" })}, title: wizardTitle(), content: <AddESProduct {...{ wizard, setWizard, onClose: () => {setShw(false); setWizard({ page: 0, type: "" })}, building, getBuildingsWithProducts: () => getBuildingsWithProducts() }} />, size: "xl" }} />
            <ContentModal {...{ show: shw === "paymentMethod", onHide: () => {setShw(false); setSelectedBuilding(undefined)}, title: t("changePaymentMethod"), content: <PaymentMethod {...{ selectedBuilding, onHide: () => {setShw(false); setSelectedBuilding(undefined)}, orgGuid: building?.organisation }} />, footer: false }} />
            <ContentModal {...{ show: shw === "frameContracts", onHide: () => {setShw(false); setSelectedBuilding(undefined)}, title: t("frameContracts"), content: <FrameContracts {...{ _orgGuid: building?.organisation }} />, footer: false, size: 'lg' }} />
        </div>
    </MainLayout>
}


const ProductTable = ({ products }) => {
    const { t } = useTranslation();
    const notCollapsable = false;

    const tableStructure = [
        {
            col: <FontAwesomeIcon icon={faList} size='sm' className='flex-shrink-0' />,
            type: 'icon'
        }, {
            col: t('title'),
            type: 'label'
        }, {
            col: t('benefits'),
            type: 'label'
        }, {
            col: t('yearlyPriceM2'),
            type: 'label'
        }, {
            col: t('contractPeriod'),
            type: 'label'
        }, {
            col: t('oncePrice'),
            type: 'label'
        }, {
            col: t('sum'),
            type: 'label'
        },  {
            col: t('infoPaper'),
            type: 'label'
        },  {
            col: t('buy'),
            type: 'label'
        }
    ]

    return <>
        <Card {...{ heading: t('addProductsBuilding') }}>
            {(products === undefined) ? (
                <TablePlaceholder {...{ structure: tableStructure }} />
            ) : (products.length === 0) ? (
                <p className="m-0">{t('noProducts')}</p>
            ) : (
                <Table responsive>
                    <thead>
                        <tr>
                            {!notCollapsable && <th>
                                <FontAwesomeIcon icon={faList} size='sm' />
                            </th>}
                            <th>
                                <span>{t("title")}</span>
                            </th>
                            <th style={{ minWidth: "150px" }}>
                                <span>{t("benefits")}</span>
                            </th>
                            <th>
                                <span>{t("yearlyPriceM2")}</span>
                            </th>
                            <th>
                                <span>{t("contractPeriod")}</span>
                            </th>
                            <th>
                                <span>{t("oncePrice")}</span>
                            </th>
                            <th>
                                <span>{t("sum")}</span>
                            </th>
                            <th>
                                <span>{t("infoPaper")}</span>
                            </th>
                            <th>
                                <span>{t("buy")}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product) => <ProductRow key={`product-${product.id}`} {...{ product }} />)}
                    </tbody>
                </Table>
            )}
        </Card>
    </>
}


export const ProductRow = ({ product }) => {
    const { login } = useAuth();
    const { t } = useTranslation();
    const _buildingGuid = useParams()["buildingGuid"];
    const [display, setDisplay] = useState(false);
    const client = useSwagger();
    // let m2 = 1000
    // let sum = product.once_price_eur + product.contract_min * product.yearly_price_per_m2_eur * m2

    let benefits = <ul></ul>

    try {
        benefits = <ul>{product.benefits.map(b => <li style={{ "list-style-type": "circle" }}>{b}</li>)}</ul>
    } catch (e) {
        console.log(e);
    }

    return (
        <>
            <tr>
                <td>
                    <Collapse boolean={display} onClick={() => setDisplay(!display)} />
                </td>
                <td>
                    <span>{product.title}</span>
                </td>
                <td>
                    <span>{benefits}</span>
                </td>
                <td>
                    <span>{product.yearly_price_per_m2_eur > 0 ? (product.yearly_price_per_m2_eur).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " €" : t/'none'}</span>
                </td>
                <td>
                    <span>{product.contract_min > 0 ? product.contract_min + ` ${t('years')}` : t('withoutTerm')}</span>
                </td>
                <td>
                    <span>{product.once_price_eur > 0 ? (product.once_price_eur).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " €" : t('none')}</span>
                </td>
                <td>
                    <span>{(product.building_sum).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " €"}</span>
                </td>
                <td>
                    {product.has_pdf && <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t("downloadBrochure")}</Tooltip>
                        }>
                        <Button variant='outline-primary' onClick={async() => {
                            if (!client) return;

                            const originalRequestInterceptor =
                              client.http.requestInterceptor;
          
                            try {
                                client.requestInterceptor = (req) => {
                                    req.headers["Content-Type"] = "application/json";
                                    req.headers["Authorization"] = login.Authorization;
                                    return req;
                                };
            
                                const response = await client.apis["building"].downloadInvoice({
                                    building_uuid: _buildingGuid,
                                    invoice_uuid: product.id,
                                });
            
                                if (response.status >= 200 && response.status < 300) {
                                    return undefined;
                                }
            
                                client.http.requestInterceptor = originalRequestInterceptor;
                            } catch (error) {
                                console.error(error);
                                client.http.requestInterceptor = originalRequestInterceptor;
                            }

                            // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/invoice/${product.id}`, {
                            //     headers: {
                            //         'Authorization': login.Authorization,
                            //         "Content-Type": "application/json"
                            //     }
                            // })
                            // .then((response) => undefined);

                        }}>
                            <FontAwesomeIcon icon={faDownload} />
                        </Button>
                    </OverlayTrigger>
                    }
                </td>
                <td>
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t("buy")}</Tooltip>
                        }>
                        <Button variant="outline-primary" onClick={async() => {

                            // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/invoice/${product.id}`, {
                            //     headers: {
                            //         'Authorization': login.Authorization,
                            //         "Content-Type": "application/json"
                            //     }
                            // })
                            // .then((response) => undefined);

                            if (!client) return;

                            const originalRequestInterceptor = client.http.requestInterceptor;

                            try {
                                client.requestInterceptor = (req) => {
                                    req.headers["Content-Type"] = "application/json";
                                    req.headers["Authorization"] = login.Authorization;
                                    return req;
                                };

                                const response = await client.apis["building"].downloadInvoice({
                                    building_uuid: _buildingGuid,
                                    invoice_uuid: product.id,
                                });

                                if (response.status >= 200 && response.status < 300) {
                                    return undefined;
                                }

                                client.http.requestInterceptor = originalRequestInterceptor;
                            } catch (error) {
                                console.error(error);
                                client.http.requestInterceptor = originalRequestInterceptor;
                            }
                        }}>
                            <FontAwesomeIcon icon={faShoppingCart} />
                        </Button>
                    </OverlayTrigger>
                </td>
            </tr>
        </>
    );
};

