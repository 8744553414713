import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../Context";
import Collapse from "../components/Collapse";
import { Button, Form, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faList, faEdit, faInfo, faTrash } from "@fortawesome/free-solid-svg-icons";
import ContentModal from "../components/ContentModal";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";

import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import Card from "../components/Card";
import { useToast } from "../NotificationsContent";
import { useSwagger } from "../context/SwaggerContext";
import { usePermissions } from "../context/PermissionsContext";
import EditAndMoreInfoContacts from "./EditAndMoreInfoContacts";
import SecurityActionModal from "../components/SecurityActionModal";
import useBuildingHook from "../hooks/useBuildingHook";

export default function BuildingContacts() {
    const _buildingGuid = useParams()['buildingGuid'];
    const { allowEdit, engineerRights } = usePermissions();

    const { addToast } = useToast();

    const { login } = useAuth();
    const { t } = useTranslation();
    const [contacts, setContacts] = useState(undefined);
    const [show, setShow] = useState(false);
    const [mainFunctions, setMainFunctions] = useState([]);
    const client = useSwagger();
    useBuildingHook()

    const loadContacts = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_contact_list({building_uuid: _buildingGuid});

            if (response.status === 204) {
                setContacts([]);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                setContacts(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            setContacts([]);
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization, t])

    
    const deleteContact = useCallback(async(deleteContactId) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_contact_destroy({building_uuid: _buildingGuid, contact_uuid: deleteContactId });

            if (response.status >= 200 && response.status < 300) {
                loadContacts();
                addToast(t("success"), t("deleteContactPersonSuccess"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization, t])


    const onClose = () => {
        loadContacts()
    };

    const ContactTable = () => {

        const tableStructure = [
            {
                col: <FontAwesomeIcon icon={faList} size='sm' className='flex-shrink-0' />,
                type: 'icon'
            }, {
                col: t('sector'),
                type: 'label'
            }, {
                col: t('name'),
                type: 'label'
            }, {
                col: t('emailAddress'),
                type: 'label'
            }, {
                col: t("actions"),
                type: "buttons",
            },
        ]

        return (
            <>
                {(contacts === undefined) ? (
                    <TablePlaceholder {...{structure: tableStructure}} />
                ) : (!Boolean(contacts?.length)) ? (
                    <p className="m-0">{t('noBuildingContacts')}</p>
                ) : (
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    <div className='d-flex' style={{width: '16px', height: '16px'}}>
                                        <FontAwesomeIcon icon={faList} size='sm' className='flex-shrink-0' />
                                    </div>
                                </th>
                                <th>{t('sector')}</th>
                                <th>{t('name')}</th>
                                <th>{t('emailAddress')}</th>
                                <th>{t('actions')}</th>

                            </tr>
                        </thead>
                        <tbody>
                            {contacts.map((c, i) => <TableRow key={`contact-key-${i}`} {...{row: c, index: i, onClose, loadContacts, deleteContact}} />)}
                        </tbody>
                    </Table>
                )}
            </>
        )
    }

    const ContactPersonContent = () => {
        const [newContact, setNewContact] = useState({contact: undefined, topic: undefined});

        const [users, setUsers] = useState([])
        const [filteredUsers, setFilteredUsers] = useState([])

        const getUsers = useCallback(async() => {
            if (!client) return;

            const originalRequestInterceptor = client.http.requestInterceptor;
    
            try {
                client.requestInterceptor = (req) => {
                    req.headers["Content-Type"] = "application/json";
                    req.headers["Authorization"] = login.Authorization;
                    return req;
                }; 
                
                const response = await client.apis["org"].getOrgUsers({
                    org_uuid: login.currentOrganisation.id,
                    activated: 1
                });

                if (response.status === 204) {
                    setUsers([])
                    return 
                }

                if (response.status >= 200 && response.status < 300) {
                    setUsers(response.obj.users) 
                }
        
                client.http.requestInterceptor = originalRequestInterceptor;

            } catch (error) {
                addToast(t("error"), t('networkError'), "error");
                client.http.requestInterceptor = originalRequestInterceptor;
            }
        }, [client, login.Authorization])

        const changeHandler = useCallback((e) => {
            const { id, value } = e.target;
        
            setNewContact(prevState => ({
                ...prevState,
                [id]: value
            }));
        }, [newContact, setNewContact]); 

        const onSubmit = useCallback(async(e) => {
            e.preventDefault();
    
            if (!client) return;

            const originalRequestInterceptor = client.http.requestInterceptor;
    
            try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(newContact);
                return req;
            };
            
            const response = await client.apis["building"].building_contact_create({building_uuid: _buildingGuid});

            if (response.status >= 200 && response.status < 300) {
                setShow(false)
                loadContacts()
            }
    
            client.http.requestInterceptor = originalRequestInterceptor;

            } catch (error) {
                addToast(t("error"), t('networkError'), "error");
                client.http.requestInterceptor = originalRequestInterceptor;
            }

        }, [client, _buildingGuid, login.Authorization, t, newContact]) 

        useEffect(() => {
            getUsers()
        }, [])

        useEffect(() => {
            let filterUsers = users.filter(user => !contacts?.some(contact => user.id === contact.contact?.id));
            setFilteredUsers(filterUsers)
            setNewContact(prevState => ({
                ...prevState,
                contact: filterUsers[0]?.id,
                topic: undefined
            }));
        }, [contacts, users])

        return (
            <Form onSubmit={(e) => onSubmit(e)}>
                <Form.Group className='mb-3'>
                    <Form.Label>{t('contactPerson')}</Form.Label>
                    <Form.Select id="contact" value={newContact['contact']} onChange={(e) => changeHandler(e)}>
                    {filteredUsers.length > 0 ? (
                        filteredUsers.map(user => <option key={`user-key-${user.id}`} value={user.id}>{user?.prename} {user?.name}</option>)
                    ) : (
                        <option value="">{t('noContacts')}</option>
                    )}
                </Form.Select>
                </Form.Group>

                {filteredUsers.length > 0 && (
                    <>
                        <Form.Group className='mb-3'>
                            <Form.Label>{t('topic')}</Form.Label>
                            <Form.Control id="topic" value={newContact['topic'] || ''} type='text' onChange={(e) => changeHandler(e)}/>
                        </Form.Group>
                        
                        <div className='d-flex flex-end'>
                            <Button disabled={!allowEdit} variant="outline-primary" type='submit'>{t('add')}</Button>
                        </div>
                    </>
                )}
            </Form>
        )
    }

    const addPermission = useCallback(() => {
        // if (login.currentOrganisation.permission_level === 1 || 'admin' in login.currentOrganisation) setMainFunctions([{ label: t('add'), onClick: () => setShow(true), key: 'add', icon: faAdd }]);
        if (engineerRights) setMainFunctions([{ label: t('add'), onClick: () => setShow(true), key: 'add', icon: faAdd }]);

    }, [t, engineerRights]);

    useEffect(() => {
        loadContacts();
    }, [loadContacts]);

    useEffect(() => {
        addPermission();
    }, [addPermission])

    return <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">
            <Card {...{ heading: `${t('contactPerson')} ${t('inThe')} ${t('building')}: ${login?.currentBuilding?.name}`, ...(allowEdit && { mainFunctions }) }}>
                <ContactTable />
                <ContentModal {...{show, onHide: () => setShow(false), title: t('addContactPerson'), content: <ContactPersonContent />}} />
            </Card>
        </div>
    </MainLayout>
}

const TableRow = ({ row, index, onClose, loadContacts, deleteContact }) => {
    const { t } = useTranslation();
    const [display, setDisplay] = useState(false);
    const [show, setShow] = useState(undefined);
    let editContact = row;
    let letContactId = row?.id;
    const { allowEdit } = usePermissions();

    return (
        <>
            <tr>
                <td>
                    <Collapse {...{ boolean: display, onClick: () => setDisplay(!display) }} />
                </td>
                <td>{row.topic}</td>
                <td>{`${row.contact?.prename} ${row.contact?.name}`}</td>
                <td>
                    <a href={`mailto:${row.contact?.mail}`}>{row.contact?.mail}</a>
                </td>
                <td>
                    <div className="actions__buttonbar grid__three">
                        <OverlayTrigger trigger={["hover", "focus"]} overlay={<Tooltip>{t("edit")}</Tooltip>}>
                            <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => {setShow("edit_contact")}}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        </OverlayTrigger>

                        <OverlayTrigger trigger={["hover", "focus"]} overlay={<Tooltip>{t("moreInfo")}</Tooltip>}>
                            <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => {setShow("moreInfo_contact")}}>
                                <FontAwesomeIcon icon={faInfo} />
                            </Button>
                        </OverlayTrigger>

                        <OverlayTrigger trigger={["hover", "focus"]} overlay={<Tooltip>{t("delete")}</Tooltip>}>
                            <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => {setShow("delete_contact")}}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </OverlayTrigger>
                    </div>
                </td>
            </tr>
            <tr className={`${!display ? 'd-none' : ''}`}>
                <td colSpan='100%'>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>{t('telephone')}</th>
                                <th>{t('comment')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {row.contact?.telephone.map((t, i) => {
                                return (<tr key={`contact-key-${index}-${i}`}>
                                        <td><a href={`tel:${t.number}`}>{t.number}</a></td>
                                        <td>{t.comment}</td>
                                    </tr>
                                )}
                            )}
                            
                        </tbody>
                    </Table>
                </td>
            </tr>

            <ContentModal
                {...{
                    show: show === "edit_contact" || show === "moreInfo_contact",
                    onHide: () => setShow(false),
                    title: show === "edit_contact" ? t("edit") : t("moreInfo"),
                    content: <EditAndMoreInfoContacts {... {editContact, show,  onClose: () => loadContacts()}} />,
                }}
            />

            <SecurityActionModal
                {...{
                    show: show === "delete_contact",
                    onHide: () => setShow(false),
                    title: t("deleteContactPerson"),
                    question: t("deleteContactPersonQuestion"),
                    action: () => deleteContact(letContactId),
                }}
            />
        </>
    )
}