import { useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Card from "../components/Card"
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useToast } from '../NotificationsContent';
import { useSwagger } from "../context/SwaggerContext";

function Reset() {
    const navigate = useNavigate();
    const token = useParams()['token'];
    const { t } = useTranslation();
    const client = useSwagger();

    const [showPassword, setShowPassword] = useState(false);
    const [showSecondPassword, setShowSecondPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [secondPassword, setSecondPassword] = useState('');
    const { addToast } = useToast();

    const toggleForm = (boolean) => {
        const form = document.getElementById('forget-form');
        const formElements = form.elements;
        for (let i = 0; i < formElements.length; i++) {
            formElements[i].disabled = boolean;
        }
    }

    const changePassword = useCallback(async(e) => {
        e.preventDefault();

        if (password !== secondPassword || !client) return;

        toggleForm(true);

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.body = JSON.stringify({ password: password });
                return req;
            };

            const response = await client.apis["User"].postResetPasswordToken({ resettoken: token });

            if (response.status >= 200 && response.status < 300) {
                addToast(t("setNewPassword"), t('resetSuccessfully'), "success");
                setTimeout(() => {
                    navigate('/login');
                }, 3000)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;

            if (statusCode === 401) {
                addToast(t("setNewPassword"), t("resetAlerts.tokenExpired"), "error");
            } else if (statusCode === 404) {
                addToast(t("setNewPassword"), t("resetAlerts.tokenValidation"), "error");
            } else if (statusCode === 406) {
                addToast(t("setNewPassword"), t("resetAlerts.passwordSecurity"), "error");
            } else {
                // Generische Fehlerbehandlung für andere Fälle
                addToast(t("setNewPassword"), t("resetAlerts.validEmail"), "error");
            }

            toggleForm(false);

            client.http.requestInterceptor = originalRequestInterceptor;
        }

        // fetch(`https://tech.sigmaheat.de/passwordreset/${token}`, {
        //     headers: {
        //         "Content-Type": "application/json"
        //     },
        //     method: 'POST',
        //     body: JSON.stringify({ password: password })
        // }).then(response => {
        //     if (response.status === 401) throw new Error(t('resetAlerts.tokenExpired'));
        //     if (response.status === 404) throw new Error(t('resetAlerts.tokenValidation'));
        //     if (response.status === 406) throw new Error(t('resetAlerts.passwordSecurity'));
        //     return {};
        // }).then(() => {
        //     addToast(t("setNewPassword"), t('resetSuccessfully'), "success");
        //     setTimeout(() => {
        //         navigate('/login');
        //     }, 3000)
        // }).catch((error) => {
        //     addToast(t("setNewPassword"), error.message, "error");
        //     setTimeout(() => console.clear());
        //     toggleForm(false);
        // });
    }, [password, secondPassword, addToast, t, token, navigate])

    return (
        <div style={{ width: '100%', maxWidth: '512px' }}>
            <Card {...{ heading: t("setNewPassword"), className: 'w-100' }}>
                <p className='text-white'>{t('resetInstructions')}</p>
                <Form id='forget-form' onSubmit={(e) => changePassword(e)} onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}>

                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">{t('newPassword')}:</label>
                        <div className='d-flex justify-content-between'>
                            <input type={showPassword ? 'text' : 'password'} className="form-control" id="password" value={password} onChange={(e) => {setPassword(e.target.value)}} />
                            
                            <Button variant="outline-primary" className={`ms-3${showPassword ? ' active' : ''}`} onClick={() => setShowPassword(!showPassword)}>
                                <FontAwesomeIcon icon={faEye} size='sm' />
                            </Button>
                        </div>
                    </div>
                    
                    <div className="mb-3">
                        <label htmlFor="secondpassword" className="form-label">{t('newPasswordAgain')}:</label>
                        <div className='d-flex justify-content-between'>
                            <input type={showSecondPassword ? 'text' : 'password'} className="form-control" id="secondpassword" value={secondPassword} onChange={(e) => {setSecondPassword(e.target.value)}} />
                            
                            <Button variant="outline-primary" className={`ms-3${showSecondPassword ? ' active' : ''}`} onClick={() => setShowSecondPassword(!showSecondPassword)}>
                                <FontAwesomeIcon icon={faEye} size='sm' />
                            </Button>
                        </div>
                    </div>

                    <Button variant="outline-primary" className='d-block w-100 btn btn-outline-primary' type='submit'>{t("confirm")}</Button>
                </Form>
            </Card>
        </div>
    )
}

export default Reset