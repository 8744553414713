export default class MyUploadAdapter {
    constructor(loader, login, building_uuid, callback, setDisableButton, addToast, t) {
        this.loader = loader;
        this.login = login;
        this.building_uuid = building_uuid;
        this.callback = callback;
        this.setDisableButton = setDisableButton;
        this.addToast = addToast;
        this.t = t;
    }

    upload() {
        return this.loader.file
            .then(file => {
                this.setDisableButton(true);
                if (file.size >= 6 * 1024 * 1024) {
                    return this._downscaleImage(file);
                } else {
                    return file;
                }
            })
            .then(file => new Promise((resolve, reject) => {
                this._initRequest();
                this._initListeners(resolve, reject, file);
                this._sendRequest(file);
            }))
            .catch(error => {
                this.addToast(this.t('upload'), error, "error");
                setTimeout(() => console.clear());
            });
    }

    _downscaleImage(file) {
        this.addToast(this.t('imageProcessing'), this.t('downscaling', {filename: file.name}), "notification");
        return new Promise((resolve, reject) => {
            const image = new Image();
            const reader = new FileReader();

            reader.onload = function (e) {
                image.onload = function () {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const maxSize = 4 * 1024 * 1024;

                    let width = image.width;
                    let height = image.height;
                    let scaleFactor = 1;

                    if (file.size > maxSize) {
                        scaleFactor = Math.sqrt(maxSize / file.size);
                        width = Math.floor(width * scaleFactor);
                        height = Math.floor(height * scaleFactor);
                    }

                    canvas.width = width;
                    canvas.height = height;

                    ctx.drawImage(image, 0, 0, width, height);

                    canvas.toBlob(blob => {
                        const downscaledFile = new File([blob], file.name, {
                            type: file.type,
                            lastModified: Date.now()
                        });
                        resolve(downscaledFile);
                    }, file.type);
                };

                image.src = e.target.result;
            };

            reader.readAsDataURL(file);
        });
    }

    abort() {
        if (this.xhr) this.xhr.abort();
    }

    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();
        xhr.open('POST', `${process.env.REACT_APP_API_URL}/building/${this.building_uuid}/picture/`, true);
        xhr.responseType = 'json';
    }

    _initListeners(resolve, reject, file) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = this.t('imageUploadError', {file: file.name});

        xhr.addEventListener('error', () => reject(genericErrorText));
        xhr.addEventListener('abort', () => reject());

        xhr.addEventListener('load', () => {
            this.setDisableButton(false);
            const response = xhr.response;

            if (!response || response.error) return reject(response && response.error ? response.error.message : genericErrorText);
            if (typeof this.callback === 'function') this.callback(response[0].id, file);

            resolve({
                default: response.url,
                id: response.id,
            });
        });

        if (xhr.upload) {
            xhr.upload.addEventListener('progress', evt => {
                if (evt.lengthComputable) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            });
        }
    }

    _sendRequest(file) {
        const data = new FormData();
        data.append('upload', file);
        this.xhr.setRequestHeader("Authorization", this.login.Authorization);
        this.xhr.send(data);
    }
}