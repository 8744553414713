import { useState, useCallback } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { useSwagger } from "../context/SwaggerContext";
import Card from "../components/Card";

export const AddEditVariant = ({ onClose, ...props }) => {
    const { login } = useAuth();
    const { addToast } = useToast();
    const { t } = useTranslation();
    const _buildingGuid = useParams()['buildingGuid'];
    const [isSubmitting, setIsSubmitting] = useState(false);
    const client = useSwagger();

    const [variant, setVariant] = useState(props.variant || {
        actual: false,
        exchange_individual_bp: false,
        max_flow_temp: 70,
        max_return_temp: 50,
        heating_limit: 18,
        fixed_flow_temperature: false
    });

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;
        setVariant(prevState => ({
            ...prevState,
            [id]: value
        }));
    }, []);

    const changeChecked = useCallback((e) => {
        const { id, checked } = e.target;
        setVariant(prevState => ({
            ...prevState,
            [id]: checked
        }));
    }, []);

    const onSubmit = useCallback(async(e) => {
        e.preventDefault();
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/variants/${variant?.id ? variant?.id +'/': ''}`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: variant?.id ? "PATCH" : "POST",
        //     body: JSON.stringify(variant)
        // })
        //     .then((response) => {
        //         if (response.status === 429) throw new Error(t("fiveVariant"));
        //         if (!response.ok) throw new Error(t('networkError'));
        //         return response.json();
        //     })
        //     .then(_ => {
        //         onClose();
        //         addToast(t("success"), variant?.id ? t("patchVariant") : t("postVariant"), "success");
        //     })
        //     .catch(error => {
        //         addToast(t("error"), error.message, "error");
        //     });
        
        if (!client || isSubmitting) return;

        setIsSubmitting(true);

        const originalRequestInterceptor = client.http.requestInterceptor;
  
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(variant);
                return req;
            };
  
            const response = variant?.id ? await client.apis["building"].building_variants_partial_update({ building_uuid: _buildingGuid, variant_uuid: variant?.id }) : await client.apis["building"].building_variants_create({ building_uuid: _buildingGuid });
    
            if (response.status >= 200 && response.status < 300) {
                onClose();
                addToast(t("success"), variant?.id ? t("patchVariant") : t("postVariant"), "success");
            }
    
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;
            addToast(t("error"), t(statusCode === 429 ? "fiveVariant" : "networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            setIsSubmitting(false);
        }
    }, [login.Authorization, variant, onClose, client]);

    return <Form onSubmit={(e) => onSubmit(e)}>
        <Form.Group className='mb-3'>
            <Form.Label>{t('name')}</Form.Label>
            <Form.Control id='name' required type='text' value={variant['name'] || ''} onChange={(e) => changeHandler(e)} />
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('realization')}</Form.Label>
            <Form.Control id='realization' className="displayDate" type='date' value={variant['realization'] || ''} min={`1800-01-01`} max={`2050-12-31`} onChange={(e) => changeHandler(e)} />
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('actualVariant')}</Form.Label>
            <Form.Check
                type="switch"
                id="actual"
                checked={variant['actual'] || false}
                onChange={(e) => changeChecked(e)} />
        </Form.Group>

        <Card {...{ heading: t("calculationbasics") }}>
            <Form.Group className='mb-3'>
                <Form.Label>{t('fixedFlowTemperature')}</Form.Label>
                <Form.Check
                    type="switch"
                    id="fixed_flow_temperature"
                    checked={variant['fixed_flow_temperature'] || false}
                    onChange={(e) => changeChecked(e)} />
            </Form.Group>

            <Form.Group className='mb-3'>
                <Form.Label>{(variant['fixed_flow_temperature'] || false) ? t('fixedFlowTemperature') : t("max_flow_temperature")} in °C</Form.Label>
                <Form.Control id='max_flow_temp' required type='number' max={90} min={25} value={variant['max_flow_temp'] || ''} onChange={(e) => changeHandler(e)} />
            </Form.Group>

            <Form.Group className='mb-3'>
                <Form.Label>{t('Max_Return_Temperature')} in °C</Form.Label>
                <Form.Control id='max_return_temp' required type='number' max={70} min={20} value={variant['max_return_temp'] || ''} disabled={variant['fixed_flow_temperature'] || false} onChange={(e) => changeHandler(e)} />
            </Form.Group>

            <Form.Group className='mb-3'>
                <Form.Label>{t('heating_limit')} in °C</Form.Label>
                <Form.Control id='heating_limit' required type='number' max={30} min={0} value={variant['heating_limit'] || ''} onChange={(e) => changeHandler(e)} />
            </Form.Group>

            <Form.Group className='mb-3'>
                <Form.Label>{t('exchange_individual_bp')}</Form.Label>
                <Form.Check
                    type="switch"
                    id="exchange_individual_bp"
                    checked={variant['exchange_individual_bp'] || false}
                    onChange={(e) => changeChecked(e)} />
            </Form.Group>
        </Card>

        <Button variant="outline-primary" disabled={isSubmitting} className='w-100' type='submit'>{t('id' in variant ? 'edit' : 'add')}</Button>
    </Form>;
};
