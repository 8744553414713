import { useState, useCallback, useRef, forwardRef, useEffect } from "react";
import { useAuth } from "../Context";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Table, Form, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faSave, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "../components/DatePicker";
import { ColumnChart } from '../components/AmCharts'
import { formatDateWithoutTime, utcToLocal, chartTimeUnit, quantityToUnit, quantityName, arrayObjectsToCSV, downloadCSV } from '../util/helpers'
import { useSwagger } from "../context/SwaggerContext";

const SelectionPage = ({ selectedGUID, handleSelections, handleSelectChange, setStep, loadMeasurements, setFirstLoad }) => {
    const { t } = useTranslation();

    useEffect(() => {
        setFirstLoad(false)
    }, [])

    return <>
        <Table responsive className='mb-3'>
            <thead>
                <tr>
                    <th>{t('measurementName')}</th>
                    <th>{t('chartType')}</th>
                </tr>
            </thead>
            <tbody>
                {selectedGUID?.map(m => {
                    return <tr key={`measurement-key-${m.id}`}>
                        <td>
                            <Form.Check
                                type='checkbox'
                                id={`measurement-${m.id}`}
                                label={m.name}
                                value={m.id}
                                onChange={(e) => handleSelections(e)}
                                checked={m.checked}
                            />
                        </td>
                        <td>
                            <Form.Select aria-label='Chart Type' value={m.type} onChange={(e) => handleSelectChange(e, m.id)}>
                                <option value='Line'>{t('lineChart')}</option>
                                <option value='Bar'>{t('barChart')}</option>
                            </Form.Select>
                        </td>
                    </tr>
                })}
            </tbody>
        </Table>
        <div className="d-flex justify-content-end">
            <Button variant='outline-primary' onClick={() => {if (Boolean(selectedGUID.filter(s => s.checked === true).length)) {setStep(2); loadMeasurements()}}} disabled={!Boolean(selectedGUID.filter(s => s.checked === true).length)}>{t('plot')}</Button>
        </div>
    </>
}

const PlotPage = forwardRef(({ from, setFrom, to, setTo, currentAggregation, setCurrentAggregation, aggregations, currentInterval, setCurrentInterval, intervals, loadMeasurements, setStep, measurementsData, load, firstLoad, dataToShow, csv }, ref) => {
    const { defaultTheme } = useAuth();
    const { t } = useTranslation();

    const handleToChange = useCallback((e) => {
        const newTo = e.detail.valueAsDate;
        if (newTo < from) {
            setTo(from);
            setFrom(newTo);
        } else {
            setTo(newTo);
        }
    }, [from])

    return <Row>
        <Col xl={12} md={12} sm={12}>
            <Form.Label>{t("period")}:</Form.Label>
        </Col>
        <Col xl={6} md={6} sm={12} className="mb-3">
            <DatePicker onChange={(e) => {setFrom(e.detail.valueAsDate)}} value={`${String(from.getFullYear())}-${String(from.getMonth() + 1).padStart(2, '0')}-${String(from.getDate()).padStart(2, '0')}`} />
        </Col>
        <Col xl={6} md={6} sm={12} className="mb-3">
            <DatePicker onChange={(e) => handleToChange(e)} value={`${String(to.getFullYear())}-${String(to.getMonth() + 1).padStart(2, '0')}-${String(to.getDate()).padStart(2, '0')}`} />
        </Col>
        <Col xl={6} md={6} sm={12} className="mb-3">
            <Form.Label>{t("aggregation")}:</Form.Label>
            <Form.Select size="md" value={currentAggregation} onChange={(e) => setCurrentAggregation(e.target.value)}>
                {Object.entries(aggregations).map(([key, value]) => <option key={`aggregation-${key}`} value={key}>{value}</option>)}
            </Form.Select>
        </Col>
        <Col xl={6} md={6} sm={12} className="mb-3">
            <Form.Label>{t("interval")}:</Form.Label>
            <Form.Select size="md" value={currentInterval} onChange={(e) => setCurrentInterval(e.target.value)}>
                {Object.entries(intervals).map(([key, value]) => <option key={`interval-${key}`} value={key}>{value}</option>)}
            </Form.Select>
        </Col>
        <Col xl={12} md={12} sm={12} className="mb-3">
            <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between">
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip className='position-fixed'>{t("reload")}</Tooltip>
                        }>
                        <Button variant='outline-primary' className='p-10 me-3' onClick={() => loadMeasurements()}>
                            <FontAwesomeIcon icon={faRedo} size="sm" />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip className='position-fixed'>{t("downloadAsCSV")}</Tooltip>
                        }>
                        <Button variant='outline-primary' className='p-10' disabled={!csv?.some(subArray => subArray.some(item => typeof item === 'object' && item !== null))} onClick={() => downloadCSV(arrayObjectsToCSV(csv))}> 
                            <FontAwesomeIcon icon={faSave} size="sm" />
                        </Button>
                    </OverlayTrigger>
                </div>
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={
                        <Tooltip className='position-fixed'>{t("previous")}</Tooltip>
                    }>
                    <Button variant='outline-primary' className='p-10' onClick={() => setStep(1)}>
                        <FontAwesomeIcon icon={faArrowLeft} size="sm" />
                    </Button>
                </OverlayTrigger>
            </div>
        </Col>

        <Col xl={12} md={12} sm={12}>
            <div style={{ width: "100%", height: "500px" }}>
                <ColumnChart {...{ref, theme: defaultTheme === 'light' ? 'dark' : 'light', data: measurementsData, load, firstLoad, dataToShow, time: {from, to}, interval: chartTimeUnit(currentInterval)}} />
            </div>
        </Col>
    </Row>
});

export const IndividualPlotContent = (props) => {
    const {login} = useAuth();
    const _buildingGuid = useParams()['buildingGuid'];
    const { t } = useTranslation();
    const chartRef = useRef(null);
    const [step, setStep] = useState(1);
    // eslint-disable-next-line no-unused-vars
    const [measurementsData, setMeasurementsData] = useState(undefined);
    const [from, setFrom] = useState(utcToLocal(new Date(new Date().setDate(new Date().getDate() - 30))));
    // eslint-disable-next-line no-unused-vars
    const [to, setTo] = useState(utcToLocal(new Date()));
    // eslint-disable-next-line no-unused-vars
    const [dataToShow, setDataToShow] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [load, setLoad] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [firstLoad, setFirstLoad] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [currentAggregation, setCurrentAggregation] = useState('avg');
    // eslint-disable-next-line no-unused-vars
    const [currentInterval, setCurrentInterval] = useState('daily');

    // eslint-disable-next-line no-unused-vars
    const aggregations = {
        min: t("minimalValue"),
        avg: t("average"),
        max: t("maximalValue")
    };

    // eslint-disable-next-line no-unused-vars
    const intervals = {
        minutely: t("minutely"),
        quarterhourly: t("quarterhourly"),
        hourly: t("hourly"),
        daily: t("daily")
    };

    console.log("DEVICES: ", props.devices)

    const measurements = [].concat(...props.devices.map(d => d.measurements));
    const [selectedGUID, setSelectedGUID] = useState(measurements.map(m => ({name: m.name, checked: false, id: m.id, type: 'Line', quantity: m.quantity})));

    const [csv, setCSV] = useState(undefined)

    const client = useSwagger();

    const measurementPromise = useCallback((measurement) => {
        return new Promise(async(resolve, reject) => {
            // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/data/${measurement}?from=${formatDate(from)}&to=${formatDate(to)}&aggregation=${currentInterval}`, {
            //     headers: {
            //         'Authorization': login.Authorization,
            //         "Content-Type": "application/json"
            //     }
            // })
            // .then((response) => {
            //     if (response.status === 200) return response.json();
            //     return [];
            // })
            // .then((data) => {
            //     resolve(data);
            // })
            // .catch((error) => {
            //     reject(error);
            // });

            if (!client) return;

            const originalRequestInterceptor = client.http.requestInterceptor;

            try {
                client.requestInterceptor = (req) => {
                    req.headers["Content-Type"] = "application/json";
                    req.headers["Authorization"] = login.Authorization;
                    return req;
                };

                const response = await client.apis["building"].building_data_retrieve({
                    building_uuid: _buildingGuid,
                    measurement_uuid: measurement,
                    aggregation: currentInterval,
                    from: formatDateWithoutTime(from),
                    to: formatDateWithoutTime(to),
                    individual_limit: 20000
                });

                if (response.status === 200) resolve(response.obj);

                client.http.requestInterceptor = originalRequestInterceptor;
            } catch (error) {
                reject(error);
                client.http.requestInterceptor = originalRequestInterceptor;
            }
        });
    }, [client, from, to, currentInterval, _buildingGuid, login.Authorization]);

    const loadMeasurements = useCallback(() => {
        setLoad(false);
        setDataToShow(false);
        setMeasurementsData(undefined);

        const checkedList = selectedGUID.filter(s => s.checked === true);

        const promises = checkedList.map(
            measurement => measurementPromise(measurement.id)
        );

        Promise.all(promises).then((values) => {
            setCSV(values)
            const nameData = values.map((data, i) => {
                const name = checkedList[i].name;
                const keyPoint = name.replace(/\s+/g, '').toLowerCase();

                const dataFormatter = (json) => {
                    var retArr = [];
                    for (var i = json.length - 1; i >= 0; i--) {
                        retArr.push({
                            date: Date.parse(utcToLocal(json[i].timestamp)),
                            [keyPoint]: parseFloat((Math.round(json[i][currentAggregation] * 100) / 100).toFixed(2)),
                        });
                    }
                    return retArr;
                }

                return {
                    name: name,
                    data: dataFormatter(data).sort(function (a, b) {
                        return new Date(a.date) - new Date(b.date);
                    }),
                    unit: checkedList[i].quantity,
                    type: checkedList[i].type
                }
            });
            
            const data = nameData.map(nd => nd.data);

            for (let i = 0; i < data.length; i++) {
                if (data[i].length !== 0) {
                    setDataToShow(true);
                    break;
                }
            }

            const groupObjectsByUnit = (inputObjects) => {
                const groupedObjects = {};

                inputObjects.forEach(obj => {
                    if (!groupedObjects[obj.unit]) {
                        groupedObjects[obj.unit] = {
                            unit: quantityToUnit(obj.unit),
                            dimension: quantityName(obj.unit),
                            name: [],
                            data: [],
                            type: []
                        };
                    }

                    groupedObjects[obj.unit].name.push(obj.name);
                    groupedObjects[obj.unit].data.push(obj.data);
                    groupedObjects[obj.unit].type.push(obj.type);
                });

                return Object.values(groupedObjects);
            }

            setMeasurementsData(groupObjectsByUnit(nameData));
            setLoad(true);
            setFirstLoad(true);
        });
    }, [selectedGUID, measurementPromise, currentAggregation])

    const handleSelections = useCallback((e) => {
        let tmpArray = [...selectedGUID];
        const value = e.target.value;
        tmpArray.find(t => t.id === value).checked = e.target.checked;
        setSelectedGUID(tmpArray);
    }, [selectedGUID])

    const handleSelectChange = (e, guid) => {
        let tmpArray = [...selectedGUID];
        const newType = e.target.value;
        tmpArray.find(t => t.id === guid).type = newType;
        setSelectedGUID(tmpArray)
    };

    if (step === 1) return <SelectionPage {...{ selectedGUID, handleSelections, handleSelectChange, setStep, loadMeasurements, setFirstLoad }} />
    if (step === 2) return <PlotPage {...{ ref: chartRef, from, setFrom, to, setTo, currentAggregation, setCurrentAggregation, aggregations, currentInterval, setCurrentInterval, intervals, loadMeasurements, setStep, measurementsData, load, firstLoad, dataToShow, csv }} />
}

export default IndividualPlotContent;