import { useState, useEffect, createContext, useContext } from "react";
import { useToast } from '../NotificationsContent';
import { useTranslation } from 'react-i18next';
import { useAuth } from "../Context";
import { useCallback } from "react";
import { useSwagger } from "../context/SwaggerContext";

const PermissionsContext = createContext();

export const usePermissions = () => {
  return useContext(PermissionsContext);
}

export const PermissionsProvider = ({children}) => {
  const { login } = useAuth();
  const client = useSwagger();
  const [userPermissions, setUserPermissions] = useState(undefined);
  const [permissionLevel, setPermissionLevel] = useState(undefined);

  const [allowEdit, setAllowEdit] = useState(false);
  const [allowPaymentMethods, setAllowPaymentMethods] = useState(false);
  const [engineerRights, setEngineerRights] = useState(false);
  const [superUser, setSuperUser] = useState(false);

  const { addToast } = useToast();
  const { t } = useTranslation();

  const getUserPermission = useCallback(async() => {

    if (!login || !login.currentOrganisation || !client || login?.accepted === false) return; 

    // fetch(`https://tech.sigmaheat.de/org/${login.currentOrganisation.id}/user/${login.id}`,
    //   {
    //     headers: {
    //       Authorization: login.Authorization,
    //       "Content-Type": "application/json",
    //     },
    //     method: "GET",
    //   }
    // )
    // .then((response) => {
    //   if (!response.ok) throw new Error(t("networkError"));
    //   return response.json();
    // })
    // .then((data) => {
    //   setPermissionLevel(data.permission_level)
    // })
    // .catch((error) => addToast(t("error"), error.message, "error"));

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login?.Authorization;
        return req;
      };

      const response = await client.apis["org"].org_user_retrieve({org_uuid: login.currentOrganisation.id, user_uuid: login.id});

      if (response.status >= 200 && response.status < 300) {
        setPermissionLevel(response.obj.permission_level)
      }

      client.http.requestInterceptor = originalRequestInterceptor;

    } catch (error) {
      addToast(t("error"), t("responseError"), "error");
      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, login, addToast])

  const getPermissions = useCallback(async() => {
    // fetch(`https://tech.sigmaheat.de/org/permission`, 
    //   {
    //     headers: {
    //       Authorization: login.Authorization,
    //       "Content-Type": "application/json",
    //     },
    //     method: "GET",
    //   }
    // )
    // .then((response) => {
    //   if (!response.ok) throw new Error(t('networkError'));
    //   if (response.status === 204) return [];
    //   return response.json();
    // })
    // .then(data => {
    //   setUserPermissions(data);
    // })
    // .catch(error => {
    //   console.log("Error:", error)
    //   addToast(t("error"), t("responseError"), "error");
    // });

    if (!client) return;
    if (login?.accepted === false || (login?.accepted === undefined && login?.Authorization === undefined) || login?.TwoFA === true) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login?.Authorization;
        return req;
      };

      const response = await client.apis["org"].org_permission_list();

      if (response.status >= 200 && response.status < 300) {
        setUserPermissions(response.obj);
      }

      client.http.requestInterceptor = originalRequestInterceptor;

    } catch (error) {
      addToast(t("error"), t("responseError"), "error");
      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, login?.Authorization, addToast, t])

  useEffect(() => {
    if (!login) return 

    getUserPermission();
    getPermissions();
  }, [login, login?.currentOrganisation]);

  useEffect(() => {
    if (!userPermissions) return;

    const userPermissionLabel = permissionLevel;

    const userPermission = userPermissions.find(permission => permission.value === userPermissionLabel);

    setEngineerRights(userPermission && userPermission.value <= 20)
    setAllowEdit(userPermission && userPermission.value <= 15);
    setAllowPaymentMethods(userPermission && userPermission.value <= 5)
    setSuperUser(userPermission && userPermission.value === 1)
  }, [userPermissions, login, permissionLevel]);

  // console.log("allow edit in context:", allowEdit)

  return (
    <PermissionsContext.Provider value={{ allowEdit, allowPaymentMethods, engineerRights, userPermissions, permissionLevel, superUser }}>
      {children}
    </PermissionsContext.Provider>
  );
};