import {
    useEffect,
    useCallback,
    useLayoutEffect,
    useRef,
    useState
} from "react";
import * as React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { SHButton } from "../style/SigmaHeatTheme";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";

const ORIGIN = Object.freeze({ x: 0, y: 0 });

const { devicePixelRatio: ratio = 1 } = window;

function diffPoints(p1, p2) {
    return { x: p1.x - p2.x, y: p1.y - p2.y };
}

function addPoints(p1, p2) {
    return { x: p1.x + p2.x, y: p1.y + p2.y };
}

function scalePoint(p1, scale) {
    return { x: p1.x / scale, y: p1.y / scale };
}

const useResizeObserver = (ref) => {
    const [boundaries, setBoundaries] = useState(null);
    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                setBoundaries(entry.contentRect);
            });
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);
    return boundaries;
};

function EditLineModal(props) {
    const edit = props.edit;
    const selectedID = props.selectedID;
    const selectedItem = props.selectedItem;
    const show = props.show;
    const onHide = props.onHide;
    const [lineWidth, setLineWidth] = useState(selectedItem?.lineWidth);
    const [height, setHeight] = useState(selectedItem?.realHeight);


    return (
      <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Linie No. {selectedID}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => {e.preventDefault(); edit(selectedID, lineWidth, height);}}>
                    <Form.Group className="mb-3" controlId="lineWidth">
                        <Form.Label>Stärke</Form.Label>
                        <Form.Control type="number" min="1" max="100" value={lineWidth} onChange={(e) => setLineWidth(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="wallHeight">
                        <Form.Label>Länge</Form.Label>
                        <Form.Control type="number" min="1" max="300" step="0.01" value={height} onChange={(e) => setHeight(e.target.value)} />
                    </Form.Group>

                    <Button variant="primary" type="submit">Bearbeiten</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
  
export default function Canvas(props) {
    const canvasRef = useRef(null);
    const [context, setContext] = useState(null);
    const [scale, setScale] = useState(1);
    const [offset, setOffset] = useState(ORIGIN);
    const [mousePos, setMousePos] = useState(ORIGIN);
    const [viewportTopLeft, setViewportTopLeft] = useState(ORIGIN);
    const isResetRef = useRef(false);
    const lastMousePosRef = useRef(ORIGIN);
    const lastOffsetRef = useRef(ORIGIN);
    const scaleRef = useRef(1);
    const viewportTopLeftRef = useRef(ORIGIN);

    const dragStartPosition = useRef({ x: 0, y: 0 });
    const currentTransformedCursor = useRef(null);
    // Loops aka Rooms
    // const graphRef = Array.from(Array(100000), () => Array());
    // const cyclesRef = Array.from(Array(100000), () => Array());
    // const cyclenumberRef = 0;

    const isDrawing = useRef(false);
    const start = useRef(ORIGIN);
    const end = useRef(ORIGIN);
    const [items, setItems] = useState([]);
    const [loops, setLoops] = useState([]);
    const itemsRef = useRef([]);

    const [modalShow, setModalShow] = useState(false);
    const [selectedID, setSelectedID] = useState(0);
    const [selectedItem, setSelectedItem] = useState(undefined);
    const [image, setImage] = useState(undefined);

    const boundaries = useResizeObserver(canvasRef);
    const { t } = useTranslation();

    const _ = require('lodash');

    useEffect(() => {
        lastOffsetRef.current = offset;
    }, [offset]);

    useEffect(() => {
        scaleRef.current = scale;
    }, [scale]);

    useEffect(() => {
        viewportTopLeftRef.current = viewportTopLeft;
    }, [viewportTopLeft]);

    const reset = useCallback((context) => {
        if (context && !isResetRef.current && boundaries) {
            context.canvas.width = boundaries.width * ratio;
            context.canvas.height = boundaries.height * ratio;
            context.scale(ratio, ratio);
            setScale(1);

            setContext(context);
            setOffset(ORIGIN);
            setMousePos(ORIGIN);
            setViewportTopLeft(ORIGIN);
            lastOffsetRef.current = ORIGIN;
            lastMousePosRef.current = ORIGIN;

            isResetRef.current = true;
        }
    }, [boundaries]);

    function getWindowToCanvas(canvas, e) {
        e = e || window.event;
        var target = e.target || e.srcElement,
            style = target.currentStyle || window.getComputedStyle(target, null),
            borderLeftWidth = parseInt(style["borderLeftWidth"], 10),
            borderTopWidth = parseInt(style["borderTopWidth"], 10),
            rect = target.getBoundingClientRect(),
            offsetX = e.clientX - borderLeftWidth - rect.left,
            offsetY = e.clientY - borderTopWidth - rect.top;
        let x = (offsetX * target.width) / target.clientWidth;
        let y = (offsetY * target.height) / target.clientHeight;

        const ctx = canvas.getContext("2d");
        var transform = ctx.getTransform();
        const invMat = transform.invertSelf();
        return {
            x: x * invMat.a + y * invMat.c + invMat.e,
            y: x * invMat.b + y * invMat.d + invMat.f
        };
    }

    const mouseMove = useCallback((event) => {
        if (context) {
            const lastMousePos = lastMousePosRef.current;
            const currentMousePos = { x: event.pageX, y: event.pageY };
            lastMousePosRef.current = currentMousePos;

            // new Sauce
            currentTransformedCursor.current = getTransformedPoint(event.offsetX, event.offsetY);
            
            if (!isDrawing.current && event.ctrlKey) {
                const mouseDiff = diffPoints(currentMousePos, lastMousePos);
                setOffset((prevOffset) => addPoints(prevOffset, mouseDiff));
            } else {
                end.current = getWindowToCanvas(canvasRef.current, event);

                lockLocation(false, true);
            }
        }
    }, [context]);
  
    const mouseUp = useCallback(() => {
        document.removeEventListener("mousemove", mouseMove);
        document.removeEventListener("mouseup", mouseUp);

        const onPlace = start.current.x === end.current.x && start.current.y === end.current.y;

        if (isDrawing.current && !onPlace) {
            var newObj = {
                start      : {x: start.current.x, y: start.current.y},
                end        : {x: end.current.x,   y: end.current.y},
                lineWidth  : 1,
                realHeight : 1,
                color      : '#000000',
                nodeID     : Math.random()
            }
            
            setItems(prev => [...prev, newObj]);
            isDrawing.current = false;
        }
    }, [mouseMove]);

    function getTransformedPoint(x, y) {
        const originalPoint = new DOMPoint(x, y);
        return canvasRef.current.getContext("2d").getTransform().invertSelf().transformPoint(originalPoint);
    }

    function distanceTwoPoints(current, inter) {
        return Math.sqrt(Math.pow(current.x - inter.x, 2) + Math.pow(current.y - inter.y, 2));
    }

    const lockLocation = (startPoint, endPoint) => {
        let startTMP = null;
        let endTMP   = null;

        itemsRef.current.forEach(i => {
            let currentDistanceStartStart = distanceTwoPoints(start.current, i.start);
            let currentDistanceStartEnd = distanceTwoPoints(start.current, i.end);

            let currentDistanceEndStart = distanceTwoPoints(end.current, i.start);
            let currentDistanceEndEnd = distanceTwoPoints(end.current, i.end);

            if (startPoint && (currentDistanceStartStart < 20 || currentDistanceStartEnd < 20)) {
                if (startTMP === null) {
                    startTMP = currentDistanceStartStart < currentDistanceStartEnd ? i.start : i.end;
                } else {
                    let diffStart = distanceTwoPoints(start.current, startTMP) - currentDistanceStartStart;
                    let diffEnd = distanceTwoPoints(start.current, startTMP) - currentDistanceStartEnd;

                    if (diffStart < diffEnd && diffStart > 0) startTMP = i.start;
                    if (diffEnd < diffStart && diffEnd > 0) startTMP = i.end;
                }
            }

            if (endPoint && (currentDistanceEndStart < 20 || currentDistanceEndEnd < 20)) {

                if (endTMP === null) {
                    endTMP = currentDistanceEndStart < currentDistanceEndEnd ? i.start : i.end;
                } else {
                    let diffStart = distanceTwoPoints(end.current, endTMP) - currentDistanceEndStart;
                    let diffEnd = distanceTwoPoints(end.current, endTMP) - currentDistanceEndEnd;

                    if (diffStart < diffEnd && diffStart > 0) endTMP = i.start;
                    if (diffEnd < diffStart && diffEnd > 0) endTMP = i.end;
                }
            }
        })

        // let startBool = start.current === startTMP;

        start.current = startTMP === null ? start.current : startTMP;
        end.current = endTMP === null ? end.current : endTMP;

        // if ()
    }
  
    const startPan = useCallback((event) => {
        document.addEventListener("mousemove", mouseMove);
        document.addEventListener("mouseup", mouseUp);
        lastMousePosRef.current = { x: event.pageX, y: event.pageY };

        // new sauce
        dragStartPosition.current = getTransformedPoint(event.offsetX, event.offsetY);

        if (event.ctrlKey) return;
        isDrawing.current = true;
        start.current = getWindowToCanvas(canvasRef.current, event);
        end.current = getWindowToCanvas(canvasRef.current, event);

        lockLocation(true, true);
    }, [mouseMove, mouseUp]);

    useLayoutEffect(() => {
        if (canvasRef.current && boundaries) {
            const renderCtx = canvasRef.current.getContext("2d");
            if (renderCtx) reset(renderCtx);
        }
    }, [reset, boundaries]);


    useLayoutEffect(() => {
        if (context && lastOffsetRef.current) {
            const offsetDiff = scalePoint(diffPoints(offset, lastOffsetRef.current), scale);
            context.translate(offsetDiff.x, offsetDiff.y);
            setViewportTopLeft((prevVal) => diffPoints(prevVal, offsetDiff));
            isResetRef.current = false;
        }
    }, [context, offset, scale]);

    useLayoutEffect(() => {
        if (context && boundaries) {
            // let winScale =  100 / (scaleRef.current * 100);
            
            if (image) {
                const middleXCanvas = boundaries.width / 2;
                const middleYCanvas = boundaries.height / 2;
                let base_image = new Image();
                base_image.src = URL.createObjectURL(image);
                base_image.onload = function() {
                    const storedTransform = context.getTransform();
                    context.canvas.width = context.canvas.width;
                    context.setTransform(storedTransform);

                    // context.clearRect(viewportTopLeft.x, viewportTopLeft.y, context.canvas.width * winScale, context.canvas.height * winScale);
                    context.drawImage(base_image, (middleXCanvas - this.width / 2), (middleYCanvas - this.height / 2));
                    drawStoredLines();
                }
            } else {
                const storedTransform = context.getTransform();
                context.canvas.width = context.canvas.width;
                context.setTransform(storedTransform);

                // context.clearRect(viewportTopLeft.x, viewportTopLeft.y, context.canvas.width * winScale, context.canvas.height * winScale);
                drawStoredLines();
            }

            itemsRef.current = items;
        }
    }, [boundaries, context, scale, offset, viewportTopLeft, items, image, loops]);


    useEffect(() => {
        const canvasElem = canvasRef.current;
        if (canvasElem === null) return;
    
        function handleUpdateMouse(event) {
            event.preventDefault();
            if (canvasRef.current) {
                const viewportMousePos = { x: event.clientX, y: event.clientY };
                const topLeftCanvasPos = {
                    x: canvasRef.current.offsetLeft,
                    y: canvasRef.current.offsetTop
                };
                setMousePos(diffPoints(viewportMousePos, topLeftCanvasPos));
            }
        }
    
        canvasElem.addEventListener("mousemove", handleUpdateMouse);
        canvasElem.addEventListener("wheel", handleUpdateMouse);
        return () => {
            canvasElem.removeEventListener("mousemove", handleUpdateMouse);
            canvasElem.removeEventListener("wheel", handleUpdateMouse);
        };
    }, []);

    useEffect(() => {
        const canvasElem = canvasRef.current;
        if (canvasElem === null) {
            return;
        }

        function handleWheel(event) {
            event.preventDefault();
            if (context && event.ctrlKey) {
                // new Sauce
                const zoom = event.deltaY < 0 ? 1.1 : 0.9;
  
                context.translate(currentTransformedCursor.x, currentTransformedCursor.y);
                context.scale(zoom, zoom);
                context.translate(-currentTransformedCursor.x, -currentTransformedCursor.y);
                    
                event.preventDefault();

                // const zoom = 1 - event.deltaY / ZOOM_SENSITIVITY;

                const viewportTopLeftDelta = {
                    x: (mousePos.x / scale) * (1 - 1 / zoom),
                    y: (mousePos.y / scale) * (1 - 1 / zoom)
                };
                const newViewportTopLeft = addPoints(
                    viewportTopLeft,
                    viewportTopLeftDelta
                );
        
                // context.translate(viewportTopLeft.x, viewportTopLeft.y);
                // context.scale(zoom, zoom);
                // context.translate(-newViewportTopLeft.x, -newViewportTopLeft.y);
        
                setViewportTopLeft(newViewportTopLeft);
                setScale(scale * zoom);
                isResetRef.current = false;
            }
        }
    
        canvasElem.addEventListener("wheel", handleWheel);
        return () => canvasElem.removeEventListener("wheel", handleWheel);
    }, [context, mousePos.x, mousePos.y, viewportTopLeft, scale]);

    function drawStoredLines() {
        items.forEach(i => {
            context.lineWidth = i.lineWidth;
            context.beginPath();
            context.moveTo(i.start.x, i.start.y);
            context.lineTo(i.end.x, i.end.y);
            context.closePath();
            context.strokeStyle = i.color;
            context.stroke();
        })

        loops.forEach(arr => {
            arr.forEach(i => {
                context.lineWidth = i.lineWidth;
                context.beginPath();
                context.moveTo(i.start.x, i.start.y);
                context.lineTo(i.end.x, i.end.y);
                context.closePath();
                context.strokeStyle = i.color === '#000000' ? "rgba(255, 255, 255, 0)" : i.color;
                context.stroke();
            })
        })
    }

    function drawLine() {
        context.lineWidth = 1;
        context.beginPath();
        context.moveTo(start.current.x, start.current.y);
        context.lineTo(end.current.x, end.current.y);
        context.closePath();
        context.strokeStyle = '#000000';
        context.stroke();
    }

    function reDraw() {
        if (!context && boundaries !== null) return;
        let winScale =  100 / (scaleRef.current * 100);
        
        if (image) {
            const middleXCanvas = boundaries.width / 2;
            const middleYCanvas = boundaries.height / 2;
            let base_image = new Image();
            base_image.src = URL.createObjectURL(image);
            base_image.onload = function() {
                const storedTransform = context.getTransform();
                context.canvas.width = context.canvas.width;
                context.setTransform(storedTransform);
                // context.clearRect(viewportTopLeft.x, viewportTopLeft.y, context.canvas.width * winScale, context.canvas.height * winScale);
                context.drawImage(base_image, (middleXCanvas - this.width / 2), (middleYCanvas - this.height / 2));
                drawStoredLines();
                drawLine();
            }
        } else {
            const storedTransform = context.getTransform();
            context.canvas.width = context.canvas.width;
            context.setTransform(storedTransform);
            // context.clearRect(viewportTopLeft.x, viewportTopLeft.y, context.canvas.width * winScale, context.canvas.height * winScale);
            drawStoredLines();
            drawLine();
        }
    }

    useEffect(() => {
        if (!context) return;

        reDraw();

        
    }, [start.current, end.current]);

    useEffect(() => {
        console.log(loops);
    }, [loops])

    const undo = () => {
        let tmpItems = [...items];
        tmpItems.pop();
        setItems(tmpItems);
    }

    const removeSelectedLine = (id, bool) => {
        if (bool) return;
        let tmpItems = [...items];
        let item = {...tmpItems[id]};
        item.color = '#000000';
        tmpItems[id] = item;
        setItems(tmpItems);
    }

    const hightlightLine = (id) => {
        let tmpItems = [...items];
        let item = {...tmpItems[id]};
        item.color = '#ff0000';
        tmpItems[id] = item;
        setItems(tmpItems);
    }

    const hightlightLoop = (id) => {
        let tmpItems = [...loops];
        let loop = _.cloneDeep(tmpItems[id]);
        
        loop.forEach(l => {
            if (l.color === "#000000")
                l.color = "#ff0000"
            else l.color = "#000000"
        })

        tmpItems[id] = loop;
        setLoops(tmpItems);
    }

    const getLineHeight = (item) => {
        const xDiff2ndPow = Math.pow(Math.abs(item.start.x - item.end.x), 2);
        const yDiff2ndPow = Math.pow(Math.abs(item.start.y - item.end.y), 2);
        return Math.sqrt(xDiff2ndPow + yDiff2ndPow);
    }

    const edit = (id, lineWidth, height) => {
        let tmpItems = [...items];
        let item = {...tmpItems[id]};
        item.lineWidth = lineWidth;
        item.realHeight = height;
        item.color = '#000000';
        tmpItems[id] = item;

        if (items[id].realHeight !== height) {
            const refHeight = getLineHeight(item);
            tmpItems.forEach((item, i) => {
                if (i === id) return;
                item.realHeight = Number((getLineHeight(item) * height) / refHeight).toFixed(2);
            })
        }
        setItems(tmpItems);
        setModalShow(false);
        setSelectedItem(undefined);
        setSelectedID(null);
    }

    const selectLine = (id) => {
        setSelectedItem(items[id]);
        setSelectedID(id);
        
        let tmpItems = [...items];
        let item = {...tmpItems[id]};
        item.color = '#ff0000';
        tmpItems[id] = item;
        setItems(tmpItems);
    }

    const addImage = (e) => {
        if (!e.target.files || e.target.files.length === 0) return;
        setImage(e.target.files[0]);
    }

    useEffect(() => {
        if (selectedItem !== undefined) setModalShow(true);
    }, [selectedItem])

    function polygonArea(polygons) {
        var j = 0;
        var area = 0;
        console.log('Polygons is...', polygons);

        for (var i = 0; i < polygons.length; i++) {
            j = (i + 1) % polygons.length;
            area += polygons[i].x * polygons[j].y;
            area -= polygons[i].y * polygons[j].x;
        }

        area /= 2;

        let realSquareArea = polygons[0].l * polygons[0].l;
        let pixelSquareArea = getLineHeight({start: {x: polygons[0].x, y: polygons[0].y}, end: {x: polygons[1].x, y: polygons[1].y}})
        pixelSquareArea = Math.pow(pixelSquareArea, 2)

        console.log("Square in PX", pixelSquareArea);
        console.log("Square in m", realSquareArea);

        area = Math.abs(area);
        console.log("Area in px", area);

        area = (area * realSquareArea) / pixelSquareArea
        console.log("Area in m", area);
        return area;
    }

    const vertices = () => {
        let graph = [];

        for (let i = 0; i < items.length; i++) {
            graph.push([{...items[i].start, pos: i, start: true}, {...items[i].end, pos: i, start: false}]);
        }

        let cycles = []
        let cycles_result = []

        function main() {
            for (const edge of graph) {
                for (const node of edge) {
                    findNewCycles([node])
                }
            }
            // console.log(cycles_result);
            setLoops(cycles_result);
        }

        function hasDuplicates(a) {
            const noDups = new Set(a);
            return a.length !== noDups.size;
        }

        function swap(sourceObj, sourceKey, targetObj, targetKey) {
            var temp = sourceObj[sourceKey];
            sourceObj[sourceKey] = targetObj[targetKey];
            targetObj[targetKey] = temp;
        }

        function findNewCycles(path) {
            const start_node = {x: path[0]?.x, y: path[0]?.y};
            let next_node = null
            let sub = []

            const path_node = {x: path[path.length - 1]?.x, y: path[path.length - 1]?.y};

            // visit each edge and each node of each edge
            for (const edge of graph) {
                const [node1, node2] = edge
                const node1_node = {x: node1?.x, y: node1?.y};
                if (edge.some(item => item.x === start_node?.x && item.y === start_node?.y)) {
                    next_node = JSON.stringify(node1_node) === JSON.stringify(start_node) ? node2 : node1
                }
                const next_node_node = {x: next_node?.x, y: next_node?.y};
                const path_result = path.map(e => ({x: e?.x, y: e?.y}))

                if (notVisited(next_node_node, path_result)) {
                    // neighbor node not on path yet
                    sub = [next_node].concat(path)
                    // explore extended path
                    findNewCycles(sub)
                } else if (path.length > 2 && JSON.stringify(next_node_node) === JSON.stringify(path_node)) {
                    // cycle found
                    const p = rotateToSmallest(path_result)
                    const inv = invert(p)
                    let unique_ids = path.map(o => o.pos);
                    if (isNew(p) && isNew(inv) && !hasDuplicates(unique_ids)) {
                        let inv_unique_ids = path.map(o => {
                            let id = o.pos;
                            return o.start ? [items[id].end, items[id].start] : [items[id].start, items[id].end]
                        });

                        if (findCorrectLoop(inv_unique_ids) === true) {
                            cycles.push(p)
                            let cycle_ids = path.map(o => o.pos);
                            let cycle_lines = [];
                            let tmp_cycle_lines = [];

                            cycle_ids.forEach(i => tmp_cycle_lines.push({...items[i], used: false}))

                            cycle_lines.push(tmp_cycle_lines[0]);

                            tmp_cycle_lines[0].used = true
                            
                            for (let i = 0; i < tmp_cycle_lines.length; i++) {
                                if (tmp_cycle_lines[i].used === true) continue;
                                let tmp = _.cloneDeep(tmp_cycle_lines[i]);
                                if (JSON.stringify(cycle_lines[cycle_lines.length - 1].end) === JSON.stringify(tmp_cycle_lines[i].end)) {
                                    tmp_cycle_lines[i].used = true
                                    swap(tmp, "end", tmp, "start");
                                    i = 0;
                                    cycle_lines.push(tmp);
                                } else if (JSON.stringify(cycle_lines[cycle_lines.length - 1].end) === JSON.stringify(tmp_cycle_lines[i].start)) {
                                    tmp_cycle_lines[i].used = true
                                    i = 0;
                                    cycle_lines.push(tmp);
                                }
                            }
                            console.log(polygonArea(cycle_lines.map(o => ({x: o.start.x, y: o.start.y, l:o.realHeight}))));
                            cycles_result.push(cycle_lines);
                        }
                    }
                }
            }
        }

        function findCorrectLoop(graph) {
            let ret = false;
            for (const edge of graph) {
                for (const node of edge) {
                    checkLoop([node])
                }
            }

            function checkLoop(path) {
                const start_node = {x: path[0]?.x, y: path[0]?.y};
                let next_node = null
                let sub = []
    
                const path_node = {x: path[path.length - 1]?.x, y: path[path.length - 1]?.y};

                for (const edge of graph) {
                    const [node1, node2] = edge
                    const node1_node = {x: node1?.x, y: node1?.y};
                    if (edge.some(item => item.x === start_node?.x && item.y === start_node?.y)) {
                        next_node = JSON.stringify(node1_node) === JSON.stringify(start_node) ? node2 : node1
                    }
                    const next_node_node = {x: next_node?.x, y: next_node?.y};
                    const path_result = path.map(e => ({x: e?.x, y: e?.y}))
    
                    if (notVisited(next_node_node, path_result)) {
                        sub = [next_node].concat(path)
                        checkLoop(sub)
                    } else if (path.length > 2 && JSON.stringify(next_node_node) === JSON.stringify(path_node)) {
                        ret = true;
                    }
                }
            }
            return ret;
        }

        function invert(path) {
            return rotateToSmallest([...path].reverse())
        }

        // rotate cycle path such that it begins with the smallest node
        function rotateToSmallest(path) {
            const n = path.indexOf(path.reduce(function(prev, curr) {
                return prev.x < curr.x ? prev : curr;
            }))
            return path.slice(n).concat(path.slice(0, n))
        }

        function isNew(path) {
            const p = JSON.stringify(path)
            for (const cycle of cycles) {
                if (p === JSON.stringify(cycle)) {
                    return false
                }
            }
            return true
        }

        function notVisited(node, path) {
            const n = JSON.stringify(node)
            for (const p of path) {
                if (n === JSON.stringify(p)) {
                    return false
                }
            }
            return true
        }

        main()
    }
  
    return (
        <div className="canvas-grid">
            <canvas
                onMouseDown={startPan}
                ref={canvasRef}
            ></canvas>
            <div className="canvas-panel">
                <div className="canvas-options">
                    <SHButton onClick={() => context && reset(context)}>{t("reset")}</SHButton>
                    <SHButton onClick={() => Boolean(items.length) && vertices()}>Vertices</SHButton>
                    <SHButton onClick={() => {setItems([]); setImage(undefined); itemsRef.current = new Array()}}>{t("clear")}</SHButton>
                    <SHButton onClick={(e) => undo(e)}>{t("undo")}</SHButton>
                </div>
                <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={addImage}
                />
                <div className="list-wrapper">
                    <div className="line-list">
                        {Boolean(items.length) && items.map((l, i)=> {
                            return <div key={`line-key-${i}`} className="line-item" onMouseEnter={() => hightlightLine(i)} onMouseLeave={() => removeSelectedLine(i, modalShow)}>
                                <span>{t("lineNo")} {i}</span>
                                <SHButton className="outline" onClick={() => selectLine(i)}>
                                    <FontAwesomeIcon icon={faEdit} size="sm" />
                                </SHButton>
                            </div>
                        })}
                    </div>

                    <div className="line-list">
                        {Boolean(loops.length) && loops.map((l, i)=> {
                            return <div key={`loop-key-${i}`} className="line-item" onMouseEnter={() => hightlightLoop(i)} onMouseLeave={() => hightlightLoop(i)}>
                                <span>Loop No. {i}</span>
                                <SHButton className="outline">
                                    <FontAwesomeIcon icon={faEdit} size="sm" />
                                </SHButton>
                            </div>
                        })}
                    </div>
                </div>
            </div>

            {modalShow && <EditLineModal
                show={modalShow}
                onHide={() => {setModalShow(false); removeSelectedLine(selectedID, false)}}
                {...{edit, selectedID, selectedItem}}
            />}
        </div>
    );
}