import { createContext, useState, useEffect, useCallback, useContext } from "react";
import SwaggerClient from "swagger-client";
import sigmaheatYaml from "../assets/SigmaHeatAPI.yaml";
import YAML from "js-yaml";
import Loading from "../components/Loading";
const API_URL = process.env.REACT_APP_API_URL;

export const SwaggerContext = createContext();

export function useSwagger() {
    return useContext(SwaggerContext);
}

export const SwaggerProvider = ({ children }) => {
    const [client, setClient] = useState(null);

    const convertYamlToJson = async (path) => {
        try {
            const yamlText = await fetch(path).then((response) => response.text());
            return YAML.load(yamlText);
        } catch (error) {
            console.error("Error converting YAML to JSON:", error);
        }
    };

    const loadSwagger = useCallback(async() => {
        try {
            const json = await convertYamlToJson(sigmaheatYaml);
            console.log("JSON: ", json)
            console.log("APIURL: ", API_URL)
            const client = await SwaggerClient({
                spec: json,
            });

            client.spec.servers = [{ url: API_URL }];

            setClient(client);
        } catch (error) {
            console.error("Fehler beim Laden der Swagger-Datei", error);
        }
    }, []);

    useEffect(() => {
        loadSwagger();
    }, [loadSwagger]);

    if (client === null) return <div><Loading/></div>;

    return <SwaggerContext.Provider value={client}>{children}</SwaggerContext.Provider>;
};