import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAuth } from '../Context'
import Gallery from '../components/Gallery'
import { useToast } from '../NotificationsContent';
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import { useSwagger } from "../context/SwaggerContext";
import useBuildingHook from '../hooks/useBuildingHook'

export default function BuildingPictures() {
    const [images, setImages] = useState([]);
    const [loadedImages, setLoadedImages] = useState([]);
    const [loader, setLoader] = useState(true);
    const [sort, setSort] = useState('date')
    const galleryRef = useRef(null);
    const client = useSwagger();

    const _buildingGuid = useParams()['buildingGuid'];
    const { login } = useAuth();
    const { addToast } = useToast();
    const { t } = useTranslation();
    useBuildingHook()

    const fetchImage = useCallback((img) => {
        return new Promise(async(resolve, reject) => {
            // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/picture/${img.id}`, {
            //     headers: {
            //         'Authorization': login.Authorization,
            //         "Content-Type": "application/json"
            //     }
            // })
            // .then((response) => {
            //     if (response.status === 200) return response.blob();
            //     return undefined;
            // })
            // .then((content) => {
            //     if (content !== undefined && content.size > 0) {
            //         let image = img;
            //         image.src = URL.createObjectURL(content);
            //         setLoadedImages(prev => {
            //             const isImageAlreadyLoaded = prev.some(img => img.id === image.id);
            //             return !isImageAlreadyLoaded ? [...prev, image] : prev;
            //         });
            //     }
            //     resolve();
            // })
            // .catch((error) => {
            //     reject(error);
            // });

            if (!client) return;

            const originalRequestInterceptor = client.http.requestInterceptor;

            try {
                client.requestInterceptor = (req) => {
                    req.headers["Content-Type"] = "application/json";
                    req.headers["Authorization"] = login.Authorization;
                    return req;
                };

                const response =  await client.apis["building"].building_picture_retrieve({ building_uuid: _buildingGuid, picture_uuid: img.id })

                if (response.status === 200 && response.data && response.data.size > 0) {
                    let image = img;
                    image.src = URL.createObjectURL(response.data);
                    setLoadedImages((prev) => {
                        const isImageAlreadyLoaded = prev.some((img) => img.id === image.id);
                        return !isImageAlreadyLoaded ? [...prev, image] : prev;
                    });
                }

                resolve();

                client.http.requestInterceptor = originalRequestInterceptor;
            } catch (error) {
                reject(error);
                client.http.requestInterceptor = originalRequestInterceptor;
            }
        });
    }, [client, login.Authorization, _buildingGuid]);

    const loadImageSrc = useCallback((startIndex, endIndex) => {
        const imagesToLoad = images.slice(startIndex, endIndex);

        Promise.all(imagesToLoad.map((img) =>
            fetchImage(img).catch((error) => {
                addToast(t('buildingPictures'), t('errorLoadingImageID', {filename: img.title}), "error")
            })
        ))
    }, [fetchImage, images, addToast, t])

    const loadPictures = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/pictures`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     }
        // })
        // .then((response) => {
        //     if (response.status === 200) return response.json();
        //     setLoader(false)
        //     return [];
        // })
        // .then((data) => {
        //     if (!Boolean(data.length)) return;

        //     setImages(data);
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_pictures_retrieve({ building_uuid: _buildingGuid });
            if (response.status === 204) {
                setLoader(false)
            }

            if (response.status === 200 && Boolean(response.obj?.length)) {
                setImages(response.obj);
            }
        } catch (error) {
            console.error(error);
        } finally {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization]);

    const uploadFiles = (files) => {
        return new Promise(async (resolve, reject) => {
            try {
                for (let i = 0; i < files.length; i++) {
                    await uploadFile(files[i]);
                    galleryRef.current.updateProgress(parseInt((i + 1) / files.length * 100), i)
                }
                resolve();

            } catch (error) {
                reject(error);
            }
        });
    }

    const downscaleImage = (file) => {
        addToast(t('imageProcessing'), t('downscaling', {filename: file.name}), "notification");
        return new Promise((resolve, reject) => {
            const image = new Image();
            const reader = new FileReader();
    
            reader.onload = function (e) {
                image.onload = function () {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const maxSize = 3 * 1024 * 1024;
    
                    let width = image.width;
                    let height = image.height;
                    let scaleFactor = 1;
    
                    if (file.size > maxSize) {
                        scaleFactor = Math.sqrt(maxSize / file.size);
                        width = Math.floor(width * scaleFactor);
                        height = Math.floor(height * scaleFactor);
                    }
    
                    canvas.width = width;
                    canvas.height = height;
    
                    ctx.drawImage(image, 0, 0, width, height);
    
                    canvas.toBlob(blob => {
                        const downscaledFile = new File([blob], file.name, {
                            type: file.type,
                            lastModified: Date.now()
                        });
                        
                        const downscaledReader = new FileReader();
                        downscaledReader.onload = function (e) {
                            const downscaledImageDataUrl = e.target.result;
                            resolve({ file: downscaledFile, reader: downscaledImageDataUrl });
                        };
                        
                        downscaledReader.onerror = function (e) {
                            reject(new Error(t('errorOptimisedFileAccess')));
                        };
                        
                        downscaledReader.readAsDataURL(downscaledFile);
                    }, file.type);
                };
    
                image.src = e.target.result;
            };
    
            reader.onerror = function (e) {
                reject(new Error(t('failedReadingFile')));
            };
    
            reader.readAsDataURL(file);
        });
    };
      
    const uploadFile = (file) => {
        const maxSize = 6 * 1024 * 1024;

        if (file.file.size > maxSize) {
            return downscaleImage(file.file)
            .then((downscaledFile) => {
                let downscaledImg = {...file};
                downscaledImg.file = downscaledFile.file
                downscaledImg.reader = downscaledFile.reader
                return uploadFile(downscaledImg);
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
        }

        return new Promise(async(resolve, reject) => {
            var data = new FormData()
            data.append('file', file.file)

            // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/picture/`, {
            //     body: data,
            //     method: 'POST',
            //     headers: {
            //         'Authorization': login.Authorization,
            //         // "Content-Type": "application/json"
            //     }
            // })
            // .then(response => {
            //     if (response.status === 201) {
            //         resolve();
            //     } else {
            //         reject(new Error(t('imageUploadError', {file: data.name})));
            //     }
            // })
            // .catch(error => {
            //     reject(error);
            // });

            if (!client) return;

            const originalRequestInterceptor = client.http.requestInterceptor;

            //   Content-Type Header für FormData: Wenn du FormData für den Upload von Dateien verwendest, solltest du den Content-Type Header nicht manuell auf application/json setzen. FormData wird typischerweise mit dem Content-Type multipart/form-data verwendet, und der Browser setzt diesen Header automatisch zusammen mit dem korrekten Boundary. Durch manuelles Setzen des Content-Type auf application/json kann es zu Problemen kommen, da der Server den Body nicht korrekt als multipart/form-data interpretiert.

            //   FormData sollte direkt als body der Anfrage übergeben werden, ohne durch den requestInterceptor modifiziert zu werden. Es ist möglich, dass der SwaggerClient nicht wie erwartet mit FormData umgeht, wenn du versuchst, es im requestInterceptor zuzuweisen.

            try {
                client.requestInterceptor = (req) => {
                    req.headers["Authorization"] = login.Authorization;
                    req.body = data;
                    return req;
                };

                const response = await client.apis["building"].building_picture_create({ building_uuid: _buildingGuid });

                if (response.status === 201) {
                    resolve();
                } else {
                    reject(new Error(t("imageUploadError", { file: data.name })));
                }

                client.http.requestInterceptor = originalRequestInterceptor;
            } catch (error) {
                reject(error);
                client.http.requestInterceptor = originalRequestInterceptor;
            }
        });
    }

    const imageUpload = (loadedImages) => {
        if (loadedImages.length === 0) return;

        uploadFiles(loadedImages)
        .then(() => {
            loadPictures();
            galleryRef.current.closeModal();
        })
        .catch(error => {
            addToast(t('buildingPictures'), t('failedFileUpload'), "error")
        });
    }

    useEffect(() => {
        loadPictures();
    }, [loadPictures]);

    useEffect(() => {
        if (!Boolean(images.length) && images.length < 12) return;
        loadImageSrc(0, 12)
    }, [images, loadImageSrc])

    useEffect(() => {
        if (loadedImages.length > 0) setLoader(false);
    }, [loadedImages])

    useEffect(() => {
        if (!Boolean(loadedImages.length)) return
        let sortedArray = [...loadedImages]
        if (sort === 'date') {
            sortedArray.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
        } else if (sort === 'title') {
            sortedArray.sort((a, b) => a.title.localeCompare(b.title));
        }
        setLoadedImages(sortedArray)
        setLoader(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort])

    return <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">
            <Gallery ref={galleryRef} {...{title: `${t('buildingGallery')} ${t('inThe')} ${t('building')}: ${login?.currentBuilding?.name}`, loader, images: loadedImages, imageUpload, loadImages: loadImageSrc, loadedImage: images, sort, setSort}} />
        </div>
    </MainLayout>
}