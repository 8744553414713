import { useState, useEffect, useCallback } from "react"
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const InvoicesTableRow = ({ invoice }) => {
    const { t } = useTranslation()

    return <tr>
        <td>
            <p className="m-0">{invoice.title}</p>
            <span>{invoice.period.start} - {invoice.period.end}</span>
        </td>
        <td>{invoice.quantity}</td>
        <td>{invoice.quantity === 0 ? "-" : invoice.amount / invoice.quantity}</td>
        <td>{invoice.amount}</td>
        <td>
            <OverlayTrigger
                trigger={['hover', 'focus']}
                overlay={
                    <Tooltip>{t("download")}</Tooltip>
                }>
                <Button variant="outline-primary" className="p-10" onClick={() => console.log("DOWNLOAD")}>
                    <FontAwesomeIcon icon={faDownload} />
                </Button>
            </OverlayTrigger>
        </td>
    </tr>
}

export const InvoicesTable = ({ stripe_subscription_id, orgGuid }) => {
    const _orgGuid = useParams()['orgGuid'] || orgGuid;
    const { login } = useAuth()
    const { addToast } = useToast()
    const { t } = useTranslation()
    const [invoices, setInvoices] = useState(undefined);

    const invoicesTableStructure = [
        {
            col: t("description"),
            type: 'label'
        }, {
            col: t('quantity'),
            type: 'label'
        }, {
            col: t('priceUnit'),
            type: 'label'
        }, {
            col: t('amount'),
            type: 'label'
        }, {
            col: t('download'),
            type: 'label'
        }
    ]

    const fetchInvoices = useCallback(() => {
        // if (stripe_subscription_id === undefined) return
        // fetch(`https://tech.sigmaheat.de/payment/org/${_orgGuid}/listinvoices/${stripe_subscription_id}`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "GET"
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t("responseError"))
        //     return response.json()
        // })
        // .then(data => {
        //     setInvoices(data)
        // })
        // .catch(error => {
        //     addToast(t("error"), error.message, "error")
        // });
    }, [login.Authorization, t, addToast, stripe_subscription_id])

    useEffect(() => {
        fetchInvoices()
    }, [])

    return <>
        {(invoices === undefined) ? (
            <TablePlaceholder {...{ structure: invoicesTableStructure }} />
        ) : (!Boolean(invoices?.length)) ? (
            <p className="m-0">{t("noLicences")}</p>
        ) : (
            <Table responsive>
                <thead>
                    <tr>
                        <th>{t("description")}</th>
                        <th>{t("quantity")}</th>
                        <th>{t("priceUnit")}</th>
                        <th>{t("amount")}</th>
                        <th>{t("download")}</th>
                    </tr>
                </thead>
                <tbody>
                    {invoices?.map(invoice => <InvoicesTableRow key={`invoice-${invoice.title}`} {...{ invoice }} /> )}
                </tbody>
            </Table>
        )}
    </>
}

export default InvoicesTable;