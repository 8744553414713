import { connectionsColor } from "./utils";

class Connection {
    constructor(startConnector, endConnector, ctx, deleteConnection) {
        this.startConnector = startConnector;
        this.endConnector = endConnector;
        this.color = connectionsColor(startConnector.type !== 'universal' ? startConnector.type : endConnector.type);
        this.type = startConnector.type !== 'universal' ? startConnector.type : endConnector.type;
        this.ctx = ctx;
        this.lineWidth = 2;
        this.deleteConnection = deleteConnection;
        this.onContextMenuHandler = this.onContextMenu.bind(this);
        this.addEventListeners();
    }

    addEventListeners() {
        this.ctx.canvas.addEventListener('contextmenu', this.onContextMenuHandler);
    }

    removeEventListeners() {
        this.ctx.canvas.removeEventListener('contextmenu', this.onContextMenuHandler);
    }

    mouseOffset(e) {
        return {
            x: e.clientX - this.startConnector.component.canvas.getBoundingClientRect().x,
            y: e.clientY - this.startConnector.component.canvas.getBoundingClientRect().y
        };
    }

    getWindowToCanvas(e) { 
        e = e || window.event;
        var target = e.target || e.srcElement,
            style = target.currentStyle || window.getComputedStyle(target, null),
            borderLeftWidth = parseInt(style["borderLeftWidth"], 10),
            borderTopWidth = parseInt(style["borderTopWidth"], 10),
            rect = target.getBoundingClientRect(),
            offsetX = e.clientX - borderLeftWidth - rect.left,
            offsetY = e.clientY - borderTopWidth - rect.top;
        let x = (offsetX * target.width) / target.clientWidth;
        let y = (offsetY * target.height) / target.clientHeight;

        var transform = this.ctx.getTransform();
        const invMat = transform.invertSelf();
        return {
            x: x * invMat.a + y * invMat.c + invMat.e,
            y: x * invMat.b + y * invMat.d + invMat.f
        };
    }

    onContextMenu(e) {
        e.preventDefault();
        e.stopPropagation();

        const { x, y } = this.getWindowToCanvas(e)

        const connection = this.containsPoint(x, y, 10)

        if (!connection) return

        let existingMenu = document.getElementById("context-menu");
        if (existingMenu) {
            existingMenu.remove();
        }
        
        const contextMenuPosition = this.mouseOffset(e);
        const contextMenu = document.createElement("div");
        contextMenu.id = "context-menu";
        contextMenu.style.left = `${contextMenuPosition.x}px`;
        contextMenu.style.top  = `${contextMenuPosition.y}px`;
        
        const ul = document.createElement("ul");

        const deleteComponent = document.createElement("li");
        deleteComponent.textContent = "Löschen";

        deleteComponent.addEventListener("click", () => {
            document.getElementById("context-menu")?.remove();
            this.deleteConnection(this);
        });

        ul.appendChild(deleteComponent);
        contextMenu.appendChild(ul);

        this.startConnector.component.canvas.parentNode.appendChild(contextMenu);
        contextMenu.style.display = "block";
    }

    updateConnectors() {
        this.startConnector = this.startConnector.component.connectors.find(c => c.x === this.startConnector.x && c.y === this.startConnector.y);
        this.endConnector = this.endConnector.component.connectors.find(c => c.x === this.endConnector.x && c.y === this.endConnector.y);
    }

    draw() {
        const componentPadding = 20

        const startConnector = this.startConnector;
        const endConnector = this.endConnector;

        this.ctx.strokeStyle = this.color;
        this.ctx.lineWidth = this.lineWidth;
        this.ctx.beginPath();
        

        const segments = this.getSegments(startConnector, endConnector, componentPadding);
        this.ctx.moveTo(startConnector.aX, startConnector.bY);
        segments.forEach(segment => {
            this.ctx.lineTo(segment.x2, segment.y2);
        });

        this.ctx.stroke();
    }

    containsPoint(x, y, radius = 10) {
        const segments = this.getSegments(this.startConnector, this.endConnector, 20);
        return segments.some(segment => this.pointNearSegment(segment, x, y, radius));
    }

    pointNearSegment(segment, x, y, radius) {
        const { x1, y1, x2, y2 } = segment;

        const dx = x2 - x1;
        const dy = y2 - y1;
        const lengthSquared = dx * dx + dy * dy;

        const t = Math.max(0, Math.min(1, ((x - x1) * dx + (y - y1) * dy) / lengthSquared));
        const closestX = x1 + t * dx;
        const closestY = y1 + t * dy;

        const distX = x - closestX;
        const distY = y - closestY;
        const distance = Math.sqrt(distX * distX + distY * distY);

        return distance <= radius;
    }

    getSegments(startConnector, endConnector, componentPadding = 20, padding = 100) {
        const segments = [];
    
        if (startConnector.direction === 'top') {
            if (endConnector.direction === 'top') {
                const xOverlapStartLeft = startConnector.aX > endConnector.component.x - componentPadding &&
                    startConnector.aX < endConnector.aX &&
                    startConnector.component.y >= endConnector.bY + endConnector.component.height + componentPadding * 2;
                
                const xOverlapStartRight = startConnector.aX <= endConnector.component.x + endConnector.component.width + componentPadding &&
                    startConnector.aX >= endConnector.aX &&
                    startConnector.bY >= endConnector.bY + endConnector.component.height + componentPadding * 2;
                
                const xOverlapEndLeft = endConnector.aX > startConnector.component.x - componentPadding &&
                    endConnector.aX < startConnector.aX &&
                    endConnector.component.y >= startConnector.bY + startConnector.component.height + componentPadding * 2;
                
                const xOverlapEndRight = endConnector.aX <= startConnector.component.x + startConnector.component.width + componentPadding &&
                    endConnector.aX >= startConnector.aX &&
                    endConnector.bY >= startConnector.bY + startConnector.component.height + componentPadding * 2;
    
                if (startConnector.component.x + startConnector.component.width < endConnector.component.x &&
                    startConnector.component.y >= endConnector.component.y && 
                    (!xOverlapStartLeft && !xOverlapStartRight) &&
                    (!xOverlapEndLeft && !xOverlapEndRight)
                ) {
                    console.log('1')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: endConnector.component.y - componentPadding },
                        { x1: startConnector.aX, y1: endConnector.component.y - componentPadding, x2: endConnector.aX, y2: endConnector.component.y - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.component.y - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
                else if (startConnector.component.x + startConnector.component.width < endConnector.component.x &&
                    startConnector.component.y < endConnector.component.y && 
                    (!xOverlapStartLeft && !xOverlapStartRight) &&
                    (!xOverlapEndLeft && !xOverlapEndRight)
                ) {
                    console.log('2')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.component.y - componentPadding, x2: endConnector.aX, y2: startConnector.component.y - componentPadding },
                        { x1: endConnector.aX, y1: startConnector.component.y - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (startConnector.component.x + startConnector.component.width >= endConnector.component.x &&
                    startConnector.component.y >= endConnector.component.y && 
                    (!xOverlapStartLeft && !xOverlapStartRight) &&
                    (!xOverlapEndLeft && !xOverlapEndRight)
                ) {
                    console.log('3')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: endConnector.component.y - componentPadding },
                        { x1: startConnector.aX, y1: endConnector.component.y - componentPadding, x2: endConnector.aX, y2: endConnector.component.y - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.component.y - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
                else if (startConnector.component.x + startConnector.component.width >= endConnector.component.x &&
                    startConnector.component.y < endConnector.component.y && 
                    (!xOverlapStartLeft && !xOverlapStartRight) &&
                    (!xOverlapEndLeft && !xOverlapEndRight)
                ) {
                    console.log('4')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.component.y - componentPadding, x2: endConnector.aX, y2: startConnector.component.y - componentPadding },
                        { x1: endConnector.aX, y1: startConnector.component.y - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (xOverlapStartLeft) {
                    console.log('5')
                    const halfY = Math.abs(startConnector.bY - endConnector.bY - endConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - halfY },
                        { x1: startConnector.aX, y1: startConnector.bY - halfY, x2: endConnector.component.x - componentPadding, y2: startConnector.bY - halfY },
                        { x1: endConnector.component.x - componentPadding, y1: startConnector.bY - halfY, x2: endConnector.component.x - componentPadding, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.component.x - componentPadding, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                } else if (xOverlapStartRight) {
                    console.log('6')
    
                    const halfY = Math.abs(startConnector.bY - endConnector.bY - endConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - halfY },
                        { x1: startConnector.aX, y1: startConnector.bY - halfY, x2: endConnector.component.x + endConnector.component.width + componentPadding, y2: startConnector.bY - halfY },
                        { x1: endConnector.component.x + endConnector.component.width + componentPadding, y1: startConnector.bY - halfY, x2: endConnector.component.x + endConnector.component.width + componentPadding, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.component.x + endConnector.component.width + componentPadding, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
    
                } else if (xOverlapEndLeft) {
                    console.log('7')
                    const halfY = Math.abs(endConnector.bY - startConnector.bY - startConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY - componentPadding, x2: startConnector.component.x - componentPadding, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.component.x - componentPadding, y1: startConnector.bY - componentPadding, x2: startConnector.component.x - componentPadding, y2: startConnector.bY + startConnector.component.height + halfY },
                        { x1: startConnector.component.x - componentPadding, y1: startConnector.bY + startConnector.component.height + halfY, x2: endConnector.aX, y2: startConnector.bY + startConnector.component.height + halfY },
                        { x1: endConnector.aX, y1: startConnector.bY + startConnector.component.height + halfY, x2: endConnector.aX, y2: endConnector.bY }
                    );
    
                } else if (xOverlapEndRight) {
                    console.log('8')
                    const halfY = Math.abs(endConnector.bY - startConnector.bY - startConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY - componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: startConnector.bY - componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: startConnector.bY + startConnector.component.height + halfY },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: startConnector.bY + startConnector.component.height + halfY, x2: endConnector.aX, y2: startConnector.bY + startConnector.component.height + halfY },
                        { x1: endConnector.aX, y1: startConnector.bY + startConnector.component.height + halfY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
            }
    
            if (endConnector.direction === 'bottom') {
                const firstCondition = startConnector.bY - componentPadding >= endConnector.bY + componentPadding;
    
                const xOverlapLeft = startConnector.component.x + startConnector.component.width + componentPadding >= endConnector.component.x - componentPadding &&
                    startConnector.component.x + startConnector.component.width < endConnector.component.x &&
                    startConnector.bY + startConnector.component.height + componentPadding < endConnector.bY - endConnector.component.height - componentPadding;
                
                const xOverlapRight = startConnector.component.x >= endConnector.component.x + endConnector.component.width &&
                    startConnector.component.x - componentPadding <= endConnector.component.x + endConnector.component.width + componentPadding &&
                    startConnector.bY + startConnector.component.height + componentPadding < endConnector.bY - endConnector.component.height - componentPadding;
    
                const xOverlapMiddleLeft = startConnector.component.x + startConnector.component.width > endConnector.component.x && startConnector.component.x < endConnector.component.x;
                const xOverlapMiddleRight = startConnector.component.x < endConnector.component.x + endConnector.component.width && startConnector.component.x >= endConnector.component.x;
                const leastDistance = startConnector.bY + startConnector.component.height >= endConnector.bY && endConnector.bY + 2 * componentPadding > startConnector.bY;
    
                const middleOverlap = (startConnector.aX - startConnector.component.x + startConnector.component.width) / 2 + startConnector.component.x >= endConnector.component.x &&
                                          (startConnector.aX - startConnector.component.x) / 2 + startConnector.component.x < endConnector.component.x + endConnector.component.width;
    
                if (firstCondition && !leastDistance) {
                    console.log('1')
                    const half = (endConnector.bY - startConnector.bY) / 2
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + half },
                        { x1: startConnector.aX, y1: startConnector.bY + half, x2: endConnector.aX, y2: startConnector.bY + half },
                        { x1: endConnector.aX, y1: startConnector.bY + half, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if ((startConnector.bY - componentPadding < endConnector.bY + componentPadding) && (!xOverlapLeft && !xOverlapRight) && ((!xOverlapMiddleLeft && !xOverlapMiddleRight) || middleOverlap && leastDistance)) {
                    console.log('2')
                    const half = startConnector.aX < endConnector.aX ? (endConnector.component.x - startConnector.component.x - startConnector.component.width) / 2 : (endConnector.component.x - endConnector.component.width - startConnector.component.x) / 2
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY - componentPadding, x2: startConnector.component.x + startConnector.component.width + half, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + half, y1: startConnector.bY - componentPadding, x2: startConnector.component.x + startConnector.component.width + half, y2: endConnector.bY + componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + half, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (xOverlapLeft && (!xOverlapMiddleLeft && !xOverlapMiddleRight)) {
                    console.log('3')
                    const halfY = Math.abs((endConnector.bY - startConnector.bY - startConnector.component.height - endConnector.component.height)) / 2;
    
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY - componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: startConnector.bY - componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: startConnector.bY + startConnector.component.height + halfY },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: startConnector.bY + startConnector.component.height + halfY, x2: endConnector.component.x - componentPadding, y2: startConnector.bY + startConnector.component.height + halfY },
                        { x1: endConnector.component.x - componentPadding, y1: startConnector.bY + startConnector.component.height + halfY, x2: endConnector.component.x - componentPadding, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.component.x - componentPadding, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
                
                if (xOverlapRight) {
                    console.log('4')
                    const halfY = Math.abs((endConnector.bY - startConnector.bY - startConnector.component.height - endConnector.component.height)) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY - componentPadding, x2: startConnector.component.x - componentPadding, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.component.x - componentPadding, y1: startConnector.bY - componentPadding, x2: startConnector.component.x - componentPadding, y2: startConnector.bY + startConnector.component.height + halfY },
                        { x1: startConnector.component.x - componentPadding, y1: startConnector.bY + startConnector.component.height + halfY, x2: endConnector.component.x + endConnector.component.width + componentPadding, y2: startConnector.bY + startConnector.component.height + halfY },
                        { x1: endConnector.component.x + endConnector.component.width + componentPadding, y1: startConnector.bY + startConnector.component.height + halfY, x2: endConnector.component.x + endConnector.component.width + componentPadding, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.component.x + endConnector.component.width + componentPadding, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (xOverlapMiddleLeft && !leastDistance && !firstCondition) {
                    console.log('5')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY - componentPadding, x2: endConnector.component.x + endConnector.component.width + componentPadding, y2: startConnector.bY - componentPadding },
                        { x1: endConnector.component.x + endConnector.component.width + componentPadding, y1: startConnector.bY - componentPadding, x2: endConnector.component.x + endConnector.component.width + componentPadding, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.component.x + endConnector.component.width + componentPadding, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (xOverlapMiddleRight && !leastDistance && !firstCondition) {
                    console.log('6')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY - componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: startConnector.bY - componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: endConnector.bY + componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (xOverlapMiddleLeft && leastDistance && !middleOverlap) {
                    console.log('7')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: endConnector.bY - endConnector.component.height - componentPadding },
                        { x1: startConnector.aX, y1: endConnector.bY - endConnector.component.height - componentPadding, x2: endConnector.component.x + endConnector.component.width + componentPadding, y2: endConnector.bY - endConnector.component.height - componentPadding },
                        { x1: endConnector.component.x + endConnector.component.width + componentPadding, y1: endConnector.bY - endConnector.component.height - componentPadding, x2: endConnector.component.x + endConnector.component.width + componentPadding, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.component.x + endConnector.component.width + componentPadding, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (xOverlapMiddleRight && leastDistance && !middleOverlap) {
                    console.log('8')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY - componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: startConnector.bY - componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: startConnector.bY + startConnector.component.height + componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: startConnector.bY + startConnector.component.height + componentPadding, x2: endConnector.aX, y2: startConnector.bY + startConnector.component.height + componentPadding },
                        { x1: endConnector.aX, y1: startConnector.bY + startConnector.component.height + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
            }
        
            if (endConnector.direction === 'left') {
                const conditionOne   = startConnector.aX + componentPadding < endConnector.aX && startConnector.bY - componentPadding > endConnector.bY;
                const conditionTwo   = startConnector.bY - componentPadding >= endConnector.component.y + endConnector.component.height + componentPadding && startConnector.aX >= endConnector.aX - componentPadding;
                const conditionThree = startConnector.bY - componentPadding <= endConnector.bY && startConnector.component.x + startConnector.component.width + componentPadding < endConnector.aX - componentPadding;
                const conditionFour  = startConnector.component.y + startConnector.component.height + componentPadding < endConnector.component.y - componentPadding &&
                                       startConnector.component.x + startConnector.component.width + componentPadding > endConnector.aX - componentPadding &&
                                       startConnector.component.x + startConnector.component.width < endConnector.aX
                const conditionFive  = startConnector.component.x - componentPadding > endConnector.aX + endConnector.component.width + componentPadding &&
                                       startConnector.bY - componentPadding < endConnector.component.y + endConnector.component.height + componentPadding &&
                                       startConnector.bY - componentPadding > endConnector.bY
                
                const conditionSix   = !conditionTwo && !conditionThree && startConnector.bY - componentPadding <= endConnector.bY;
                const conditionSeven = startConnector.component.x + startConnector.component.width >= endConnector.component.x &&
                                       startConnector.component.x + startConnector.component.width <= endConnector.component.x + endConnector.component.width
    
                if (conditionOne) {
                    console.log('1')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: endConnector.bY },
                        { x1: startConnector.aX, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionTwo) {
                    console.log('2')
                    const halfY = Math.abs(startConnector.bY - endConnector.component.y - endConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - halfY },
                        { x1: startConnector.aX, y1: startConnector.bY - halfY, x2: endConnector.aX - componentPadding, y2: startConnector.bY - halfY },
                        { x1: endConnector.aX - componentPadding, y1: startConnector.bY - halfY, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionThree) {
                    console.log('3')
                    const halfX = Math.abs(endConnector.component.x - startConnector.component.x - startConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY - componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: startConnector.bY - componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: endConnector.bY },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFour) {
                    console.log('4')
                    const halfY = Math.abs(endConnector.component.y - startConnector.component.y - startConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY - componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: startConnector.bY - componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: startConnector.bY + startConnector.component.height + halfY },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: startConnector.bY + startConnector.component.height + halfY, x2: endConnector.aX - componentPadding, y2: startConnector.bY + startConnector.component.height + halfY },
                        { x1: endConnector.aX - componentPadding, y1: startConnector.bY + startConnector.component.height + halfY, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFive) {
                    console.log('5')
                    const halfX = Math.abs(startConnector.component.x - endConnector.component.x - endConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY - componentPadding, x2: startConnector.component.x - halfX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.component.x - halfX, y1: startConnector.bY - componentPadding, x2: startConnector.component.x - halfX, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: startConnector.component.x - halfX, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSix && !conditionFour && !conditionSeven || (!conditionOne && !conditionTwo && !conditionThree && !conditionFour && !conditionFive && !conditionSeven)) {
                    console.log('6')
                    if (startConnector.bY - componentPadding < endConnector.component.y - componentPadding) {
                        segments.push(
                            { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - componentPadding },
                            { x1: startConnector.aX, y1: startConnector.bY - componentPadding, x2: endConnector.aX - componentPadding, y2: startConnector.bY - componentPadding },
                            { x1: endConnector.aX - componentPadding, y1: startConnector.bY - componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                            { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                        );
                    } else {
                        segments.push(
                            { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: endConnector.component.y - componentPadding },
                            { x1: startConnector.aX, y1: endConnector.component.y - componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.component.y - componentPadding },
                            { x1: endConnector.aX - componentPadding, y1: endConnector.component.y - componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                            { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                        );
                    }
                }
    
                if (conditionSeven && !conditionOne && !conditionTwo) {
                    console.log('7')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.component.y - componentPadding, x2: startConnector.component.x - componentPadding, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.component.x - componentPadding, y1: startConnector.component.y - componentPadding, x2: startConnector.component.x - componentPadding, y2: endConnector.bY },
                        { x1: startConnector.component.x - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
            }
        
            if (endConnector.direction === 'right') {
                const conditionOne   = startConnector.aX - componentPadding > endConnector.aX && startConnector.bY - componentPadding > endConnector.bY;
                const conditionTwo   = startConnector.bY - componentPadding >= endConnector.component.y + endConnector.component.height + componentPadding && startConnector.aX <= endConnector.aX + componentPadding;
                const conditionThree = startConnector.bY - componentPadding <= endConnector.bY && startConnector.component.x - componentPadding > endConnector.component.x + endConnector.component.width + componentPadding;
                
                const conditionFour  = startConnector.component.y + startConnector.component.height + componentPadding < endConnector.component.y - componentPadding &&
                                       startConnector.component.x - componentPadding < endConnector.component.x + endConnector.component.width + componentPadding &&
                                       startConnector.component.x > endConnector.aX
    
                const conditionFive  = startConnector.component.x + startConnector.component.width + componentPadding < endConnector.component.x - componentPadding &&
                                       startConnector.bY - componentPadding < endConnector.component.y + endConnector.component.height + componentPadding &&
                                       startConnector.bY - componentPadding > endConnector.bY
                
                const conditionSix   = !conditionTwo && !conditionThree && startConnector.bY - componentPadding <= endConnector.bY;
    
                const conditionSeven = startConnector.component.x <= endConnector.component.x + endConnector.component.width &&
                                       startConnector.component.x >= endConnector.component.x
                
                if (conditionOne) {
                    console.log('1')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: endConnector.bY },
                        { x1: startConnector.aX, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionTwo) {
                    console.log('2')
                    const halfY = Math.abs(startConnector.bY - endConnector.component.y - endConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - halfY },
                        { x1: startConnector.aX, y1: startConnector.bY - halfY, x2: endConnector.aX + componentPadding, y2: startConnector.bY - halfY },
                        { x1: endConnector.aX + componentPadding, y1: startConnector.bY - halfY, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionThree) {
                    console.log('3')
                    const halfX = Math.abs(startConnector.component.x - endConnector.aX) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY - componentPadding, x2: startConnector.component.x - halfX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.component.x - halfX, y1: startConnector.bY - componentPadding, x2: startConnector.component.x - halfX, y2: endConnector.bY },
                        { x1: startConnector.component.x - halfX, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFour) {
                    console.log('4')
                    const halfY = Math.abs(endConnector.component.y - startConnector.component.y - startConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY - componentPadding, x2: startConnector.component.x - componentPadding, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.component.x - componentPadding, y1: startConnector.bY - componentPadding, x2: startConnector.component.x - componentPadding, y2: startConnector.bY + startConnector.component.height + halfY },
                        { x1: startConnector.component.x - componentPadding, y1: startConnector.bY + startConnector.component.height + halfY, x2: endConnector.aX + componentPadding, y2: startConnector.bY + startConnector.component.height + halfY },
                        { x1: endConnector.aX + componentPadding, y1: startConnector.bY + startConnector.component.height + halfY, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFive) {
                    console.log('5')
                    const halfX = Math.abs(endConnector.component.x - startConnector.component.x - startConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY - componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: startConnector.bY - componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: startConnector.bY - componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSix && !conditionFour && !conditionSeven || (!conditionOne && !conditionTwo && !conditionThree && !conditionFour && !conditionFive && !conditionSeven)) {
                    console.log('6')
                    if (startConnector.bY - componentPadding < endConnector.component.y - componentPadding) {
                        segments.push(
                            { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY - componentPadding },
                            { x1: startConnector.aX, y1: startConnector.bY - componentPadding, x2: endConnector.aX + componentPadding, y2: startConnector.bY - componentPadding },
                            { x1: endConnector.aX + componentPadding, y1: startConnector.bY - componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                            { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                        );
                    } else {
                        segments.push(
                            { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: endConnector.component.y - componentPadding },
                            { x1: startConnector.aX, y1: endConnector.component.y - componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.component.y - componentPadding },
                            { x1: endConnector.aX + componentPadding, y1: endConnector.component.y - componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                            { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                        );
                    }
                }
    
                if (conditionSeven && !conditionOne && !conditionTwo) {
                    console.log('7')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.aX, y1: startConnector.component.y - componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: startConnector.component.y - componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: endConnector.bY },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
            }
        }
    
        if (startConnector.direction === 'bottom') {
            if (endConnector.direction === 'top') {
                const conditionOne   = startConnector.bY + componentPadding < endConnector.bY - componentPadding
                const conditionTwo   = startConnector.bY + componentPadding >= endConnector.bY - componentPadding && startConnector.component.x + startConnector.component.width + componentPadding < endConnector.component.x - componentPadding
                const conditionThree = startConnector.bY + componentPadding >= endConnector.bY - componentPadding && startConnector.component.x - componentPadding > endConnector.component.x + endConnector.component.width + componentPadding
                const conditionFour  = startConnector.component.y - componentPadding > endConnector.component.y + endConnector.component.height + componentPadding &&
                                       startConnector.component.x + startConnector.component.width + componentPadding >= endConnector.component.x - componentPadding &&
                                       endConnector.component.x >= startConnector.component.x + startConnector.component.width
                
                const conditionFive  = startConnector.component.y - componentPadding > endConnector.component.y + endConnector.component.height + componentPadding &&
                                       startConnector.component.x - componentPadding <= endConnector.component.x + endConnector.component.width + componentPadding &&
                                       endConnector.component.x + endConnector.component.width <= startConnector.component.x
                
                const conditionSix   = startConnector.component.x < endConnector.component.x + endConnector.component.width &&
                                       startConnector.component.x > endConnector.component.x
                const conditionSeven = startConnector.component.x + startConnector.component.width > endConnector.component.x &&
                                       startConnector.component.x <= endConnector.component.x
    
                if (conditionOne) {
                    console.log('1')
                    const halfY = Math.abs(endConnector.bY - startConnector.bY) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + halfY },
                        { x1: startConnector.aX, y1: startConnector.bY + halfY, x2: endConnector.aX, y2: startConnector.bY + halfY },
                        { x1: endConnector.aX, y1: startConnector.bY + halfY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionTwo) {
                    console.log('2')
                    const halfX = Math.abs(endConnector.component.x - startConnector.component.x - startConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: endConnector.bY - componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionThree) {
                    console.log('3')
                    const halfX = Math.abs(startConnector.component.x - endConnector.component.x - endConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x - halfX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.component.x - halfX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x - halfX, y2: endConnector.bY - componentPadding },
                        { x1: startConnector.component.x - halfX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFour) {
                    console.log('4')
                    const halfY = Math.abs(startConnector.component.y - endConnector.component.y - endConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: startConnector.bY + componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: startConnector.component.y - halfY },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.component.x - componentPadding, y2: startConnector.component.y - halfY },
                        { x1: endConnector.component.x - componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.component.x - componentPadding, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.component.x - componentPadding, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFive) {
                    console.log('5')
                    const halfY = Math.abs(startConnector.component.y - endConnector.component.y - endConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x - componentPadding, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.component.x - componentPadding, y1: startConnector.bY + componentPadding, x2: startConnector.component.x - componentPadding, y2: startConnector.component.y - halfY },
                        { x1: startConnector.component.x - componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.component.x + endConnector.component.width + componentPadding, y2: startConnector.component.y - halfY },
                        { x1: endConnector.component.x + endConnector.component.width + componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.component.x + endConnector.component.width + componentPadding, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.component.x + endConnector.component.width + componentPadding, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSix && !conditionOne) {
                    console.log('6');
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: endConnector.component.x - componentPadding, y2: startConnector.bY + componentPadding },
                        { x1: endConnector.component.x - componentPadding, y1: startConnector.bY + componentPadding, x2: endConnector.component.x - componentPadding, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.component.x - componentPadding, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSeven && !conditionOne || (!conditionOne && !conditionTwo && !conditionThree && !conditionFour && !conditionFive && !conditionSix)) {
                    console.log('7')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x - componentPadding, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.component.x - componentPadding, y1: startConnector.bY + componentPadding, x2: startConnector.component.x - componentPadding, y2: endConnector.bY - componentPadding },
                        { x1: startConnector.component.x - componentPadding, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
            }
    
            if (endConnector.direction === 'bottom') {
                const conditionOne   = (endConnector.component.x + endConnector.component.width + componentPadding <= startConnector.aX ||
                                       startConnector.aX <= endConnector.component.x - componentPadding) && startConnector.bY <= endConnector.bY
                
                const conditionTwo   = endConnector.component.x + endConnector.component.width + componentPadding > startConnector.aX &&
                                       endConnector.component.x <= startConnector.component.x &&
                                       startConnector.bY + componentPadding < endConnector.component.y - componentPadding
    
                const conditionThree = endConnector.component.x > startConnector.component.x &&
                                       endConnector.component.x - componentPadding < startConnector.aX && 
                                       startConnector.bY + componentPadding < endConnector.component.y - componentPadding
                
                const conditionFour = (endConnector.component.x + endConnector.component.width + componentPadding <= startConnector.aX ||
                                      startConnector.aX <= endConnector.component.x - componentPadding) && startConnector.bY > endConnector.bY
    
                const conditionFive = endConnector.component.x + endConnector.component.width + componentPadding > startConnector.aX &&
                                       endConnector.component.x <= startConnector.component.x &&
                                       startConnector.component.y - componentPadding > endConnector.bY + componentPadding
    
                const conditionSix  = endConnector.component.x > startConnector.component.x &&
                                      endConnector.component.x - componentPadding < startConnector.aX && 
                                      startConnector.component.y - componentPadding > endConnector.bY + componentPadding
                
                const conditionSeven = startConnector.bY <= endConnector.bY
    
                if (conditionOne || (!conditionTwo && !conditionThree && !conditionFive && !conditionSix && conditionSeven)) {
                    console.log('1')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionTwo) {
                    console.log('2')
                    const halfY = Math.abs(endConnector.component.y - startConnector.bY) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + halfY },
                        { x1: startConnector.aX, y1: startConnector.bY + halfY, x2: endConnector.component.x + endConnector.component.width + componentPadding, y2: startConnector.bY + halfY },
                        { x1: endConnector.component.x + endConnector.component.width + componentPadding, y1: startConnector.bY + halfY, x2: endConnector.component.x + endConnector.component.width + componentPadding, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.component.x + endConnector.component.width + componentPadding, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionThree) {
                    console.log('3')
                    const halfY = Math.abs(endConnector.component.y - startConnector.bY) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + halfY },
                        { x1: startConnector.aX, y1: startConnector.bY + halfY, x2: endConnector.component.x - componentPadding, y2: startConnector.bY + halfY },
                        { x1: endConnector.component.x - componentPadding, y1: startConnector.bY + halfY, x2: endConnector.component.x - componentPadding, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.component.x - componentPadding, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFour || (!conditionOne && !conditionTwo && !conditionThree && !conditionFive && !conditionSix && !conditionSeven)) {
                    console.log('4')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: endConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: startConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFive) {
                    console.log('five')
                    const halfY = Math.abs(startConnector.component.y - endConnector.bY) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x - componentPadding, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.component.x - componentPadding, y1: startConnector.bY + componentPadding, x2: startConnector.component.x - componentPadding, y2: startConnector.component.y - halfY },
                        { x1: startConnector.component.x - componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.aX, y2: startConnector.component.y - halfY },
                        { x1: endConnector.aX, y1: startConnector.component.y - halfY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSix) {
                    console.log('six')
                    const halfY = Math.abs(startConnector.component.y - endConnector.bY) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: startConnector.bY + componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: startConnector.component.y - halfY },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.aX, y2: startConnector.component.y - halfY },
                        { x1: endConnector.aX, y1: startConnector.component.y - halfY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
            }
    
            if (endConnector.direction === 'left') {
                const conditionOne   = startConnector.aX < endConnector.aX - componentPadding && startConnector.bY < endConnector.bY - componentPadding
                const conditionTwo   = startConnector.component.x + startConnector.component.width + componentPadding < endConnector.aX - componentPadding && startConnector.bY >= endConnector.bY - componentPadding
                const conditionThree = startConnector.component.x + startConnector.component.width + componentPadding >= endConnector.aX - componentPadding && startConnector.component.x + startConnector.component.width < endConnector.aX &&
                                       startConnector.component.y - componentPadding >= endConnector.component.y + endConnector.component.height + componentPadding
                const conditionFour  = startConnector.component.x + startConnector.component.width >= endConnector.aX && startConnector.component.x < endConnector.component.x &&
                                       startConnector.component.y - componentPadding >= endConnector.component.y + endConnector.component.height + componentPadding
                const conditionFive  = startConnector.component.x >= endConnector.component.x  &&
                                       startConnector.bY >= endConnector.component.y + endConnector.component.height
    
                const conditionSix   = startConnector.aX >= endConnector.aX - componentPadding && startConnector.bY + componentPadding <= endConnector.component.y - componentPadding
    
                const conditionSeven = startConnector.aX >= endConnector.aX - componentPadding && startConnector.component.x - componentPadding >= endConnector.component.x + endConnector.component.width + componentPadding &&
                                       startConnector.bY + componentPadding > endConnector.component.y - componentPadding && startConnector.bY <= endConnector.component.y
                
                const conditionEight = startConnector.bY < endConnector.component.y + endConnector.component.height && startConnector.bY > endConnector.component.y && 
                                       startConnector.component.x > endConnector.component.x + endConnector.component.width
    
                if (conditionOne) {
                    console.log('1')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: endConnector.bY },
                        { x1: startConnector.aX, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionTwo) {
                    console.log('2')
                    const halfX = Math.abs(endConnector.aX - startConnector.component.x - startConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: endConnector.bY },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionThree) {
                    console.log('3')
                    const halfY = Math.abs(startConnector.component.y - endConnector.component.y - endConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: startConnector.bY + componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: startConnector.component.y - halfY },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.aX - componentPadding, y2: startConnector.component.y - halfY },
                        { x1: endConnector.aX - componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFour) {
                    console.log('4')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x - componentPadding, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.component.x - componentPadding, y1: startConnector.bY + componentPadding, x2: startConnector.component.x - componentPadding, y2: endConnector.bY },
                        { x1: startConnector.component.x - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFive) {
                    console.log('5')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: endConnector.aX - componentPadding, y2: startConnector.bY + componentPadding },
                        { x1: endConnector.aX - componentPadding, y1: startConnector.bY + componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSix) {
                    console.log('6')
                    const halfY = Math.abs(endConnector.component.y - startConnector.component.y - startConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + halfY },
                        { x1: startConnector.aX, y1: startConnector.bY + halfY, x2: endConnector.aX - componentPadding, y2: startConnector.bY + halfY },
                        { x1: endConnector.aX - componentPadding, y1: startConnector.bY + halfY, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSeven) {
                    console.log('7');
                    const halfX = Math.abs(startConnector.component.x - endConnector.component.x - endConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x - halfX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.component.x - halfX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x - halfX, y2: endConnector.component.y - componentPadding },
                        { x1: startConnector.component.x - halfX, y1: endConnector.component.y - componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.component.y - componentPadding },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.component.y - componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionEight || (!conditionOne && !conditionTwo && !conditionThree && !conditionFour && !conditionFive && !conditionSix && !conditionSeven)) {
                    console.log('8');
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: startConnector.aX, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
            }
            
            if (endConnector.direction === 'right') {
                const conditionOne   = endConnector.component.x + endConnector.component.width + componentPadding <= startConnector.aX &&
                                       endConnector.bY >= startConnector.bY + componentPadding
    
                const conditionTwo   = endConnector.component.x + endConnector.component.width + componentPadding <= startConnector.component.x - componentPadding &&
                                       endConnector.bY < startConnector.bY + componentPadding
    
                const conditionThree = endConnector.component.x + endConnector.component.width + componentPadding > startConnector.component.x - componentPadding &&
                                       endConnector.component.x + endConnector.component.width <= startConnector.component.x &&
                                       endConnector.component.y + endConnector.component.height + componentPadding < startConnector.component.y - componentPadding
                
                const conditionFour  = endConnector.component.x <= startConnector.component.x &&
                                       endConnector.component.x + endConnector.component.width > startConnector.component.x &&
                                       endConnector.component.y + endConnector.component.height + componentPadding < startConnector.component.y - componentPadding
    
                const conditionFive  = endConnector.component.x > startConnector.component.x &&
                                       endConnector.component.y < startConnector.component.y
    
                const conditionSix   = endConnector.component.x > startConnector.component.x &&
                                       endConnector.component.y >= startConnector.component.y &&
                                       endConnector.component.y <= startConnector.component.y + startConnector.component.height
    
                const conditionSeven = startConnector.component.x + startConnector.component.width + componentPadding < endConnector.component.x - componentPadding &&
                                       endConnector.component.y > startConnector.component.y + startConnector.component.height &&
                                       endConnector.component.y - componentPadding <= startConnector.component.y + startConnector.component.height + componentPadding
                
                const conditionEight = endConnector.component.y - componentPadding > startConnector.component.y + startConnector.component.height + componentPadding &&
                                       startConnector.aX < endConnector.component.x + endConnector.component.width + componentPadding
    
    
                if (conditionOne) {
                    console.log('1')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: endConnector.bY },
                        { x1: startConnector.aX, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionTwo) {
                    console.log('2')
                    const halfX = Math.abs(startConnector.component.x - endConnector.component.x - endConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x - halfX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.component.x - halfX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x - halfX, y2: endConnector.bY },
                        { x1: startConnector.component.x - halfX, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionThree) {
                    console.log('3')
                    const halfY = Math.abs(startConnector.component.y - endConnector.component.y - endConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x - componentPadding, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.component.x - componentPadding, y1: startConnector.bY + componentPadding, x2: startConnector.component.x - componentPadding, y2: startConnector.component.y - halfY },
                        { x1: startConnector.component.x - componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.aX + componentPadding, y2: startConnector.component.y - halfY },
                        { x1: endConnector.aX + componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFour) {
                    console.log('4')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: startConnector.bY + componentPadding, x2: startConnector.component.x + startConnector.component.width + componentPadding, y2: endConnector.bY },
                        { x1: startConnector.component.x + startConnector.component.width + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFive || (!conditionOne && !conditionTwo && !conditionThree && !conditionFour && !conditionSix && !conditionSeven && !conditionEight)) {
                    console.log('5')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: endConnector.aX + componentPadding, y2: startConnector.bY + componentPadding },
                        { x1: endConnector.aX + componentPadding, y1: startConnector.bY + componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSix) {
                    console.log('6')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: startConnector.aX, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSeven) {
                    console.log('7')
                    const halfX = Math.abs(endConnector.component.x - startConnector.component.x - startConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.aX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: startConnector.bY + componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: startConnector.bY + componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: endConnector.component.y - componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: endConnector.component.y - componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.component.y - componentPadding },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.component.y - componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionEight) {
                    console.log('8')
                    const halfX = Math.abs(endConnector.component.y - startConnector.bY) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX, y2: startConnector.bY + halfX },
                        { x1: startConnector.aX, y1: startConnector.bY + halfX, x2: endConnector.aX + componentPadding, y2: startConnector.bY + halfX },
                        { x1: endConnector.aX + componentPadding, y1: startConnector.bY + halfX, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
            }
        }
        
        if (startConnector.direction === 'left') {
            if (endConnector.direction === 'top') {
                const conditionOne   = startConnector.aX - componentPadding >= endConnector.aX && startConnector.bY + componentPadding <= endConnector.bY
                const conditionTwo   = startConnector.component.x - componentPadding >= endConnector.component.x + endConnector.component.width + componentPadding && startConnector.bY + componentPadding > endConnector.bY
                const conditionThree = startConnector.aX - componentPadding < endConnector.component.x + endConnector.component.width + componentPadding &&
                                       startConnector.aX >= endConnector.component.x + endConnector.component.width &&
                                       startConnector.component.y - componentPadding >= endConnector.component.y + endConnector.component.height + componentPadding
                const conditionFour  = startConnector.component.x >= endConnector.component.x &&
                                       startConnector.aX < endConnector.component.x + endConnector.component.width &&
                                       startConnector.component.y - componentPadding >= endConnector.component.y + endConnector.component.height + componentPadding
    
                const conditionFive  = startConnector.component.x < endConnector.component.x &&
                                       startConnector.component.y >= endConnector.component.y
                
                const conditionSix   = startConnector.component.y < endConnector.component.y &&
                                       startConnector.component.y + startConnector.component.height >= endConnector.component.y &&
                                       startConnector.component.x + startConnector.component.width + componentPadding <= endConnector.component.x - componentPadding
    
                const conditionSeven = startConnector.component.y + startConnector.component.height < endConnector.component.y &&
                                       startConnector.component.y + startConnector.component.height + componentPadding >= endConnector.component.y - componentPadding &&
                                       startConnector.component.x + startConnector.component.width + componentPadding <= endConnector.component.x - componentPadding
                
                const conditionEight = startConnector.component.y + startConnector.component.height + componentPadding < endConnector.component.y - componentPadding &&
                                       startConnector.aX - componentPadding <= endConnector.aX
    
                if (conditionOne) {
                    console.log('1')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: endConnector.aX, y2: startConnector.bY },
                        { x1: endConnector.aX, y1: startConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionTwo) {
                    console.log('2')
                    const halfX = Math.abs(endConnector.component.x + endConnector.component.width - startConnector.aX) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - halfX, y2: startConnector.bY },
                        { x1: startConnector.aX - halfX, y1: startConnector.bY, x2: startConnector.aX - halfX, y2: endConnector.component.y - componentPadding },
                        { x1: startConnector.aX - halfX, y1: endConnector.component.y - componentPadding, x2: endConnector.aX, y2: endConnector.component.y - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.component.y - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionThree) {
                    console.log('3')
                    const halfY = Math.abs(startConnector.component.y - endConnector.component.y - endConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.component.y - halfY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.component.x + endConnector.component.width + componentPadding, y2: startConnector.component.y - halfY },
                        { x1: endConnector.component.x + endConnector.component.width + componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.component.x + endConnector.component.width + componentPadding, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.component.x + endConnector.component.width + componentPadding, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFour) {
                    console.log('4')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: endConnector.component.x - componentPadding, y2: startConnector.bY },
                        { x1: endConnector.component.x - componentPadding, y1: startConnector.bY, x2: endConnector.component.x - componentPadding, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.component.x - componentPadding, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFive || (!conditionOne && !conditionTwo && !conditionThree && !conditionFour && !conditionSix && !conditionSeven && !conditionEight)) {
                    console.log('5')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: endConnector.bY - componentPadding },
                        { x1: startConnector.aX - componentPadding, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSix) {
                    console.log('6')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.component.y - componentPadding, x2: endConnector.aX, y2: startConnector.component.y - componentPadding },
                        { x1: endConnector.aX, y1: startConnector.component.y - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSeven) {
                    console.log('7')
                    const halfX = Math.abs(endConnector.component.x - startConnector.component.x - startConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: endConnector.bY - componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionEight) {
                    console.log('8')
                    const halfY = Math.abs(endConnector.component.y - startConnector.component.y - startConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.component.y + startConnector.component.height + halfY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.component.y + startConnector.component.height + halfY, x2: endConnector.aX, y2: startConnector.component.y + startConnector.component.height + halfY },
                        { x1: endConnector.aX, y1: startConnector.component.y + startConnector.component.height + halfY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
            }
    
            if (endConnector.direction === 'bottom') {
                const conditionOne   = endConnector.aX <= startConnector.aX - componentPadding &&
                                       startConnector.bY >= endConnector.bY + componentPadding
    
                const conditionTwo   = startConnector.bY < endConnector.bY + componentPadding &&
                                       endConnector.component.x + endConnector.component.width + componentPadding <= startConnector.aX - componentPadding
    
                const conditionThree = startConnector.component.y + startConnector.component.height + componentPadding <= endConnector.component.y - componentPadding &&
                                       endConnector.component.x + endConnector.component.width + componentPadding > startConnector.aX - componentPadding &&
                                       startConnector.component.x >= endConnector.component.x + endConnector.component.width
    
                const conditionFour  = startConnector.component.x < endConnector.component.x + endConnector.component.width &&
                                       startConnector.aX >= endConnector.component.x &&
                                       startConnector.component.y <= endConnector.component.y
                
                const conditionFive  = startConnector.aX < endConnector.component.x && startConnector.component.y + startConnector.component.height <= endConnector.bY 
    
                const conditionSix   = startConnector.component.y + startConnector.component.height > endConnector.bY && startConnector.component.y < endConnector.component.y + endConnector.component.height &&
                                       startConnector.component.x + startConnector.component.width < endConnector.component.x
    
                const conditionSeven = startConnector.component.y >= endConnector.component.y + endConnector.component.height && 
                                       startConnector.component.y - componentPadding <= endConnector.component.y + endConnector.component.height + componentPadding &&
                                       startConnector.component.x + startConnector.component.width + componentPadding <= endConnector.component.x - componentPadding
    
                const conditionEight = startConnector.component.y - componentPadding > endConnector.component.y + endConnector.component.height + componentPadding &&
                                       startConnector.aX - componentPadding <= endConnector.aX
    
                if (conditionOne) {
                    console.log('1')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: endConnector.aX, y2: startConnector.bY },
                        { x1: endConnector.aX, y1: startConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionTwo) {
                    console.log('2')
                    const halfX = Math.abs(startConnector.component.x - endConnector.component.x - endConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - halfX, y2: startConnector.bY },
                        { x1: startConnector.aX - halfX, y1: startConnector.bY, x2: startConnector.aX - halfX, y2: endConnector.bY + componentPadding },
                        { x1: startConnector.aX - halfX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionThree) {
                    console.log('3')
                    const halfY = Math.abs(endConnector.component.y - startConnector.component.y - startConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.component.y + startConnector.component.height + halfY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.component.y + startConnector.component.height + halfY, x2: endConnector.component.x + endConnector.component.width + componentPadding, y2: startConnector.component.y + startConnector.component.height + halfY },
                        { x1: endConnector.component.x + endConnector.component.width + componentPadding, y1: startConnector.component.y + startConnector.component.height + halfY, x2: endConnector.component.x + endConnector.component.width + componentPadding, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.component.x + endConnector.component.width + componentPadding, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFour || (!conditionOne && !conditionTwo && !conditionThree && !conditionFive && !conditionSix && !conditionSeven && !conditionEight)) {
                    console.log('4')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: endConnector.component.x - componentPadding, y2: startConnector.bY },
                        { x1: endConnector.component.x - componentPadding, y1: startConnector.bY, x2: endConnector.component.x - componentPadding, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.component.x - componentPadding, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFive) {
                    console.log('5')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: endConnector.bY + componentPadding },
                        { x1: startConnector.aX - componentPadding, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSix) {
                    console.log('6')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: endConnector.aX, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: endConnector.aX, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSeven) {
                    console.log('7')
                    const halfX = Math.abs(endConnector.component.x - startConnector.component.x - startConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.component.y - componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: startConnector.component.y - componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionEight) {
                    console.log('8')
                    const halfY = Math.abs(startConnector.component.y - endConnector.component.y - endConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.component.y - halfY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.aX, y2: startConnector.component.y - halfY },
                        { x1: endConnector.aX, y1: startConnector.component.y - halfY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
            }
    
            if (endConnector.direction === 'left') {
                const conditionOne   = startConnector.component.y - componentPadding >= endConnector.bY && startConnector.aX <= endConnector.aX
                const conditionTwo   = startConnector.aX > endConnector.aX && endConnector.component.y + endConnector.component.height + componentPadding <= startConnector.bY
                const conditionThree = endConnector.component.y + endConnector.component.height + componentPadding > startConnector.bY &&
                                       startConnector.component.y >= endConnector.component.y &&
                                       startConnector.component.x - componentPadding >= endConnector.component.x + endConnector.component.width + componentPadding
    
                const conditionFour  = startConnector.component.y < endConnector.component.y &&
                                       startConnector.bY >= endConnector.component.y - componentPadding &&
                                       startConnector.component.x - componentPadding >= endConnector.component.x + endConnector.component.width + componentPadding
    
                const conditionFive  = startConnector.bY < endConnector.component.y - componentPadding &&
                                       startConnector.aX >= endConnector.aX
                
                const conditionSix   = startConnector.aX < endConnector.aX &&
                                       startConnector.component.y + startConnector.component.height + componentPadding <= endConnector.bY
                               
                const conditionSeven = startConnector.component.y + startConnector.component.height + componentPadding > endConnector.bY &&
                                       startConnector.component.y <= endConnector.component.y &&
                                       startConnector.component.x + startConnector.component.width + componentPadding <= endConnector.component.x - componentPadding
    
                const conditionEight = startConnector.component.y > endConnector.component.y &&
                                       startConnector.component.y < endConnector.bY + componentPadding &&
                                       startConnector.component.x + startConnector.component.width + componentPadding <= endConnector.component.x - componentPadding
    
                if (conditionOne || (startConnector.aX < endConnector.aX && !conditionTwo && !conditionThree && !conditionFour && !conditionFive && !conditionSix && !conditionSeven && !conditionEight)) {
                    console.log('1')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionTwo || (startConnector.aX >= endConnector.aX && !conditionOne && !conditionThree && !conditionFour && !conditionFive && !conditionSix && !conditionSeven && !conditionEight)) {
                    console.log('2')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: endConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: startConnector.bY, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionThree) {
                    console.log('3')
                    const halfX = Math.abs(startConnector.component.x - startConnector.component.width - endConnector.component.x) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - halfX, y2: startConnector.bY },
                        { x1: startConnector.aX - halfX, y1: startConnector.bY, x2: startConnector.aX - halfX, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: startConnector.aX - halfX, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFour) {
                    console.log('4')
                    const halfX = Math.abs(startConnector.component.x - startConnector.component.width - endConnector.component.x) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - halfX, y2: startConnector.bY },
                        { x1: startConnector.aX - halfX, y1: startConnector.bY, x2: startConnector.aX - halfX, y2: endConnector.component.y - componentPadding },
                        { x1: startConnector.aX - halfX, y1: endConnector.component.y - componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.component.y - componentPadding },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.component.y - componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFive) {
                    console.log('5')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: endConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: startConnector.bY, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSix) {
                    console.log('6')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSeven) {
                    console.log('7')
                    const halfX = Math.abs(endConnector.component.x - startConnector.component.x - startConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: endConnector.bY },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionEight) {
                    console.log('8')
                    const halfX = Math.abs(endConnector.component.x - startConnector.component.x - startConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.component.y - componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: startConnector.component.y - componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: endConnector.bY },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
            }
    
            if (endConnector.direction === 'right') {
                const conditionOne   = startConnector.aX - componentPadding >= endConnector.aX + componentPadding
    
                const conditionTwo   = startConnector.aX - componentPadding < endConnector.aX + componentPadding &&
                                       startConnector.component.y + startConnector.component.height + componentPadding <= endConnector.component.y - componentPadding
                
                const conditionThree = startConnector.aX - componentPadding < endConnector.aX + componentPadding &&
                                       startConnector.component.y - componentPadding >= endConnector.component.y + endConnector.component.height + componentPadding
    
                const conditionFour  = startConnector.component.y - componentPadding < endConnector.component.y + endConnector.component.height + componentPadding &&
                                       startConnector.component.y >= endConnector.component.y + endConnector.component.height &&
                                       startConnector.component.x + startConnector.component.width + componentPadding <= endConnector.component.x - componentPadding
                
                const conditionFive  = startConnector.component.y + startConnector.component.height + componentPadding > endConnector.component.y - componentPadding &&
                                       startConnector.component.y + endConnector.component.height <= endConnector.component.y &&
                                       startConnector.component.x + startConnector.component.width + componentPadding <= endConnector.component.x - componentPadding
    
                const conditionSix   = startConnector.component.y + startConnector.component.height > endConnector.component.y &&
                                       startConnector.component.y <= endConnector.component.y &&
                                       startConnector.component.x + startConnector.component.width + componentPadding <= endConnector.component.x - componentPadding
    
                const conditionSeven = startConnector.component.y < endConnector.component.y + endConnector.component.height &&
                                       startConnector.component.y > endConnector.component.y &&
                                       startConnector.component.x + startConnector.component.width + componentPadding <= endConnector.component.x - componentPadding
    
                if (conditionOne) {
                    console.log('1')
                    const halfX = Math.abs(startConnector.aX - endConnector.aX) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - halfX, y2: startConnector.bY },
                        { x1: startConnector.aX - halfX, y1: startConnector.bY, x2: startConnector.aX - halfX, y2: endConnector.bY },
                        { x1: startConnector.aX - halfX, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionTwo) {
                    console.log('2')
                    const halfY = Math.abs(endConnector.component.y - startConnector.component.y - startConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.component.y + startConnector.component.height + halfY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.component.y + startConnector.component.height + halfY, x2: endConnector.aX + componentPadding, y2: startConnector.component.y + startConnector.component.height + halfY },
                        { x1: endConnector.aX + componentPadding, y1: startConnector.component.y + startConnector.component.height + halfY, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionThree) {
                    console.log('3')
                    const halfY = Math.abs(startConnector.component.y - endConnector.component.y - endConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.component.y - halfY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.aX + componentPadding, y2: startConnector.component.y - halfY },
                        { x1: endConnector.aX + componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFour) {
                    console.log('4')
                    const halfX = Math.abs(endConnector.component.x - startConnector.component.x - startConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.component.y - componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: startConnector.component.y - componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFive) {
                    console.log('5')
                    const halfX = Math.abs(endConnector.component.x - startConnector.component.x - startConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: startConnector.component.x + startConnector.component.width + halfX, y2: endConnector.component.y - componentPadding },
                        { x1: startConnector.component.x + startConnector.component.width + halfX, y1: endConnector.component.y - componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.component.y - componentPadding },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.component.y - componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSix|| (!conditionOne && !conditionTwo && !conditionThree && !conditionFour && !conditionFive && !conditionSeven)) {
                    console.log('6')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: startConnector.aX - componentPadding, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSeven) {
                    console.log('7')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.bY, x2: startConnector.aX - componentPadding, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: startConnector.aX - componentPadding, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: endConnector.aX + componentPadding, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: endConnector.aX + componentPadding, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
            }
        }
    
        if (startConnector.direction === 'right') {
            if (endConnector.direction === 'top') {
                const conditionOne   = startConnector.aX + componentPadding <= endConnector.aX && startConnector.bY + componentPadding <= endConnector.bY
                const conditionTwo   = startConnector.aX + componentPadding > endConnector.aX &&
                                       startConnector.component.y + startConnector.component.height + componentPadding <= endConnector.component.y - componentPadding
    
                const conditionThree = startConnector.component.y + startConnector.component.height + componentPadding > endConnector.component.y - componentPadding &&
                                       startConnector.component.y + startConnector.component.height <= endConnector.component.y &&
                                       startConnector.component.x - componentPadding >= endConnector.component.x + endConnector.component.width + componentPadding
    
                const conditionFour  = startConnector.component.y <= endConnector.component.y &&
                                       startConnector.component.y + startConnector.component.height > endConnector.component.y &&
                                       startConnector.component.x - componentPadding >= endConnector.component.x + endConnector.component.width + componentPadding
    
                const conditionFive  = startConnector.component.y > endConnector.component.y &&
                                       startConnector.aX >= endConnector.component.x + endConnector.component.width
    
                const conditionSix   = startConnector.component.y - componentPadding > endConnector.component.y + endConnector.component.height + componentPadding &&
                                       startConnector.aX < endConnector.component.x + endConnector.component.width &&
                                       startConnector.aX + componentPadding >= endConnector.component.x - componentPadding
                
                const conditionSeven = startConnector.aX + componentPadding < endConnector.component.x - componentPadding &&
                                       startConnector.bY > endConnector.bY - componentPadding
    
                if (conditionOne) {
                    console.log('1')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: endConnector.aX, y2: startConnector.bY },
                        { x1: endConnector.aX, y1: startConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionTwo) {
                    console.log('2')
                    const halfY = Math.abs(endConnector.component.y - startConnector.component.y - startConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.component.y + startConnector.component.height + halfY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.component.y + startConnector.component.height + halfY, x2: endConnector.aX, y2: startConnector.component.y + startConnector.component.height + halfY },
                        { x1: endConnector.aX, y1: startConnector.component.y + startConnector.component.height + halfY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionThree) {
                    console.log('3')
                    const halfX = Math.abs(endConnector.component.x - endConnector.component.height - startConnector.component.x) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: startConnector.aX - halfX, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: startConnector.aX - halfX, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: startConnector.aX - halfX, y2: endConnector.component.y - componentPadding },
                        { x1: startConnector.aX - halfX, y1: endConnector.component.y - componentPadding, x2: endConnector.aX, y2: endConnector.component.y - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.component.y - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFour) {
                    console.log('4')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.component.y - componentPadding, x2: endConnector.aX, y2: startConnector.component.y - componentPadding },
                        { x1: endConnector.aX, y1: startConnector.component.y - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFive || (!conditionOne && !conditionTwo && !conditionThree && !conditionFour && !conditionSix && !conditionSeven)) {
                    console.log('5')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: endConnector.bY - componentPadding },
                        { x1: startConnector.aX + componentPadding, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSix) {
                    console.log('6')
                    const halfY = Math.abs(startConnector.component.y - endConnector.component.y - endConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.component.y - halfY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.component.x - componentPadding, y2: startConnector.component.y - halfY },
                        { x1: endConnector.component.x - componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.component.x - componentPadding, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.component.x - componentPadding, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSeven) {
                    console.log('7')
                    const halfX = Math.abs(endConnector.component.x - startConnector.component.x - startConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + halfX, y2: startConnector.bY },
                        { x1: startConnector.aX + halfX, y1: startConnector.bY, x2: startConnector.aX + halfX, y2: endConnector.bY - componentPadding },
                        { x1: startConnector.aX + halfX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY - componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY - componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
            }
    
            if (endConnector.direction === 'bottom') {
                const conditionOne   = startConnector.aX + componentPadding <= endConnector.aX &&
                                       startConnector.bY - componentPadding >= endConnector.bY
    
                const conditionTwo   = startConnector.aX + componentPadding <= endConnector.component.x - componentPadding &&
                                       startConnector.bY - componentPadding < endConnector.bY
    
                const conditionThree = startConnector.aX + componentPadding > endConnector.component.x - componentPadding &&
                                       startConnector.component.x + startConnector.component.width <= endConnector.component.x && 
                                       startConnector.component.y + startConnector.component.height + componentPadding < endConnector.bY - componentPadding
    
                const conditionFour  = startConnector.component.x + startConnector.component.width > endConnector.component.x &&
                                       startConnector.component.x <= endConnector.component.x &&
                                       startConnector.component.y + startConnector.component.height + componentPadding < endConnector.bY - componentPadding
    
                const conditionFive  = startConnector.component.x > endConnector.component.x &&
                                       startConnector.component.y + startConnector.component.height <= endConnector.bY
    
                const conditionSix   = startConnector.component.y <= endConnector.component.y + endConnector.component.height &&
                                       startConnector.component.y > endConnector.component.y &&
                                       startConnector.component.x >= endConnector.component.x
    
                const conditionSeven = startConnector.component.y > endConnector.component.y + endConnector.component.height &&
                                       startConnector.component.y - componentPadding <= endConnector.component.y + endConnector.component.height + componentPadding &&
                                       startConnector.component.x - componentPadding >= endConnector.component.x + endConnector.component.width + componentPadding
    
                const conditionEight = startConnector.component.y - componentPadding > endConnector.component.y + endConnector.component.height + componentPadding &&
                                       startConnector.aX + componentPadding >= endConnector.aX
    
                if (conditionOne) {
                    console.log('1')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: endConnector.aX, y2: startConnector.bY },
                        { x1: endConnector.aX, y1: startConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionTwo) {
                    console.log('2')
                    const halfX = Math.abs(endConnector.component.x - startConnector.component.x - startConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + halfX, y2: startConnector.bY },
                        { x1: startConnector.aX + halfX, y1: startConnector.bY, x2: startConnector.aX + halfX, y2: endConnector.bY + componentPadding },
                        { x1: startConnector.aX + halfX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionThree) {
                    console.log('3')
                    const halfY = Math.abs(endConnector.component.y - startConnector.component.y - startConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.component.y + startConnector.component.height + halfY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.component.y + startConnector.component.height + halfY, x2: endConnector.component.x - componentPadding, y2: startConnector.component.y + startConnector.component.height + halfY },
                        { x1: endConnector.component.x - componentPadding, y1: startConnector.component.y + startConnector.component.height + halfY, x2: endConnector.component.x - componentPadding, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.component.x - componentPadding, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFour) {
                    console.log('4')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: endConnector.component.x + endConnector.component.height + componentPadding, y2: startConnector.bY },
                        { x1: endConnector.component.x + endConnector.component.height + componentPadding, y1: startConnector.bY, x2: endConnector.component.x + endConnector.component.height + componentPadding, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.component.x + endConnector.component.height + componentPadding, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFive) {
                    console.log('5')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: endConnector.bY + componentPadding },
                        { x1: startConnector.aX + componentPadding, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSix || (!conditionOne && !conditionTwo && !conditionThree && !conditionFour && !conditionFive && !conditionSix && !conditionSeven && !conditionEight)) {
                    console.log('6')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: endConnector.aX, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: endConnector.aX, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSeven) {
                    console.log('7')
                    const halfX = Math.abs(startConnector.component.x - endConnector.component.x - endConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.component.y - componentPadding, x2: startConnector.component.x - halfX, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.component.x - halfX, y1: startConnector.component.y - componentPadding, x2: startConnector.component.x - halfX, y2: endConnector.bY + componentPadding },
                        { x1: startConnector.component.x - halfX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY + componentPadding },
                        { x1: endConnector.aX, y1: endConnector.bY + componentPadding, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionEight) {
                    console.log('8')
                    const halfY = Math.abs(startConnector.component.y - endConnector.component.y - endConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.component.y - halfY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.aX, y2: startConnector.component.y - halfY },
                        { x1: endConnector.aX, y1: startConnector.component.y - halfY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
            }
    
            if (endConnector.direction === 'left') {
                const conditionOne   = startConnector.aX + componentPadding <= endConnector.aX - componentPadding
                const conditionTwo   = startConnector.aX + componentPadding > endConnector.aX - componentPadding && 
                                     startConnector.component.y + startConnector.component.height + componentPadding <= endConnector.component.y - componentPadding
    
                const conditionThree = startConnector.aX + componentPadding > endConnector.aX - componentPadding && 
                                     startConnector.component.y - componentPadding >= endConnector.component.y + endConnector.component.height + componentPadding
    
                const conditionFour  = startConnector.component.y + startConnector.component.height + componentPadding > endConnector.component.y - componentPadding &&
                                       startConnector.component.y + startConnector.component.height <= endConnector.component.y &&
                                       startConnector.component.x - componentPadding >= endConnector.component.x + endConnector.component.width + componentPadding
    
                const conditionFive  = startConnector.component.y + startConnector.component.height > endConnector.component.y &&
                                       startConnector.component.y <= endConnector.component.y &&
                                       startConnector.component.x > endConnector.component.x
                
                const conditionSix   = startConnector.component.y > endConnector.component.y &&
                                       startConnector.component.y <= endConnector.component.y + endConnector.component.height &&
                                       startConnector.component.x > endConnector.component.x
    
                const conditionSeven = startConnector.component.y - componentPadding < endConnector.component.y + endConnector.component.height + componentPadding &&
                                       startConnector.component.y > endConnector.component.y + endConnector.component.height &&
                                       startConnector.component.x - componentPadding >= endConnector.component.x + endConnector.component.width + componentPadding
    
                if (conditionOne) {
                    console.log('1')
                    const halfX = Math.abs(endConnector.aX - startConnector.aX) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + halfX, y2: startConnector.bY },
                        { x1: startConnector.aX + halfX, y1: startConnector.bY, x2: startConnector.aX + halfX, y2: endConnector.bY },
                        { x1: startConnector.aX + halfX, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionTwo) {
                    console.log('2')
                    const halfY = Math.abs(endConnector.component.y - startConnector.component.y - startConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.component.y + startConnector.component.height + halfY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.component.y + startConnector.component.height + halfY, x2: endConnector.aX - componentPadding, y2: startConnector.component.y + startConnector.component.height + halfY },
                        { x1: endConnector.aX - componentPadding, y1: startConnector.component.y + startConnector.component.height + halfY, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionThree) {
                    console.log('3')
                    const halfY = Math.abs(startConnector.component.y - endConnector.component.y - endConnector.component.height) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.component.y - halfY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.aX - componentPadding, y2: startConnector.component.y - halfY },
                        { x1: endConnector.aX - componentPadding, y1: startConnector.component.y - halfY, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFour) {
                    console.log('4')
                    const halfX = Math.abs(startConnector.component.x - endConnector.component.x - endConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: startConnector.component.x - halfX, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: startConnector.component.x - halfX, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: startConnector.component.x - halfX, y2: endConnector.component.y - componentPadding },
                        { x1: startConnector.component.x - halfX, y1: endConnector.component.y - componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.component.y - componentPadding },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.component.y - componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFive || (!conditionOne && !conditionTwo && !conditionThree && !conditionFour && !conditionFive && !conditionSix && !conditionSeven) ) {
                    console.log('5')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: startConnector.aX + componentPadding, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSix) {
                    console.log('6')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: endConnector.aX - componentPadding, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: endConnector.aX - componentPadding, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSeven) {
                    console.log('7')
                    const halfX = Math.abs(startConnector.component.x - endConnector.component.x - endConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.component.y - componentPadding, x2: startConnector.component.x - halfX, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.component.x - halfX, y1: startConnector.component.y - componentPadding, x2: startConnector.component.x - halfX, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: startConnector.component.x - halfX, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX - componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX - componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
            }
    
            if (endConnector.direction === 'right') {
                const conditionOne   = (startConnector.component.y + startConnector.component.height + componentPadding <= endConnector.bY ||
                                        startConnector.component.y - componentPadding >= endConnector.bY) &&
                                        startConnector.aX >= endConnector.aX
    
                const conditionTwo   = startConnector.component.y + startConnector.component.height + componentPadding > endConnector.bY &&
                                       startConnector.component.y <= endConnector.component.y &&
                                       startConnector.component.x - componentPadding >= endConnector.aX + componentPadding
    
                const conditionThree = startConnector.component.y - componentPadding < endConnector.bY &&
                                       startConnector.component.y > endConnector.component.y &&
                                       startConnector.component.x - componentPadding >= endConnector.aX + componentPadding
    
                const conditionFour  = startConnector.aX < endConnector.aX &&
                                       (endConnector.component.y - componentPadding >= startConnector.bY ||
                                        endConnector.component.y + endConnector.component.height + componentPadding <= startConnector.bY)
    
                const conditionFive  = startConnector.component.y - componentPadding < endConnector.bY &&
                                       startConnector.component.y > endConnector.component.y &&
                                       startConnector.component.x + startConnector.component.width + componentPadding <= endConnector.component.x - componentPadding
    
                const conditionSix   = startConnector.component.y + startConnector.component.height + componentPadding > endConnector.bY &&
                                       startConnector.component.y <= endConnector.component.y &&
                                       startConnector.component.x + startConnector.component.width + componentPadding <= endConnector.component.x - componentPadding
    
                if (conditionOne || (!conditionOne && !conditionTwo && !conditionThree && !conditionFour && !conditionFive && !conditionSix)) {
                    console.log('1')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionTwo) {
                    console.log('2')
                    const halfX = Math.abs(startConnector.component.x - endConnector.component.x - endConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: startConnector.component.x - halfX, y2: startConnector.component.y + startConnector.component.height + componentPadding },
                        { x1: startConnector.component.x - halfX, y1: startConnector.component.y + startConnector.component.height + componentPadding, x2: startConnector.component.x - halfX, y2: endConnector.bY },
                        { x1: startConnector.component.x - halfX, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionThree) {
                    console.log('3')
                    const halfX = Math.abs(startConnector.component.x - endConnector.component.x - endConnector.component.width) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.bY, x2: startConnector.aX + componentPadding, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.aX + componentPadding, y1: startConnector.component.y - componentPadding, x2: startConnector.component.x - halfX, y2: startConnector.component.y - componentPadding },
                        { x1: startConnector.component.x - halfX, y1: startConnector.component.y - componentPadding, x2: startConnector.component.x - halfX, y2: endConnector.bY },
                        { x1: startConnector.component.x - halfX, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFour) {
                    console.log('4')
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: endConnector.aX + componentPadding, y2: startConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: startConnector.bY, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionFive) {
                    console.log('5')
                    const halfX = Math.abs(endConnector.component.x - startConnector.aX) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + halfX, y2: startConnector.bY },
                        { x1: startConnector.aX + halfX, y1: startConnector.bY, x2: startConnector.aX + halfX, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: startConnector.aX + halfX, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.component.y + endConnector.component.height + componentPadding },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.component.y + endConnector.component.height + componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
    
                if (conditionSix) {
                    console.log('6')
                    const halfX = Math.abs(endConnector.component.x - startConnector.aX) / 2;
                    segments.push(
                        { x1: startConnector.aX, y1: startConnector.bY, x2: startConnector.aX + halfX, y2: startConnector.bY },
                        { x1: startConnector.aX + halfX, y1: startConnector.bY, x2: startConnector.aX + halfX, y2: endConnector.component.y - componentPadding },
                        { x1: startConnector.aX + halfX, y1: endConnector.component.y - componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.component.y - componentPadding },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.component.y - componentPadding, x2: endConnector.aX + componentPadding, y2: endConnector.bY },
                        { x1: endConnector.aX + componentPadding, y1: endConnector.bY, x2: endConnector.aX, y2: endConnector.bY }
                    );
                }
            }
        }
    
        return segments
    }
}

export default Connection;