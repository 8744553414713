import { useState, useCallback, useEffect } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faTrash, faEdit, faUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";
import ContentModal from "../components/ContentModal";
import SecurityActionModal from "../components/SecurityActionModal";
import { formatDate, specificColorGenerator, extractInitials } from "../util/helpers";
import { useSwagger } from "../context/SwaggerContext";
import Collapse from "../components/Collapse";
import { usePermissions } from "../context/PermissionsContext"

const AdditionInfoForm = ({ selectedItem, building, onClose }) => {
    const { login } = useAuth();
    const { addToast } = useToast(); 
    const { t } = useTranslation();
    const _buildingGuid = useParams()['buildingGuid'];
    const client = useSwagger();
    const { allowEdit } = usePermissions();

    const [item, setItem] = useState({
        value: selectedItem?.value || "",
        organisation_key: selectedItem?.organisation_key || ""
    });

    const [keys, setKeys] = useState([]);

    const changeHandler = (e) => {
        const { id, value } = e.target;
        setItem(prev => ({
            ...prev,
            [id]: value
        }))
    }

    const fetchOrganisationsKey = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["org"].getAllObais({ org_uuid: building?.organisation });
            
            if (response.status >= 200 && response.status < 300) {
                const buildingKeys = response.obj;
                if (item.organisation_key === "") setItem(prev => ({...prev, organisation_key: buildingKeys?.[0]?.id}))
                setKeys(buildingKeys);
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, addToast, t, item]);

    const submit = useCallback(async(e) => {
        e.preventDefault();

        let obj = { organisation_key_uuid: item.organisation_key?.id || item.organisation_key, value: item.value };

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(
                    selectedItem?.id !== undefined ? obj : { additions: [obj] }
                );
                return req;
            };

            const response = selectedItem?.id === undefined ? await client.apis["building"].building_additional_create({ building_uuid: _buildingGuid }) : await client.apis["building"].building_additional_partial_update({ building_uuid: _buildingGuid, additional_uuid: selectedItem.id });

            if (response.status >= 200 && response.status < 300) {
                onClose();
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [login.Authorization, item, addToast, t, onClose])

    useEffect(() => {
        fetchOrganisationsKey();
    }, [])

    return (
        <Form onSubmit={(e) => submit(e)}>
            <Form.Group className="mb-3" controlId="organisation_key">
                <Form.Label>{t('key')}</Form.Label>
                <Form.Select value={item?.organisation_key?.id} onChange={(e) => changeHandler(e)}>
                    {keys?.map(k => <option key={`key-${k.id}`} value={k.id}>{k.key}</option>)}
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="value">
                <Form.Label>{t('value')}</Form.Label>
                <Form.Control type="text" value={item?.value} onChange={(e) => changeHandler(e)} />
            </Form.Group>

            <div className='d-flex justify-content-end w-100'>
                <Button disabled={!allowEdit} variant="outline-primary" type='submit'>{selectedItem?.id !== undefined ? t('edit') : t('add')}</Button>
            </div>
        </Form>
    )
}

const OBAIRow = ({ obai, setShowAddEditModal, setShowSecurity, setSelectedItem, c_b, u_b }) => {
    const { login, defaultTheme } = useAuth();
    const { t } = useTranslation();
    const client = useSwagger();
    const [created_by, setCreated_by] = useState(undefined);
    const [updated_by, setUpdated_by] = useState(undefined);
    const [display, setDisplay] = useState(false);

    const { allowEdit } = usePermissions();

    // const loadCreatedProfileImage = useCallback(async() => {
    //     if (!client || obai.created_by === null) return;

    //     const originalRequestInterceptor = client.http.requestInterceptor;
  
    //     try {
    //         client.requestInterceptor = (req) => {
    //             req.headers["Content-Type"] = "application/json";
    //             req.headers["Authorization"] = login.Authorization;
    //             return req;
    //         };
    
    //         const response = await client.apis["User"].getUserProfilePicture({ user_uuid_path: obai.created_by.id, size: 48 })

    //         if (response.status === 204)
    //             setCreated_by(false);

    //         if (response.status >= 200 && response.status < 300 && response.status !== 204)
    //             setCreated_by(URL.createObjectURL(response.data))
    
    //         client.http.requestInterceptor = originalRequestInterceptor;
    //     } catch (error) {
    //         setCreated_by(false);
    //         client.http.requestInterceptor = originalRequestInterceptor;
    //     }

    // }, [client, obai, login.Authorization, t])

    // const loadUpdatedProfileImage = useCallback(async() => {
    //     if (!client || obai.updated_by === null) return;

    //     const originalRequestInterceptor = client.http.requestInterceptor;
  
    //     try {
    //         client.requestInterceptor = (req) => {
    //             req.headers["Content-Type"] = "application/json";
    //             req.headers["Authorization"] = login.Authorization;
    //             return req;
    //         };
    
    //         const response = await client.apis["User"].getUserProfilePicture({ user_uuid_path: obai.updated_by.id, size: 48 })
            
    //         if (response.status === 204)
    //             setUpdated_by(false);

    //         if (response.status >= 200 && response.status < 300 && response.status !== 204)
    //             setUpdated_by(URL.createObjectURL(response.data))
    
    //         client.http.requestInterceptor = originalRequestInterceptor;
    //     } catch (error) {
    //         setUpdated_by(false);
    //         client.http.requestInterceptor = originalRequestInterceptor;
    //     }

    // }, [client, obai, login.Authorization, t])

    // useEffect(() => {
    //     loadCreatedProfileImage()
    //     loadUpdatedProfileImage()
    // }, [loadCreatedProfileImage, loadUpdatedProfileImage])

    return <>
        <tr>
            <td>
                <Collapse {...{ boolean: display, onClick: () => setDisplay(!display) }} />
            </td>
            <td>{obai?.organisation_key?.key}</td>
            <td>{obai.value}</td>
            <td>
                <div className="actions__buttonbar grid__two">
                    <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => {setShowAddEditModal(true); setSelectedItem(obai)}}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => {setShowSecurity(true); setSelectedItem(obai)}}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </div>
            </td>
        </tr>

        {display && <tr>
            <td colSpan="100%">

                <Table responsive className='my-3'>
                    <thead>
                        <tr>
                            <th>{t('createdBy')}</th>
                            <th>{t('updatedBy')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {/* <div className="d-flex">
                                    <div className="flex-shrink-0">
                                        {created_by && <img className="" src={created_by} alt='' />}
                                        {created_by === false && <div className="log__profile" style={{ backgroundColor: specificColorGenerator(obai.created_by?.prename + ' ' + obai.created_by?.name, true) }}>
                                            <span className="initials" style={{ color: specificColorGenerator(obai.created_by?.prename + ' ' + obai.created_by?.name, false) }}>{extractInitials(obai.created_by?.prename + ' ' + obai.created_by?.name)}</span>
                                        </div>}
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h5 className="mt-0">
                                            <a className={defaultTheme === 'light' ? "text-black" : "text-white"} href="/#">{obai.created_by?.prename + " " + obai.created_by?.name}</a>
                                        </h5>
                                        {formatDate(obai.created_at)}
                                    </div>
                                </div> */}
                                <div className="d-flex align-items-center">
                                    {c_b && <img className="author__img" src={c_b} alt='' />}
                                    {c_b === false && <div className="log__profile" style={{ backgroundColor: specificColorGenerator(obai.created_by?.prename + ' ' + obai.created_by?.name, true) }}>
                                        <span className="initials" style={{ color: specificColorGenerator(obai.created_by?.prename + ' ' + obai.created_by?.name, false) }}>{extractInitials(obai.created_by?.prename + ' ' + obai.created_by?.name)}</span>
                                    </div>}
                                    <div className="flex-grow-1 ms-3">
                                        <h5 className="mt-0">
                                            <a className={defaultTheme === 'light' ? "text-black" : "text-white"} href="/#">{obai.created_by !== null ? obai.created_by?.prename + " " + obai.created_by?.name : " "}</a>
                                        </h5>
                                        {formatDate(obai.created_at)}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex">
                                    {/* <div className="flex-shrink-0">
                                        {updated_by && <img src={updated_by} alt='' />}
                                        {updated_by === false && <div className="log__profile" style={{ backgroundColor: specificColorGenerator(obai.updated_by?.prename + ' ' + obai.updated_by?.name, true) }}>
                                            <span className="initials" style={{ color: specificColorGenerator(obai.updated_by?.prename + ' ' + obai.updated_by?.name, false) }}>{extractInitials(obai.updated_by?.prename + ' ' + obai.updated_by?.name)}</span>
                                        </div>}
                                    </div> */}
                                    <div className="d-flex align-items-center">
                                        {u_b && <img className="author__img" src={u_b} alt='' />}
                                        {u_b === false && <div className="log__profile" style={{ backgroundColor: specificColorGenerator(obai.updated_by?.prename + ' ' + obai.updated_by?.name, true) }}>
                                            <span className="initials" style={{ color: specificColorGenerator(obai.updated_by?.prename + ' ' + obai.updated_by?.name, false) }}>{extractInitials(obai.updated_by?.prename + ' ' + obai.updated_by?.name)}</span>
                                        </div>}
                                        <span>
                                        </span>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h5 className="mt-0">
                                            <a className={defaultTheme === 'light' ? "text-black" : "text-white"} href="/#">{obai.created_by !== null ? obai.updated_by?.prename + " " + obai.updated_by?.name : " "}</a>
                                        </h5>
                                        {formatDate(obai.updated_at)}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>

            </td>
        </tr>}
    </>
};

export const BuildingAdditionalInfos = ({ building }) => {
    const { login } = useAuth();
    const { addToast } = useToast(); 
    const { t } = useTranslation();
    const _buildingGuid = useParams()['buildingGuid'];

    const LOADITEMS = 5;
    const [showSecurity, setShowSecurity] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [visibleCount, setVisibleCount] = useState(LOADITEMS);
    const [selectedItem, setSelectedItem] = useState(undefined);
    const [addInfos, setAddInfos] = useState([])
    const client = useSwagger();

    const { allowEdit } = usePermissions();
    const [userCache, setUserCache] = useState({});

    const fetchBuildingKeys = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_additional_retrieve({ building_uuid: _buildingGuid });

            if (response.status >= 200 && response.status < 300) {
                if (response.status === 204) return [];
                setAddInfos(response.obj);

                const uniqueUserIds = new Set();

                response.obj.forEach((entry) => {
                    if (entry.created_by?.id) uniqueUserIds.add(entry.created_by?.id);
                    if (entry.updated_by?.id) uniqueUserIds.add(entry.updated_by?.id);

                });

                for (let userId of uniqueUserIds) {
                    await profileImage(userId);
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization, addToast, t]);

    const deleteBuildingKeys = useCallback(async(additonal) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_additional_destroy({
                building_uuid: _buildingGuid,
                additional_uuid: additonal,
            });

            if (response.status >= 200 && response.status < 300) {
                fetchBuildingKeys();
                addToast(t("success"), t("additionalInfoBuildingDeletedSuccess"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization, fetchBuildingKeys, addToast, t]);

    const loadMoreItems = () => {
        const newVisibleCount = visibleCount + LOADITEMS;
        setVisibleCount(newVisibleCount);
    };

    const loadProfileImage = useCallback(async(userID) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;
  
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
    
            const response = await client.apis["User"].getUserProfilePicture({ user_uuid_path: userID, size: 48 })

            if (response.status >= 200 && response.status < 300 && response.status !== 204)
                setUserCache((prevCache) => ({ ...prevCache, [userID]: URL.createObjectURL(response.data) }));
    
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login.Authorization])

    const profileImage = useCallback(async(userID) => {
        if (!userCache[userID])
            await loadProfileImage(userID);

        return userCache[userID];
    }, [userCache])

    useEffect(() => {
        fetchBuildingKeys()
    }, [])

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-2 gap-3 mb-3">
                {/* <h5 className='m-0'>{t('additionalInformations')}</h5> */}
                <h5 className='m-0'/>
                <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => setShowAddEditModal(true)}>
                    <FontAwesomeIcon icon={faAdd} />
                </Button>
            </div>
            <Table responsive className='mb-3'>
                <thead>
                    <tr>
                        <th>
                            <div className='d-flex' style={{width: '16px', height: '16px'}}>
                                <FontAwesomeIcon icon={faUpDownLeftRight} size='sm' className='flex-shrink-0' />
                            </div>
                        </th>
                        <th>{t('key')}</th>
                        <th>{t('value')}</th>
                        <th>{t('editDelete')}</th>
                    </tr>
                </thead>
                <tbody>
                    {addInfos.slice(0, visibleCount).map((item, i) => <OBAIRow key={`additional-info-${i}`} {...{ obai: item, setShowAddEditModal, setShowSecurity, setSelectedItem, c_b: userCache[item.created_by?.id], u_b: userCache[item.updated_by?.id] }} />)}
                </tbody>
            </Table>
            <div className='d-flex justify-content-between w-100'>
                <Button variant="outline-primary" className='mx-auto' disabled={visibleCount >= addInfos.length} onClick={() => loadMoreItems()}>{t('loadNMoreItems', { n: addInfos.length - visibleCount > LOADITEMS ? LOADITEMS : visibleCount >= addInfos.length ? 0 : addInfos.length - visibleCount })}</Button>
            </div> 
            <SecurityActionModal {...{show: showSecurity, onHide: () => {setSelectedItem(undefined); setShowSecurity(false)}, title: t('additionalInformationsDelete'), question: t('additionalInformationsDeleteQuestion'), action: () => deleteBuildingKeys(selectedItem.id)}} />
            <ContentModal {...{ show: showAddEditModal, onHide: () => {setSelectedItem(undefined); setShowAddEditModal(false)}, title: t('additionalInformationsAdd'), content: <AdditionInfoForm {...{selectedItem, building, onClose: () => {fetchBuildingKeys(); setSelectedItem(undefined); setShowAddEditModal(false)}}} />, topMost: true }} />
        </>
    )
}

export default BuildingAdditionalInfos;