import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../Context";
import Collapse from "../components/Collapse";
import { Button, OverlayTrigger, Placeholder, Table, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faCalculator,
  faCopy,
  faDrawPolygon,
  faEdit,
  faInfo,
  faToggleOn,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import AddEditRoom from "./AddEditRoom";
import AddEditLevel from "./AddEditLevel";

import { Calculate } from "./CalculateDIN12831";
import InlineEdit from "../components/InlineEdit";
import ContentModal from "../components/ContentModal";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import Card from "../components/Card";
import SecurityActionModal from "../components/SecurityActionModal";
import { RoomTable } from "./BuildingRoom";
import { useToast } from "../NotificationsContent";
import { useSwagger } from "../context/SwaggerContext";
import InfoLevel from "./InfoLevel";
import PieChart from "../charts/Piechart"
import Bubblechart from "../charts/Bubblechart"
import BuildingPiechart from "./BuildingPiechart";
import useBuildingHook from "../hooks/useBuildingHook";
import { usePermissions } from "../context/PermissionsContext";

export default function BuildinLevel() {
  const _buildingGuid = useParams()["buildingGuid"];
  const { login } = useAuth();
  const { addToast } = useToast();
  const [building, setBuilding] = useState(undefined);
  const { t } = useTranslation();
  const client = useSwagger();

  const loadbuilding = useCallback(async() => {
    // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/`, {
    //   headers: {
    //     Authorization: login.Authorization,
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((response) => {
    //     if (!response.ok) throw new Error(t("networkError"));
    //     if (response.status === 204) return [];
    //     return response.json();
    //   })
    //   .then((data) => {
    //     setBuilding(data);
    //   });

    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["building"].building_retrieve({ building_uuid: _buildingGuid });

      if (response.status === 204) {
        setBuilding([]);
        return;
      }

      if (response.status >= 200 && response.status < 300) {
        setBuilding(response.obj);
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      addToast(t("error"), t("networkError"), "error");
      client.http.requestInterceptor = originalRequestInterceptor;
    }

  }, [client, _buildingGuid, login.Authorization, t]);

  useEffect(() => {
    loadbuilding();
  }, [loadbuilding]);

  return (
    <MainLayout {...{ background: Background }}>
      <div className="sidebar__padding">
        <FloorTable {...{ building }} />
      </div>
    </MainLayout>
  );
}

const FloorTable = ({ building }) => {
  const { login } = useAuth();
  const _buildingGuid = useParams()["buildingGuid"];
  const { t } = useTranslation();
  const [show, setShow] = useState(undefined);
  const [floors, setFloors] = useState(undefined);
  const [floor, setFloor] = useState(undefined);
  const [updateRoom, setUpdateRoom] = useState();
  const { addToast } = useToast();
  const client = useSwagger();
  // const [rooms, setRooms] = useState(undefined);
  // const [buildingparts, setBuildingparts] = useState(undefined);
  const [types, setTypes] = useState(undefined);
  const { allowEdit } = usePermissions();
  useBuildingHook()

  const loadFloors = useCallback(async() => {
    setFloors(undefined);
    // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/floor/`, {
    //   headers: {
    //     Authorization: login.Authorization,
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((response) => {
    //     if (!response.ok) throw new Error(t("networkError"));
    //     if (response.status === 204) return [];
    //     return response.json();
    //   })
    //   .then((data) => {
    //     let a = data.sort((a, b) => {
    //       return a.floor < b.floor ? -1 : 1;
    //     });
    //     setFloors(a);
    //   });

    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["building"].getFloors({building_uuid: _buildingGuid});

      if (response.status === 204) {
        setFloors([]);
        return;
      }

      if (response.status >= 200 && response.status < 300) {
        let a = response.obj.sort((a, b) => {
          return a.level < b.level ? -1 : 1;
        });
        setFloors(a);
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      addToast(t("error"), t("networkError"), "error");

      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, login.Authorization, _buildingGuid, t]);

  const copyLevel = useCallback(async(floor) => {
      // fetch(
      //   `https://tech.sigmaheat.de/building/${_buildingGuid}/floor/${floor?.id}/copy`,
      //   {
      //     headers: {
      //       Authorization: login.Authorization,
      //       "Content-Type": "application/json",
      //     },
      //     method: "POST",
      //     //   body: JSON.stringify(floor.name),
      //   }
      // )
      //   .then((response) => {
      //     if (!response.ok) throw new Error(t("networkError"));
      //     if (response.status === 204) return [];
      //     return response.json();
      //   })
      //   .then((data) => {
      //     console.log("DATA", data);
      //     //refresh
      //     loadFloors();
      //   });
    
    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["building"].building_floor_copy_create({building_uuid: _buildingGuid, floor_uuid: floor?.id});

      if (response.status >= 200 && response.status < 300) {
        loadFloors();
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      addToast(t("error"), t("networkError"), "error");
      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, _buildingGuid, login.Authorization, t]);

  const deleteLevel = useCallback(async() => {
    // fetch(
    //   `https://tech.sigmaheat.de/building/${_buildingGuid}/floor/${floor?.id}/delete/all`,
    //   {
    //     headers: {
    //       Authorization: login.Authorization,
    //       "Content-Type": "application/json",
    //     },
    //     method: "DELETE",
    //   }
    // ).then((response) => {
    //   if (!response.ok) throw new Error(t("floorDeletedError"));
    //   return response;
    //   // loadFloors();
    //   // addToast(t("success"), t("floorDeleted"), "success");
    // })
    // .then((data) => {
    //   loadFloors();
    //   addToast(t("success"), t("floorDeleted"), "success");
    // })
    // .catch((error) => {
    //   addToast(t("error"), error.message, "error");
    // })
  
    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["building"].building_floor_delete_all_destroy({building_uuid: _buildingGuid, floor_uuid: floor?.id});

      if (response.status >= 200 && response.status < 300) {
        loadFloors();
        addToast(t("success"), t("floorDeleted"), "success");
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      addToast(t("error"), t("networkError"), "error");
      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, login.Authorization, loadFloors, addToast, t, floor])

  const getTypes = useCallback(async () => {
    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
        client.requestInterceptor = (req) => {
            req.headers["Content-Type"] = "application/json";
            req.headers["Authorization"] = login.Authorization;
            return req;
        };

        const response = await client.apis["buildingparttypes"].buildingparttypes_retrieve();

        if (response.status >= 200 && response.status < 300) {
            let responseEP = response.status === 204 ? [] : response.obj;

            const labelMap = {
                Floor: "floor",
                OuterWall: "outerwall",
                Ceiling: "ceiling",
                Window: "window",
                OuterDoor: "outerdoor",
                Roof: "roof",
                InnerWall: "innerwall",
                InnerDoor: "innerdoor",
                InnerCeiling: "innerceiling",
                Shutterbox: "rollershutterbox",
                "Virtual Group": "virtualGroup",
                "Ground Soil" : "groundSoil",
                "OuterWall Soil": "outerWallSoil",
            };

            const updatedItems = responseEP.map(item => {
                const newLabel = labelMap[item.label];
                return newLabel ? { ...item, label: newLabel } : item;
            });

            setTypes(updatedItems);
        }
        client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
        client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, login.Authorization]);

  // const getBuildingparts = useCallback(async() => {
  //   if (!client) return;

  //   const originalRequestInterceptor = client.http.requestInterceptor;

  //   try {
  //       client.requestInterceptor = (req) => {
  //           req.headers["Content-Type"] = "application/json";
  //           req.headers["Authorization"] = login.Authorization;
  //           return req;
  //       };

  //       const response = await client.apis["building"].building_buildingpart_list({building_uuid: _buildingGuid})

  //       if (response.status === 204) {
  //           setBuildingparts([])
  //           return;
  //       }

  //       if (response.status >= 200 && response.status < 300) {
  //           setBuildingparts(response.obj);
  //       }
  //       client.http.requestInterceptor = originalRequestInterceptor;
  //   } catch (error) {
  //       client.http.requestInterceptor = originalRequestInterceptor;
  //   }
  // }, [client, _buildingGuid, login.Authorization]);

  // const getRooms = useCallback(async() => {
  //   if (!client) return;

  //   const originalRequestInterceptor = client.http.requestInterceptor;

  //   try {
  //       client.requestInterceptor = (req) => {
  //           req.headers["Content-Type"] = "application/json";
  //           req.headers["Authorization"] = login.Authorization;
  //           return req;
  //       };

  //       const response = await client.apis["building"].getRooms({building_uuid: _buildingGuid})

  //       if (response.status === 204) {
  //           setRooms([])
  //           return;
  //       }

  //       if (response.status >= 200 && response.status < 300) {
  //           setRooms(response.obj);
  //       }
  //       client.http.requestInterceptor = originalRequestInterceptor;
  //   } catch (error) {
  //       client.http.requestInterceptor = originalRequestInterceptor;
  //   }
  // }, [client, _buildingGuid, login.Authorization]);

  const onClose = () => {
    setShow(false);
    setFloor(undefined);
    loadFloors();
  };

  const mainFunctions = [
    {
      label: t("addLevel"),
      onClick: () => setShow("AddLevel"),
      key: "add_level",
      icon: faAdd,
    },
    // {
    //   label: t("drawLevel"),
    //   onClick: () =>
    //     window.open(`${window.location.origin}/canvas`, "_blank").focus(),
    //   key: "draw_level",
    //   icon: faDrawPolygon,
    // },
    {
      label: t("calculate"),
      onClick: () => setShow("Calculate"),
      key: "calculate",
      icon: faCalculator,
    },
  ];

  const tableStructure = [
    {
      col: (
        <FontAwesomeIcon
          icon={faToggleOn}
          size="sm"
          className="flex-shrink-0"
        />
      ),
      type: "icon",
    }, {
      col: t("name"),
      type: "label",
    }, {
      col: t("floorNumber"),
      type: "label",
    },
    {
      col: t("heatingLossSum"),
      type: "label",
    },
    {
      col: t("thermalPower"),
      type: "label",
    },
    {
      col: t("heatedArea"),
      type: "label",
    },
    {
      col: t("actions"),
      type: "buttons",
    },
  ];

  useEffect(() => {
    loadFloors();
  }, [loadFloors]);

  useEffect(() => {
    // getBuildingparts();
    // getRooms()
    getTypes()
  }, [floors])
  
  return (
    <>
      <Card {...{ heading: `${t('buildingParts')} ${t('inThe')} ${t('building')}: ${login?.currentBuilding?.name}`, ...(allowEdit && { mainFunctions }) }}>
        {floors === undefined ? (
          <>
            <Card {...{ heading: t('overview'), active: true, className: "height-fit mb-3" }}>
              <div style={{ width: "100%", height: "500px" }}>
                <Placeholder as="div" animation="glow" style={{ width: "100%", height: "100%" }}>
                  <Placeholder xs={12} style={{ width: "100%", height: "100%" }} />
                </Placeholder>
              </div>
            </Card>
            <TablePlaceholder {...{ structure: tableStructure }} />
          </>
        ) : (floors !== undefined && !Boolean(floors?.length)) ? (
          <>
            <Card {...{ heading: t('overview'), active: true, className: "height-fit mb-3" }}>
              <div style={{ width: "100%", height: "500px" }}>
                <p>{t('noDataAvailable')}</p>
              </div>
            </Card>
            <p className="m-0">{t("noBuildingLevel")}</p>
          </>
        ) : (
          <>
            <BuildingPiechart {...{types}}/>
            <Table responsive>
              <thead>
                <tr>
                    {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                      <span>{(ts.type == "label") ? t(ts.col) : ts.col}</span>
                    </th>)}
                </tr>
                {/* <tr>
                  <th>
                    <div
                      className="d-flex"
                      style={{ width: "16px", height: "16px" }}
                    >
                      <FontAwesomeIcon
                        icon={faToggleOn}
                        size="sm"
                        className="flex-shrink-0"
                      />
                    </div>
                  </th>
                  <th>
                    <span>{t("name")}</span>
                  </th>
                  <th>
                    <span>{t("floorNumber")}</span>
                  </th>
                  <th>
                    <span>{t("heatingLossSum")}</span>
                  </th>
                  <th>
                    <span>{t("thermalPower")}</span>
                  </th>
                  <th>
                    <span>{t("heatedArea")}</span>
                  </th>
                  <th>
                    <span>{t("actions")}</span>
                  </th>
                </tr> */}
              </thead>
              <tbody>
                {floors.map((floor) => (
                  <FloorRow
                    key={`floor-${floor.id}`}
                    {...{
                      building,
                      floor,
                      setFloor,
                      setShow,
                      updateRoom,
                      copyLevel,
                      deleteLevel,
                    }}
                  />
                ))}
              </tbody>
            </Table>
          </>
        )}
      </Card>
      <ContentModal
        {...{
          show: show === "AddLevel",
          onHide: onClose,
          title: t("addLevel"),
          content: <AddEditLevel {...{ onClose }} />,
        }}
      />
      <ContentModal
        {...{
          show: show === "Calculate",
          onHide: onClose,
          title: t("calculateDIN12831"),
          content: <Calculate {...{ onClose }} />,
        }}
      />
      <ContentModal
        {...{
          show: show === "Room",
          onHide: onClose,
          title: t("calculate"),
          content: <Calculate {...{ onClose }} />,
        }}
      />
      <ContentModal
        {...{
          show: show === "Level",
          onHide: onClose,
          title: t("editLevel"),
          content: <AddEditLevel {...{ onClose, floor }} />,
        }}
      />
      <ContentModal
        {...{
          show: show === "moreInfo",
          onHide: onClose,
          title: t("additionalInformations"),
          content: <InfoLevel {...{ onClose, floor }} />,
        }}
      />
      <ContentModal
        {...{
          show: show === "AddRoom",
          onHide: onClose,
          title: t("addRoom"),
          content: (
            <AddEditRoom
              {...{
                onClose: () => {
                  setShow(false);
                  setUpdateRoom(new Date());
                },
                floor,
              }}
            />
          ),
        }}
      />

      <SecurityActionModal
        {...{
          show: show === "delete_level",
          onHide: () => setShow(false),
          title: t("deleteLevel"),
          question: t("deleteLevelQuestion"),
          action: () => deleteLevel(),
        }}
      />
    </>
  );
};

export const FloorRow = ({
  building,
  floor,
  setFloor,
  setShow,
  updateRoom,
  copyLevel,
  deleteLevel,
}) => {
  const { t } = useTranslation();
  const [display, setDisplay] = useState(false);
  const { allowEdit } = usePermissions();

  return (
    <>
      <tr>
        <td>
          <Collapse
            {...{ boolean: display, onClick: () => setDisplay(!display) }}
          />
        </td>
        <td>
          {/* <InlineEdit
            dictKey={"name"}
            dict={floor}
            setDict={setFloor}
            onEnter={() => {}}
            onCancel={() => {
              setFloor(floor);
            }}
          /> */}
            <span>
            {floor.name.toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </span>
        </td>
        <td>
          <span>
            {floor.level.toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </span>
        </td>
        <td>
          <span>
            {(floor.heating_load / 1000).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}{" "}
            kW
          </span>
        </td>
        <td>
          <span>
            {(floor.thermal_power / 1000).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}{" "}
            kW
          </span>
        </td>
        <td>
          <span>
            {floor.calculated_area.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}{" "}
            m²
          </span>
        </td>
        <td>
          <div className="actions__buttonbar grid__five">
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={<Tooltip>{t("addRoom")}</Tooltip>}
            >
              <Button
                disabled={!allowEdit}
                variant="outline-primary"
                className="p-10"
                onClick={() => {
                  setFloor(floor);
                  setShow("AddRoom");
                }}
              >
                <FontAwesomeIcon icon={faAdd} />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={<Tooltip>{t("edit")}</Tooltip>}
            >
              <Button
                disabled={!allowEdit}
                variant="outline-primary"
                className="p-10"
                onClick={() => {
                  setFloor(floor);
                  setShow("Level");
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={<Tooltip>{t("copy")}</Tooltip>}
            >
              {/* <Button variant="outline-primary" className="p-10" onClick={() => setShow('copy_level')}> */}
              <Button
                disabled={!allowEdit}
                variant="outline-primary"
                className="p-10"
                onClick={() => {
                  copyLevel(floor);
                  setShow("copy_level");
                }}
              >
                <FontAwesomeIcon icon={faCopy} />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={<Tooltip>{t("moreInfo")}</Tooltip>}
            >
              <Button
                variant="outline-primary"
                className="p-10"
                onClick={() => {
                  setFloor(floor);
                  setShow("moreInfo");
                }}
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={<Tooltip>{t("delete")}</Tooltip>}
            >
              <Button
                disabled={!allowEdit}
                variant="outline-primary"
                className="p-10"
                onClick={() => {
                  // deleteLevel(floor);
                  setFloor(floor)
                  setShow("delete_level");
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </OverlayTrigger>
          </div>
        </td>
      </tr>
      {display && (
        <tr>
          <td colSpan="100%">
            <Card {...{ heading: t('level') + ": " + floor.name, active: true, className: "height-fit" }}>
              <RoomTable {...{ building, floor, updateRoom }} />
            </Card>
          </td>
        </tr>
      )}
    </>
  );
};
