import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { Prices } from "./Prices"
import "./style.css"

export const HorizontalPricingTable = ({ plans, setPlans, prices, setPrices, select }) => {
    const { t } = useTranslation()

    const handleInputChange = useCallback((id, e) => {
        const { value } = e.target;
        const numericRegex = /[0-9\/]+/;
        if (numericRegex.test(value)) {
            setPlans(prev => {
                let tmpArray = [...prev];
                let index = tmpArray.findIndex(p => p.id === id);
                tmpArray[index].quantity = value;
                return tmpArray;
            })
        }
    }, [plans]);

    const onClick = useCallback((obj, type) => {
        select(obj, type)
    }, [select])

    return <div className="horizontal__wrapper">
        {plans?.map((plan, i) => {
            return <div className="horizontal__item" key={`plan-column-${plan.id}`}>
                <h3 className="horizontal__title">{plan.title}</h3>

                <ul className="horizontal__ul">
                    {/* {Object.values(plan.benefits).map((benefit, i) => <li key={`plan-benefit-${plan.id}-${i}`}>{benefit}</li>)} */}
                    {/* {JSON.parse(plan.benefits).map((benefit, i) => <li key={`plan-benefit-${plan.id}-${i}`}>{benefit}</li>)} */}
                    {Object.values(plan.benefits).map((benefit, i) => (
                        <li key={`plan-benefit-${plan.id}-${i}`}>{benefit.value}</li>
                    ))}
                </ul>

                {/* <p className="horizontal__description">{plan.description}</p> */}

                {/* <Prices {...{ prices: plan.prices, category: plan.title.replace(/[^a-zA-Z]/g, '').toLowerCase(), pricesArray: prices, setPrices, chk: prices.filter(item => plan.prices.map(p => p.price_id).includes(item))[0] }} /> */}

                <Prices {...{ prices: plan.price_per_appartment_month, category: plan.title.replace(/[^a-zA-Z]/g, '').toLowerCase(), pricesArray: prices, setPrices, chk: prices.filter(item => plan.price_per_appartment_month == item) }} />

                {/* <div className="horizontal__btn__wrapper">
                    <div className="horizontal__interval">
                        <span>ab</span> */}
                        {/* <p>{plan.prices.filter(item => prices.includes(item.price_id))?.[0].price >= 0 ? plan.prices.filter(item => prices.includes(item.price_id))?.[0].price * plan.quantity : false || plan.quantity < plan.prices.filter(item => prices.includes(item.price_id))?.[0].tiers?.[0].up_to ? plan.prices.filter(item => prices.includes(item.price_id))?.[0].tiers?.[0].flat_amount : plan.prices.filter(item => prices.includes(item.price_id))?.[0].tiers?.[1].unit_amount * plan.quantity}€ / {plan.prices.filter(item => prices.includes(item.price_id))?.[0].recurring.interval_count} {t(plan.prices.filter(item => prices.includes(item.price_id))?.[0].recurring.interval)}</p> */}
                        {/* <p>{plan.price_per_appartment_month}</p> */}
                    {/* </div> */}

                    {/* <input type="number" className="horizontal__quantity" value={plan.quantity} min={1} pattern="[0-9\.\-]*" onChange={(e) => handleInputChange(plan.id, e)} /> */}
                    {/* <button className="horizontal__button" onClick={() => onClick({price_id: plan.prices.filter(item => prices.includes(item.price_id))?.[0].price_id, quantity: plan.quantity, es_id: plan.id})}>{t("choose")}</button> */}
                {/* </div> */}

                <div className="d-flex flex-column justify-content-end">
                    {/* <button className="horizontal__button mb-3" onClick={() => onClick({price_id: plan.prices.filter(item => prices.includes(item.price_id))?.[0].price_id, quantity: plan.quantity, es_id: plan.id}, "offer")}>Angebot generieren</button> */}
                    <button className="horizontal__button mb-3" onClick={() => onClick({price_id: plan.price_per_appartment_month, quantity: plan.quantity, es_id: plan.id}, "offer")}>{t("generateOffer")}</button>
                    {/* <button className="horizontal__button" disabled={!plan.signed} onClick={() => onClick({price_id: plan.prices.filter(item => prices.includes(item.price_id))?.[0].price_id, quantity: plan.quantity, es_id: plan.id}, "order")}>Beauftragen</button> */}
                    <button className="horizontal__button" disabled={!plan.signed} onClick={() => onClick({price_id: plan.price_per_appartment_month, quantity: plan.quantity, es_id: plan.id}, "order")}>{t("assign")}</button>
                </div>
            </div>
        })}
    </div>
}

export default HorizontalPricingTable;