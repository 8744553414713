import { useState, useEffect, useCallback, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../Context'
import { Button, ButtonGroup, Table, Form, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenNib, faToggleOn, faChartSimple, faChartLine, faKey, faArrowDownWideShort } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import TablePlaceholder from '../components/Placeholders/TablePlaceholder'
import Collapse from '../components/Collapse';
import { ColumnChart } from '../components/AmCharts'
import { formatDateWithoutTime, utcToLocal, chartTimeUnit, quantityToUnit, quantityName } from '../util/helpers'
import DatePicker from "../components/DatePicker";
import ContentModal from '../components/ContentModal'
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import Card from '../components/Card'
import IndividualPlotContent from './IndividualPlotContent'
import ScatterPlotContent from './ScatterPlotContent'
import { useSwagger } from "../context/SwaggerContext";
import useBuildingHook from '../hooks/useBuildingHook'

const DeviceReadings = ({ device }) => {
    const [digital, setDigital] = useState(device.measurements.map(m => ({ id: m.id, checked: m.digital })))
    const { t } = useTranslation();

    const handleChange = useCallback((checked, id) => {
        let tmpList = [...digital];
        tmpList.find(d => d.id === id).checked = checked
        setDigital(tmpList) 
    }, [digital])

    return (
        <Table responsive>
            <thead>
                <tr>
                    <th>{t('cableNumber')}</th>
                    <th>{t('measurementPoint')}</th>
                    <th>{t('metric')}</th>
                    <th>Digimeta?</th>
                </tr>
            </thead>
            <tbody>
                {device.measurements.map(m => {
                    return <tr key={`measurement-point-reading-key-${m.uuid}`}>
                        <td>
                            <span>{m.position}</span>
                        </td>
                        <td>
                            <span>{m.name}</span>
                        </td>
                        <td>
                            <span>{m.quantity}</span>
                        </td>
                        <td>
                            <Form.Check checked={digital.find(d => d.id === m.id).checked} onChange={(e) => handleChange(e.target.checked, m.uuid)} />
                        </td>
                    </tr>
                })}
            </tbody>
        </Table>
    )
}

const DeviceActivation = ({ device, pregen }) => {
    const { t } = useTranslation();

    const idGenerator = () => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < 8; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength));
        return pregen ? result : '';
    }

    const [token, setToken] = useState(`SH!${idGenerator()}`);
    const [key, setKey] = useState(`SH!${idGenerator()}`);
    
    return (
        <Form>
            <Form.Group className='mb-3'>
                <Form.Label htmlFor="token">Token</Form.Label>
                <Form.Control id="token" placeholder="" value={token} onChange={(e) => setToken(e.target.value)} />
            </Form.Group>
            <Form.Group className='mb-3'>
                <Form.Label htmlFor="key">{t('key')}</Form.Label>
                <Form.Control id="key" placeholder="" value={key} onChange={(e) => setKey(e.target.value)} />
            </Form.Group>
            <div className="d-flex justify-content-end">
                <Button variant='outline-primary' onClick={() => console.log('lol')}>{t('confirm')}</Button>
            </div>
        </Form>
    )
}

const DeviceNameDescription = ({ type, device }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(type === 'DeviceName' ? device?.name : device?.deviceversionid?.description)
    
    return (
        <Form>
            <Form.Group className='mb-3'>
                <Form.Label htmlFor="device">{type === 'DeviceName' ? t('deviceName') : t('deviceDescription')}</Form.Label>
                <Form.Control id="device" placeholder="" value={value} onChange={(e) => setValue(e.target.value)} />
            </Form.Group>
            <div className="d-flex justify-content-end">
                <Button variant='outline-primary' onClick={() => console.log('lol')}>{t('change')}</Button>
            </div>
        </Form>
    )
}

const TimeRangeSelector = ({ initialConfig, onApply }) => {
    const { t } = useTranslation();
    const [config, setConfig] = useState(initialConfig);
    const [isChanged, setIsChanged] = useState(false);
    const [activeButton, setActiveButton] = useState(null);
    const [enabledIntervals, setEnabledIntervals] = useState(['quarterhourly', 'hourly', 'daily', 'weekly']);
  
    // Helper function to clear time from date
    const clearTime = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    };
  
    // Preset buttons with functions to set the date ranges and range check logic
    const presetButtons = [
        {
            name: t('oneDay'),
            function: () => {
                setConfig((prev) => ({
                    ...prev,
                    from: clearTime(new Date(prev.to.getTime() - 1 * 24 * 60 * 60 * 1000)),
                    interval: 'quarterhourly'
                }));
                setActiveButton(0);
            },
            rangeCheck: (from, to) => {
                const fromDate = clearTime(from);
                const toDate = clearTime(to);
                return (toDate - fromDate) / (24 * 60 * 60 * 1000) === 1;
            }
        }, {
            name: t('oneWeek'),
            function: () => {
                setConfig((prev) => ({
                    ...prev,
                    from: clearTime(new Date(prev.to.getTime() - 7 * 24 * 60 * 60 * 1000)),
                    interval: 'hourly'
                }));
                setActiveButton(1);
            },
            rangeCheck: (from, to) => {
                const fromDate = clearTime(from);
                const toDate = clearTime(to);
                return (toDate - fromDate) / (24 * 60 * 60 * 1000) === 7;
            }
        }, {
            name: t('ninetyDays'),
            function: () => {
                setConfig((prev) => ({
                    ...prev,
                    from: clearTime(new Date(prev.to.getTime() - 90 * 24 * 60 * 60 * 1000)),
                    interval: 'daily'
                }));
                setActiveButton(2);
            },
            rangeCheck: (from, to) => {
                const fromDate = clearTime(from);
                const toDate = clearTime(to);
                return (toDate - fromDate) / (24 * 60 * 60 * 1000) === 90;
            }
        }, {
            name: t('oneYear'),
            function: () => {
                setConfig((prev) => ({
                    ...prev,
                    from: clearTime(new Date(prev.to.getTime() - 365 * 24 * 60 * 60 * 1000)),
                    interval: 'weekly'
                }));
                setActiveButton(3);
            },
            rangeCheck: (from, to) => {
                const fromDate = clearTime(from);
                const toDate = clearTime(to);
                return (toDate - fromDate) / (24 * 60 * 60 * 1000) === 365;
            }
        }
    ];
  
    const intervalButtons = [
        { name: t('quarterhourly'), key: 'quarterhourly' },
        { name: t('hourly'), key: 'hourly' },
        { name: t('daily'), key: 'daily' },
        { name: t('weekly'), key: 'weekly' },
    ];
    
    const handleChange = (key, value) => {
        setConfig((prev) => {
            const updatedConfig = { ...prev, [key]: value };
    
            // Ensure 'from' is always less than or equal to 'to', swap if necessary
            if (key === 'from' && updatedConfig.from > updatedConfig.to) {
                [updatedConfig.from, updatedConfig.to] = [updatedConfig.to, updatedConfig.from];
            } else if (key === 'to' && updatedConfig.to < updatedConfig.from) {
                [updatedConfig.from, updatedConfig.to] = [updatedConfig.to, updatedConfig.from];
            }
    
            return updatedConfig;
        });
    };
    
    // Check if the current date range matches any of the preset ranges
    useEffect(() => {
        setActiveButton(0);
    }, []);
    
    // Update the enabled intervals based on the selected date range
    useEffect(() => {
        const fromDate = clearTime(config.from);
        const toDate = clearTime(config.to);
        const daysDifference = (toDate - fromDate) / (24 * 60 * 60 * 1000);
    
        // Enable or disable intervals based on the date range
        if (daysDifference >= 1 && daysDifference < 7) {
            setEnabledIntervals(['quarterhourly', 'hourly', 'daily']);
        } else if (daysDifference >= 7) {
            setEnabledIntervals(['quarterhourly', 'hourly', 'daily', 'weekly']);
        }
    }, [config.from, config.to]);
    
    useEffect(() => {
        setIsChanged(JSON.stringify(config) !== JSON.stringify(initialConfig));
    }, [config, initialConfig]);
    
    const handleApply = useCallback(() => {
        onApply(config);
        setIsChanged(false);
    }, [config, onApply]);
    
    const formatDate = (date) => {
        return `${String(date.getFullYear())}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    };
  
    return (
        <div className="timeRangeSelector mb-3">
            {/* Preset Buttons */}
            <ButtonGroup className="mb-3">
                {presetButtons.map((button, index) => (
                    <Button
                        key={index}
                        variant="outline-primary"
                        onClick={() => {
                            button.function();
                        }}
                        className={activeButton === index ? 'active' : ''}
                    >{button.name}</Button>
                ))}
            </ButtonGroup>
    
            {/* Date Inputs */}
            <Row className="mb-3">
                <Col>
                    <DatePicker
                        selected={config.from}
                        onChange={(e) => {
                            handleChange('from', e.detail.valueAsDate);
                            setActiveButton(-1)
                        }}
                        value={formatDate(config.from)}
                    />
                </Col>
                <Col>
                    <DatePicker
                        selected={config.to}
                        onChange={(e) => {
                            handleChange('to', e.detail.valueAsDate);
                            setActiveButton(-1)
                        }}
                        value={formatDate(config.to)}
                    />
                </Col>
            </Row>
    
            {/* Interval Selection */}
            <ButtonGroup className="mb-3">
                {intervalButtons.map((button, index) => (
                    <Button
                        key={index}
                        variant="outline-primary"
                        onClick={() => {
                            handleChange('interval', button.key);
                            setActiveButton(-1)
                        }}
                        className={config.interval === button.key ? 'active' : ''}
                        disabled={!enabledIntervals.includes(button.key)}
                    >{button.name}</Button>
                ))}
            </ButtonGroup>
    
            {/* Apply Button */}
            <div className="d-flex justify-content-end">
                <Button variant="outline-primary" onClick={handleApply} disabled={!isChanged}>{t('apply')}</Button>
            </div>
        </div>
    );
};

const TableRow = ({ device, changeModalContent, setShow }) => {
    const {login, defaultTheme} = useAuth();
    const _buildingGuid = useParams()['buildingGuid'];
    const { t } = useTranslation();
    const chartRef = useRef(null);
    const [display, setDisplay] = useState(false);
    const [measurements, setMeasurements] = useState(undefined);
    const [from, setFrom] = useState(utcToLocal(new Date(new Date(device.lastupload).getTime() - 1 * 24 * 60 * 60 * 1000)));
    // eslint-disable-next-line no-unused-vars
    const [to, setTo] = useState(utcToLocal(new Date(device.lastupload)));
    const [dataToShow, setDataToShow] = useState(false);
    const [load, setLoad] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [currentAggregation, setCurrentAggregation] = useState('avg');
    // eslint-disable-next-line no-unused-vars
    const [currentInterval, setCurrentInterval] = useState('quarterhourly');
    const client = useSwagger();

    const [initialConfig, setInitialConfig] = useState({
        from: utcToLocal(new Date(new Date(device.lastupload).getTime() - 1 * 24 * 60 * 60 * 1000)),
        to: utcToLocal(new Date(device.lastupload)),
        interval: 'quarterhourly',
    });

    const [chartConfig, setChartConfig] = useState(initialConfig);

    const updateChart = useCallback((config) => {
        setChartConfig(config);
    }, []);

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };

    const measurementPromise = useCallback((measurement) => {
        return new Promise(async(resolve, reject) => {
            if (!client) return;

            const originalRequestInterceptor = client.http.requestInterceptor;

            try {
                client.requestInterceptor = (req) => {
                    req.headers["Content-Type"] = "application/json";
                    req.headers["Authorization"] = login.Authorization;
                    return req;
                };

                const response = await client.apis["building"].building_data_retrieve({
                    building_uuid: _buildingGuid,
                    measurement_uuid: measurement,
                    from: formatDateWithoutTime(chartConfig.from),
                    to: formatDateWithoutTime(chartConfig.to),
                    aggregation: chartConfig.interval,
                    individual_limit: 20000
                });

                if (response.status === 200) {
                    resolve(response.obj);
                }

                client.http.requestInterceptor = originalRequestInterceptor;
            } catch (error) {
                reject(error);
                client.http.requestInterceptor = originalRequestInterceptor;
            }
        });
    }, [login.Authorization, _buildingGuid, chartConfig]);

    const loadMeasurements = useCallback(() => {
        setLoad(false);
        setDataToShow(false);
        setMeasurements(undefined);
        
        const promises = device.measurements.map(
            measurement => measurementPromise(measurement.id)
        );

        Promise.all(promises).then((values) => {
            const nameData = values.map((data, i) => {
                const name = device.measurements[i].name;
                const keyPoint = name.replace(/\s+/g, '').toLowerCase();

                const dataFormatter = (json) => {
                    var retArr = [];
                    for (var i = json.length - 1; i >= 0; i--) {
                        retArr.push({
                            date: Date.parse(utcToLocal(json[i].timestamp)),
                            [keyPoint]: parseFloat((Math.round(json[i][currentAggregation] * 100) / 100).toFixed(2)),
                        });
                    }
                    return retArr;
                }

                return {
                    name: name,
                    data: dataFormatter(data).sort(function (a, b) {
                        return new Date(a.date) - new Date(b.date);
                    }),
                    unit: device.measurements[i].quantity,
                    type: 'Line'
                }
            });
            
            const data = nameData.map(nd => nd.data);

            for (let i = 0; i < data.length; i++) {
                if (data[i].length !== 0) {
                    setDataToShow(true);
                    break;
                }
            }

            const groupObjectsByUnit = (inputObjects) => {
                const groupedObjects = {};

                inputObjects.forEach(obj => {
                    console.log(obj)
                    if (!groupedObjects[obj.unit]) {
                        groupedObjects[obj.unit] = {
                            unit: quantityToUnit(obj.unit),
                            dimension: quantityName(obj.unit),
                            name: [],
                            data: [],
                            type: []
                        };
                    }

                    groupedObjects[obj.unit].name.push(obj.name);
                    groupedObjects[obj.unit].data.push(obj.data);
                    groupedObjects[obj.unit].type.push(obj.type);
                });

                return Object.values(groupedObjects);
            }

            setLoad(true);
            setFirstLoad(true);
            setMeasurements(groupObjectsByUnit(nameData));
        });
    }, [device.measurements, measurementPromise, currentAggregation, chartConfig])

    const collapse = useCallback(() => {
        if (!display) loadMeasurements()
        setDisplay(!display)
    }, [display, loadMeasurements])

    // eslint-disable-next-line no-unused-vars
    const changeRange = (e) => {
        const currentDate = new Date();
        if (e < currentDate) {
            setFrom(utcToLocal(new Date(e)));
            chartRef.current.zoomInit();
        }
    }

    const resetChartState = () => {
        setMeasurements(undefined);
        // setFrom(utcToLocal(new Date(new Date().setDate(new Date().getDate() - 1))));
        // setTo(utcToLocal(new Date()));
        setDataToShow(false);
        setLoad(false);
        setFirstLoad(false);
        setCurrentAggregation('avg');
        setCurrentInterval('quarterhourly');
    }

    useEffect(() => {
        if (!display) resetChartState();
    }, [display])

    useEffect(() => {
        if (chartConfig === undefined) return
        if (display) loadMeasurements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartConfig?.from, chartConfig?.to, display, loadMeasurements])

    return (
        <>
            <tr className='align-middle'>
                <td>
                    <Collapse {...{ boolean: display, onClick: () => collapse() }} />
                </td>
                <td>
                    <div className='edit__btn__wrapper'>
                        <span>{device.name}</span>
                        {/* <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip className='position-absolute'>{t("edit")}</Tooltip>
                        }>
                            <button className='clear__btn' onClick={() => {changeModalContent(t('deviceName'), device); setShow(true)}}>
                                <FontAwesomeIcon icon={faPenNib} size="sm" color="#ffffff" />
                            </button>
                        </OverlayTrigger> */}
                    </div>
                </td>
                <td>{utcToLocal(new Date(device.lastupload)).toLocaleString('de-DE', options)}</td>
                <td>
                    <div className='edit__btn__wrapper'>
                        <span>{device.comment}</span>
                        {/* <OverlayTrigger
                            trigger={['hover', 'focus']}
                            overlay={<Tooltip className='position-absolute'>{t("edit")}</Tooltip>}
                        >
                            <button className='clear__btn' onClick={() => {changeModalContent(t('deviceDescription'), device); setShow(true)}}>
                                <FontAwesomeIcon icon={faPenNib} size="sm" color="#ffffff" />
                            </button>
                        </OverlayTrigger> */}
                    </div>
                </td>
                {/* <td>
                    <div className='d-flex gap-3'>
                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            overlay={<Tooltip className='position-absolute'>{t("assignActivationDataDevice")}</Tooltip>}
                        >
                            <button className='clear__btn' onClick={() => {changeModalContent('DeviceActivation', device); setShow(true)}}>
                                <FontAwesomeIcon icon={faKey} size="sm" color="#ffffff" />
                            </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            overlay={<Tooltip className='position-absolute'>{t("changeDeviceReadings")}</Tooltip>}
                        >
                            <button className='clear__btn' onClick={() => {changeModalContent('DeviceReadings', device); setShow(true)}}>
                                <FontAwesomeIcon icon={faArrowDownWideShort} size="sm" color="#ffffff" />
                            </button>
                        </OverlayTrigger>
                    </div>
                </td> */}
            </tr>
            {display && <tr>
                <td colSpan='100%'>
                    <TimeRangeSelector {...{ initialConfig, onApply: (config) => updateChart(config) }} />

                    <div style={{ width: "100%", height: "500px" }}>
                        <ColumnChart {...{ref: chartRef, theme: defaultTheme === 'light' ? 'dark' : 'light', data: measurements, load, firstLoad, dataToShow, time: {from, to}, interval: chartTimeUnit(currentInterval)}} />
                    </div>
                </td>
            </tr>}
        </>
    )
}

export const BuildingDevices = () => {
    const { login } = useAuth();
    const client = useSwagger();
    const [devices, setDevices] = useState(undefined);
    const _buildingGuid = useParams()['buildingGuid'];
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [contentModal, setContentModal] = useState({
        title: '',
        content: <></>
    });
    useBuildingHook()

    const tableStructure = [
        {
            col: <FontAwesomeIcon icon={faToggleOn} size='sm' className='flex-shrink-0' />,
            type: 'icon'
        }, {
            col: t('name'),
            type: 'label'
        }, {
            col: t('description'),
            type: 'label'
        }, {
            col: t('lastUpdate'),
            type: 'label'
        },
        // {
        //     col: t('actions'),
        //     type: 'buttons'
        // }
    ]

    const loadDevices = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_device_retrieve({ building_uuid: _buildingGuid });

            if (response.status >= 200 && response.status < 300) {
                setDevices(response.status === 204 ? [] : response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log(error)
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization]);

    console.log("devices")
    console.log(devices)

    const changeModalContent = (type, device) => {
        if (type === 'ScatterPlot') setContentModal({title: t('heatingCurve'), content: <ScatterPlotContent {...{devices}} />, size: 'xl'})
        if (type === 'Individual') setContentModal({title: t('individualDiagram'), content: <IndividualPlotContent {...{devices}} />, size: 'xl'})
        if (type === 'DeviceName') setContentModal({title: t('changeDeviceName'), content: <DeviceNameDescription {...{type: 'DeviceName', device}} />})
        if (type === 'DeviceDescription') setContentModal({title: t('changeDeviceDescription'), content: <DeviceNameDescription {...{type: 'DeviceDescription', device}} />})
        if (type === 'DeviceActivation') setContentModal({title: t('assignActivationDataDevice'), content: <DeviceActivation {...{device, pregen: true}} />})
        if (type === 'DeviceReadings') setContentModal({title: t('changeDeviceReadings'), content: <DeviceReadings {...{device}} />, size: 'lg'})
    }

    const mainFunctions = [
        {label: t('heatingCurve'), onClick: () => {changeModalContent('ScatterPlot'); setShow(true)}, key: 'heatingCurve', icon: faChartLine},
        {label: t('individualDiagram'), onClick: () => {changeModalContent('Individual'); setShow(true)}, key: 'individualDiagram', icon: faChartSimple}
    ]

    useEffect(() => {
        loadDevices()
    }, [loadDevices])

    return <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">
            <Card {...{ heading: `${t('devices')} ${t('inThe')} ${t('building')}: ${login?.currentBuilding?.name}`, mainFunctions }}>
                {(Boolean(devices === undefined)) ? (
                    <TablePlaceholder {...{structure: tableStructure}} />
                ) : (Boolean(devices.length)) ? (
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    <div className='d-flex' style={{width: '16px', height: '16px'}}>
                                        <FontAwesomeIcon icon={faToggleOn} size='sm' className='flex-shrink-0' />
                                    </div>
                                </th>
                                <th>{t('name')}</th>
                                <th>{t('lastUpdate')}</th>
                                <th>{t('description')}</th>
                                {/* <th>{t('actions')}</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {devices.map(device => <TableRow key={`device-key-${device.id}`} {...{device, changeModalContent, setShow}} />)}
                        </tbody>
                    </Table>
                ) : (
                    <p className='m-0'>{t('noDevices')}</p>
                )}
            </Card>
            <ContentModal {...{show, onHide: () => setShow(false), ...contentModal}} />
        </div>
    </MainLayout>
}

export default BuildingDevices;