import { useState, useContext, createContext, useCallback, useEffect } from 'react';

const ToastContext = createContext();

export const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) throw new Error('useToast must be used within a ToastProvider');
    return context;
};

export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);
  
    const addToast = useCallback((title, content, status) => {
        const date = new Date();
        const newToast = {
            id: Math.random().toString(),
            title: title,
            time: date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toString(),
            content: content,
            status: status
        };
    
        setToasts((prevToasts) => {
            // let tmpToast = [...prevToasts].slice(-5);
            return [...prevToasts, newToast];
        });
    }, []);
  
    const removeToast = useCallback((id) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    }, []);

    useEffect(() => {
        console.log(toasts)
    }, [toasts])
  
    return <ToastContext.Provider value={{ toasts, addToast, removeToast }}>{children}</ToastContext.Provider>
};