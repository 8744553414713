import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../Context";
import { useTranslation } from "react-i18next";
import { Form, Col, Row, Button } from "react-bootstrap";
import { useToast } from "../NotificationsContent";
import { useSwagger } from "../context/SwaggerContext";
import Select from 'react-select';

export const AddEditBuilding = ({ building, setBuilding, loadbuildings, onHide }) => {
    const { t } = useTranslation();
    const { login } = useAuth();
    const { addToast } = useToast();
    const client = useSwagger();
    const [bwzk, setBWZK] = useState([])
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (selectedOption) => {
        console.log(selectedOption)
        setSelectedOption(selectedOption);
    };

    const getBWZK = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["bwzk"].bwzk_retrieve()

            if (response.status >= 200 && response.status < 300)
                setBWZK(Object.entries(response.obj).map(([key, value]) => ({
                    value: key,
                    label: value
                })))

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("building"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, addToast, t])

    const onSubmit = useCallback(async(e) => {
        const buildingData = {
            id: building.id,
            name: building.name,
            street: building.street,
            no: building.no,
            zip: building.zip,
            city: building.city,
            construction_year: building.construction_year,
          };
          
        e.preventDefault();

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;

                req.body = JSON.stringify(buildingData);
                return req;
            };

            const response = building.id ? await client.apis["building"].building_partial_update({ building_uuid: building.id }) : await client.apis["building"].building_create({ org_uuid: login.currentOrganisation.id });

            if (response.status >= 200 && response.status < 300) {
                loadbuildings();
                addToast(t("building"), building.id ? t("buildingPATCH") : t("buildingPOST"), "success");
                onHide();
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("building"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, building, addToast, loadbuildings, login.currentOrganisation.id, onHide, t])

    const editBuildingObj = useCallback((e, type) => {
        let b = { ...building };
        if (type === 'string') b[e.target.id] = e.target.value;
        if (type === 'int') b[e.target.id] = parseInt(e.target.value);
        setBuilding({ ...b });
    }, [building, setBuilding])

    useEffect(() => {
        getBWZK()
    }, [])

    return (<Form onSubmit={(e) => onSubmit(e)}>
        <Form.Group id='name' className='mb-3'>
            <Form.Label>{t('name')}</Form.Label>
            <Form.Control id='name' required type='text' value={building['name'] || ''} onChange={(e) => editBuildingObj(e, 'string')} />
        </Form.Group>

        <Form.Group id='address' className='mb-3'>
            <Row className='mb-3'>
                <Col xs={8}>
                    <Form.Label>{t('street')}</Form.Label>
                    <Form.Control id='street' required type='text' value={building['street'] || ''} onChange={(e) => editBuildingObj(e, 'string')} />
                </Col>
                <Col>
                    <Form.Label>{t('housenumber')}</Form.Label>
                    <Form.Control id='no' required type='text' value={building['no'] || ''} onChange={(e) => editBuildingObj(e, 'string')} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label>{t('zip')}</Form.Label>
                    <Form.Control id='zip' required type='text' min='0' max='99999' value={building['zip'] || ''} onChange={(e) => editBuildingObj(e, 'int')} />
                </Col>
                <Col>
                    <Form.Label>{t('city')}</Form.Label>
                    <Form.Control id='city' required type='text' value={building['city'] || ''} onChange={(e) => editBuildingObj(e, 'string')} />
                </Col>
            </Row>
        </Form.Group>

        <Form.Group id='construction_year' className='mb-3'>
            <Form.Label>{t('constructionYear')}</Form.Label>
            <Form.Control id='construction_year' required type='number' value={building['construction_year']} min='1800' max={new Date().getFullYear() + 1} onChange={(e) => editBuildingObj(e, 'int')} />
        </Form.Group>

        <Form.Group id='bwzk' className='mb-3'>
            <Form.Label>BWZK</Form.Label>
            <Select
                value={selectedOption}
                onChange={handleChange}
                options={bwzk}
                isSearchable={true}
                placeholder={t('search')}
            />
        </Form.Group>

        <Button variant='outline-primary' className='w-100' type='submit'>{t('confirm')}</Button>
    </Form>)
}

export default AddEditBuilding;