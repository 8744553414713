import React, { useCallback, useEffect, useState } from 'react'
import MainLayout from '../components/MainLayout'
import Background from "../assets/background_3_3.png";
import Card from '../components/Card';
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import Collapse from "../components/Collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faDownload, faEdit, faEye, faInfoCircle, faList, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from '../Context';
import { useSwagger } from '../context/SwaggerContext';
import { useParams } from 'react-router-dom';
import { useToast } from '../NotificationsContent';
import { useTranslation } from 'react-i18next';
import { Button, OverlayTrigger, Placeholder, Table, Tooltip } from 'react-bootstrap';
import ContentModal from '../components/ContentModal';
import AddEditDocument from './AddEditDocument';
import SecurityActionModal from '../components/SecurityActionModal';
import BuildingDocumentData from './BuildingDocumentData';
import SearchFile from '../components/SearchFile';
import Loading from '../components/Loading';
import Preview from '../components/Preview'
import { usePermissions } from "../context/PermissionsContext";
import Pagination from '../components/Pagination';

export const BuildingDocuments = ({...props}) => {
    const subsidy = props?.subsidyRow;
    const searchFromSubsidy = props?.search;

    const _buildingGuid = useParams()['buildingGuid'];
    const client = useSwagger();
    const { login } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();

    const [show, setShow] = useState(false);
    const [files, setFiles] = useState(subsidy?.files || undefined);
    const [file, setFile] = useState(undefined);
    const [foundItem, setFoundItem] = useState(props?.foundItem || undefined)
    const [previewFile, setPreviewFile] = useState(undefined)

    const [loading, setLoading] = useState(false)
    let showSearchbarOnBigDevice = true
    const { allowEdit } = usePermissions();

    const mainFunctions = [
        { label: t('add'), onClick: () => setShow('addDocument'), key: 'add', icon: faAdd, disabled: !allowEdit },
        // {label: t('search'), onClick: () => setShow('searchBar'), key: 'search', icon: faSearch}

         // wird bei kleinen Bildschirmen ausgeführt
        //  {label: t('search'), onClick: () => setShow('searchBar'), key: 'searchFileSmallDevice', icon: faSearch},

         // wird bei größeren Bildschirmen ausgeführt
         { component: <SearchFile {...{ setFiles, setShow, setFoundItem, setLoading, showSearchbarOnBigDevice }} />, key: 'searchFile' }
    ]

    const tableStructure = [
        {
            col: <div className='d-flex' style={{ width: '16px', height: '16px' }}><FontAwesomeIcon icon={faList} size='sm' className='flex-shrink-0' /></div>,
            type: 'icon'
        }, {
            col: "name",
            type: 'label'
        }, {
            col: "size",
            type: 'label'
        }, {
            col: "created",
            type: 'label'
        }, {
            col: "type",
            type: 'label'
        }, {
            col: "file_type",
            type: 'label'
        }, {
            col: t('actions'),
            type: 'buttons'
        },
    ]

    const downloadFile = (blob, fileName) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.style.display = 'none'; // Das Element wird nicht angezeigt
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    };

    const getAllFiles = useCallback(async () => {
        if (!client || searchFromSubsidy) return;
        setFiles([]);

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = subsidy ? await client.apis["building"].building_action_retrieve({building_uuid: _buildingGuid, action_uuid: subsidy?.id}) : await client.apis["building"].building_file_retrieve({building_uuid: _buildingGuid});
            console.log(response)
            if (response.status === 204) {
                setFiles([]);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                subsidy ? setFiles(response.obj?.files) : setFiles(response.obj);
                if (!subsidy) {
                    setFoundItem(undefined)
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [_buildingGuid, addToast, client, login.Authorization, t, searchFromSubsidy])

    // download
    const getFile = useCallback(async ({download}) => {
        if (!client) return;
        setLoading(true)

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                // req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = subsidy ? await client.apis["building"].building_action_file_retrieve({building_uuid: _buildingGuid, action_uuid: subsidy?.id, file_uuid: file?.id}) : await client.apis["building"].building_file_retrieve_2({building_uuid: _buildingGuid, file_uuid: file?.id});

            if (response.status >= 200 && response.status < 300) {
                const blob = await response.data;
                download ? downloadFile(blob, file.title) : setPreviewFile(blob)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            download && setShow(false);
            download && setFile(undefined);
            setLoading(false)
        }
    }, [client, subsidy, _buildingGuid, file?.id, login.Authorization, addToast, t])

    const deleteFile = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                // req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = subsidy ? await client.apis["building"].building_action_file_destroy({building_uuid: _buildingGuid, action_uuid: subsidy?.id, file_uuid: file?.id}) : await client.apis["building"].building_file_destroy({building_uuid: _buildingGuid, file_uuid: file?.id});

            if (response.status >= 200 && response.status < 300) {
                // searched ? : getAllFiles()
                if (searchFromSubsidy) {
                    const updatedDocuments = subsidy?.files?.filter(doc => doc.id !== file?.id);
                    setFiles(updatedDocuments)
                    subsidy.files = updatedDocuments
                } else {
                    getAllFiles()
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            setShow(false);
            setFile(undefined);
        }
    }, [client, subsidy, _buildingGuid, file?.id, login.Authorization, searchFromSubsidy, getAllFiles, addToast, t])

    const onClose = () => {
        setShow(false);
        setFiles(undefined);
        getAllFiles()
    }

    // pagination
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 15;
    const [pageCount, setPageCount] = useState(Math.ceil((files?.length || 0) / pageSize));

    useEffect(() => {
        setPageCount(Math.ceil((files?.length || 0) / pageSize));
    }, [files, pageSize]);

    const indexOfLastItem = (currentPage + 1) * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentItems = files?.slice(indexOfFirstItem, indexOfLastItem) || [];

    useEffect(() => {
        getAllFiles()
    }, [getAllFiles])

    useEffect(() => {
        if (show === "download") getFile({download: true})
        if (show === "preview") getFile({download: false})
    }, [getFile, show])

    useEffect(() => {
        if (subsidy && subsidy.files) {
            setFiles(subsidy?.files)
        }
    },[foundItem, subsidy])

    return (
        <>
            {subsidy ? (
                (files === undefined) ? (
                    <TablePlaceholder {...{ structure: tableStructure }} />
                ) : (!Boolean(files?.length)) ? (
                    <p className="m-0">{t("noDocuments")}</p>
                ) : (
                    <>
                        <Table responsive>
                            <thead>
                                <tr>
                                    {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                                        <span>{(ts.type == "label") ? t(ts.col.toLowerCase()) : ts.col}</span>
                                    </th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((document) => <DocumentRow key={`document-key-${document.id}`} {...{ document, setShow, setFile, foundItem : props?.foundItem }} />)}
                            </tbody>
                        </Table>
                        {/* only edit */}
                        <ContentModal show={show === 'editFile'} onHide={() => setShow(false)} title={t('editDocument')} content={<AddEditDocument onClose={onClose} file={file} subsidy={subsidy} getAllFiles={getAllFiles} />} />
                    </>

                )

            ) : (
                <MainLayout {...{ background: Background }}>
                    <div className="sidebar__padding">
                        <Card {...{ heading: `${t('documents')} ${t('inThe')} ${t('building')}: ${login?.currentBuilding?.name}`, mainFunctions, search: true }}>
                            <SearchFile {...{ setFiles, setShow, setFoundItem, setLoading }} />
                            {(files === undefined) ? (
                                <TablePlaceholder {...{ structure: tableStructure }} />
                            ) : (!Boolean(files?.length)) ? (
                                <p className="m-0">{t("noDocuments")}</p>
                            ) : (
                                <>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                                                    <span>{(ts.type == "label") ? t(ts.col.toLowerCase()) : ts.col}</span>
                                                </th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentItems.map((document) => <DocumentRow key={`document-key-${document.id}`} {...{ document, setShow, setFile, foundItem }} />)}
                                        </tbody>
                                    </Table>

                                    {files && (
                                        <Pagination
                                            currentPage={currentPage}
                                            pageSize={pageSize}
                                            onPageChange={(page) => setCurrentPage(page)}
                                            pageCount={pageCount}
                                        />
                                    )}
                                </>
                            )}
                        </Card>
                        {loading && (
                            <Loading/>
                        )}
                        <ContentModal {...{ show: show === 'addDocument' || show === 'editFile', onHide: () => { setShow(false); setFile(undefined)}, title: show === 'editFile' ? t('editDocument') : t('addDocument'), content: <AddEditDocument {...{ onClose, file, getAllFiles }} /> }} />
                        <ContentModal {...{ show: show === 'searchBar', onHide: () => setShow(false), title: `${t('search')} ${t('document')}`, content: <SearchFile {...{ setFiles, setShow, setFoundItem, showSearchbarOnBigDevice }}/> }} />                  
                    </div>
                </MainLayout>
            )}
            <ContentModal {...{ show: (show === 'preview' && !loading), doNotShwCloseBtn: true, fullscreen: previewFile?.type === "application/pdf" ? true : false, onHide: () => { setFile(undefined); setShow(false); setPreviewFile(undefined) }, content: <Preview {...{ previewFile, onClose }} /> }} />
            <SecurityActionModal {...{ show: show === "deleteFile", onHide: () => { setFile(undefined); setShow(false) }, title: t('deleteDocument'), question: t('deleteDocumentQuestion'), action: () => deleteFile() }} />
        </>
    )
}

const DocumentRow = ({ document, setShow, setFile, foundItem }) => {
    const { t } = useTranslation();
    const [display, setDisplay] = useState(false);

    const isoString = document.created_at

    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString('de-DE');
    const formattedTime = date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    const { allowEdit } = usePermissions();

    return <>
        <tr>
            <td>
                <Collapse {...{ boolean: display, onClick: () => setDisplay(!display) }} />
            </td>
            <td>
                <span>{document.title === "" ? document?.supplier_name_prename : document.title}</span>

                {document?.supplier_name_prename &&
                    <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{t("requested")}</Tooltip>}>
                        <FontAwesomeIcon className="mx-2" icon={faInfoCircle} />
                    </OverlayTrigger>
                }
            </td>
            <td>
                <span>{document.file_size_kb} KB</span>
            </td>
            <td>
                <span>{formattedDateTime}</span>
            </td>
            <td>
                <span>{t(document.type_name?.toLowerCase())}</span>
            </td>
            <td>
                <span>{document.content_type}</span>
            </td>
            <td>
                <div className='actions__buttonbar grid__four'>
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t("preview")}</Tooltip>
                        }>
                        <Button disabled={document.filename === "asdasd.docx" || document.filename === "" || !document.file_size_kb } variant="outline-primary" className="p-10" onClick={() => { setFile(document); setShow("preview") }}>
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t("download")}</Tooltip>
                        }>
                        <Button disabled={!allowEdit || document.filename === "" || !document.file_size_kb } variant="outline-primary" className="p-10" onClick={() => { setFile(document); setShow("download") }}>
                            <FontAwesomeIcon icon={faDownload} />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t("edit")}</Tooltip>
                        }>
                        <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setFile(document); setShow("editFile") }}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t("delete")}</Tooltip>
                        }>
                        <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setFile(document); setShow("deleteFile") }}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </OverlayTrigger>
                </div>
            </td>
        </tr>
        {display && (
            <tr>
                <td colSpan="100%">
                    <Card {...{ heading: t('extractedWordsFromFile'), active: true, className: "height-fit" }}>
                        {document.ocr_document_data?.JobId === undefined ? (
                            <BuildingDocumentData {...{data : document.ocr_text_data, item: foundItem}}/>
                        ) : (
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={6} style={{ width: '100%' }} size="lg" />
                            </Placeholder>
                        )}
                    </Card>
                </td>
            </tr>
        )}
    </>
}

export default BuildingDocuments;
