import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../Context";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useToast } from "../NotificationsContent";
import Card from "../components/Card";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import { Button, Form, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { faAdd, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentModal from "../components/ContentModal";
import SecurityActionModal from "../components/SecurityActionModal";
import { useSwagger } from "../context/SwaggerContext";

export const StrandReference = ({ variantID }) => {
    const _buildingGuid = useParams()['buildingGuid'];
    const { login } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();
    const [strandReferences, setStrandReferences] = useState(undefined)
    const [show, setShow] = useState(false);
    const [strandRef, setStrandRef] = useState(undefined);
    const client = useSwagger();

    const mainFunctions = [
        { label: t('add'), onClick: () => onClick('add/edit', undefined), key: 'add', icon: faAdd }
    ]

    const tableStructure = [
        {
            col: "Name",
            type: 'label'
        }, {
            col: t("date"),
            type: 'label'
        }, {
            col: t("maxDistance"),
            type: 'label'
        }, {
            col: "Meta",
            type: 'label'
        }, {
            col: t("targetPressureMBAR"),
            type: 'label'
        }, {
            col: t("actions"),
            type: 'buttons'
        }
    ];

    const onClick = (show, ref) => {
        setShow(show);
        setStrandRef(ref)
    }

    const fetchSR = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/variants/${variantID}/strandreference/`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "GET"
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t('networkError'));
        //     return response.json()
        // })
        // .then(data => {
        //     //meta wird als object übergeben
        //     //Umwandlung in ein array 
    
        //     const newData = data.results.map(item => {
        //         return {
        //             ...item,
        //             meta: Array.isArray(item.meta) ? item.meta : [item.meta]
        //         };
        //     });

        //     setStrandReferences(newData)
        //     onClick();
        // })
        // .catch(error => addToast(t("error"), error.message, "error"))

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variants_strandreference_list({building_uuid: _buildingGuid, variant_uuid: variantID});

            if (response.status >= 200 && response.status < 300) {
                console.log("response: ", response.obj)
                const modifiedArray = response.obj.results.map((item) => {           
                    const arr = Object.keys(item.meta).map(key => ({
                        key: key,
                        value: JSON.stringify(item.meta[key])
                    }));
                    return item = {...item, meta: arr}; 
                })

                setStrandReferences(modifiedArray)
                onClick();
            }

            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            addToast(t('error'), t('networkError'), "error");
        }
    }, [client, login.Authorization, _buildingGuid, variantID, t, addToast])

    const strandRefHandler = useCallback((e) => {
        const { id, value } = e.target;
        setStrandRef(prev => ({
            ...prev,
            [id]: value
        }))
    }, [strandRef])

    const onSubmit = useCallback(async(e) => {
        e.preventDefault();

        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/variants/${variantID}/strandreference/${strandRef?.id ? strandRef?.id : ''}`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: strandRef?.id ? "PATCH" : "POST",
        //     body: JSON.stringify(strandRef)
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t('networkError'));
        //     return response.json()
        // })
        // .then(_ => {
        //     fetchSR();
        //     addToast(t("success"), t(strandRef?.id ? "strandReferenceEdited" : "strandReferenceAdded"), "success");
        // })
        // .catch(error => addToast(t("error"), error.message, "error"))
        let sR = {...strandRef}

        let attributesObj = {};
        strandRef.meta.forEach((item, i) => {
            attributesObj[item.key] = JSON.stringify(JSON.parse(item.value));
        });

        sR.meta = attributesObj

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(sR);
                return req;
            };

            const response = sR?.id ? await client.apis["building"].building_variants_strandreference_partial_update({building_uuid: _buildingGuid, variant_uuid: variantID, strandreference_uuid: strandRef?.id}) : await client.apis["building"].building_variants_strandreference_create({building_uuid: _buildingGuid, variant_uuid: variantID});

            if (response.status >= 200 && response.status < 300) {
                fetchSR();
                addToast(t("success"), t(strandRef?.id ? "strandReferenceEdited" : "strandReferenceAdded"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t('error'), t('networkError'), "error");
        }
    }, [client, login.Authorization, _buildingGuid, variantID, t, addToast, strandRef])

    const onDelete = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/variants/${variantID}/strandreference/${strandRef?.id}`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "DELETE",
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t('networkError'));
        //     return {}
        // })
        // .then(_ => {
        //     fetchSR();
        //     addToast(t("success"), t("strandReferenceDeleted"), "success");
        // })
        // .catch(error => addToast(t("error"), error.message, "error"))

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(strandRef);
                return req;
            };

            const response = await client.apis["building"].building_variants_strandreference_destroy({building_uuid: _buildingGuid, variant_uuid: variantID, strandreference_uuid: strandRef?.id});

            if (response.status >= 200 && response.status < 300) {
                fetchSR();
                addToast(t("success"), t("strandReferenceDeleted"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t('error'), t('networkError'), "error");
        }
    }, [client, login.Authorization, _buildingGuid, variantID, t, addToast, strandRef])

    useEffect(() => {
        fetchSR();
    }, [])

    useEffect(() => {
        console.log(strandReferences)
    }, [strandReferences])

    return <>
        <Card {...{ heading: "Strand References", mainFunctions }}>
            {(strandReferences === undefined) ? (
                <TablePlaceholder {...{ structure: tableStructure }} />
            ) : (!Boolean(strandReferences?.length)) ? (
                <p className="m-0">{t("noStrandReferences")}</p>
            ) : (
                <Table responsive>
                    <thead>
                        <tr>
                            {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                                <span>{ts.col}</span>
                            </th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {strandReferences?.map((sR) => <SRRow key={`strandref-key-${sR.id}`} {...{ sR, onClick: (show, ref) => onClick(show, ref) }} />)}
                    </tbody>
                </Table>
            )}
        </Card> 
        <ContentModal {...{ show: show === "add/edit", onHide: () => onClick(false, undefined), title: t(strandRef?.id ? "editStrandReference" : "addStrandReference"), content: <AddEditStrandReference {...{ strandRef, strandRefHandler, onSubmit, setStrandRef, strandReferences, setStrandReferences}} />, topMost: true }} />
        <SecurityActionModal {...{ show: show === "delete", onHide: () => onClick(false, undefined), title: t("deleteStrandReference"), question: t("deleteStrandReferenceQuestion"), action: () => onDelete() }} />
    </>
}

const SRRow = ({ sR, onClick }) => {
    const { t } = useTranslation();

    return <tr>
        <td>
            <span>{sR?.name}</span>
        </td>
        <td>
            <span>{sR?.date}</span>
        </td>
        <td>
            <span>{sR?.max_distance}</span>
        </td>
        <td>
            {sR?.meta.map((item, index) => (
                <span key={`meta-${index}`}>
                    {item.key}: {item.value}
                    {index !== sR.meta.length - 1 && ", "}
                </span>
            ))}
        </td>
        <td>
            <span>{sR?.target_pressure_mbar}</span>
        </td>

        <td>
            <div className='actions__buttonbar grid__two'>
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={
                        <Tooltip>{t("edit")}</Tooltip>
                    }>
                    <Button variant="outline-primary" className="p-10" onClick={() => onClick("add/edit", sR)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                </OverlayTrigger>

                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={
                        <Tooltip>{t("delete")}</Tooltip>
                    }>
                    <Button variant="outline-primary" className="p-10" onClick={() => onClick("delete", sR)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </OverlayTrigger>
            </div>
        </td>
    </tr>
};

const AddEditStrandReference = ({ strandRef, strandRefHandler, onSubmit, setStrandRef }) => {
    const { t } = useTranslation();
    
    console.log("STRAND REF", strandRef)
    // console.log("strandRef?.meta?", strandRef.meta)

    const handleInputChange = useCallback((e, rowIndex, colIndex) => {
        const updatedArray = [...strandRef?.meta];
        updatedArray[rowIndex][colIndex] = e.target.value;
        setStrandRef(prev => ({ ...prev, meta: updatedArray }));
    }, [strandRef])

    const deleteRow = useCallback((rowID) => {
        let tmpData = [...strandRef?.meta]
        tmpData.splice(rowID, 1);
        setStrandRef(prev => ({ ...prev, meta: tmpData }));
    }, [strandRef])

    const addRow = useCallback(() => {
        let tmpData = [];
        if (Array.isArray(strandRef?.meta)) {
            tmpData = [...strandRef.meta];
        }
        tmpData.push({ key: "", value: "" });
        setStrandRef(prev => ({ ...prev, meta: tmpData }));
    }, [strandRef]);

    return <Form onSubmit={(e) => onSubmit(e)}>
        <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control type="name" value={strandRef?.name || ""} onChange={(e) => strandRefHandler(e)} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="max_distance">
            <Form.Label>{t("maxDistance")}</Form.Label>
            <Form.Control type="number" value={strandRef?.max_distance || ""} onChange={(e) => strandRefHandler(e)} />
        </Form.Group>

        {/* <Form.Group className="mb-3" controlId="meta">
            <Form.Label>Meta</Form.Label>
            <Form.Control type="text" value={JSON.stringify(strandRef?.meta) || ""} onChange={(e) => strandRefHandler(e)} />
        </Form.Group> */}

        <Form.Group className="mb-3" controlId="meta">
            <Form.Label>{t("Meta")}</Form.Label>
            <Table responsive className="mb-3">
                <thead>
                    <tr>
                        <th>{t("key")}</th>
                        <th>{t("value")}</th>
                        <th><FontAwesomeIcon icon={faTrash} size='sm' /></th>
                    </tr>
                </thead>
                <tbody>
                    {strandRef?.meta?.map((row, rowIndex) => <tr key={`selected-row-${rowIndex}`}>
                        <td>
                            <input className="form-control" type="text" value={row.key} onChange={(e) => handleInputChange(e, rowIndex, "key")} />
                        </td>
                        <td>
                            <input className="form-control" type="text" value={row.value} onChange={(e) => handleInputChange(e, rowIndex, "value")} />
                        </td>
                        <td>
                            <Button variant="outline-primary" className="p-10" style={{ width: "38px" }} onClick={() => deleteRow(rowIndex)}>
                                <FontAwesomeIcon icon={faTrash} size='sm' />
                            </Button>
                        </td>
                    </tr>)}
                </tbody>
            </Table>
            <Button className="w-100 btn-icon" variant="outline-primary" onClick={() => addRow()}><FontAwesomeIcon icon={faAdd} size='sm' /></Button>
        </Form.Group>


        <Form.Group className="mb-3" controlId="target_pressure_mbar">
            <Form.Label>{t("targetPressureMBAR")}</Form.Label>
            <Form.Control type="number" value={strandRef?.target_pressure_mbar || ""} onChange={(e) => strandRefHandler(e)} />
        </Form.Group>

        <Button variant="outline-primary" className="w-100" type="submit">{t('submit')}</Button>
    </Form>
}

export default StrandReference;