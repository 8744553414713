import { useCallback, useEffect, useState } from 'react'
import { useSwagger } from '../context/SwaggerContext';
import { useAuth } from '../Context';
import { useParams } from 'react-router-dom';

const useBuildingHook = () => {
    const { login, doSetLogin } = useAuth();
    const client = useSwagger();
    const _buildingGuid = useParams()['buildingGuid'];

    const [currentBuilding, setCurrentBuilding] = useState(false)

    const getBuildingData = useCallback(async() => {
        if (!login || !client || !_buildingGuid) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_retrieve({ building_uuid: _buildingGuid });

            if (response.status >= 200 && response.status < 300) {
                let tmpLogin = { ...login };
                tmpLogin.currentBuilding = {id: response.obj.id, name: response.obj.name};
                doSetLogin(tmpLogin);
                setCurrentBuilding(true)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [_buildingGuid, client, login])

    useEffect(() => {
        if (currentBuilding) return
        let tmpLogin = { ...login };

        if (!_buildingGuid && tmpLogin.currentBuilding) {
            delete tmpLogin.currentBuilding;
            doSetLogin(tmpLogin);
            setCurrentBuilding(true)
            return
        }

        if(!login?.currentBuilding || login?.currentBuilding?.id !== _buildingGuid) {
            getBuildingData()
        }
    }, [_buildingGuid, getBuildingData, login?.currentBuilding, currentBuilding])
}

export default useBuildingHook