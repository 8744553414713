import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";

export const RoofDormer = ({ width, height, ClosedSidesNo, onWidthChange, onHeightChange, onClosedSidesNoChange }) => {
    const { t } = useTranslation();

    return <Row>
        <Col className="mb-3">
            <Form.Group className="d-flex flex-column justify-content-end h-100" controlId="width">
                <Form.Label>{t("width")}</Form.Label>
                <Form.Control type="number" value={width} onChange={(e) => onWidthChange(e.target.value)} />
            </Form.Group>
        </Col>
        <Col className="mb-3">
            <Form.Group className="d-flex flex-column justify-content-end h-100" controlId="height">
                <Form.Label>{t("height")}</Form.Label>
                <Form.Control type="number" value={height} onChange={(e) => onHeightChange(e.target.value)} />
            </Form.Group>
        </Col>
        <Col className="mb-3">
            <Form.Group className="d-flex flex-column justify-content-end h-100" controlId="ClosedSidesNo">
                <Form.Label>{t("completedPagesNo")}</Form.Label>
                <Form.Control type="number" value={ClosedSidesNo} onChange={(e) => onClosedSidesNoChange(e.target.value)} />
            </Form.Group>
        </Col>
    </Row>
}

export default RoofDormer;