import React, { useEffect, useRef, useState } from 'react';
// import { Document, Page, pdfjs } from "react-pdf";
import { Document as PDFViewerDocument, Page as PDFViewerPage, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const Preview = ({previewFile}) => {
    const { t } = useTranslation();
    const [numPages, setNumPages] = useState(null);
    // const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        // setNumPages(numPages);

        const totalPages = Number(numPages); // String in Zahl umwandeln
        // Erstelle ein Array von 1 bis totalPages
        const pagesArray = Array.from({ length: totalPages }, (_, index) => index + 1);
        setNumPages(pagesArray);

    }

    const myImage = (previewFile && previewFile?.type?.startsWith("image/")) && URL.createObjectURL(previewFile);

    // const fileUrl  = (previewFile && previewFile?.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && URL.createObjectURL(previewFile);

    return (
        <>
            {(previewFile === undefined) ? (
                <div style={{ width: "100%", height: "500px" }}>
                    <Placeholder as="div" animation="glow" style={{ width: "100%", height: "100%" }}>
                        <Placeholder xs={12} style={{ width: "100%", height: "100%" }} />
                    </Placeholder>
                </div>
            ) : ((previewFile === false) ? ( 
                    <p>{t('noDocuments')}</p>
                ) :(
                    <div>
                        {myImage ? (
                            <img alt="" src={myImage} className='image_pdf_viewer' />
                        ) : (
                            <>
                                {/* <PDFViewerDocument file={previewFile} onLoadSuccess={onDocumentLoadSuccess}>
                                    <PDFViewerPage pageNumber={pageNumber} style={{width: "fill"}} renderTextLayer={false} />
                                </PDFViewerDocument> */}
                                
                                <PDFViewerDocument file={previewFile} onLoadSuccess={onDocumentLoadSuccess}>
                                        {numPages?.map(page => (
                                            <div style={{ position: 'relative' }}>
                                                <PDFViewerPage pageNumber={page} renderTextLayer={false} />
                                                <p className='page_numbers'>
                                                    Page {page} of {numPages.length}
                                                </p>
                                            </div>
                                        ))}
                                </PDFViewerDocument>

                                {/* <div>
                                    <p>
                                        Page {pageNumber} of {numPages}
                                    </p>
                                </div>
                                <div className='btn_container_pdf_viewer' >
                                    <button disabled={pageNumber <= 1} onClick={() => setPageNumber(pageNumber - 1)}>
                                        Previous
                                    </button>

                                    <button disabled={pageNumber >= numPages} onClick={() => setPageNumber(pageNumber + 1)}>
                                        Next
                                    </button>
                                </div> */}
                            </>
                            ) 
                        }
                    </div> 
                )
            )}
        </>   
    );
}

export default Preview;