import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useAuth } from "../Context";
import { useToast } from '../NotificationsContent';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import { faAirFreshener, faElevator, faFire, faHandsWash, faKitchenSet, faOtter, faPowerOff, faTrash, faTreeCity, faWater, faWifi } from '@fortawesome/free-solid-svg-icons';
import { useSwagger } from "../context/SwaggerContext";

export const din276_classes = {
    '100': 'Grundstück',
    '110': 'Grundstückswert',
    '120': 'Grundstücksnebenkosten',
    '121': 'Vermessungsgebühren',
    '122': 'Gerichtsgebühren',
    '123': 'Notariatsgebühren',
    '124': 'Grunderwerbsteuer',
    '125': 'Untersuchungen',
    '126': 'Wertermittlungen',
    '127': 'Genehmigungsgebühren',
    '128': 'Bodenordnung, Grenzregulierung',
    '129': 'Sonstiges zur KG 120',
    '130': 'Rechte Dritter',
    '131': 'Abfindungen',
    '132': 'Ablösen dinglicher Rechte',
    '139': 'Sonstiges zur KG 130',
    '200': 'Vorbereitende Maßnahmen',
    '210': 'Herrichten',
    '211': 'Sicherungsmaßnahmen',
    '212': 'Abbruchmaßnahmen',
    '213': 'Altlastenbeseitigung Bauwerk',
    '214': 'Herrichten der Geländeoberfläche',
    '215': 'Kampfmittelräumung',
    '216': 'Kulturhistorische Funde',
    '219': 'Herrichten, sonstiges',
    '220': 'Öffentliche Erschließung',
    '221': 'Abwasserentsorgung',
    '222': 'Wasserversorgung',
    '223': 'Gasversorgung',
    '224': 'Fernwärmeversorgung',
    '225': 'Stromversorgung',
    '226': 'Telekommunikation',
    '227': 'Verkehrserschließung',
    '228': 'Abfallentsorgung',
    '229': 'Sonstiges zur KG 220',
    '230': 'Nichtöffentliche Erschließung',
    '240': 'Ausgleichsmaßnahmen und -abgaben',
    '241': 'Ausgleichsmaßnahmen',
    '242': 'Ausgleichsabgaben',
    '243': 'Sonstiges zur KG 240',
    '250': 'Übergangsmaßnahmen',
    '251': 'Bauliche Maßnahmen',
    '252': 'Organisatorische Maßnahmen',
    '259': 'Sonstiges zur KG 250',
    '300': 'Bauwerk, Baukonstruktionen',
    '310': 'Baugrube',
    '311': 'Herstellung',
    '312': 'Umschließung',
    '313': 'Wasserhaltung',
    '314': 'Vortrieb',
    '319': 'Sonstiges zur KG 310',
    '320': 'Gründung, Unterbau',
    '321': 'Baugrundverbesserung',
    '322': 'Flachgründungen und Bodenplatten',
    '323': 'Tiefgründungen',
    '324': 'Gründungsbeläge',
    '325': 'Abdichtungen und Bekleidungen',
    '326': 'Drainagen',
    '329': 'Sonstiges zur KG 320',
    '330': 'Außenwände/Vertikale Baukonstruktionen, außen',
    '331': 'Tragende Außenwände',
    '332': 'Nichttragende Außenwände',
    '333': 'Außenstützen',
    '334': 'Außenwandöffnungen',
    '335': 'Außenwandbekleidungen außen',
    '336': 'Außenwandbekleidungen innen',
    '337': 'Elementierte Außenwandkonstruktionen',
    '338': 'Lichtschutz zur KG 330',
    '339': 'Sonstiges zur KG 330',
    '340': 'Innenwände/Vertikale Baukonstruktionen, innen',
    '341': 'Tragende Innenwände',
    '342': 'Nichttragende Innenwände',
    '343': 'Innenstützen',
    '344': 'Innenwandöffnungen',
    '345': 'Innenwandbekleidungen',
    '346': 'Elementierte Innenwandkonstruktionen',
    '347': 'Lichtschutz zur KG 340',
    '349': 'Sonstiges zur KG 340',
    '350': 'Decken/Horizontale Baukonstruktionen',
    '351': 'Deckenkonstruktionen',
    '352': 'Deckenöffnungen',
    '353': 'Deckenbeläge',
    '354': 'Deckenbekleidungen',
    '355': 'Elementierte Deckenkonstruktionen',
    '359': 'Sonstiges zur KG 350',
    '360': 'Dächer',
    '361': 'Dachkonstruktionen',
    '362': 'Dachöffnungen',
    '363': 'Dachbeläge',
    '364': 'Dachbekleidungen',
    '365': 'Elementierte Dachkonstruktionen',
    '366': 'Lichtschutz zur KG 360',
    '369': 'Sonstiges zur KG 360',
    '370': 'Infrastrukturanlagen',
    '371': 'Anlagen für den Straßenverkehr',
    '372': 'Anlagen für den Schienenverkehr',
    '373': 'Anlagen für den Flugverkehr',
    '374': 'Anlagen des Wasserbaus',
    '375': 'Anlagen der Abwasserentsorgung',
    '376': 'Anlagen der Wasserversorgung',
    '377': 'Anlagen der Energie- und Informationsversorgung',
    '378': 'Anlagen der Abfallentsorgung',
    '379': 'Sonstiges zur KG 370',
    '380': 'Baukonstruktive Einbauten',
    '381': 'Allgemeine Einbauten',
    '382': 'Besondere Einbauten',
    '383': 'Landschaftsgestalterische Einbauten',
    '384': 'Mechanische Einbauten',
    '385': 'Einbauten in Konstruktionen des Ingenieurbaus',
    '386': 'Orientierungs- und Informationssysteme',
    '387': 'Schutzeinbauten',
    '389': 'Sonstiges zur KG 380',
    '390': 'Sonstige Maßnahmen für Baukonstruktionen',
    '391': 'Baustelleneinrichtung',
    '392': 'Gerüste',
    '393': 'Sicherungsmaßnahmen',
    '394': 'Abbruchmaßnahmen',
    '395': 'Instandsetzung',
    '396': 'Materialentsorgung',
    '397': 'Zusätzliche Maßnahmen, Schutzmaßnahmen',
    '398': 'Provisorische Baukonstruktion',
    '399': 'Sonstiges zur KG 390',
    '400': 'Bauwerk - Technische Anlagen',
    '410': 'Abwasser-, Wasser-, Gasanlagen',
    '411': 'Abwasseranlagen',
    '412': 'Wasseranlagen',
    '413': 'Gasanlagen',
    '419': 'Sonstiges zur KG 410',
    '420': 'Wärmeversorgungsanlagen',
    '421': 'Wärmerzeugungsanlagen',
    '422': 'Wärmeverteilnetze',
    '423': 'Raumheizflächen',
    '424': 'Verkehrsheizflächen',
    '429': 'Sonstiges zur KG 420',
    '430': 'Raumlufttechnische Anlagen',
    '431': 'Lüftungsanlagen',
    '432': 'Teilklimaanlagen',
    '433': 'Klimaanlagen',
    '434': 'Kälteanlagen',
    '439': 'Sonstiges zur KG 430',
    '440': 'Elektrische Anlagen',
    '441': 'Hoch- und Mittelspannungsanlagen',
    '442': 'Eigenstromversorgungsanlagen',
    '443': 'Niederspannungsschaltanlagen',
    '444': 'Niederspannungsinstallationsanlagen',
    '445': 'Beleuchtungsanlagen',
    '446': 'Blitzschutz- und Erdungsanlagen',
    '447': 'Fahrleitungssysteme',
    '449': 'Starkstromanlagen, sonstiges',
    '450': 'Fernmelde- u. Informationstechn. Anlagen',
    '451': 'Telekommunikationsanlagen',
    '452': 'Such- und Signalanlagen',
    '453': 'Zeitdienstanlagen',
    '454': 'Elektroakustische Anlagen',
    '455': 'Audiovisuelle Medien- und Antennenanlagen',
    '456': 'Gefahrenmelde- und Alarmanlagen',
    '457': 'Datenübertragungsnetze',
    '458': 'Verkehrsbeeinflussungsanlagen',
    '459': 'Sonstiges zur KG 450',
    '460': 'Förderanlagen',
    '461': 'Aufzugsanlagen',
    '462': 'Fahrtreppen, Fahrsteige',
    '463': 'Befahranlagen',
    '464': 'Transportanlagen',
    '465': 'Krananlagen',
    '466': 'Hydraulikanlagen',
    '469': 'Sonstiges zur KG 460',
    '470': 'Nutzungsspezifische Anlagen',
    '471': 'Küchentechnische Anlagen',
    '472': 'Wäscherei- und Reinigungsanlagen',
    '473': 'Medienversorgungsanlagen',
    '474': 'Feuerlöschanlagen',
    '475': 'Prozesswärme-, kälte- und -luftanlagen',
    '476': 'Weitere nutzungsspezifische Anlagen',
    '477': 'Verfahrenstechnische Anlagen, Wasser, Abwasser und Gase',
    '478': 'Verfahrenstechnische Anlagen, Feststoffe, Wertstoffe und Abfälle',
    '479': 'Sonstiges zur KG 470',
    '480': 'Gebäude- und Anlagenautomation',
    '481': 'Automationseinrichtungen',
    '482': 'Schaltschränke, Automationsschwerpunkte',
    '483': 'Automationsmanagement',
    '484': 'Kabel, Leitungen und Verlegesysteme',
    '485': 'Datenübertragungsnetze',
    '489': 'Sonstiges zur KG 480',
    '490': 'Sonstige Maßnahmen f. technische Anlagen',
    '491': 'Baustelleneinrichtung',
    '492': 'Gerüste',
    '493': 'Sicherungsmaßnahmen',
    '494': 'Abbruchmaßnahmen',
    '495': 'Instandsetzung',
    '496': 'Materialentsorgung',
    '497': 'Zusätzliche Maßnahmen',
    '498': 'Provisorische Technische Anlagen',
    '499': 'Sonstiges zur KG 490',
    '500': 'Außenanlagen und Freiflächen',
    '510': 'Erdbau',
    '511': 'Herstellung',
    '512': 'Umschließung',
    '513': 'Wasserhaltung',
    '514': 'Vortrieb',
    '519': 'Sonstiges zur KG 510',
    '520': 'Gründung, Unterbau',
    '521': 'Baugrundverbesserung',
    '522': 'Gründungen und Bodenplatten',
    '523': 'Gründungsbeläge',
    '524': 'Abdichtungen und Bekleidungen',
    '525': 'Dränagen',
    '529': 'Sonstiges zur KG 520',
    '530': 'Oberbau, Deckschichten',
    '531': 'Wege',
    '532': 'Straßen',
    '533': 'Plätze, Höfe, Terrassen',
    '534': 'Stellplätze',
    '535': 'Sportplatzflächen',
    '536': 'Spielplatzflächen',
    '537': 'Gleisanlagen',
    '538': 'Flugplatzflächen',
    '539': 'Sonstiges zur KG 530',
    '540': 'Baukonstruktionen',
    '541': 'Einfriedungen',
    '542': 'Schutzkonstruktionen',
    '543': 'Wandkonstruktionen',
    '544': 'Rampen, Treppen, Tribünen',
    '545': 'Überdachungen',
    '546': 'Stege',
    '547': 'Kanal- und Schachtkonstruktionen',
    '548': 'Wasserbecken',
    '549': 'Sonstiges zur KG 540',
    '550': 'Technische Anlagen',
    '551': 'Abwasseranlagen',
    '552': 'Wasseranlagen',
    '553': 'Anlagen für Gase und Flüssigkeiten',
    '554': 'Wärmeversorgungsanlagen',
    '555': 'Raumlufttechnische Anlagen',
    '556': 'Elektrische Anlagen',
    '557': 'Kommunikations-, sicherheits- und informationstechnische Anlagen, Automation',
    '558': 'Nutzungsspezifische Anlagen',
    '559': 'Sonstiges zur KG 550',
    '560': 'Einbauten in Außenanlagen und Freiflächen',
    '561': 'Allgemeine Einbauten',
    '562': 'Besondere Einbauten',
    '563': 'Orientierungs- und Informationssysteme',
    '569': 'Sonstiges zur KG 560',
    '570': 'Vegetationsflächen',
    '571': 'Vegetationstechnische Bodenbearbeitung',
    '572': 'Sicherungsbauweisen',
    '573': 'Pflanzflächen',
    '574': 'Rasen- und Saatflächen',
    '579': 'Sonstiges zur KG 570',
    '580': 'Wasserflächen',
    '581': 'Befestigungen',
    '582': 'Abdichtungen',
    '589': 'Sonstiges zur KG 580',
    '590': 'Sonstige Maßnahmen für Außenanlagen',
    '591': 'Baustelleneinrichtung',
    '592': 'Gerüste',
    '593': 'Sicherungsmaßnahmen',
    '594': 'Abbruchmaßnahmen',
    '595': 'Instandsetzung',
    '596': 'Materialentsorgung',
    '597': 'Zusätzlichen Maßnahmen',
    '598': 'Provisorische Aussenanlagen',
    '599': 'Sonstiges zur KG 590',
    '600': 'Ausstattung und Kunstwerke',
    '610': 'Allgemeine Ausstattung',
    '620': 'Besondere Ausstattung',
    '630': 'Informationstechnische Ausstattung',
    '640': 'Künstlerische Ausstattung',
    '641': 'Kunstobjekte',
    '642': 'Künstlerische Gestaltung des Bauwerks',
    '643': 'Künstlerische Gestaltung der Außenanlagen und Freiflächen',
    '649': 'Sonstiges zur KG 640',
    '690': 'Sonstige Ausstattung',
    '700': 'Baunebenkosten',
    '710': 'Bauherrenaufgaben',
    '711': 'Projektleitung',
    '712': 'Bedarfsplanung',
    '713': 'Projektsteuerung',
    '714': 'Sicherheits- und Gesundheitsschutzkoordination',
    '715': 'Vergabeverfahren',
    '719': 'Sonstiges zur KG 710',
    '720': 'Vorbereitung der Objektplanung',
    '721': 'Untersuchungen',
    '722': 'Wertermittlungen',
    '723': 'Städtebauliche Leistungen',
    '724': 'Landschaftsplanerische Leistungen',
    '725': 'Wettbewerbe',
    '729': 'Sonstiges zur KG 720',
    '730': 'Sonstiges zur KG 730',
    '731': 'Gebäude und Innenräume',
    '732': 'Freianlagenplanung',
    '733': 'Planung der raumbildende Ausbauten',
    '734': 'Planung der Ingenieurbauwerke und Verkehrsanlagen',
    '740': 'Fachplanung',
    '741': 'Tragwerksplanung',
    '742': 'Technische Ausrüstung',
    '743': 'Bauphysik',
    '744': 'Geotechnik',
    '745': 'Ingenieurvermessung',
    '746': 'Lichttechnik, Tageslichttechnik',
    '747': 'Brandschutz',
    '748': 'Altlasten, Kampfmittel, kulturhistorische Funde',
    '749': 'Sonstiges zur KG 740',
    '750': 'Künstlerische Leistungen',
    '751': 'Kunstwettbewerbe',
    '752': 'Honorare',
    '759': 'Sonstiges zur KG 750',
    '760': 'Allgemeine Baunebenkosten',
    '761': 'Gutachten und Beratung',
    '762': 'Prüfungen, Genehmigungen, Abnahmen',
    '763': 'Bewirtschaftungskosten',
    '764': 'Bemusterungskosten',
    '765': 'Betriebskosten nach der Abnahme',
    '766': 'Versicherungen',
    '769': 'Sonstiges zur KG 760',
    '790': 'Sonstige Baunebenkosten',
    '791': 'Bestandsdokumentation',
    '799': 'Sonstiges zur KG 790',
    '800': 'Finanzierung',
    '810': 'Finanzierungsnebenkosten',
    '820': 'Fremdkapitalzinsen',
    '830': 'Eigenkapitalzinsen',
    '840': 'Bürgschaften',
    '890': 'Sonstige Finanzierungskosten'
};

export const DIN276_main = () => {
    const { t } = useTranslation();

    const theta = useMemo(() => {
        return {
            420: { short: t('din276Main.short.heat'), long: t('din276Main.long.heat'), icon: faFire },
            440: { short: t('din276Main.short.power'), long: t('din276Main.long.power'), icon: faPowerOff },
            430: { short: t('din276Main.short.ventilation'), long: t('din276Main.long.ventilation'), icon: faAirFreshener },
            410: { short: t('din276Main.short.water'), long: t('din276Main.long.water'), icon: faWater },
            461: { short: t('din276Main.short.elevator'), long: t('din276Main.long.elevator'), icon: faElevator },
            471: { short: t('din276Main.short.kitchen'), long: t('din276Main.long.kitchen'), icon: faKitchenSet },
            472: { short: t('din276Main.short.laundry'), long: t('din276Main.long.laundry'), icon: faHandsWash },
            474: { short: t('din276Main.short.fireSuppression'), long: t('din276Main.long.fireSuppression'), icon: faWater },
            480: { short: t('din276Main.short.automation'), long: t('din276Main.long.automation'), icon: faWifi },
            378: { short: t('din276Main.short.waste'), long: t('din276Main.long.waste'), icon: faTrash },
            500: { short: t('din276Main.short.outdoorArea'), long: t('din276Main.long.outdoorArea'), icon: faTreeCity },
            0:   { short: t('din276Main.short.other'), long: t('din276Main.long.other'), icon: faOtter }
        }
    }, [t])

    return theta
}

export const AddEditPlant = ({ onClose, ...props }) => {
    const { login, reload, setReload } = useAuth();
    const { addToast } = useToast();
    const _buildingGuid = useParams()['buildingGuid'];
    const { t } = useTranslation();
    const [plant, setPlant] = useState(props.plant || { din276_type: 0 });
    const client = useSwagger();

    const onSubmit = useCallback(async(e) => {
        e.preventDefault();
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/plant/${plant.id === undefined ? '' : `${plant.id}/`}`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         'Content-Type': 'application/json'
        //     },
        //     method: (plant.id !== undefined) ? 'PATCH' : 'POST',
        //     body: JSON.stringify(plant)
        // }).then(response => {
        //     if (!response.ok) throw new Error(t('networkError'));
        //     setReload(reload + 1);
        //     addToast(t('plant'), plant.id ? t('plantPatch') : t('plantPost'), "success");
        //     onClose();
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(plant);
                return req;
            };

            const response = plant.id === undefined ? await client.apis["building"].building_plant_create({building_uuid: _buildingGuid}) : await client.apis["building"].building_plant_partial_update({building_uuid: _buildingGuid, plant_uuid: plant.id});

            if (response.status >= 200 && response.status < 300) {
                setReload(reload + 1);
                addToast( t("plant"), plant.id ? t("plantPatch") : t("plantPost"), "success");
                onClose();
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, t, addToast, plant])

    useEffect(() => {
        setPlant(props?.plant === undefined ? { din276_type: 0 } : props.plant);
    }, [props.plant])

    return <Form onSubmit={(e) => onSubmit(e)}>
        <Form.Group id='name' className='mb-3'>
            <Form.Label>{t('name')}</Form.Label>
            <Form.Control id='name' required type='text' value={plant?.name || ''} onChange={(e) => { let a = { ...plant }; a[e.target.id] = e.target.value; setPlant({ ...a }); }} />
        </Form.Group>
        <Form.Group id='din-type' className='mb-3'>
            <Form.Label>{t('type')}</Form.Label>
            <Form.Select value={plant?.din276_type} id='din276_type' onChange={(e) => { let a = { ...plant }; a[e.target.id] = e.target.value; setPlant({ ...a }); }}>
                {Object.keys(DIN276_main()).sort((a, b) => (a > b) ? -1 : 1).map(e => {
                    return (e === 420 || e === 480) ? <React.Fragment key={`nooption-key-${Math.random()}`}></React.Fragment> : <option key={`option-key-${e}`} value={e}>{DIN276_main()[e].long}</option>
                })}
            </Form.Select>
        </Form.Group>
        <Form.Group id='din-type' className='mb-3'>
            <Form.Label>{t('number')}</Form.Label>
            <Form.Control id='no' type='number' value={plant?.no || ''} onChange={(e) => { let a = { ...plant }; a[e.target.id] = e.target.value; setPlant({ ...a }); }} />
        </Form.Group>
        <Button variant="outline-primary" className='w-100' type='submit'>{(plant?.id === undefined) ? t('add') : t('edit')}</Button>
    </Form>
}