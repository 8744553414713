import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../Context";
import { Button, OverlayTrigger, Placeholder, Table, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faList, faCalculator, faTriangleExclamation, faDownload, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import Card from "../components/Card";
import ContentModal from "../components/ContentModal";
import SecurityActionModal from "../components/SecurityActionModal";
import AddEditConfigurationsLog from "./AddEditConfigurationsLog";
import HeatpartConfigurationsLog from "./HeatpartConfigurationsLog";
import { useToast } from "../NotificationsContent";
import { useSwagger } from "../context/SwaggerContext";
import { Calculate } from "./CalculateDIN12831";
import Bubblechart from "../charts/Bubblechart";
import useBuildingHook from "../hooks/useBuildingHook";
import { usePermissions } from "../context/PermissionsContext";

export default function BuildingHeatparts() {
    const _buildingGuid = useParams()['buildingGuid'];
    const { login } = useAuth();
    const client = useSwagger();
    const { addToast } = useToast();
    const { t } = useTranslation()
    const [heatparts, setHeatparts] = useState(undefined)
    useBuildingHook()

    async function loadRoomHP(room) {
        // try {
        //     let response = await fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/room/${room.id}/heatpart`, {
        //         headers: {
        //             'Authorization': login.Authorization,
        //             "Content-Type": "application/json"
        //         }
        //     });
        //     if (!response.ok) {
        //         throw new Error(`HTTP error! status: ${response.status}`);
        //     }
        //     let data = await response.json();
        //     return data;
        // } catch (error) {
        //     return [];
        // }

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].getHeatpartFromRoom({ building_uuid: _buildingGuid, room_uuid: room.id });

            if (response.status >= 200 && response.status < 300) {
                // console.log("response", response)
                // let data = await response.json();
                // return data;
                return response.body;
            }
        } catch (error) {
            console.error('Error:', error);
            return [];
        } finally {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }

    const loadHeatparts = useCallback(async () => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/room/`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     }
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t('error'));
        //     if (response.status === 204) return [];
        //     return response.json();
        // })
        // .then(async(data) => {
        //     let promises = data.map(async(room) => ({ id: room.id, room: room.name, heatparts: await loadRoomHP(room) }))
        //     let heatparts = await Promise.all(promises);
        //     setHeatparts(heatparts);
        // })

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].getHeatpartSummary({ building_uuid: _buildingGuid });

            if (response.status === 204) {
                setHeatparts([]);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                // console.log("RESPOSNE: ", response.obj)
                // let promises = response.obj?.map(async (room) => {
                //     const heatparts = await loadRoomHP(room);
                //     console.log(`Heatparts for room ${room}:`, room);
                //     return {
                //         id: room.id,
                //         room: room.name,
                //         room_temperature: room.room_temperature,
                //         heatparts: heatparts
                //     };
                // });

                // let heatparts = await Promise.all(promises);
                // console.log(response.obj)

                const sortedRoomsWithoutUnit = response?.obj?.rooms_without_unit?.sort((a, b) => a?.floor?.level - b?.floor?.level);
                const sortedUnits = response?.obj?.units?.sort((a, b) => {
                    const minFloorLevelA = Math.min(...a?.unit_rooms.map(room => room?.floor?.level));
                    const minFloorLevelB = Math.min(...b?.unit_rooms.map(room => room?.floor?.level));
                    return minFloorLevelA - minFloorLevelB;
                });

                setHeatparts({rooms_without_unit: sortedRoomsWithoutUnit, units: sortedUnits});
            }
        } catch (error) {
            console.error('Error:', error);
            addToast(t('error'), t('responseError'), "error");
        } finally {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization, t])

    useEffect(() => {
        loadHeatparts();
    }, [loadHeatparts]);

    return <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">
            <HeatpartsTable {...{ heatparts, loadHeatparts }} />
        </div>
    </MainLayout>
}

const HeatpartsTable = ({ heatparts, loadHeatparts }) => {
    const _buildingGuid = useParams()['buildingGuid'];
    const { addToast } = useToast();
    const { login } = useAuth();
    const [show, setShow] = useState(undefined);
    const { t } = useTranslation();
    const [addLOG, setAddLOG] = useState(undefined);
    const client = useSwagger();
    const [variant, setVariant] = useState(undefined)
    const { allowEdit } = usePermissions();

    const tableStructure = [
        {
            col: t('unit'),
            type: 'label'
        }, {
            col: t('room'),
            type: 'label'
        },{
            col: t('radiator'),
            type: 'label'
        }, {
            col: t('normHeatpower'),
            type: 'label'
        }, {
            col: t('massFlow'),
            type: 'label'
        }, {
            col: t('returnTemperature'),
            type: 'label'
        }, {
            col: t('differentialPressure'),
            type: 'label'
        }, {
            col: t('kvValue'),
            type: 'label'
        }, {
            col: t('settingValue'),
            type: 'label'
        }, {
            col: t('possiblePower'),
            type: 'label'
        }, {
            col: t('roomTemperature'),
            type: 'label'
        }, {
            col: t('achievableRoomTemperature'),
            type: 'label'
        }, {
            col: t('actions'),
            type: 'buttons'
        }
    ]

    const getActualVariant = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].getVariants({
                building_uuid: _buildingGuid,
            });

            if (response.status === 204) {
                setVariant(undefined);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                const result = response.obj.filter((variant) => variant.actual);
                setVariant(result);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization])

    const deleteHP = useCallback(async (deleteHeatpart) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_room_heatpart_destroy({ building_uuid: _buildingGuid, room_uuid: deleteHeatpart?.roomID, room_heatpart_uuid: deleteHeatpart?.hpID });

            if (response.status >= 200 && response.status < 300) {
                loadHeatparts();
                setAddLOG(undefined)
                addToast(t("success"), t("hpDeletedSuccessfully"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t('error'), t("responseError"), "error");
        }
    }, [client, login.Authorization, addToast, t, loadHeatparts]);


    const downloadFile = (blob, fileName) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.style.display = 'none'; // Das Element wird nicht angezeigt
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    };

    const downloadPDF = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_heatpart_group_list({ building_uuid: _buildingGuid });

            if (response.status >= 200 && response.status < 300) {
                downloadFile(response.data, 'heatparts.pdf')
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t('error'), t("responseError"), "error");
        }
    }, [client, login.Authorization, addToast, t]);


    useEffect(() => {
        getActualVariant()
    }, [])

    const mainFunctions = [
        {
            label: t("calculate"),
            onClick: () => setShow("Calculate"),
            key: "calculate",
            icon: faCalculator,
        }, {
            label: t("pdfExport"),
            onClick: () => downloadPDF(),
            key: "pdfExport",
            icon: faDownload,
        },
    ];

    const onClose = () => {
        setShow(false);
        loadHeatparts();
    };

    let h = []

    heatparts?.units?.map(unit =>
        unit.unit_rooms?.map(room => room.heatparts?.map(hp => h.push(<HeatpartRow key={`heatpart-${hp.id}`} {...{ unit_name:unit.name, id: room.id, name: room.name, heatpart: hp, setAddLOG, variant: variant && variant[0], room_temperature: room.room_temperature }} />
        ))));
    heatparts?.rooms_without_unit?.map(room => room.heatparts?.map(hp => h.push(<HeatpartRow key={`heatpart-${hp.id}`} {...{ unit_name:undefined, id: room.id, name: room.name, heatpart: hp, setAddLOG, variant: variant && variant[0], room_temperature: room.room_temperature }} />
    )));

    return <>
            {(heatparts === undefined) ? (
                <>
                    <Card {...{ heading: t('overview'), active: true, className: "height-fit mb-3" }}>
                        <div style={{ width: "100%", height: "500px" }}>
                            <Placeholder as="div" animation="glow" style={{ width: "100%", height: "100%" }}>
                                <Placeholder xs={12} style={{ width: "100%", height: "100%" }} />
                            </Placeholder>
                        </div>
                    </Card>
                    <Card {...{ heading: `${t('heatparts')} ${t('inThe')} ${t('building')}: ${login?.currentBuilding?.name}`, ...(allowEdit && { mainFunctions }) }}>
                        <TablePlaceholder {...{ structure: tableStructure }} />
                    </Card>
                </>
            ) : (!Boolean(h.length)) ? (
                <>
                    <Card {...{ heading: t('overview'), active: true, className: "height-fit mb-3" }}>
                        <div style={{ width: "100%", height: "500px" }}>
                            <p>{t('noDataAvailable')}</p>
                        </div>
                    </Card>
                    <Card {...{ heading: `${t('heatparts')} ${t('inThe')} ${t('building')}: ${login?.currentBuilding?.name}`, active: true, className: "height-fit mb-3" }}>
                        <p className="m-0">{t('noHP')}</p>
                    </Card>
                </>
            ) : (
                <>
                    <Card {...{ heading: t('overview'), active: true, className: "height-fit mb-3" }}>
                        <Bubblechart {...{ heatparts}}/ >
                    </Card>
                    <Card {...{ heading: `${t('heatparts')} ${t('inThe')} ${t('building')}: ${login?.currentBuilding?.name}`, ...(allowEdit && { mainFunctions })}}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    {tableStructure?.map((column, i) => <td key={`heatpart-column-${i}`}>{column.col}</td>)}
                                </tr>
                            </thead>
                            <tbody>
                                {h.map(hp => hp)}

                                {/* // {heatparts?.rooms_without_unit?.map(room => room.heatparts?.map(hp => <HeatpartRow key={`heatpart-${hp.id}`} {...{ id: room.id, name: room.room, heatpart: hp, setAddLOG, variant: variant && variant[0], room_temperature: room.room_temperature }} />))} */}
                            </tbody>
                        </Table>
                    </Card>
                </>
            )}

        <ContentModal {...{ show: addLOG?.show === "add/edit", onHide: () => setAddLOG(undefined), title: t("addConfigurationsLog"), content: <AddEditConfigurationsLog {...{ roomID: addLOG?.roomID, hpID: addLOG?.hpID, heatpart: addLOG?.hp, onClose: () => setAddLOG(undefined) }} /> }} />
        <ContentModal {...{ show: addLOG?.show === "listlogs", onHide: () => setAddLOG(undefined), title: t("configurationsLog"), content: <HeatpartConfigurationsLog {...{ roomID: addLOG?.roomID, hpID: addLOG?.hpID, onClose: () => setAddLOG(undefined) }} />, size: 'xl' }} />
        <ContentModal
            {...{
                show: show === "Calculate",
                onHide: onClose,
                title: t("calculateDIN12831"),
                content: <Calculate {...{ onClose }} />,
            }}
        />
        {/* <SecurityActionModal {...{ show: addLOG?.show === "deleteLog", onHide: () => setAddLOG(undefined), title: t("deleteHeatingPart"), question: t("questionDeleteHeatingPart"), action: () => deleteHP(addLOG) }} /> */}
    </>
}

export const HeatpartRow = ({ unit_name,id, name, heatpart, setAddLOG, variant, room_temperature }) => {
    const { t } = useTranslation();
    const { allowEdit } = usePermissions();

    const parse_to_no_calculation = (value, digits = 2, unit = "", minus_1_text = "kein Ergebnis") => {
        if (value == -1 || value * 1000 == -1) {
            return minus_1_text
        }
        else {
            return Number.parseFloat(value).toFixed(digits) + " " + unit
        }
    }

    return <tr>
        <td>{unit_name || "keine Zuordnung"}</td>
        <td>{name}</td>
        <td>
            {variant && heatpart.target_return_temp > variant?.max_return_temp ? (
                <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{t('radiatorTooSmallToMaintainReturnTemperature', { maxReturnTemperature: Number(variant?.max_return_temp) })}</Tooltip>}>
                    <span>
                        {heatpart.name} <FontAwesomeIcon icon={faTriangleExclamation} color="yellow" style={{ paddingLeft: "5px" }} />
                    </span>
                </OverlayTrigger>
            ) : (
                heatpart.name
            )}

            {(heatpart?.comment !== "") && 
                <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{heatpart?.comment}</Tooltip>}>
                    <span>
                        <FontAwesomeIcon icon={faCircleInfo} color="yellow" style={{ paddingLeft: "5px" }} />
                    </span>
                </OverlayTrigger>
            }
        </td>
        <td>{parse_to_no_calculation(heatpart.thermal_power_of_heatpart / 1000, 2, "kW")}</td>
        <td>{parse_to_no_calculation(heatpart.target_mass_flow, 2, "kg/h")}</td>
        <td>{parse_to_no_calculation(heatpart.target_return_temp, 2, "°C")}</td>
        <td>{parse_to_no_calculation(heatpart.target_pressure_mbar, 2, "mbar")}</td>
        <td>{parse_to_no_calculation(heatpart.target_kv, 4)}</td>
        <td>{parse_to_no_calculation(heatpart.target_setting_value, 2)}</td>
        <td>{parse_to_no_calculation(heatpart.target_thermal_power_to_ambient / 1000, 2, "kW")}</td>
        <td>{parse_to_no_calculation(room_temperature, 2, "°C")}</td>
        <td>{parse_to_no_calculation(heatpart.target_room_temp, 2, "°C")}</td>
        <td>
            <div className="actions__buttonbar grid__three">
                <OverlayTrigger
                    trigger={["hover", "focus"]}
                    overlay={<Tooltip>Add Configurations Log</Tooltip>}
                >
                    <Button
                        disabled={!allowEdit}
                        variant="outline-primary"
                        className="p-10"
                        onClick={() => setAddLOG({ show: "add/edit", roomID: id, hpID: heatpart.id, hp: heatpart })}
                    >
                        <FontAwesomeIcon icon={faAdd} />
                    </Button>
                </OverlayTrigger>

                <OverlayTrigger
                    trigger={["hover", "focus"]}
                    overlay={<Tooltip>List Configurations Logs</Tooltip>}
                >
                    <Button
                        variant="outline-primary"
                        className="p-10"
                        onClick={() => setAddLOG({ show: "listlogs", roomID: id, hpID: heatpart.id, hp: heatpart })}
                    >
                        <FontAwesomeIcon icon={faList} />
                    </Button>
                </OverlayTrigger>

                {/* <OverlayTrigger
                    trigger={["hover", "focus"]}
                    overlay={<Tooltip>{t("delete")}</Tooltip>}
                >
                    <Button
                        variant="outline-primary"
                        className="p-10"
                        onClick={() => setAddLOG({ show: "deleteLog", roomID: id, hpID: heatpart.id })}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </OverlayTrigger> */}
            </div>
        </td>
    </tr>
};