import React from "react";
import Card from "../../components/Card";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

export const Academy = ({ heading, descriptiton }) => {
    const { t } = useTranslation();

    const handleClick = () => {
        window.location.href = 'https://academy.sigmaheat.de/'; 
    };

    return <Card {...{ heading: heading }}>
        <div className="events__grid">
            <span><small>{descriptiton}</small></span>
            <div className="d-flex justify-content-end">
                <Button variant="outline-primary" onClick={handleClick}>{t("goToAcademy")}</Button>
            </div>
        </div>
    </Card>
}

export default Academy;