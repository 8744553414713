import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToast } from "../NotificationsContent";
import { Button, Form } from "react-bootstrap";
import { useAuth } from "../Context";
import { useSwagger } from "../context/SwaggerContext";

const SchemaGenerator = ({ canvasClassRef, onClose }) => {
    const { t } = useTranslation();
    const { login } = useAuth();
    const client = useSwagger();
    const { addToast } = useToast();
    const [elements, setElements] = useState(undefined);
    const connection_types = [{
        id: 0,
        label: t("parallel")
    }, {
        id: 1,
        label: t("serial")
    }];
    const [data, setData] = useState({
        source_no: 1,
        cylinder_no: 1,
        exchange_no: 1,
        connection_type: 0,
        exchange_before_cylinder: false, // True: Before Accumulator, False After Accumulator
        heatpart_no: 0,
        existing_heat_parts: false
    })

    const loadComponent = component => {
        return new Promise((resolve, reject) => {
            const encodedData = encodeURIComponent(component.svg);
            const imgSrc = `data:image/svg+xml;charset=utf-8,${encodedData}`;

            const imageElement = new Image();
            imageElement.src = imgSrc;
            imageElement.alt = '';

            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = decodeURIComponent(encodedData);
            const svgElement = tempDiv.querySelector('svg');

            document.body.appendChild(tempDiv);
            const bBox = svgElement.getBoundingClientRect();
            document.body.removeChild(tempDiv);

            imageElement.onload = function() {
                imageElement.width = bBox.width
                imageElement.height = bBox.height

                resolve({
                    id: component.id,
                    name: component.name,
                    part: component.part,
                    comment: component.comment,
                    x: 0,
                    y: 0,
                    connection_ports: component.connections_ports,
                    type: component.type,
                    rect: {
                        top: 0,
                        right: bBox.width,
                        bottom: bBox.height,
                        left: 0
                    },
                    rotate: component.rotation,
                    img: imageElement
                });
            };

            imageElement.onerror = reject;
        });
    };

    const getSchemaElements = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["heatpartcatalog"].heatpartcatalog_schema_elements_list({
                org_uuid: login.currentOrganisation.id,
                heatpart_catalog_uuid: "b8d2b1aa-1362-4c9b-a8dc-45a3e98c940a"
            });

            if (response.status >= 200 && response.status < 300) {
                const components = await Promise.all(Object.values(response.obj).map(loadComponent));

                setElements({
                    source: components[0],
                    exchange: components[1],
                    cylinder: components[2],
                    heatpart: components[3]
                })
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;  
            addToast(t("error"), t(statusCode === 401 ? "noAuthorization" : "THE CATALOG DOES NOT CONTAIN ALL THE NECCESSARY ELEMENTS TO GENERATE THE SCHEMA"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization])

    const handleDataChange = useCallback((e) => {
        const { id, value, type, checked } = e.target;
        setData(prev => ({
            ...prev,
            [id]: type === 'checkbox' ? checked : value
        }));
    }, []);

    const submitBlueprint = useCallback((e) => {
        e.preventDefault();

        let whole_structure = canvasClassRef.current.getComponentsAndConnections()
        let extra_hp = data.existing_heat_parts ? whole_structure.components.filter(c => c.type === 2).filter(component => {
            return !whole_structure.connections.some(connector => 
                connector.startConnector.component.x === component.x && connector.startConnector.component.y === component.y ||
                connector.endConnector.component.x === component.x && connector.endConnector.component.y === component.y
            );
        }) : []
        canvasClassRef.current.initSchemaBuilder(elements, extra_hp, data);
        onClose();
    }, [canvasClassRef.current, elements, data, onClose])

    useEffect(() => {
        getSchemaElements()
    }, [getSchemaElements])

    return <Form onSubmit={(e) => submitBlueprint(e)}>
        <Form.Group className="mb-3" controlId="source_no">
            <Form.Label>{t('heat_sources_no')}</Form.Label>
            <Form.Control type="number" placeholder="Heat Sources Number" value={data.source_no} onChange={(e) => handleDataChange(e)} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="cylinder_no">
            <Form.Label>{t('accumulators_no')}</Form.Label>
            <Form.Control type="number" placeholder="Accumulators Number" value={data.cylinder_no} onChange={(e) => handleDataChange(e)} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exchange_no">
            <Form.Label>{t('heat_exchangers_no')}</Form.Label>
            <Form.Control type="number" placeholder="Heat Exchanger Number" value={data.exchange_no} onChange={(e) => handleDataChange(e)} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="connection_type">
            <Form.Label>{t('connection_type')}</Form.Label>
            <Form.Select className="mb-3" value={data.connection_type} onChange={(e) => handleDataChange(e)}>
                {connection_types.map(ct => <option key={`connection-type-key-${ct.id}`} value={ct.id}>{ct.label}</option>)}
            </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Check type="checkbox" id="exchange_before_cylinder" label={t('heat_exchanger_position')} checked={data.exchange_before_cylinder} onChange={(e) => handleDataChange(e)} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="heatpart_no">
            <Form.Label>{t('additional_heat_parts_no')}</Form.Label>
            <Form.Control type="number" placeholder="Additional Heat Parts Number" value={data.heatpart_no} onChange={(e) => handleDataChange(e)} />
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Check type="checkbox" id="existing_heat_parts" label={t('existing_heat_parts')} checked={data.existing_heat_parts} onChange={(e) => handleDataChange(e)} />
        </Form.Group>

        <Button variant='outline-primary' className='w-100' type='submit'>{t('generate')}</Button>
    </Form>
}

export default SchemaGenerator;