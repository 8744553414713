import React, { useCallback, useEffect, useState } from 'react'
import { useSwagger } from '../context/SwaggerContext';
import { useAuth } from '../Context';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

const Document = ({setInfo, info, setSelectedFile, selectedFile, edit, required=true}) => {
    const client = useSwagger();
    const { login } = useAuth();
    const { t } = useTranslation();

    const [filetypes, setFiletypes] = useState([]);
    const [error, setError] = useState(undefined);

    let editFile = edit
  
    const getDataTypes = useCallback(async() => {
        if (!client) return;
        const originalRequestInterceptor = client.http.requestInterceptor;
  
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
  
            const response = await client.apis["filetypes"].filetypes_retrieve();

            if (response.status >= 200 && response.status < 300) {
                const typesArray = Object.entries(response.obj).map(([key, value]) => ({
                    value: key,
                    label: value
                }));

                const result = typesArray.find(item => item.value == editFile?.type);

                setFiletypes(typesArray);
                setInfo(prevState => ({
                    ...prevState,
                    type: result?.value|| typesArray[0]?.value
                }));
            }
    
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            client.http.requestInterceptor = originalRequestInterceptor;
        } 
    }, [login.Authorization, client]);

    // const handleFileChange = (e) => {
    //     // setSelectedFile(e.target.files[0]);
    //     setSelectedFile(Array.from(e.target.files));
    // }

    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB in Bytes

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const validFiles = [];
        const invalidFiles = [];

        files.forEach((file) => {
            if (file.size > MAX_FILE_SIZE) {
                invalidFiles.push(file.name);
            } else {
                validFiles.push(file);
            }
        });

        if (invalidFiles?.length > 0) {
            setError(`${t('maxSize10MB')} ${invalidFiles.join(', ')}`);
            setSelectedFile(undefined);
        } else {
            setError(''); 
            setSelectedFile(validFiles);
        }
    };

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;
        setInfo(prevState => ({
            ...prevState,
            [id]: value
        }));
    }, []);

    useEffect(() => {
        getDataTypes()
    }, [getDataTypes])

    return (
        <>
            <Form.Group className='mb-3'>
                <Form.Label>{`${t('title')} ${t('document')}`}</Form.Label>
                <Form.Control id='title' required={required} type='text' value={info['title'] || ''} onChange={(e) => changeHandler(e)} />
            </Form.Group>

            <Form.Group className='mb-3'>
                <Form.Label>{t('type')}</Form.Label>
                <Form.Select id="type" value={info['type'] || ''} onChange={(e) => changeHandler(e)}>
                        {filetypes?.map(type => <option key={`filetypes-${type.value}`} value={type?.value}>{t(type?.label?.toLowerCase())}</option>)}
                </Form.Select>
            </Form.Group>
                
            <Form.Group className='mb-3'>
                <Form.Label>{t('description')}</Form.Label>
                <Form.Control id='description'  as='textarea' rows={3} value={info['description'] || ''} onChange={(e) => changeHandler(e)} />
            </Form.Group>

            {!editFile &&
                <Form.Group className="mb-3" controlId="excelFile">
                    <Form.Control
                        type="file"
                        multiple // Ermöglicht das Hochladen mehrerer Dateien
                        onChange={(e) => handleFileChange(e)}
                    />
                </Form.Group>
            }

            {selectedFile?.length > 0 && (
                <ul className='mb-3'>
                    {selectedFile.map((file, index) => (
                        <li key={index}>{file.name}</li>
                    ))}
                </ul>
            )}

            {error && (
                <p>{error}</p>
            )}
        </>
    )
}

export default Document