import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const SelectYear = ({ onYearChange, selectedYear, className, disabled }) => {
    const { t } = useTranslation();
    const [year, setYear] = useState(selectedYear || '');

    const currentYear = new Date().getFullYear();
    const startYear = 1900;

    const years = [];
    for (let year = currentYear; year >= startYear; year--) {
        years.push(year);
    }

    const handleChange = (event) => {
        const selectedYear = event.target.value;
        onYearChange(selectedYear);
    };

    useEffect(() => {
        setYear(selectedYear);
    }, [selectedYear]);

    return (
        <Form.Select aria-label="Year select" value={year} onChange={handleChange} className={className} disabled={disabled}>
            <option>{t("selectYear")}</option>
            {years.map((year) => (
                <option key={year} value={year}>{year}</option>
            ))}
        </Form.Select>
    );
};

export default SelectYear;