import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useCallback, forwardRef } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SecurityActionModal from "../components/SecurityActionModal"

export const TableStructure = forwardRef(({ data, setData, excelData, setExcelData, mandatoryFields }, ref) => {
    const [header, setHeader] = useState(undefined)
    const [structure, setStructure] = useState(undefined);
    const [show, setShow] = useState(false);
    const [rowID, setRowID] = useState(-1);
    const { t } = useTranslation();

    const compareObjects = (obj1, obj2) => {
        return obj1.key === obj2.key;
    }

    const handleSelectChange = useCallback((newKey, oldKey) => {
        const oldIndex = header.findIndex(obj => obj.key === oldKey);
        const newIndex = excelData[0].findIndex(obj => obj.key === newKey);

        let tmpHeader = [...header].filter(obj => obj.key !== oldKey);
        tmpHeader.splice(oldIndex, 0, excelData[0].find(col => col.key === newKey));

        const tmpStructure = structure.map((innerArray, i) => {
            const tmpArray = [...innerArray]
            tmpArray[oldIndex] = excelData[i + 1][newIndex] === null ? "" : excelData[i + 1][newIndex]
            return tmpArray
        });

        setHeader(tmpHeader);
        setStructure(tmpStructure);
        setData([tmpHeader, ...tmpStructure])
    }, [excelData, header, setData])

    const handleInputChange = (e, rowIndex, colIndex) => {
        const updatedArray = [...structure];
        updatedArray[rowIndex][colIndex] = e.target.value;
        setStructure(updatedArray);
    }

    const deleteRow = useCallback(() => {
        let tmpData = [...data];
        let tmpExcelData = [...excelData];
        let tmpStructure = [...structure];

        tmpData.splice(rowID + 1, 1);
        tmpExcelData.splice(rowID + 1, 1);
        tmpStructure.splice(rowID, 1);

        setData(tmpData)
        setExcelData(tmpExcelData)
        setStructure(tmpStructure)
        setShow(false)
    }, [rowID, data, excelData, structure])

    useEffect(() => {
        setHeader(data[0])
        setStructure([...data].splice(1, data.length - 1))
    }, [data])

    if (structure === undefined || header === undefined) return <></>

    return <>
        <Table responsive>
            <thead>
                <tr>
                    <th>{t("delete")}</th>
                    {header.map(headerCol => (
                        <th key={`header-${headerCol.key}`}>
                            {mandatoryFields.some(mF => mF.lookup.map(m => m.toLowerCase()).includes( (typeof headerCol?.name === 'string')?headerCol?.name?.toLowerCase():headerCol?.name)) ? (
                                <span>{headerCol.name}</span>
                            ) : (
                                <Form.Select value={headerCol.key} onChange={(e) => handleSelectChange(parseInt(e.target.value), headerCol.key)}>
                                    <option value={headerCol.key}>{headerCol.name}</option>
                                    {excelData[0].filter(obj2 =>
                                        !header.some(obj1 => compareObjects(obj2, obj1))
                                    ).map(col => <option key={`option-${col.key}`} value={col.key}>{col.name}</option>)}
                                </Form.Select>
                            )}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody ref={ref}>
                {structure.map((rows, rowIndex) => <tr key={`selected-row-${rowIndex}`}>
                    <td>
                        <Button variant="outline-primary" className="p-10" style={{width: "38px"}} onClick={() => { setRowID(rowIndex); setShow(true) }}>
                            <FontAwesomeIcon icon={faTrash} size='sm' />
                        </Button>
                    </td>
                    {rows.map((row, colIndex) => <td key={`selected-colrow-${colIndex}`}>
                        <input className="form-control" type="text" value={row} onChange={(e) => handleInputChange(e, rowIndex, colIndex)} key={`input-${rowIndex}-${colIndex}`} />
                    </td>)}
                </tr>)}
            </tbody>
        </Table>
        <SecurityActionModal {...{ show, onHide: () => setShow(false), title: t("deleteRowTitle"), question: t("deleteRowQuestion"), action: () => deleteRow()}} />
    </>
})

export default TableStructure;