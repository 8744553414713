import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../Context";
import { useToast } from '../NotificationsContent';
import { Button, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import SecurityActionModal from "../components/SecurityActionModal";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import Card from "../components/Card";
import ContentModal from "../components/ContentModal";
import { AddEditPlant } from "./AddEditPlant";
import AddEditBuilding from "../BuildingListV2/AddEditBuilding";
import { useSwagger } from "../context/SwaggerContext";
import { usePermissions } from "../context/PermissionsContext";

export default function BuildingSettings() {
    const { login, reload, setReload } = useAuth();
    const client = useSwagger();
    const { addToast } = useToast();
    const _buildingGuid = useParams()['buildingGuid'];
    const { t } = useTranslation();
    const [building, setBuilding] = useState(undefined);
    const [bTMP, setBTMP] = useState(undefined)
    const [show, setShow] = useState(false);
    const [plant, setPlant] = useState(undefined);
    const navigate = useNavigate();

    const [showSecurity, setShowSecurity] = useState(false);
    const { allowEdit } = usePermissions();

    const loadBuilding = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     }
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t('networkError'));
        //     if (response.status === 204) return {};
        //     return response.json();
        // })
        // .then((data) => {
        //     setBuilding(data);
        //     setBTMP(data);
        // })
        // .catch(error => {
        //     addToast(t('error'), error, "error");
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_retrieve({
                building_uuid: _buildingGuid,
            });

            if (response.status === 204) {
                setBuilding({});
                setBTMP({});
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                setBuilding(response.obj);
                setBTMP(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization, t, addToast])

    
    const deleteEntry = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/`, {
        //     headers: {
        //         Authorization: login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: 'DELETE',
        // })
        // .then(response => {
        //     if (!response.ok) throw new Error(t("buildingDeleteError"))
        //     return {}
        // })
        // .then(content => {
        //     addToast(t("building"), t("buildingDeleteSuccess"), "success");
        //     navigate(`/${login?.currentOrganisation?.id}/buildings`);
        // })
        // .catch(error => {
        //     addToast(t("error"), error.message, "error");
        // })

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_destroy({building_uuid: _buildingGuid});

            if (response.status >= 200 && response.status < 300) {
                addToast(t("building"), t("buildingDeleteSuccess"), "success");
                navigate(`/${login?.currentOrganisation?.id}/buildings`);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            
            addToast(t("error"), t("buildingDeleteError"), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization, t, addToast])


    const deletePlant = useCallback(async(_plantGuid) => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/plant/${_plantGuid}/`, {
        //     method: "DELETE",
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     }
        // })
        // .then((response) => {
        //     if (response.status >= 200 && 300 > response.status) {
        //         setReload(reload + 1);
        //         loadBuilding();
        //         addToast(t('buildingSettings'), t('successfulPlantDelete'), "success");
        //     }
        // })

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;
  
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
    
            const response = await client.apis["building"].building_plant_destroy({
                building_uuid: _buildingGuid,
                plant_uuid: _plantGuid,
            });
    
            if (response.status >= 200 && response.status < 300) {
                setReload(reload + 1);
                loadBuilding();
                addToast( t("buildingSettings"), t("successfulPlantDelete"), "success");
            }
    
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.error(error);
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login.Authorization, _buildingGuid, loadBuilding, reload, setReload, addToast, t])

    const mainFunctions = [
        {label: t('add'), onClick: () => { setPlant(undefined); setShow("plant") }, key: 'add', icon: faAdd},
    ]

    const EditAndDelete = ({ plant }) => {

        return (
            <div className="actions__buttonbar grid__two">
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={
                        <Tooltip>{t('edit')}</Tooltip>
                    }>
                    <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setPlant(plant); setShow("plant") }}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={
                        <Tooltip>{t('delete')}</Tooltip>
                    }>
                    <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setShowSecurity("deletePlant"); setPlant(plant) }}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </OverlayTrigger>
            </div>
        )
    }

    const buildingTableStructure = useMemo(() => [
        {
            col: t('name'),
            type: 'label'
        }, {
            col: t('street'),
            type: 'label'
        }, {
            col: t('housenumber'),
            type: 'label'
        }, {
            col: t('zip'),
            type: 'label'
        }, {
            col: t('city'),
            type: 'label'
        }, {
            col: t('constructionYear'),
            type: 'label'
        }, {
            col: t('delete'),
            type: 'buttons'
        }
    ], [t])

    const plantsTableStructure = useMemo(() => [
        {
            col: '#',
            type: 'label'
        }, {
            col: t('anlageName'),
            type: 'label'
        }, {
            col: t('editDelete'),
            type: 'buttons'
        }
    ], [t])

    const onClose = (reload) => {
        setShow(false);
        setPlant(undefined);
        if (reload) loadBuilding();
    }

    useEffect(() => {
        loadBuilding();
    }, [loadBuilding]);

    return <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">
            <Card {...{ heading: t('building') }}>
                {(building === undefined) ? (
                    <TablePlaceholder {...{structure: buildingTableStructure, no: 1}} />
                ) : (
                    <Table responsive className="mb-3">
                        <thead>
                            <tr>
                                <th>{t('name')}</th>
                                <th>{t('street')}</th>
                                <th>{t('housenumber')}</th>
                                <th>{t('zip')}</th>
                                <th>{t('city')}</th>
                                <th>{t('constructionYear')}</th>
                                <th>{t('editDelete')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{building?.name}</td>
                                <td>{building?.street}</td>
                                <td>{building?.no}</td>
                                <td>{building?.zip}</td>
                                <td>{building?.city}</td>
                                <td>{building?.construction_year}</td>
                                <td>
                                    <div className="actions__buttonbar grid__two">
                                        <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            overlay={
                                                <Tooltip>{t('edit')}</Tooltip>
                                            }>
                                            <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => setShow("building") }>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                        </OverlayTrigger>
                                        {/* <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            overlay={
                                                <Tooltip>{t('delete')}</Tooltip>
                                            }>
                                            <Button variant="outline-danger" className="p-10" onClick={() => console.log("delete")}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </OverlayTrigger> */}

                                        <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            overlay={
                                                <Tooltip>{t('deleteBuilding')}</Tooltip>
                                            }>
                                            {/* <Button variant='outline-primary' className='p-10' onClick={() => deleteEntry(building.id)} disabled={building.heating_load > 0 || building.thermal_power > 0}> */}
                                            <Button variant='outline-primary' className='p-10' onClick={() => setShowSecurity("deleteBuilding")} disabled={!allowEdit || building.heating_load > 0 || building.thermal_power > 0}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </OverlayTrigger>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                )}

                {/* <Card {...{ heading: t('plants'), mainFunctions }}>
                    {(building === undefined) ? (
                        <TablePlaceholder {...{structure: plantsTableStructure}} />
                    ) : (!Boolean(building?.plants?.length)) ? (
                        <p className="m-0">{t('noPlants')}</p>
                    ) : (
                        <Table responsive className="mb-3">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t('anlageName')}</th>
                                    <th>{t('editDelete')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Boolean(building?.plants?.length) && building.plants.map(p => <tr key={`plant-key-${p.id}`}>
                                        <td>{p.no}</td>
                                        <td>{p.name}</td>
                                        <td><EditAndDelete {...{plant: p}} /></td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    )}
                </Card> */}
            </Card>
            <ContentModal {...{ show: show === "plant", onHide: () => onClose(false), title: t('addPlant'), content: <AddEditPlant {...{ plant, onClose: () => onClose(true) }} /> }} />
            <ContentModal {...{ show: show === "building", onHide: () => {setBuilding(bTMP); setShow(false)}, title: building?.property ? (building?.id ? t('editBuilding') : t('addBuildingWithProperty')) : (building?.id ? t('editBuilding') : t('addBuildingWithoutProperty')), content: <AddEditBuilding {...{ building, setBuilding, loadbuildings: () => loadBuilding(), onHide: () => setShow(false) }} /> }} />
            <SecurityActionModal {...{ show: showSecurity === "deleteBuilding", onHide: () => setShowSecurity(false), title: t('deleteBuilding'), question: t("deleteBuildingQuestion"), action: () => deleteEntry(building.id) }} />
            {/* <SecurityActionModal {...{show: showSecurity, onHide: () => {setShowSecurity(false); setPlant(undefined)}, title: t('deletePlant'), question: t('deletePlantQuestion'), action: () => {deletePlant(plant?.id); setPlant(undefined)}}} /> */}
            <SecurityActionModal {...{show: showSecurity === "deletePlant", onHide: () => {setShowSecurity(false); setPlant(undefined)}, title: t('deletePlant'), question: t('deletePlantQuestion'), action: () => {deletePlant(plant?.id); setPlant(undefined)}}} />
        </div>
    </MainLayout>
}