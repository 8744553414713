import React from "react"
import "../dashboard/style.css"

export const MainLayout = ({ background, children }) => {
    return <div className="main__wrapper">
        <div className="main__inner">
            {children}
        </div>
        <div className="main__background" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.4)), url(${background})` }}></div>
    </div>
}

export default MainLayout;