import React, { useCallback, useEffect, useState } from 'react'
import { useAuth } from '../Context';
import { useSwagger } from '../context/SwaggerContext';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Ganttchart from '../charts/Ganttchart';
import * as am5 from "@amcharts/amcharts5";

const BuildingGanttchart = ({subsidies}) => {
    const { t } = useTranslation();

    const [data, setData] = useState(undefined)
    const [categories, setCategories] = useState(undefined)

    const colors = [
        "#008000", // Grün
        "#ADFF2F", // Hellgrün
        "#FFFF00", // Gelb
        "#FFD700", // Gold
        "#FFA500", // Orange
        "#D2691E", // Schokoladenbraun
        "#A52A2A", // Braun
        "#8B4513", // Sattelbraun
    ];

    const createDataStructure = (data) => {
        if (!data) return

        const dataStructure = data?.map((item, index) => ({
            // category: t('subsidies'),  
            category: item.action,
            start: new Date(item.planned_start_date).getTime(),
            end: new Date(item.planned_end_date).getTime(),  
            // start: new Date(2000 + index, 0, 1).getTime(),
            // end: new Date(2000 + index, 0, 14).getTime(),
            columnSettings: {
                fill: am5.color(colors[index % colors.length])
            },
            task: item.action || "Default Task"  
        }));

        const categorys = data.map((item) => ({
            category: t(item.action)
        }));

        setData(dataStructure)
        setCategories(categorys)
    }

    useEffect(() => {
        if (subsidies) {
            createDataStructure(subsidies)
        }
    }, [subsidies])

    return (
        <>
            <div className="chart-container">
                <div className="chart-item">
                    <Ganttchart {...{data: data, element: "ganttChart", categories : categories, heading: <span>{t('overview')}: {t('building_actions')}</span>}} />
                </div>
            </div>
        </>
    )
}

export default BuildingGanttchart