import { useState, useEffect, useCallback } from "react"
import { useAuth } from "../Context"
import { useParams } from "react-router-dom"
import { useToast } from "../NotificationsContent"
import { useTranslation } from "react-i18next"
import { Button, Form, Table } from "react-bootstrap"
import TablePlaceholder from "../components/Placeholders/TablePlaceholder"
import { useSwagger } from "../context/SwaggerContext"

export const PaymentMethod = ({ selectedBuilding, onHide, orgGuid }) => {
    const _orgGuid = useParams()['orgGuid'] || orgGuid;
    const { login } = useAuth()
    const { t } = useTranslation()
    const { addToast } = useToast()
    const [defaultPM, setDefaultPM] = useState(undefined)
    const [paymentMethods, setPaymentMethods] = useState(undefined)
    const [disabled, setDisabled] = useState(false)
    const client = useSwagger();

    const buildingsTableStructure = [
        {
            col: t("name"),
            type: 'label'
        }, {
            col: t('address'),
            type: 'label'
        }, {
            col: t('paymentMethod'),
            type: 'label'
        }
    ]

    const fetchPaymentMethods = useCallback(async () => {
    //     fetch(`https://tech.sigmaheat.de/payment/org/${_orgGuid}/paymentmethods`, {
    //         headers: {
    //             'Authorization': login.Authorization,
    //             "Content-Type": "application/json"
    //         },
    //         method: "GET"
    //     })
    //     .then((response) => {
    //         if (!response.ok) throw new Error(t("responseError"))
    //         return response.json()
    //     })
    //     .then(data => {
    //         setPaymentMethods(data)
    //         if (Boolean(data.length)) fetchDefaultPM(data)
    //     })
    //     .catch(error => {
    //         addToast(t("error"), error.message, "error")
    //     });
    // }, [login.Authorization, t, addToast, selectedBuilding])

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["payment"].payment_org_paymentmethods_list({org_uuid: _orgGuid});

            if (response.status >= 200 && response.status < 300) {
                setPaymentMethods(response.data);
                if (Boolean(response.data.length)) fetchDefaultPM(response.data);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, t, addToast, selectedBuilding]);

    const fetchDefaultPM = useCallback(async (paymentMethods) => {
    //     fetch(`https://tech.sigmaheat.de/org/${_orgGuid}/getbuildingswproduct/${selectedBuilding?.stripe_subscription_id}`, {
    //         headers: {
    //             'Authorization': login.Authorization,
    //             "Content-Type": "application/json"
    //         },
    //         method: "GET"
    //     })
    //     .then((response) => {
    //         if (!response.ok) throw new Error(t("responseError"))
    //         return response.json()
    //     })
    //     .then(data => {
    //         console.log("Payment method", data)
    //         if (data.payment_method_id !== null) setDefaultPM(data.payment_method_id)
    //         if (data.payment_method_id === null) setDefaultPM(paymentMethods[0].id)
    //     })
    //     .catch(error => {
    //         addToast(t("error"), error.message, "error")
    //     });
    // }, [login.Authorization, t, addToast, selectedBuilding])

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["org"].org_getbuildingswproduct_retrieve({org_uuid: _orgGuid, es_uuid: selectedBuilding?.stripe_subscription_id});

            if (response.status >= 200 && response.status < 300) {
                console.log("Payment method", response.obj);
                if (response.obj.payment_method_id !== null) setDefaultPM(response.obj.payment_method_id);
                if (response.obj.payment_method_id === null) setDefaultPM(paymentMethods[0].id);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, t, addToast, selectedBuilding]);

    const changeStatus = useCallback(async(building) => {
    //     fetch(`https://tech.sigmaheat.de/building/${building.building}/esproducts`, {
    //         headers: {
    //             'Authorization': login.Authorization,
    //             "Content-Type": "application/json"
    //         },
    //         method: "PATCH",
    //         body: JSON.stringify({ stripe_subscription_id: building.stripe_subscription_id })
    //     })
    //     .then((response) => {
    //         console.log(response)
    //         if (!response.ok) throw new Error(t("responseError"))
    //         return {}
    //     })
    //     .catch(error => {
    //         addToast(t("error"), error.message, "error")
    //     });
    // }, [login.Authorization, t, addToast])
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;

                req.body = JSON.stringify({ stripe_subscription_id: building.stripe_subscription_id })
                return req;
            };

            const response = await client.apis["building"].building_esproducts_partial_update({building_uuid: building.building});

            if (response.status >= 200 && response.status < 300) {
                console.log(response);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, addToast, t]);

    const patchPaymentMethod = useCallback(async () => {
        if (disabled) {
            addToast(t("error"), t("waitForProccess"), "error")
            return
        }

        if (defaultPM === undefined) {
            addToast(t("error"), "Bitte wählen Sie sich eine Zahlungsmethoden!", "error")
            return
        }
        fetch(`https://tech.sigmaheat.de/payment/org/${_orgGuid}/updatepaymentmethod`, {
            headers: {
                'Authorization': login.Authorization,
                "Content-Type": "application/json"
            },
            method: "PATCH",
            body: JSON.stringify({ stripe_subscription_id: selectedBuilding?.stripe_subscription_id, payment_method_id: defaultPM })
        })
        .then((response) => {
            if (!response.ok) throw new Error(t("responseError"))
            return {}
        })
        .then(async(_) => {
            await changeStatus(selectedBuilding)
            addToast(t("success"), t("paymentMethodChanged"), "success")
            onHide()
            setDisabled(false)
        })
        .catch(error => {
            addToast(t("error"), error.message, "error")
        });
    }, [login.Authorization, t, addToast, selectedBuilding, defaultPM, disabled])

    //     if (!client) return;

    //     const originalRequestInterceptor = client.http.requestInterceptor;

    //     try {
    //         client.requestInterceptor = (req) => {
    //             req.headers["Content-Type"] = "application/json";
    //             req.headers["Authorization"] = login.Authorization;
    //             req.body = JSON.stringify({ stripe_subscription_id: selectedBuilding?.stripe_subscription_id, payment_method_id: defaultPM })
    //             return req;
    //         };

    //         const response = await client.apis["payment"].payment_org_paymentmethods_partial_update({org_uuid: _orgGuid, payment_method_uuid: !!!!!!!!!!!});

    //         if (response.status >= 200 && response.status < 300) {
    //             await changeStatus(selectedBuilding);
    //             addToast(t("success"), t("paymentMethodChanged"), "success");
    //             onHide();
    //             setDisabled(false);
    //         }

    //         client.http.requestInterceptor = originalRequestInterceptor;
    //     } catch (error) {
    //         addToast(t("error"), t("responseError"), "error");

    //         client.http.requestInterceptor = originalRequestInterceptor;
    //     }
    // }, [client, login.Authorization, t, addToast, selectedBuilding, defaultPM, disabled]);

    useEffect(() => {
        if (selectedBuilding === undefined) return
        fetchPaymentMethods()
    }, [])

    useEffect(() => {
        if (paymentMethods instanceof Array && !Boolean(paymentMethods.length)) {
            // onHide()
            addToast(t("info"), "Es gibt keine Zahlungsmethoden vorhanden!", "notification")
        }
    }, [paymentMethods])

    return <>
        {(selectedBuilding === undefined || defaultPM === undefined) ? (
            <TablePlaceholder {...{structure: buildingsTableStructure}} />
        ) : (!Boolean(Object.keys(selectedBuilding)?.length)) ? (
            <p className="mb-3"></p>
        ) : (
            <>
                <Table responsive className="mb-3">
                    <thead>
                        <tr>
                            <th>{t("name")}</th>
                            <th>{t("address")}</th>
                            <th>{t("paymentMethod")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{selectedBuilding.building_name}</td>
                            <td>{selectedBuilding.building_address}</td>
                            <td>
                                <Form.Group controlId={`building-paymentMethos-${selectedBuilding.id}`}>
                                    <Form.Select onChange={(e) => setDefaultPM(e.target.value)} value={defaultPM}>
                                        {paymentMethods?.map(pM => {
                                            if (pM.hasOwnProperty('card')) return <option key={`payment-method-${pM.id}`} value={pM.id}>{pM.card.brand.charAt(0).toUpperCase() + pM.card.brand.slice(1) + " " + pM.card.last4}</option>
                                            if (pM.hasOwnProperty('sepa_debit')) return <option key={`payment-method-${pM.id}`} value={pM.id}>{pM.type.charAt(0).toUpperCase() + pM.type.slice(1) + " " + pM.sepa_debit.last4}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className="d-flex justify-content-end">
                    <div className={`${disabled ? "placeholder-glow" : ""}`}>
                        <Button variant="outline-primary" tabIndex="-1" aria-hidden="true" className={`${disabled ? "placeholder" : ""}`} disabled={disabled || paymentMethods instanceof Array && !Boolean(paymentMethods.length)} onClick={() => {setDisabled(true); patchPaymentMethod()}}>{t("change")}</Button>
                    </div>
                </div>
            </>
        )}
    </>
}

export default PaymentMethod;