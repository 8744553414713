import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose, faTrash } from "@fortawesome/free-solid-svg-icons";
import SecurityActionModal from "../components/SecurityActionModal";
import { timeReadableFormat } from "../util/helpers";
import { useSwagger } from "../context/SwaggerContext";
import { extractInitials, specificColorGenerator } from "../util/helpers";
import { usePermissions } from "../context/PermissionsContext";

export const HeatpartConfigurationsLog = ({ roomID, hpID, cL, onClose }) => {
    const _buildingGuid = useParams()['buildingGuid'];
    const { login } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();
    const [configurationsLogs, setConfigurationsLogs] = useState(undefined);
    const [deleteLog, setDeleteLog] = useState(false)
    const client = useSwagger();

    const tableStructure = [
        {
            col: t('person'),
            type: 'label'
        }, {
            col: t('settingValue'),
            type: 'label'
        }, {
            col: t('kv'),
            type: 'label'
        }, {
            col: t('valveAuthority'),
            type: 'label'
        }, {
            col: t('calcFromExternal'),
            type: 'label'
        },
        {
            col: t('comment'),
            type: 'label'
        }, {
            col: t('createdAt'),
            type: 'label'
        }, {
            col: t('actions'),
            type: 'buttons'
        },
    ]

    const getConfigurationsLog = useCallback(async () => {

        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/room/${roomID}/heatpart/${hpID}/configurationlogentry/`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "GET"
        // })
        // .then((response) => {
        //     if (response.status === 409) throw new Error("CONFLICT");
        //     if (!response.ok) throw new Error(t('networkError'));
        //     return response.json();
        // })
        // .then(data => {
        //     setConfigurationsLogs(data.results)
        // })
        // .catch(error => {
        //     addToast(t("error"), error.message, "error");
        // });
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_room_heatpart_configurationlogentry_list({
                building_uuid: _buildingGuid,
                room_uuid: roomID,
                room_heatpart_uuid: hpID
            });

            if (response.status >= 200 && response.status < 300) {
                setConfigurationsLogs(response.obj.results ? response.obj.results : [])
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;
            addToast(t("error"), statusCode === 409 ? "CONFLICT" : t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, roomID, hpID])

    const deleteLogEntry = useCallback(async (clID) => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/room/${roomID}/heatpart/${hpID}/configurationlogentry/${clID}`, {
        //     headers: {
        //         Authorization: login.Authorization,
        //         "Content-Type": "application/json",
        //     },
        //     method: "DELETE",
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t("notDeletedConfigurationsLog"));
        //     getConfigurationsLog();
        //     addToast(t("success"), t("deletedConfigurationsLog"), "success");
        // })
        // .catch((error) => {
        //     addToast(t("error"), error.message, "error");
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_room_heatpart_configurationlogentry_destroy({
                building_uuid: _buildingGuid,
                room_uuid: roomID,
                room_heatpart_uuid: hpID,
                conf_logentry_uuid: clID
            });

            if (response.status >= 200 && response.status < 300) {
                getConfigurationsLog();
                addToast(t("success"), t("deletedConfigurationsLog"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, addToast, t, getConfigurationsLog]);


    useEffect(() => {
        getConfigurationsLog()
    }, []);

    return <>
        {(configurationsLogs === undefined) ? (
            <TablePlaceholder {...{ structure: tableStructure }} />
        ) : (configurationsLogs.length === 0) ? (
            <p className="m-0">{t("noConfigurationsLog")}</p>
        ) : (
            <Table responsive>
                <thead>
                    <tr>
                        {tableStructure.map(head => <th key={`header-${Math.random()}`}><span>{head.col}</span></th>)}
                    </tr>
                </thead>
                <tbody>
                    {configurationsLogs.map(log => <ConfigurationLogRow key={`cL-${log.updated_at}-${log.id}`} {...{ log, setDeleteLog }} />)}
                </tbody>
            </Table>
        )}

        <SecurityActionModal {...{ show: deleteLog, onHide: () => setDeleteLog(false), title: t("deleteConfigurationsLog"), question: t("deleteConfigurationsLogQuestion"), action: () => deleteLogEntry(deleteLog) }} />
    </>
};


const ConfigurationLogRow = ({ log, setDeleteLog }) => {
    const { t } = useTranslation();
    const { login, defaultTheme } = useAuth();
    const [image, setImage] = useState(false);
    const client = useSwagger(); 
    const { allowEdit } = usePermissions();

    const parse_to_no_calculation = (value, digits = 2, unit = "", minus_1_text = t('to_calculate')) => {
        if (value == -1) {
            return minus_1_text
        }
        else {
            return Number.parseFloat(value).toFixed(digits) + " " + unit
        }
    }


    const loadProfileImage = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;
  
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
    
            const response = await client.apis["User"].getUserProfilePicture({ user_uuid_path: log.author_uuid, size: 48 })
    
            if (response.status >= 200 && response.status < 300) {
                setImage(response.status === 204 ? false : URL.createObjectURL(response.data))
            }
    
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            setImage(false);
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, log.author_uuid, login.Authorization, t])

    useEffect(() => {
        loadProfileImage()
    }, [loadProfileImage])


    return <tr>
        <td>
            <div className="d-flex align-items-center">
                {image && <img className="author__img" src={image} alt='' />}
                {image === false && <div className="log__profile" style={{ backgroundColor: specificColorGenerator(log.prename + ' ' + log.name, true) }}>
                    <span className="initials" style={{ color: specificColorGenerator(log.prename + ' ' + log.name, false) }}>{extractInitials(log.prename + ' ' + log.name)}</span>
                </div>}
                <span>
                    <a className={defaultTheme === 'light' ? "text-black" : "text-white"} href={("mailto:" + log.mail + "?subject=" + encodeURIComponent("Frage zum Objektlog") + "&body=" + encodeURIComponent("Titel:" + log.title + "\nObjektLog-ID: " + log.id + "\n" , "https://tech.sigmaheat.de" + window.location.pathname))}>{log.prename + " " + log.name}</a>
                </span>
            </div>
        </td>

        <td>
            <span>{parse_to_no_calculation(log.setting_value)}</span>
        </td>
        <td>
            <span>{parse_to_no_calculation(log.kv, 4)}</span>
        </td>
        <td>
            <span>{parse_to_no_calculation(log.valve_authority)}</span>
        </td>

        <td>
            <FontAwesomeIcon icon={log.calc_from_external ? faCheck : faClose} />
        </td>
        <td>
            <span>{log.comment}</span>
        </td>

        <td>
            <span>{timeReadableFormat(log.created_at)}</span>
        </td>

        <td>
            <OverlayTrigger
                trigger={['hover', 'focus']}
                overlay={
                    <Tooltip>{t("delete")}</Tooltip>
                }>
                <Button disabled={!allowEdit} variant='outline-primary' className="p-10" onClick={() => setDeleteLog(log.id)}>
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            </OverlayTrigger>
        </td>
    </tr>
}

export default HeatpartConfigurationsLog;