import React from 'react'
import { useAuth } from '../Context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

function Collapse({ index, boolean, className, onClick }) {
    // eslint-disable-next-line no-unused-vars
    const {_, __, ___, ____, defaultTheme} = useAuth();

    return (
        <button className={`align-middle arrow ${boolean ? 'open' : ''} ${className !== undefined ? className : ''}`} onClick={(e) => { e.preventDefault(); onClick(!boolean, index)}}>
            <FontAwesomeIcon icon={faChevronRight} style={{ color: defaultTheme === 'light' ? '#000000' : '#ffffff' }} />
        </button>
    )
}

export default Collapse