import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../Context";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { dateToDateString, dateToString } from "../util/helpers";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import Card from "../components/Card";
import { useSwagger } from "../context/SwaggerContext";

export default function BuildingInvoice() {
    const _buildingGuid = useParams()['buildingGuid'];
    const { login } = useAuth();
    const [invoices, setInvoices] = useState(undefined);
    const { t } = useTranslation()
    const client = useSwagger();

    const loadInvoices = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/invoice/`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     }
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t('error'));
        //     if (response.status === 204) return [];
        //     return response.json();
        // })
        // .then((data) => {
        //     setInvoices(data);
        // })

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_invoice_retrieve({ building_uuid: _buildingGuid });

            if (response.status === 204) {
                setInvoices([]);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                setInvoices(response.obj);
            } else {
                setInvoices([]);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            new Error(t("error"));
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [_buildingGuid, client, login.Authorization, t]);

    useEffect(() => {
        console.log(_buildingGuid);
        loadInvoices();
    }, [_buildingGuid, loadInvoices]);

    return <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">
            <InvoiceTable {...{ invoices }} />
        </div>
    </MainLayout>
}



const InvoiceTable = ({ invoices }) => {
    const { t } = useTranslation();
    let with_performance_period = false;

    // eslint-disable-next-line array-callback-return
    invoices?.forEach(i => {
        if (i.performance_period_from !== undefined || i.performance_period_to !== undefined) with_performance_period = true;
    });

    const tableStructure = [
        {
            col: t('title'),
            type: 'label'
        }, {
            col: t('type'),
            type: 'label'
        }, {
            col: t('performancePeriod'),
            type: 'label'
        }, {
            col: t('createdAt'),
            type: 'label'
        }, {
            col: t('actions'),
            type: 'buttons'
        }
    ]

    return <>
        <Card {...{ heading: t('buildingInvoices') }}>
            {(invoices === undefined) ? (
                <TablePlaceholder {...{structure: tableStructure}} />
            ) : (!Boolean(invoices.length)) ? (
                <p className="m-0">{t('noInvoices')}</p>
            ) : (
                <Table responsive>
                    <thead>
                        <tr>
                            <th>
                                <span>{t("title")}</span>
                            </th>
                            <th>
                                <span>{t("type")}</span>
                            </th>
                            {with_performance_period && <th>
                                <span>{t("performancePeriod")}</span>
                            </th>}
                            <th>
                                <span>{t("createdAt")}</span>
                            </th>
                            <th>
                                <span>{t("actions")}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices.map((invoice) => <InvoiceRow key={`floor-${invoice.id}`} {...{ with_performance_period, invoice }} />)}
                    </tbody>
                </Table>
            )}
        </Card>
    </>
}


export const InvoiceRow = ({ with_performance_period, invoice }) => {
    const { login } = useAuth();
    const { t } = useTranslation();
    const _buildingGuid = useParams()["buildingGuid"];
    const client = useSwagger();

    return (<tr>
        <td>
            <span>{(invoice.title).toLocaleString(undefined, { maximumFractionDigits: 0 })}</span>
        </td>
        <td>
            <span>{t(invoice.ressource_type)}</span>
        </td>
        {with_performance_period && (invoice.performance_period_from && invoice.performance_period_to) ? <td>
            <span>{t("from") + " " + dateToDateString(new Date(Date.parse(invoice.performance_period_from))) + " " + t('to') + " " + dateToDateString(new Date(Date.parse(invoice.performance_period_from)))}</span>
        </td>:<td>
            <span>{t("notSpecified")}</span>
        </td>}
        <td>
            <span>{dateToString(new Date(Date.parse(invoice.created_at)))}</span>
        </td>
        <td>
            <div className="d-flex gap-3">
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={
                        <Tooltip>{t("download")}</Tooltip>
                    }>
                    <Button variant="outline-primary" onClick={() => {
                        fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/invoice/${invoice.id}`, {
                            headers: {
                                'Authorization': login.Authorization,
                                "Content-Type": "application/json"
                            }
                        })
                        .then((response) => response.blob()).then(blob => {
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(blob);
                            link.download = `${invoice.title}.pdf`;
                            document.body.append(link);
                            link.click();
                            link.remove();
                            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
                        })
                    }}>
                        <FontAwesomeIcon icon={faDownload} />
                    </Button>
                </OverlayTrigger>

                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={
                        <Tooltip>{t("open_in_browser")}</Tooltip>
                    }>
                    <Button variant="outline-primary" onClick={async() => {
                        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/invoice/${invoice.id}`, {
                        //     headers: {
                        //         'Authorization': login.Authorization,
                        //         "Content-Type": "application/json"
                        //     }
                        // })
                        // .then((response) => response.blob()).then(blob => {
                        //     let pdfUrl = URL.createObjectURL(blob)
                        //     var newWindow = window.open(pdfUrl, '_blank');
                        //     setTimeout(function(){ newWindow.document.title =  `${invoice.title}.pdf`; }, 100);
                        //     setTimeout(() => URL.revokeObjectURL(pdfUrl), 7000);
                        // })

                        if (!client) return;

                        const originalRequestInterceptor = client.http.requestInterceptor;

                        try {
                            client.requestInterceptor = (req) => {
                                req.headers["Content-Type"] = "application/json";
                                req.headers["Authorization"] = login.Authorization;
                                return req;
                            };

                            const response = await client.apis["Invoice"].downloadInvoice({ building_uuid: _buildingGuid, invoice_uuid: invoice.id });

                            if (response.status >= 200 && response.status < 300) {
                                const link = document.createElement("a");
                                link.href = URL.createObjectURL(response.obj);
                                link.download = `${invoice.title}.pdf`;
                                document.body.append(link);
                                link.click();
                                link.remove();
                                setTimeout(() => URL.revokeObjectURL(link.href), 7000);
                            }

                            client.http.requestInterceptor = originalRequestInterceptor;
                        } catch (error) {
                            client.http.requestInterceptor = originalRequestInterceptor;
                        }
                    }}>
                        <FontAwesomeIcon icon={faEye} />
                    </Button>
                </OverlayTrigger>
            </div>
        </td>
    </tr>);
};