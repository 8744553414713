import { useState, useCallback } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Card, Form, Table } from "react-bootstrap";

export const ListExchangeHeatpart = ({ onClose, ...props }) => {
    const { login } = useAuth();
    const { addToast } = useToast();
    const { t } = useTranslation();
    const _buildingGuid = useParams()['buildingGuid'];

    let outputs = props.variant?.output_heatpart_exchangelist;

    if (outputs == undefined  || Object.keys(outputs).length==0) return <></>

    return <>
        <h5 style={{paddingTop:"20px"}}>Austauschliste</h5>
        <Table responsive>
            <thead>
                <tr>
                    <th>Etage</th>
                    <th>Raum</th>
                    <th>Bedarf</th>
                    <th>Heizkörper</th>
                    <th>Alt</th>
                    <th>Neu (75/65/20)</th>
                    <th>Oder Ergänzung bei Auslegung</th>
                </tr>
            </thead>
            <tbody>

                {Object.keys(outputs)?.map(m => {
                    let entry = outputs[m];
                    return <tr>
                        <td>
                            {entry.floor?.level}
                        </td>
                        <td>
                            {entry.room?.name}
                        </td>
                        <td>
                            {entry.heatingload?.toLocaleString(undefined, { maximumFractionDigits: 2 })} Watt
                        </td>
                        <td>
                            {entry.heatpart?.name}
                        </td>
                        <td>
                            {entry.thermal_power ? entry.thermal_power.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0} Watt
                        </td>
                        <td>
                            {entry.config?.exchange["75_65_20_power"].toLocaleString(undefined, { maximumFractionDigits: 2 })} Watt
                        </td>
                        <td>
                            {entry.config?.additional_power_needed_to_heatingload.toLocaleString(undefined, { maximumFractionDigits: 2 })} Watt
                        </td>
                    </tr>
                })}
            </tbody>
        </Table>
    </>
};
