import { useState, useEffect, useMemo, useCallback } from "react";
import { useAuth } from "../../Context";
import { useToast } from '../../NotificationsContent';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraRetro, faRoad, fa3, faSignsPost, faCity, faTrowelBricks, faLightbulb, faBoltLightning } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "../../style/Loader";
import Card from "../../components/Card";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ContentModal from "../../components/ContentModal";
import SecurityActionModal from "../../components/SecurityActionModal";
import BuildingAdditionalInfos from "../../BuildingPage/BuildingAdditionalInfos";
import { useSwagger } from "../../context/SwaggerContext";

export const BuildingProfile = () => {
    const { addToast } = useToast();
    const client = useSwagger();
    const { login } = useAuth();
    const _buildingGuid = useParams()['buildingGuid'];
    const [building, setBuilding] = useState(undefined);
    const [kWh_m2, setkWh_m2] = useState(undefined);
    // eslint-disable-next-line no-unused-vars
    const [year, setYear] = useState(undefined);
    // eslint-disable-next-line no-unused-vars
    const [co2, setCo2] = useState(undefined);
    const [image, setImage] = useState(undefined);
    const [showContent, setShowContent] = useState(false);
    const [showDBPM, setShowDBPM] = useState(false);
    const { t } = useTranslation();

    let data = useMemo(() => [{
        icon: faRoad,
        name: t('street'),
        value: building?.street,
    }, {
        icon: fa3,
        name: t('housenumber'),
        value: building?.no,
    }, {
        icon: faSignsPost,
        name: t('zip'),
        value: building?.zip,
    }, {
        icon: faCity,
        name: t('city'),
        value: building?.city,
    }, {
        icon: faTrowelBricks,
        name: t('constructionYear'),
        value: building?.construction_year,
    }, {
        icon: faLightbulb,
        name: `${t('energyEfficiencyClass')}`,
        value: building?.variant?.ee_class === null ? `(${t('afterCalc')})` : building?.variant?.ee_class,
    }, {
        icon: faBoltLightning,
        name: `${t('specificConsumption')}`,
        value: `${!building?.variant?.specific_consumption ? `(${t('afterCalc')})` : `${parseFloat(building.variant.specific_consumption.toFixed(2))} kWh/m² & ${t('year')}`}`,
    }], [building, t]);

    const mainFunctions = [
        {label: t('toTheDetails'), onClick: () => setShowContent(true), key: 'setShowContent'},
        {label: t('deleteImage'), onClick: () => setShowDBPM(true), key: 'setShowDBPM'}
    ]

    const loadImage = useCallback(async(building) => {
        if (building.picture === null) setImage(false);
        if (!client || building.picture === null) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_picture_retrieve({
                building_uuid: _buildingGuid,
                picture_uuid: building.picture,
            });

            if (response.status >= 200 && response.status < 300) {
                setImage(URL.createObjectURL(response.data));
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            setImage(false);
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [_buildingGuid, client, login.Authorization]);

    const loadBuilding = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     }
        // })
        // .then((response) => {
        //     if (response.status >= 200 && response.status <= 299) return response.json();
        //     if (response.status === 404) setBuilding({status: 404})
        //     if (response.status === 403) setBuilding({status: 403})
        //     return {};
        // })
        // .then((data) => {
        //     console.log("ddsggsd")
        //     if (Object.keys(data).length === 0) return;
        //     data.picture ? loadImage(data) : setImage(false);

        //     data?.annual_consumption?.sort((a, b) => (a.year > b.year ? 1 : a.year < b.year ? -1 : 0))
        //     // eslint-disable-next-line array-callback-return
        //     data?.annual_consumption?.map(ac => {
        //         setkWh_m2(ac.consumption_MWh / data.calculated_area * 1000)
        //         setYear(ac.year)
        //         setCo2(ac.co2_kg_heating)
        //     })
        //     setBuilding(data)
        // })
        // .catch(error => {
        //     addToast(t('error'), error, "error");
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;

                return req;
            };

            const response = await client.apis["building"].building_retrieve({
                building_uuid: _buildingGuid,
            });

            if (response.status >= 200 && response.status < 300) {
                if (Object.keys(response.obj).length === 0) return;
                response.obj.picture ? loadImage(response.obj) : setImage(false);

                response.obj?.annual_consumption?.sort((a, b) => a.year > b.year ? 1 : a.year < b.year ? -1 : 0);
                // eslint-disable-next-line array-callback-return
                response.obj?.annual_consumption?.map((ac) => {
                });
                setBuilding(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;

            if (statusCode === 404) setBuilding({ status: 404 });
            if (statusCode === 403) setBuilding({ status: 403 });

            addToast(t("error"), error, "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization, loadImage, addToast, t]);

    const deleteBuldingPhoto = useCallback(async(id) => {
        const MySwal = withReactContent(Swal)

        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/picture/${id}`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: 'DELETE'
        // })
        // .then((response) => {
        //     MySwal.fire({
        //         title: t('manageProfileImage'),
        //         text: response.status >= 200 && response.status <= 299 ? t('deletingProfileIMGSuccessfully') : t('deletingProfileIMGUnsuccessfully'),
        //         icon: response.status >= 200 && response.status <= 299 ? 'success' : 'error'
        //     })
        //     loadBuilding();
        // });
    
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_picture_destroy({
                building_uuid: _buildingGuid,
                picture_uuid: id
            });

            if (response.status >= 200 && response.status < 300) {
                MySwal.fire({
                    title: t("manageProfileImage"),
                    text: t("deletingProfileIMGSuccessfully"),
                    icon: "success",
                });
                loadBuilding();
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            MySwal.fire({
                title: t("manageProfileImage"),
                text: t("deletingProfileIMGUnsuccessfully"),
                icon: "error"
            });
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization, t, loadBuilding])

    const downscaleImage = (file) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            const reader = new FileReader();
        
            reader.onload = function (e) {
                image.onload = function () {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const maxSize = 3 * 1024 * 1024;
            
                    let width = image.width;
                    let height = image.height;
                    let scaleFactor = 1;
            
                    if (file.size > maxSize) {
                        scaleFactor = Math.sqrt(maxSize / file.size);
                        width = Math.floor(width * scaleFactor);
                        height = Math.floor(height * scaleFactor);
                    }
            
                    canvas.width = width;
                    canvas.height = height;
            
                    ctx.drawImage(image, 0, 0, width, height);
            
                    canvas.toBlob(blob => {
                        const downscaledFile = new File([blob], file.name, {
                            type: file.type,
                            lastModified: Date.now()
                        });
                        
                        const downscaledReader = new FileReader();
                        downscaledReader.onload = function (e) {
                            const downscaledImageDataUrl = e.target.result;
                            resolve({ file: downscaledFile, reader: downscaledImageDataUrl });
                        };
                        
                        downscaledReader.onerror = function (e) {
                            reject(new Error('Failed to read downscaled file.'));
                        };
                        
                        downscaledReader.readAsDataURL(downscaledFile);
                    }, file.type);
                };
        
                image.src = e.target.result;
            };
        
            reader.onerror = function (e) {
                reject(new Error('Failed to read file.'));
            };
        
            reader.readAsDataURL(file);
        });
    };
    
    const uploadFile = async(img) => {
        setImage(undefined);
        const maxSize = 6 * 1024 * 1024;
        if (img.size > maxSize) {
            return downscaleImage(img)
            .then((downscaledFile) => {
                let downscaledImg = {...img};
                downscaledImg = downscaledFile.file
                return uploadFile(downscaledImg);
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
        }
    
        var data = new FormData()
        data.append('file', img)
        data.append('target', 'mainImage')
        data.append('profile_picture', true)
    
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/picture/`, {
        //     body: data,
        //     method: "POST",
        //     headers: {
        //         'Authorization': login.Authorization,
        //     }
        // })
        // .then((response) => {
        //     if (response.status === 201) loadBuilding();
        // });
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Authorization"] = login.Authorization;

                req.body = data;
                return req;
            };

            const response = await client.apis["building"].building_picture_create({
                building_uuid: _buildingGuid,
            });

            if (response.status === 201) {
                loadBuilding();
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }

    useEffect(() => {
        loadBuilding();
    }, [loadBuilding])

    useEffect(() => {
        if (image === undefined || !image) return;
        const img = document.querySelector('.image__uploader__wrapper img')
        const svg = document.querySelector('.image__uploader__wrapper svg')
        img.classList.remove('visibility__hidden');
        svg.classList.add('visibility__hidden');
    }, [image])

    return <>
        <Card {...{ heading: t('buildingData'), mainFunctions }}>
            <div>
                <div className="py-3">
                    <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{t('uploadPhoto')}</Tooltip>} placement="top">
                        <div className='image__uploader__wrapper animation'>
                            <FontAwesomeIcon icon={faCameraRetro} />
                            <input type='file' accept='image/*' onChange={(e) => uploadFile(e.target.files[0])} />
                            {image && <img src={image} alt='' />}

                            <div className='loader__absolute'>
                                {image === undefined && <Loader />}
                            </div>
                        </div>
                    </OverlayTrigger>
                </div>

                {data.map(d => <div key={`building-data-key-${Math.random()}`} className="d-flex justify-content-between py-3">
                    <div className="d-flex align-items-center">
                        <div className="svg__icon">
                            <FontAwesomeIcon icon={d.icon} size='lg' />
                        </div>
                        <p className="m-0 ms-3">{d.name}</p>
                    </div>
                    <p className="m-0 ms-3">{d.value}</p>
                </div>)}
            </div>
        </Card>

        <ContentModal {...{ show: showContent, onHide: () => setShowContent(false), title: t('additionalInformations'), content: <BuildingAdditionalInfos {...{ building }} />, size: 'lg' }} />
        <SecurityActionModal {...{show: showDBPM, onHide: () => setShowDBPM(false), title: t('deleteBuildingsImageProfile'), question: t('deleteBuildingsImageProfileQuestion'), action: () => deleteBuldingPhoto(building.picture)}} />
    </>
}

export default BuildingProfile;