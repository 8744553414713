import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../Context';
import { useTranslation } from 'react-i18next';

import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import * as am5xy from "@amcharts/amcharts5/xy";

import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const LICENCE = process.env.REACT_APP_AMCHART5;

const LineChart = ({data, counterID}) => {
    const { login } = useAuth();
    const { t } = useTranslation();
    const [valuePercentTotal, setValuePercentTotal] = useState(false)
    const [heatRules, setHeatRules] = useState(false)

    const [loading, setLoading] = useState(true)
    const rootRef = useRef(null);
    const seriesRef = useRef(null);

    let firstColor = login.settings.theme_code === 'dark' ? am5.color(0xf3f3f3) : am5.color(0x000000);
    let secondColor = login.settings.theme_code === 'dark' ? am5.color(0xffd981) : am5.color(0x072c50);

    useEffect(() => {
        if (data) {
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, [data])

    // function createChart(div, rootRef, seriesRef, data) {
    useEffect(() => {
        am5.addLicense(LICENCE)

        if (document.getElementById(`chartdiv-${counterID}`) === null) return

        let root = am5.Root.new(`chartdiv-${counterID}`);
        seriesRef.current = [];
        root.interfaceColors.set("grid", am5.color(firstColor));

        // Set themes
        root.setThemes([am5themes_Animated.new(root)]);

        // Create chart
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX:true,
            paddingLeft: 0
        }));

        // Hourglass
        let indicator = root.container.children.push(am5.Container.new(root, {
            width: am5.p100,
            height: am5.p100,
            layer: 1000,
            background: am5.Rectangle.new(root, {
                fillOpacity: 0.7
            })
        }));

        indicator.children.push(am5.Label.new(root, {
            text: "Loading...",
            fill: secondColor,
            fontSize: 25,
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50
        }));

        let hourglass = indicator.children.push(am5.Graphics.new(root, {
            width: 32,
            height: 32,
            fill: secondColor,
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            dy: -45,
            svgPath: "M12 5v10l9 9-9 9v10h24V33l-9-9 9-9V5H12zm20 29v5H16v-5l8-8 8 8zm-8-12-8-8V9h16v5l-8 8z"
        }));

        let hourglassanimation = hourglass.animate({
            key: "rotation",
            to: 180,
            loops: Infinity,
            duration: 2000,
            easing: am5.ease.inOut(am5.ease.cubic)
        });

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        behavior: "none"
        }));
        cursor.lineY.set("visible", false);

        // Create axes
        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0.2,
            baseInterval: {
                timeUnit: "day",
                count: 1
            },
            renderer: am5xy.AxisRendererX.new(root, {
                minorGridEnabled:true
            }),
            tooltip: am5.Tooltip.new(root, {}),
        }));

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
                pan:"zoom"
            }),
        }));

        let yRenderer = yAxis.get("renderer");
        yRenderer.labels.template.setAll({
            fill: am5.color(firstColor),
            fontSize: "1.5em",
        });

        let xRenderer = xAxis.get("renderer");
        xRenderer.labels.template.setAll({
            fill: am5.color(firstColor),
            fontSize: "1.5em",
        });

        yAxis.children.unshift(am5.Label.new(root, {
            text: t('consumption'),
            textAlign: 'center',
            fontSize: "1.25rem",
            y: am5.p50,
            rotation: -90,
            fill: am5.color(firstColor),
        }));

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        let series = chart.series.push(am5xy.LineSeries.new(root, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
                labelText: "{valueY}"
            }),
            stroke: am5.color(firstColor),
        }));

        // Add scrollbar
        // chart.set("scrollbarX", am5.Scrollbar.new(root, {
        //     orientation: "horizontal"
        // }));

        // Stacked Area
        series.fills.template.setAll({
            fillOpacity: 0.5,
            visible: true,
        });
        series.set("fill", am5.color(secondColor));

        // Make stuff animate on load
        series.appear(1000);
        chart.appear(1000, 100);
        
        // Set data
        series.data.setAll(data);

        if (loading) {
            hourglassanimation.play();
            indicator.show();
        } else {
            hourglassanimation.pause();
            indicator.hide();  
            series.data.setAll(data);
            seriesRef.current.push(series);

            // Make stuff animate on load
            seriesRef.current[0]?.appear(2500, 100);
            chart.appear(1000, 100);
        }
        return () => root.dispose();

    }, [loading, setLoading])

    // Effect for updating chart data
    useEffect(() => {
        if (!loading && data && seriesRef.current?.length > 0) {
            seriesRef.current[0]?.data.setAll(data);
        }
    }, [data, loading]);

    return (
        <div id={`chartdiv-${counterID}`} className="chart-div" />
    )
}

export default LineChart