import React, { useState, useEffect, useRef } from 'react'
import { Alert, Modal, Col, Row, Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

function SecurityActionModal({ show, onHide, title, question, action }) {
    const [a, setA] = useState();
    const [b, setB] = useState();
    const [op, setOp] = useState();
    const userResponseRef = useRef(null);
    const [alert, setAlert] = useState(false);
    const { t } = useTranslation();


    const mathematicalQuestion = () => {
        setA(Math.floor(Math.random() * 10) + 1);
        setB(Math.floor(Math.random() * 10) + 1);
        setOp(["*", "+", "-"][Math.floor(Math.random()*3)]);
    }

    const onClose = (e) => {
        e.preventDefault();
        // eslint-disable-next-line no-eval
        if (String(userResponseRef.current.value) === String(eval(a + op + b))) {
            action()
            setAlert(false)
            onHide()
        } else {
            mathematicalQuestion()
            setAlert(true)
        }
    }

    useEffect(() => {
        mathematicalQuestion();
    }, [show])

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby='contained-modal-title-vcenter'
            centered
            backdrop='static'
            className='top__most__modal'
            backdropClassName='top__most__backdrop'
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert className={alert ? 'd-block' : 'd-none'} variant='danger'>{t('incorrectSolution')}</Alert>

                <p>{question}</p>

                <Form onSubmit={(e) => onClose(e)}>
                    <Form.Group>
                        <Form.Label>{`${t('howMuch')} ${a} ${op} ${b}?`}</Form.Label>
                        <Form.Control type="text" ref={userResponseRef} />
                        <div className="form-text text-white">{t('solveEquation')}</div>
                    </Form.Group>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Row className='w-100'>
                    <Col sm={8}>
                        <Button variant='outline-primary' className='w-100' onClick={(e) => onClose(e)}>{t('yes')}</Button>
                    </Col>
                    <Col sm={4}>
                        <Button variant='secondary' className='w-100' onClick={onHide}>{t('no')}</Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default SecurityActionModal