import { connectionsColor } from "./utils";

class Component {
    constructor(id, name, img, part, type, x, y, width, height, rotate, canvas, ctx, connectors, canvasDraw, updateConnections, deleteComponent, setShow) {
        this.id = id;
        this.name = name;
        this.img = img;
        this.part = part;
        this.type = type;
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.rotate = rotate;
        this.canvas = canvas;
        this.ctx = ctx;
        this.connectors = this.calculateAbsoluteConnectors(connectors);
        this.canvasDraw = canvasDraw;
        this.updateConnections = updateConnections;
        this.deleteComponent = deleteComponent;
        this.setShow = setShow;

        this.onContextMenuHandler = this.onContextMenu.bind(this);
        this.addEventListeners();
        this.updateConnections();
    }

    calculateAbsoluteConnectors(connectors) {
        return connectors.map(connector => {
            const absoluteCoords = this.calculateAbsoluteCoordinates(connector.x, connector.y);
            return {
                ...connector,
                aX: absoluteCoords.x,
                bY: absoluteCoords.y,
                component: this,
                type: connector.type,
                io: connector.io,
                direction: this.calculateDirection(absoluteCoords.x, absoluteCoords.y)
            };
        });
    }

    calculateAbsoluteCoordinates(connectorX, connectorY) {
        const centerX = this.x + this.width / 2;
        const centerY = this.y + this.height / 2;
        const localX = connectorX - this.width / 2;
        const localY = connectorY - this.height / 2;

        const angle = this.rotate * Math.PI / 180;
        const cos = Math.cos(angle);
        const sin = Math.sin(angle);
        const rotatedX = localX * cos - localY * sin;
        const rotatedY = localX * sin + localY * cos;

        return {
            x: centerX + rotatedX,
            y: centerY + rotatedY
        };
    }

    getPointPosition(minDistance, topDistance, bottomDistance, leftDistance, rightDistance) {
        if ((minDistance === topDistance && this.rotate === 0) || (minDistance === leftDistance && this.rotate === 90) || (minDistance === bottomDistance && this.rotate === 180) || (minDistance === rightDistance && this.rotate === 270)) {
            return "top";
        } else if ((minDistance === bottomDistance && this.rotate === 0) || (minDistance === rightDistance && this.rotate === 90) || (minDistance === topDistance && this.rotate === 180) || (minDistance === leftDistance && this.rotate === 270)) {
            return "bottom";
        } else if ((minDistance === leftDistance && this.rotate === 0) || (minDistance === bottomDistance && this.rotate === 90) || (minDistance === rightDistance && this.rotate === 180) || (minDistance === topDistance && this.rotate === 270)) {
            return "left";
        } else if ((minDistance === rightDistance && this.rotate === 0) || (minDistance === topDistance && this.rotate === 90) || (minDistance === leftDistance && this.rotate === 180) || (minDistance === bottomDistance && this.rotate === 270)) {
            return "right";
        }
    }
    
    calculateDirection(px, py) {
        const cx = this.width / 2 + this.x;
        const cy = this.height / 2 + this.y;
        // Step 1: Translate the point relative to the center of the rectangle
        let translatedX = px - cx;
        let translatedY = py - cy;
    
        // Step 2: Rotate the point by the negative of the rectangle's rotation angle
        const angleRad = -this.rotate * Math.PI / 180;
        let cosTheta = Math.cos(angleRad);
        let sinTheta = Math.sin(angleRad);
    
        let localX = translatedX * cosTheta - translatedY * sinTheta;
        let localY = translatedX * sinTheta + translatedY * cosTheta;
    
        // Step 3: Determine if the point is on the top, bottom, left, or right
        let halfWidth = this.width / 2;
        let halfHeight = this.height / 2;
    
        if (Math.abs(localX) <= halfWidth && Math.abs(localY) <= halfHeight) {
            let topDistance = Math.abs(localY - (-halfHeight));
            let bottomDistance = Math.abs(localY - halfHeight);
            let leftDistance = Math.abs(localX - (-halfWidth));
            let rightDistance = Math.abs(localX - halfWidth);
    
            // Determine which side is closest
            let minDistance = Math.min(topDistance, bottomDistance, leftDistance, rightDistance);
    
            return this.getPointPosition(minDistance, topDistance, bottomDistance, leftDistance, rightDistance)
        }
        return "outside";
    }

    updateConnectors() {
        this.connectors = this.calculateAbsoluteConnectors(this.connectors);
        this.updateConnections();
    }

    drawText(x, y, maxWidth) {
        this.ctx.font = '14px Arial';
        this.ctx.textAlign = 'center';
        this.ctx.textBaseline = 'top';
        this.ctx.fillStyle = 'black';
    
        let text = this.name;
        let textWidth = this.ctx.measureText(text).width;
    
        if (textWidth > maxWidth) {
            while (textWidth > maxWidth && text.length > 0) {
                text = text.slice(0, -1);
                textWidth = this.ctx.measureText(text + '...').width;
            }
            text = text + '...';
        }

        let startX = this.x + maxWidth / 2;

        this.ctx.fillText(text, startX, y + 20);
    }

    draw() {
        // Draw Component
        this.ctx.save();

        const centerX = this.x + this.img.width / 2;
        const centerY = this.y + this.img.height / 2;

        this.ctx.translate(centerX, centerY);
        this.ctx.rotate(this.rotate * Math.PI / 180);
        this.ctx.drawImage(this.img, -this.img.width / 2, -this.img.height / 2);
        this.ctx.restore();

        const maxWidth = this.width;
        const x = this.x + this.width / 2;
        const y = this.y + this.height;
        this.drawText(x, y, maxWidth);

        // Draw connectors
        this.connectors.forEach(port => {
            console.log(port.type)
            this.ctx.beginPath();
            this.ctx.arc(port.aX, port.bY, 3, 0, 2 * Math.PI, false);
            this.ctx.fillStyle = connectionsColor(port.type);
            this.ctx.fill();
        });
    }

    addEventListeners() {
        this.ctx.canvas.addEventListener('contextmenu', this.onContextMenuHandler);
    }

    removeEventListeners() {
        this.ctx.canvas.removeEventListener('contextmenu', this.onContextMenuHandler);
    }

    getWindowToCanvas(e) { 
        e = e || window.event;
        var target = e.target || e.srcElement,
            style = target.currentStyle || window.getComputedStyle(target, null),
            borderLeftWidth = parseInt(style["borderLeftWidth"], 10),
            borderTopWidth = parseInt(style["borderTopWidth"], 10),
            rect = target.getBoundingClientRect(),
            offsetX = e.clientX - borderLeftWidth - rect.left,
            offsetY = e.clientY - borderTopWidth - rect.top;
        let x = (offsetX * target.width) / target.clientWidth;
        let y = (offsetY * target.height) / target.clientHeight;

        var transform = this.ctx.getTransform();
        const invMat = transform.invertSelf();
        return {
            x: x * invMat.a + y * invMat.c + invMat.e,
            y: x * invMat.b + y * invMat.d + invMat.f
        };
    }

    isWithinBounds(mouseX, mouseY) {
        const cx = this.x + this.width / 2;
        const cy = this.y + this.height / 2;
        const translatedX = mouseX - cx;
        const translatedY = mouseY - cy;
        
        const angle = -this.rotate * Math.PI / 180;
        const cos = Math.cos(angle);
        const sin = Math.sin(angle);
        const localX = translatedX * cos - translatedY * sin;
        const localY = translatedX * sin + translatedY * cos;
        
        return (
            localX >= -this.width / 2 && localX <= this.width / 2 &&
            localY >= -this.height / 2 && localY <= this.height / 2
        );
    }

    mouseOffset(e) {
        return {
            x: e.clientX - this.canvas.getBoundingClientRect().x,
            y: e.clientY - this.canvas.getBoundingClientRect().y
        };
    }

    onContextMenu(e) {
        e.preventDefault();
        e.stopPropagation();

        const { x, y } = this.getWindowToCanvas(e)

        if (!this.isWithinBounds(x, y)) return
        const contextMenuPosition = this.mouseOffset(e);
        const contextMenu = document.createElement("div");
        contextMenu.id = "context-menu";
        contextMenu.style.left = `${contextMenuPosition.x}px`;
        contextMenu.style.top  = `${contextMenuPosition.y}px`;
        
        const ul = document.createElement("ul");
        // Edit [OPEN MODAL]
        const edit = document.createElement("li");
        edit.textContent = "Bearbeiten";

        edit.addEventListener("click", () => {
            document.getElementById("context-menu").remove();
            if (this.id) this.setShow(this)
        });

        // Rotate
        const rotate = document.createElement("li");
        rotate.textContent = "Drehen";

        rotate.addEventListener("click", () => {
            document.getElementById("context-menu").remove();
            this.rotate = ((this.rotate || 0) + 90) % 360;
            this.updateConnectors();
            this.canvasDraw();
        });

        const deleteComponent = document.createElement("li");
        deleteComponent.textContent = "Löschen";

        deleteComponent.addEventListener("click", () => {
            document.getElementById("context-menu")?.remove();
            this.deleteComponent(this);
            this.canvasDraw();
        });

        ul.appendChild(edit);
        ul.appendChild(rotate);
        ul.appendChild(deleteComponent);
        contextMenu.appendChild(ul);

        this.canvas.parentNode.appendChild(contextMenu);
        contextMenu.style.display = "block";
    }
}

export default Component