import { useEffect } from 'react';
import { Pagination as Page } from 'react-bootstrap';
import { faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PaginationItems = ({ onPageChange, pageCount, currentPage }) => {

    useEffect(() => {
        console.log("rerender")
    }, [])

    let items = [];
    let start = (currentPage - 5) > 0 ? currentPage - 5 : 1;
    let diff = -(currentPage - 5 - start + 1);
    let end = (currentPage + 6 + diff) < pageCount ? currentPage + 6 + diff : pageCount;

    for (let number = start; number <= end; number++) {
        const isItemActive = currentPage === number - 1;
        if (number === start && start > 1) {
            items.push(<Page.Ellipsis key={`key-pagination-${Math.random()}`}></Page.Ellipsis>)
        } else if (number === end && end < pageCount - 1) {
            items.push(<Page.Ellipsis key={`key-pagination-${Math.random()}`}></Page.Ellipsis>)
        } else {
            items.push(<Page.Item key={`key-pagination-${Math.random()}`} active={isItemActive} onClick={() => onPageChange(Math.max(number - 1))}>{number}</Page.Item>)
        }
    };
    return items;
}

export const Pagination = ({ onPageChange, pageCount, currentPage }) => {

    return <div className="d-flex justify-content-center w-100">
        <Page size="sm" className="mt-2 mb-0 flex-wrap">
            <Page.Prev onClick={() => onPageChange(0)} className={`${currentPage == 0 && 'disabled'}`}>
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </Page.Prev>
            <Page.Prev onClick={() => onPageChange(Math.max(0, currentPage - 1))}  className={`${currentPage == 0 && 'disabled'}`}>
                <FontAwesomeIcon icon={faAngleLeft} />
            </Page.Prev>
            <PaginationItems {...{ onPageChange, pageCount, currentPage }} />
            <Page.Next onClick={() => onPageChange(Math.min(pageCount - 1, currentPage + 1))} className={`${currentPage == pageCount - 1 && 'disabled'}`}>
                <FontAwesomeIcon icon={faAngleRight} />
            </Page.Next>
            <Page.Next onClick={() => onPageChange(pageCount - 1)} className={`${currentPage == pageCount - 1 && 'disabled'}`}>
                <FontAwesomeIcon icon={faAngleDoubleRight} />
            </Page.Next>
        </Page>
    </div>;
};

export default Pagination;