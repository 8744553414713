import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { useSwagger } from "../context/SwaggerContext";


export const AddEditRoom = ({ floor, unit, onClose, ...props }) => {
    const { login } = useAuth();
    const { addToast } = useToast();
    const _buildingGuid = useParams()['buildingGuid'];
    const { t } = useTranslation();

    let room_types = [
        [t('corridor'), 18],
        [t('livingRoom'), 20],
        [t('kitchen'), 20],
        [t('bath'), 24],
        [t('bedroom'), 17],
    ]

    const [room, setRoom] = useState(props.room || { floor: floor?.id, utilization_unit: unit?.id, room_temperature: 20, area: 5, name: room_types[0][0], air_exchange_rate: 0.5, height: floor?.height || unit?.entrance_level?.height || 2.7 });
    const [floors, setFloors] = useState(undefined);
    const [units, setUnits] = useState(undefined);
    // const [filteredUnits, setFilteredUnits] = useState(undefined)
    const [sortedUnits, setSortedUnits] = useState(undefined)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const client = useSwagger();

    const sortUnitsByFloor = useCallback(() => {
        // if(!floor) return
        if (!units) return


        let sorted = units.sort((a, b) => {
            const floorA = a.entrance_level ? a.entrance_level.floor : Infinity;
            const floorB = b.entrance_level ? b.entrance_level.floor : Infinity;

            return floorA - floorB;
        });

        return setSortedUnits(sorted);
    });

    // const filterUnits = useCallback(() => {
    //     if(!floor) return

    //     const url = `https://tech.sigmaheat.de/building/${_buildingGuid}/unit/?level=${floor.id}`;
    //     fetch(url, {
    //         headers: {
    //             Authorization: login.Authorization,
    //             "Content-Type": "application/json"
    //         }
    //     }) 
    //     .then((response) => {
    //         if (!response.ok) throw new Error(t('networkError'));
    //         if (response.status === 204) return [];
    //         return response.json();
    //     })
    //     .then((data) => {
    //         setFilteredUnits(data)
    //         });
    // })

    const loadFloors = useCallback(async () => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/floor/`, {
        //     headers: {
        //         Authorization: login.Authorization,
        //         "Content-Type": "application/json"
        //     }
        // })
        //     .then((response) => {
        //         if (!response.ok) throw new Error(t('networkError'));
        //         if (response.status === 204) return [];
        //         return response.json();
        //     })
        //     .then((data) => {
        //         let a = data.sort((a, b) => {
        //             if (a.floor < b.floor) return -1;
        //             else return 1;
        //         });

        //         // !unit && setRoom((prevRoom) => ({
        //         //     ...prevRoom,
        //         //     floor: a[0].id,
        //         // }));

        //         setRoom((prevRoom) => ({
        //             ...prevRoom,
        //             floor: a[0].id,
        //         }));

        //         setFloors(a);
        //     })

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].getFloors({ building_uuid: _buildingGuid });

            if (response.status >= 200 && response.status < 300) {
                if (response.status === 204) return
                let a = response.obj.sort((a, b) => {
                    return a.level < b.level ? -1 : 1
                });

                if (room.id === undefined ) {
                    setRoom((prevRoom) => ({
                        ...prevRoom,
                        floor: unit ? unit?.entrance_level?.id : a[0].id,
                    }));
                }
                setFloors(a);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, _buildingGuid, t])

    const loadUnits = useCallback(async () => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/unit/`, {
        //     headers: {
        //         Authorization: login.Authorization,
        //         "Content-Type": "application/json"
        //     }
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t('networkError'));
        //     if (response.status === 204) return [];
        //     return response.json();
        // })
        // .then((data) => {
        //     let a = data.sort((a, b) => {
        //         if (a.floor < b.floor) return -1;
        //         else return 1;
        //     });

        //     setRoom((prevRoom) => ({
        //         ...prevRoom,
        //         unit: a[0]?.id,
        //     }));
        //     setUnits(a);
        // })

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].getUnit({ building_uuid: _buildingGuid });

            if (response.status >= 200 && response.status < 300) {
                if (response.status === 204) return

                let a = response.obj.sort((a, b) => {
                    return a.floor < b.floor ? -1 : 1
                });

                setRoom((prevRoom) => ({
                    ...prevRoom,
                    unit: a[0]?.id,
                }));

                setUnits(a);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login.Authorization, _buildingGuid, t])

    const onSubmit = useCallback(async (e) => {

        e.preventDefault();
        let newRoom = {
            utilization_unit: (typeof room.utilization_unit === 'object' && room.utilization_unit !== null) ? room.utilization_unit?.id : room.utilization_unit,
            floor: typeof room.floor === 'object' ? room.floor.id : room.floor,
            name: room.name,
            area: room.area,
            room_temperature: room.room_temperature,
            air_exchange_rate: room.air_exchange_rate,
            height: room.height
        }

        if (room.utilization_unit === null) {
            delete newRoom.utilization_unit
        }

        if (room?.id)
            newRoom['id'] = room.id

        // let url = `https://tech.sigmaheat.de/building/${_buildingGuid}${room.id === undefined ? (floor ? `/floor/${floor.id}/room` : `/unit/${unit?.id}/room`) : `/room/${room.id}/`}`;
        // fetch(url, {
        //     headers: {
        //         "Authorization": login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: (room.id !== undefined) ? "PATCH" : "POST",
        //     body: JSON.stringify(room)
        // })
        //     .then((response) => {
        //         if (response.status === 400) throw new Error(t('errorRoomName'))
        //         if (!response.ok) throw new Error(t('responseError'));
        //         addToast(t('room'), room.id ? t('roomPatch') : t('roomPost'), "success");
        //         onClose();
        //     })
        //     .catch(error => addToast(t("error"), error.message, "error"))

        if (!client || isSubmitting) return;

        setIsSubmitting(true);

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(newRoom);
                return req;
            };

            const response = newRoom.id === undefined ? (floor ? await client.apis["building"].building_floor_room_create({ building_uuid: _buildingGuid, floor_uuid: floor.id }) : await client.apis["building"].building_unit_room_create({ building_uuid: _buildingGuid, unit_uuid: unit?.id })) : await client.apis["building"].building_room_partial_update({ building_uuid: _buildingGuid, room_uuid: room?.id })

            if (response.status >= 200 && response.status < 300) {
                addToast(t('room'), newRoom.id ? t('roomPatch') : t('roomPost'), "success");
                onClose();
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;
            addToast(t("error"), t(statusCode === 400 ? 'errorRoomName' : 'responseError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            setIsSubmitting(false);
        }
    }, [client, login.Authorization, room, unit?.id, _buildingGuid, addToast, onClose, floor, t])

    const editHandler = useCallback((e) => {
        const { id, value } = e.target;

        // if (id == "floor") {
        //     console.log(value)
        //     console.log(id)
        // }

        setRoom((prevRoom) => ({
            ...prevRoom,
            [id]: value,
        }));
    }, []);

    useEffect(() => {
        if (floor === undefined || room.id) loadFloors();
    }, [loadFloors])

    useEffect(() => {
        if (unit === undefined || room.id) loadUnits();
    }, [loadUnits])

    // useEffect(() => {
    //     console.log("Room:", room)
    // }, [room])

    // useEffect(() => {
    //     if (unit) {
    //         setRoom(prevRoom => ({
    //             ...prevRoom,
    //             'floor': unit.entrance_level.id
    //         }));
    //     }
    // }, [unit]);

    // useEffect(() => {
    //     if (floor) {
    //         filterUnits()
    //     }
    // }, [filterUnits, floor]);

    useEffect(() => {
        if (floor) sortUnitsByFloor()
    }, [floor, sortUnitsByFloor, units]);

    let get_room_height = (room, floor, unit) => {
        if (room.height !== undefined && room.height !== null) {
            return Number(room['height'])
        }

        if (floor == undefined && unit != undefined) {
            return Number(unit.entrance_level.height)
        }

        if (floor != undefined) {
            return Number(floor.height)
        }

        return 2.7

    }

    let height = get_room_height(room, floor, unit);

    return <Form onSubmit={(e) => onSubmit(e)}>
        {(floor || room.id) && <Form.Group className="mb-3">
            <Form.Label>{t("utilizationKey")}</Form.Label>
            <Form.Select id="utilization_unit" className="w-100" aria-label="" value={typeof room.utilization_unit === 'string' ? room.utilization_unit : room.utilization_unit?.id} onChange={(e) => editHandler(e)}>
                {!room?.utilization_unit && <option key={`unit-key-undefined`} value={undefined}>{t('belongsToNoAppartment')}</option>}
                {/* {units?.map(u => <option key={`unit-key-${u.id}`} value={u.id}>{u.name} ({t('entranceLevel')}: {u.entrance_level.floor} - {u.entrance_level.name})</option>)} */}
                {sortedUnits?.map(u => <option key={`unit-key-${u.id}`} value={u.id}>{u.name} ({t('entranceLevel')}: {u.entrance_level?.floor} - {u.entrance_level?.name})</option>)}
                {/* {filteredUnits?.map(u => <option key={`unit-key-${u.id}`} value={u.id}>{u.name} ({t('entranceLevel')}: {u.entrance_level.floor} - {u.entrance_level.name})</option>)} */}
            </Form.Select>
        </Form.Group>}

        {(unit || room.id) && <Form.Group className="mb-3">
            <Form.Label>{t("level")}</Form.Label>
            <Form.Select className="w-100" aria-label="" id="floor" value={typeof room.floor === 'string' ? room.floor : room.floor?.id} onChange={(e) => editHandler(e)}>
                {floors?.map(f => <option key={`floor-key-${f.id}`} value={f.id}>{f.level} - {f.name}</option>)}
            </Form.Select>
        </Form.Group>}

        {/* {unit && <Form.Group className="mb-3">
            <Form.Label>{t("floor")}</Form.Label>
            <Form.Control id="floor" type="text" value={unit.entrance_level.name} disabled />
        </Form.Group>} */}

        {('id' in room) === false && <Form.Group className="mb-3">
            <Form.Label>{t("roomTypePreselection")}</Form.Label>
            <Form.Select className="w-100" aria-label="" value={room.level} onChange={(e) => { let a = { ...room }; a["room_temperature"] = room_types[e.target.value][1]; a["name"] = room_types[e.target.value][0]; setRoom({ ...a }); }}>
                {room_types?.map((t, index) => <option key={`room-key-${index}`} value={index}>{t[0]}</option>)}
            </Form.Select>
        </Form.Group>}

        <Form.Group className="mb-3">
            <Form.Label>{t("name")}</Form.Label>
            <Form.Control id="name" required type="text" value={room['name'] || ''} onChange={(e) => editHandler(e)} />
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>{t("area") + " (m²)"}</Form.Label>
            <Form.Control id="area" required type="number" value={room['area'] || ''} step={0.01} onChange={(e) => editHandler(e)} />
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>{t("roomTemperature")}</Form.Label>
            <Form.Control id="room_temperature" required type="number" value={room['room_temperature'] || ''} onChange={(e) => editHandler(e)} />
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>{t("airExchangeRate")+" (1/h)"}</Form.Label>
            <Form.Control id="air_exchange_rate" required type="number" value={room['air_exchange_rate'] || ''} onChange={(e) => editHandler(e)} />
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>{t("height")} (m)</Form.Label>
            <Form.Control id="height" step={0.1} required type="number" value={height} onChange={(e) => editHandler(e)} />
        </Form.Group>

        <Button variant="outline-primary" disabled={isSubmitting} className="w-100" type="submit">{(room.id === undefined) ? t("add") : t('edit')}</Button>
    </Form>
}

export default AddEditRoom;