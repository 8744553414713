import { useState, useEffect, useCallback, useMemo, useRef, forwardRef } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { Button, Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import Card from "../components/Card";
import * as XLSX from 'xlsx/xlsx.mjs'
import ContentModal from "../components/ContentModal"
import NotificationModal from "../components/NotificationModal"
import TableStructure from "./TableStructure";
import { PricingTableWrapper } from "../components/PricingTable";
import cloneDeep from 'lodash/cloneDeep';
import ListAllOrganisations from "../organisations/ListAllOrganisations";
import Nav from "react-bootstrap/Nav";
import { useSwagger } from "../context/SwaggerContext";


const Organisations = ({ setPage }) => {
    return <>
        {/* <ListAllOrganisations /> */}
        <ListAllOrganisations {...{ setPage }} />
        {/* <Button variant="outline-primary" className="d-flex justify-content-around w-100 mt-3" onClick={() => setPage(2)}>
            <FontAwesomeIcon icon={faAdd} />
        </Button> */}
    </>
}

const OrganisationsDetails = ({ name, setName, street, setStreet, streetNo, setStreetNo, zip, setZip, city, setCity, terms, setTerms, pA, setPA, pp, setPP, rights, setRights, mother }) => {
    const { t } = useTranslation();

    return <>
        <Form.Group className='mb-3' controlId='inputName'>
            <Form.Label>{t('name')}</Form.Label>
            <Form.Control type='text' required value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Group>
        <Row>
            <Col>
                <Form.Group className='mb-3' controlId='inputStreet'>
                    <Form.Label>{t('street')}</Form.Label>
                    <Form.Control type='text' required value={street} onChange={(e) => setStreet(e.target.value)} />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className='mb-3' controlId='inputStreetNo'>
                    <Form.Label>{t('housenumber')}</Form.Label>
                    <Form.Control type='text' required value={streetNo} onChange={(e) => setStreetNo(e.target.value)} />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Group className='mb-3' controlId='inputZIP'>
                    <Form.Label>{t('zip')}</Form.Label>
                    <Form.Control type='text' required value={zip} onChange={(e) => setZip(e.target.value)} />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className='mb-3' controlId='inputCity'>
                    <Form.Label>{t('city')}</Form.Label>
                    <Form.Control type='text' required value={city} onChange={(e) => setCity(e.target.value)} />
                </Form.Group>
            </Col>
        </Row>

        <Form.Check
            className="mb-3"
            type="checkbox"
            id="termsandconditions"
            checked={terms}
            onChange={(e) => setTerms(e.target.checked)}
            label={
                <Trans i18nKey='organisationTermsnConditions'>
                    <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-decoration-underline" style={{ color: "inherit" }} />
                </Trans>
            }
        />

        <Form.Check
            className="mb-3"
            type="checkbox"
            id="processingAgreement"
            checked={pA}
            onChange={(e) => setPA(e.target.checked)}
            label={
                <Trans i18nKey='organisationProcessingAgg'>
                    <a href="https://sigmaheat.de/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-decoration-underline" style={{ color: "inherit" }} />
                </Trans>
            }
        />

        <Form.Check
            className="mb-3"
            type="checkbox"

            id="privacyPolicy"
            checked={pp}
            onChange={(e) => setPP(e.target.checked)}
            label={
                <Trans i18nKey='organisationPP'>
                    <a href="https://sigmaheat.de/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-decoration-underline" style={{ color: "inherit" }} />
                </Trans>
            }
        />

        {mother && (
          <Form.Check
            className="mb-3"
            type="checkbox"
            id="hasRights"
            checked={rights}
            onChange={(e) => setRights(e.target.checked)}
            label={t("organisationShouldHaveRights")}
          />
        )}
    </>
}

export const UploadEditExcel = forwardRef(
  (
    {
      excelData,
      setExcelData,
      data,
      setData,
      setDataBuilding,
      dataInit,
      building,
      buildingHandler
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [modal, setModal] = useState(false);

    const mandatoryFields = useMemo(
      () => [
        {
          lookup: ["Name","Bezeichnung"],
          key: "name",
          keyDict: t("name"),
          mandatory: true,
        },
        {
          lookup: ["Straße", "Strasse", "Street"],
          key: "street",
          keyDict: t("street"),
          mandatory: true,
        },
        {
          lookup: ["Hausnummer", "House Number", "Nummer","Nr"],
          key: "no",
          keyDict: t("housenumber"),
          mandatory: true,
        },
        {
          lookup: ["ZIP", "PLZ", "Postleitzahl", "Post Code", "Postal Code"],
          key: "zip",
          keyDict: t("zip"),
          mandatory: true,
        },
        {
          lookup: ["Stadt", "Ort", "City", "Place"],
          key: "city",
          keyDict: t("city"),
          mandatory: true,
        },
        {
          lookup: ["Baujahr", "Construction Year"],
          key: "construction_year",
          keyDict: t("constructionYear"),
          mandatory: true,
        },
        {
          lookup: ["Address", "Adresse"],
          key: "address",
          keyDict: t("address"),
          mandatory: false,
        },
      ],
      [t]
    );

    const extractAddress = (address) => {
      const addressRegex =
        /^(.+?)\s+(\d+[a-zA-Z]?)?(?:,\s+(\d{5}))?(?:\s+(.+?))?(?:\s+([^\n]+))?$/;
      const match = address.match(addressRegex);
      return match ? match : undefined;
    };

    const processString = (input) => {
      if (input == null) return "";
      const withoutSpecialChars = input.replace(/[^\w\s]/gi, "");
      const lowercase = withoutSpecialChars.toLowerCase();
      const withoutSpaces = lowercase.replace(/\s/g, "");
      return withoutSpaces;
    };

    const getIndex = (name) => {
      const lowercaseName = (typeof name === 'string')?name?.toLowerCase():name;
      const index = mandatoryFields.findIndex((item) =>
        item.lookup.map((item) => item.toLowerCase()).includes(lowercaseName)
      );
      return index;
    };

    const removeEmptyElements = (arrays) => {
      const emptyIndices = arrays[0].reduce((indices, element, index) => {
        if (element === "" || element === undefined || element === null)
          indices.push(index);
        return indices;
      }, []);

      const tmpArray = arrays.map((array) =>
        array.filter((_, index) => !emptyIndices.includes(index))
      );

      const doubleCols = tmpArray[0]
        .map((item, i) => {
          if (getIndex(item) !== -1) return i;
        })
        .filter((index) => index !== undefined);

      const newArrayofArrays = tmpArray.map((array) =>
        array.filter((_, index) => !doubleCols.includes(index))
      );
      return newArrayofArrays;
    };

    const handleFileChange = useCallback(
      (e) => {
        const file = e.target.files[0];

        if (!file) return;

        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
          setData(dataInit);
          setExcelData(dataInit);

          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const tmpEXCELDATA = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            defval: null,
          });

          const nonUnique = tmpEXCELDATA[0].filter(
            (item, index) =>
              item !== null &&
              tmpEXCELDATA[0].indexOf(item) !== index &&
              tmpEXCELDATA[0].lastIndexOf(item) === index
          );

          if (Boolean(nonUnique.length)) {
            setShow(nonUnique);
            return;
          }

          let initialDATA = Array.from(
            Array(tmpEXCELDATA.slice(1).length),
            () =>
              new Array(
                mandatoryFields.filter(
                  (obj) => obj["mandatory"] === true
                ).length
              ).fill("")
          );

          tmpEXCELDATA[0].forEach((col, index) => {
            console.log("typeof col");
            console.log(typeof col);
            mandatoryFields.forEach((mF, i) => {
              if (
                mF.lookup
                  .map((m) => m?.toLowerCase())
                  .includes((typeof col === 'string')?col?.toLowerCase():col)
              ) {
                initialDATA.forEach((elem, id) => {
                  if (
                    col?.toLowerCase() === "address" ||
                    col?.toLowerCase() === "adresse"
                  ) {
                    let address = extractAddress(
                      tmpEXCELDATA.slice(1)[id][index]
                    );
                    if (address) {
                      if (elem[1] === "") elem[1] = address[1]?.toString();
                      if (elem[2] === "") elem[2] = address[2]?.toString();
                      if (elem[3] === "") elem[3] = address[3]?.toString();
                      if (elem[4] === "") elem[4] = address[4]?.toString();
                    }
                  } else {
                    elem[i] = tmpEXCELDATA.slice(1)[id][index]?.toString();
                  }
                });
              }
            });
          });

          let filteredArray = removeEmptyElements(tmpEXCELDATA);
          let cols = filteredArray[0].map((m, i) => {
            if (m !== null) return { key: i, name: m };
          });

          let filteredData = [...filteredArray.splice(1)];

          let ultimateData = [...initialDATA];
          initialDATA.forEach((d, i) => {
            d.push(...filteredData[i]);
          });

          let mandatoryCols = JSON.parse(
            JSON.stringify({ ...dataInit.slice(0, 1) })
          );
          let ultimateCols = [...mandatoryCols[0], ...cols];
          let ultimateExcel = [ultimateCols, ...ultimateData];

          setData(ultimateExcel);
          setExcelData([cols, ...filteredArray.splice(1)]);
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
      },
      [data, excelData, dataInit]
    );

    const addRow = useCallback(() => {
      let tmpData = [...data];
      let tmpExcel = [...excelData];

      tmpData.push(Array(tmpData[0].length).fill(""));
      tmpExcel.push(Array(tmpExcel[0].length).fill(""));

      setData(tmpData);
      setExcelData(tmpExcel);
    }, [excelData, data, setData, setExcelData]);

    const AddColumn = () => {
      const compareObjects = (obj1, obj2) => {
        return obj1.key === obj2.key;
      };

      const [select, setSelect] = useState(
        excelData[0].filter(
          (obj2) => !data[0].some((obj1) => compareObjects(obj2, obj1))
        )?.[0]?.key || excelData[0].length
      );
      const [value, setValue] = useState("");

      const addColumn = useCallback(() => {
        let tmpData = JSON.parse(JSON.stringify(data));
        let tmpExcel = JSON.parse(JSON.stringify(excelData));

        if (select !== excelData[0].length) {
          let header = tmpExcel[0].find((obj) => obj.key === select);
          tmpData[0].push(header);
          tmpData.forEach((d, i) => {
            if (i !== 0) d.push(tmpExcel[i][select]);
          });
          setData(tmpData);
        } else {
          if (value === "") return;

          const header = {
            name: value,
            key: processString(value),
          };

          tmpData[0].push(header);
          tmpData.forEach((d, i) => {
            if (i !== 0) d.push("");
          });

          tmpExcel[0].push(header);
          tmpExcel.forEach((d, i) => {
            if (i !== 0) d.push("");
          });

          setData(tmpData);
          setExcelData(tmpExcel);
        }
        setShow(false);
      }, [select, value]);

      return (
        <>
          <Form.Group className="mb-3" controlId="selectColumn">
            <Form.Label>{t("selectFormExcelFile")}</Form.Label>
            <Form.Select
              value={select}
              onChange={(e) => setSelect(parseInt(e.target.value))}
            >
              {excelData[0]
                .filter(
                  (obj2) => !data[0].some((obj1) => compareObjects(obj2, obj1))
                )
                .map((col) => (
                  <option key={`option-${col.key}`} value={col.key}>
                    {col.name}
                  </option>
                ))}
              <option value={excelData[0].length}>{t("addNewColumn")}</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="writeColumn">
            <Form.Label>{t("columnName")}</Form.Label>
            <Form.Control
              required
              type="text"
              disabled={select !== excelData[0].length}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </Form.Group>

          <div className="d-flex justify-content-end">
            <Button variant="outline-primary" onClick={() => addColumn()}>
              {t("add")}
            </Button>
          </div>
        </>
      );
    };

    return (
      <>
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link
              className={modal ? "table-nav-link" : "table-nav-link-active"}
              onClick={() => {
                setDataBuilding(false);
                setModal(false);
              }}
            >
              {t("singleBuilding")}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={modal ? "table-nav-link-active" : "table-nav-link"}
              onClick={() => {
                setDataBuilding(true);
                setModal(true);
              }}
            >
              {t("buildinglist")}
            </Nav.Link>
          </Nav.Item>
        </Nav>

        {modal ? (
          <>
            <div>
              <p className="">{t("downloadTemplateAs")}</p>
              <div className="d-flex justify-content-between mb-3">
                <Button variant="outline-primary">.xlsx</Button>
                <Button variant="outline-primary">.ods</Button>
              </div>

              <Form.Group className="mb-3" controlId="excelFile">
                <Form.Control
                  type="file"
                  onChange={(e) => handleFileChange(e)}
                />
              </Form.Group>

              <div className="d-flex justify-content-end mb-3">
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  overlay={<Tooltip>{t("addColumn")}</Tooltip>}
                >
                  <Button
                    variant="outline-primary"
                    className="p-10"
                    onClick={() => setShow("column")}
                  >
                    <FontAwesomeIcon icon={faAdd} size="sm" />
                  </Button>
                </OverlayTrigger>
              </div>
            </div>

            <TableStructure
              ref={ref}
              {...{
                data,
                setData,
                excelData,
                setExcelData,
                mandatoryFields,
              }}
            />

            <Button
              variant="outline-primary"
              className="w-100 my-3"
              onClick={() => addRow()}
            >
              {t("addRow")}
            </Button>
          </>
        ) : (
          <>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="text"
                required
                value={building?.name || ""}
                onChange={(e) => buildingHandler(e)}
              />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="street">
                  <Form.Label>{t("street")}</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={building?.street || ""}
                    onChange={(e) => buildingHandler(e)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="no">
                  <Form.Label>{t("housenumber")}</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={building?.no || ""}
                    onChange={(e) => buildingHandler(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="zip">
                  <Form.Label>{t("zip")}</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={building?.zip || ""}
                    onChange={(e) => buildingHandler(e)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="city">
                  <Form.Label>{t("city")}</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={building?.city || ""}
                    onChange={(e) => buildingHandler(e)}
                  />
                </Form.Group>
              </Col>
            <Col>
                <Form.Group className="mb-3" controlId="construction_year">
                  <Form.Label>{t("constructionYear")}</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={building?.construction_year || ""}
                    onChange={(e) => buildingHandler(e)}
                  />
                </Form.Group>
              </Col>
              </Row>
          </>
        )}

        <ContentModal
          {...{
            show: show === "column",
            onHide: () => setShow(false),
            title: t("addColumn"),
            content: <AddColumn />,
          }}
        />
        <NotificationModal
          {...{
            show: Array.isArray(show),
            onHide: () => setShow(false),
            title: t("error"),
            content: (
              <p className="m-0">
                {t("excelMultipleNonUniqueNames", {
                  cols: Array.isArray(show) && show?.join(", "),
                })}
              </p>
            ),
          }}
        />
      </>
    );
  }
);


export const PaymentMethod = ({ paymentMethod, paymentMethodHandler, setPaymentAgreement, paymentAgreement, highlightCheck }) => {
    const { login } = useAuth();
    const { addToast } = useToast();
    const { t } = useTranslation();
    const [types, setTypes] = useState([])
    const client = useSwagger();

    const getPaymentTypes = useCallback(async() => {
        // fetch('https://tech.sigmaheat.de/payment/types/', {
        //     headers: {
        //         "Authorization": login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: 'GET'
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t('responseError'));
        //     return response.json()
        // })
        // .then((data) => {
        //     setTypes(data)
        // })
        // .catch(error => {
        //     addToast(t("error"), error.message, "error")
        // });

      if (!client) return;

      const originalRequestInterceptor = client.http.requestInterceptor;
  
      try {
        client.requestInterceptor = (req) => {
          req.headers["Content-Type"] = "application/json";
          req.headers["Authorization"] = login.Authorization;
          return req;
        };
  
        const response = await client.apis["payment"].payment_types_retrieve();
  
        if (response.status >= 200 && response.status < 300) {
          let paymentMethods = response.obj.map(method => {
            if (method.label === "SEPA_Direct_Debit") {
                return {
                    ...method,
                    label: "SEPA-Lastschriftmandat"
                };
            }
            return method;
        });
          setTypes(paymentMethods)
        }
  
        client.http.requestInterceptor = originalRequestInterceptor;
      } catch (error) {
        console.error(error);
        addToast(t("error"), t("responseError"), "error");
  
        client.http.requestInterceptor = originalRequestInterceptor;
      }
    }, [client, login.Authorization, t, addToast])

    useEffect(() => {
        getPaymentTypes();
    }, [])

    return <Form>
        <Form.Label style={{ fontWeight: 'bold' }}>{t('addPayment')}</Form.Label>

        {login?.currentOrganisation?.name && <Form.Group className="mb-3" controlId="name">
            <Form.Label>{t('organisation')}</Form.Label>
            <Form.Control type="text" disabled value={login?.currentOrganisation?.name}/>
        </Form.Group>}

        <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" value={paymentMethod?.name} onChange={(e) => paymentMethodHandler(e)} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="type">
            <Form.Label>{t("type")}</Form.Label>
            <Form.Select value={paymentMethod?.type} onChange={(e) => paymentMethodHandler(e)}>
                {types?.map(typ => <option key={`payment-type-${typ.id}`} value={typ.id}>{typ.label}</option>)}
            </Form.Select>
        </Form.Group>

        {/* <Form.Group className="mb-3" controlId="data">
            <Form.Label>{t("data")}</Form.Label>
            <Form.Control type="text" value={paymentMethod?.data} onChange={(e) => paymentMethodHandler(e)} />
        </Form.Group> */}

        <Form.Group className="mb-3" controlId="iban">
            <Form.Label>{t("IBAN")}</Form.Label>
            <Form.Control type="text" required value={paymentMethod?.iban} onChange={(e) => paymentMethodHandler(e)} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="bic">
            <Form.Label>{t("BIC")}</Form.Label>
            <Form.Control type="text" required value={paymentMethod?.bic} onChange={(e) => paymentMethodHandler(e)} />
        </Form.Group>

        <Form.Check
          className="mb-3"
          type="checkbox"
          id="paymentAgreement"
          checked={paymentAgreement}
          label={t('authorizePayments')}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Form.Check.Input
            onChange={(e) => {setPaymentAgreement(e.target.checked)}}
            style={{
                outline: highlightCheck ? '2px solid #ff0000' : 'none',
                outlineOffset: highlightCheck ? '2px' : 'none'
            }}
          />
          <Form.Check.Label style={{ marginLeft: '10px' }}>
              {t('authorizePayments')}
          </Form.Check.Label>
        </Form.Check>
    </Form>
}

const OrderSummary = ({ planID, setWizard, wizard }) => {
    const { t } = useTranslation()

    useEffect(() => {
      if (!planID) {
        setWizard(wizard + 1);
      }
    },[planID, setWizard, wizard])

    return (
        <>
          {planID &&
            <div className="order__summary__wrapper mb-3">
              <table>
                  <thead>
                      <tr>
                          <th>{t("product")}</th>
                          <th>{t("amount")}</th>
                          <th>{t("quantity")}</th>
                          <th>{t("totalAmount")}</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td>{planID.title} - Software</td>
                          <td>{planID.price} €</td>
                          <td>{planID.quantity}</td>
                          <td>{planID.price * planID.quantity} €</td>
                      </tr>
                  </tbody>
              </table>
            <hr />
            <div className="order__total">
                <span>Total</span>
                <span>{planID.price * planID.quantity} €</span>
            </div>
          </div>
        }
      </>
    )
}

const CreateOrganisation = ({ setPage, wizard, setWizard, myOrgID, createDaughterOrg, motherID, organization }) => {

    const mother = createDaughterOrg

    const client = useSwagger();
    const [dataBuilding, setDataBuilding] = useState(false);
    const { addToast } = useToast();
    const { login, doSetLogin } = useAuth();
    const { t } = useTranslation();
    const _orgGuid = useParams()['orgGuid'];

    const [terms, setTerms] = useState(false);
    const [paymentAgreement, setPaymentAgreement] = useState(false);

    const [pA, setPA] = useState(false);
    const [pp, setPP] = useState(false);
    const [rights, setRights] = useState(false);

    const [name, setName] = useState('');
    const [street, setStreet] = useState('');
    const [streetNo, setStreetNo] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [newOrgID, setNewOrgID] = useState();
    const [plans, setPlans] = useState([]);
    const [planID, setPlanID] = useState(undefined);
    const [paymentMethod, setPaymentMethod] = useState({
        name: '',
        type: 1,
        // data: ""
        iban: ""
    })

    const [selectedLicense, setSelectedLicense] = useState(undefined)
    const [highlightCheck, setHighlightCheck] = useState(false);

    const tableRef = useRef()
    const navigate = useNavigate();

    const dataInit = [
        [
            {name: t('name'), key: 'name'},
            {name: t('street'), key: 'street'},
            {name: t('housenumber'), key: 'no'},
            {name: t('zip'), key: 'zip'},
            {name: t('city'), key: 'city'},
            {name: t('constructionYear'), key: 'construction_year'}
        ], [
            "", "", "", "", "", ""
        ]
    ];

    const [excelData, setExcelData] = useState([...dataInit]);
    const [data, setData] = useState([...dataInit]);
    const [building, setBuilding] = useState()
    const [isValid, setIsValid] = useState(null);

    const [daughterOrganisation, setDughterOrganisation] = useState(null);

    // eslint-disable-next-line no-unused-vars
    const submitOrganisation = useCallback(async() => {
        if (street === "" || street === null || street === undefined) {
            addToast(t("error"), t("organisationValidation.street"), "error")
            return
        }
        if (streetNo === "" || streetNo === null || streetNo === undefined) {
            addToast(t("error"), t("organisationValidation.no"), "error")
            return
        }
        if ((zip === "" || zip === null || zip === undefined) || (zip !== "" && (!/^\d+$/.test(zip) || zip.length !== 5))) {
            addToast(t("error"), t("organisationValidation.zip"), "error")
            return
        }
        if ((city === "" || city === null || city === undefined) || (city !== "" && (/\d/.test(city)))) {
            addToast(t("error"), t("organisationValidation.city"), "error")
            return
        }

        if (!terms) {
            addToast(t("error"), t("organisationValidation.terms"), "error")
            return
        }

        if (!pA) {
            addToast(t("error"), t("organisationValidation.pA"), "error")
            return
        }

        if (!pp) {
            addToast(t("error"), t("organisationValidation.pp"), "error")
            return
        }

        const data = {
          name: name,
          street: street,
          no: streetNo,
          zip: zip,
          city: city
        }

        if (mother) {
          data.mother = motherID;
          data.has_rights = rights;
        }

        // fetch('https://tech.sigmaheat.de/org/', {
        //     body: JSON.stringify(data),
        //     method: 'POST',
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     }
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t('responseError'));
        //     return response.json()
        // })
        // .then((data) => {
        //     let loginCopy = cloneDeep(login);
        //     const newOrg = {
        //         id: data.id,
        //         name: data.name,
        //         permission: "Superuser",
        //         permission_level: 1
        //     }
        //     loginCopy.organisations.push(newOrg)

        //     doSetLogin(loginCopy)

        //     console.log("window.location.href", window.location.href)

        //     setNewOrgID(data.id);
        //     addToast("Organisation", t('orgPOST'), "success");
        //     setWizard(wizard + 1);
        // })
        // .catch(error => {
        //     addToast(t("error"), error.message, "error")
        // });
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
          client.requestInterceptor = (req) => {
            req.headers["Content-Type"] = "application/json";
            req.headers["Authorization"] = login.Authorization;
            req.body = JSON.stringify(data);
            return req;
          };
    
          const response = mother ? await client.apis["org"].org_add_create({org_uuid: _orgGuid}) : await client.apis["org"].org_create();
    
          if (response.status >= 200 && response.status < 300) {
            let loginCopy = cloneDeep(login);
            const newOrg = {
              id: response.obj.id,
              name: response.obj.name,
              permission: "Superuser",
              permission_level: 1,
              in_onboarding: 0
            };
            loginCopy.organisations.push(newOrg);
            !mother ? doSetLogin(loginCopy) : setDughterOrganisation(newOrg)
            // doSetLogin(loginCopy);
            setNewOrgID(response.obj.id);
            addToast("Organisation", t("orgPOST"), "success");
            setWizard(wizard + 1);
          }
    
          client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
          addToast(t("error"), t("responseError"), "error");
    
          client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, name, street, streetNo, zip, city, doSetLogin, login, wizard, setWizard, addToast, t, terms, pA, pp, mother, rights, motherID])

    const transformArray = (inputArray) => {
        const objArray = [];
        
        for (let i = 1; i < inputArray.length; i++) {
            const obj = {};
            const subArray = inputArray[i];
            
            for (let j = 0; j < 6; j++) {
                const key = inputArray[0][j].key;
                const value = subArray[j];
                obj[key] = value;
            }

            let additional_data = []
            for (let j = 6; j < subArray.length; j++) {
                const value = subArray[j];
                if (value !== "" && value !== null) additional_data.push({ key: inputArray[0][j].name, value: value })
            }

            if (Boolean(additional_data.length)) obj["additions"] = additional_data
            
            objArray.push(obj);
        }
        
        return objArray;
    }

    const obaiArray = (inputArray) => {
        const objArray = [];
        
        for (let i = 1; i < inputArray.length; i++) {
            const subArray = inputArray[i];
            for (let j = 6; j < subArray.length; j++) {
                const value = subArray[j];
                if (value !== "" && value !== null && !objArray.includes(inputArray[0][j].name)) objArray.push(inputArray[0][j].name);
            }
        }
        
        return objArray;
    }

    function isNumber(str) {
      return !isNaN(str) && str.trim() !== '';
    }

    const postBuildingAI = useCallback(async(id, building, obaiKeys) => {
      console.log("OBAIAIIII")
        console.log(building)
        console.log(obaiKeys)
        console.log(id)
        let additions = [];
        for (let i = 0; i < building.additions.length; i++) {
            console.log("KEY: ", building.additions[i].key)
            let obaiObj = obaiKeys.find(obj => {
                const additionKey = building.additions[i].key;
                if (typeof additionKey === 'string') {
                  console.log("=========")
                  console.log(obj["key"].trim().toLowerCase())
                  console.log(additionKey.trim().toLowerCase())
                  console.log("=========")
                  return obj["key"].trim().toLowerCase() === additionKey.trim().toLowerCase();
                } else {
                  return parseInt(obj["key"]) === additionKey;
                }
              });
              console.log('afterwards: ', obaiObj)
            additions.push({ value: building.additions[i].value, organisation_key_uuid: obaiObj.id })
            console.log('afterwards push')
        }

        console.log(additions)

        // fetch(`https://tech.sigmaheat.de/building/${id}/additional/`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         'Content-Type': 'application/json'
        //     },
        //     method: "POST",
        //     body: JSON.stringify({additions: [...additions]})
        // })
        // .then(response => {
        //     if (!response.ok) throw new Error(t("aIpostError"))
        // })
        // .catch(error => {
        //     addToast(t("error"), error.message, "error");
        // });

      if (!client) return;

      const originalRequestInterceptor = client.http.requestInterceptor;

      try {
        client.requestInterceptor = (req) => {
          req.headers["Content-Type"] = "application/json";
          req.headers["Authorization"] = login.Authorization;
          req.body = JSON.stringify({ additions: [...additions] });
          return req;
        };

        const response = await client.apis["building"].building_additional_create({
          building_uuid: id,
        });

        console.log("response: ", response)

        client.http.requestInterceptor = originalRequestInterceptor;
      } catch (error) {
        addToast(t("error"), t("aIpostError"), "error");
        client.http.requestInterceptor = originalRequestInterceptor;
      }
    }, [client, login.Authorization, addToast, t])

    const postBuilding = (building, orgID, obaiKeys) => {
        return new Promise(async(resolve, reject) => {
            console.log(building)
            building["construction_year"] = parseInt(building["construction_year"])
            building["zip"] = parseInt(building["zip"])

            // fetch(`https://tech.sigmaheat.de/${orgID ? orgID : myOrgID}/building`, {
            //     headers: {
            //         'Authorization': login.Authorization,
            //         'Content-Type': 'application/json'
            //     },
            //     method: "POST",
            //     body: JSON.stringify({city: building.city, construction_year: building.construction_year, name: building.name, no: building.no, street: building.street, zip: building.zip})
            // })
            // .then(response => {
            //     if (!response.ok) throw new Error(t('responseError'))
            //     return response.json();
            // })
            // .then(async(content) => {
            //     if (building?.additions) await postBuildingAI(content.id, building, obaiKeys);
            //     resolve();
            // })
            // .catch(error => {
            //     addToast(t("building"), error.message, "error");
            //     reject(error.message);
            // });

            if (!client) return;

            const originalRequestInterceptor = client.http.requestInterceptor;
      
            try {
              client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
      
                req.body = JSON.stringify({
                  city: building.city,
                  construction_year: building.construction_year,
                  name: building.name,
                  no: building.no,
                  street: building.street,
                  zip: building.zip,
                  addition_informations: []
                });
                return req;
              };
              
              const response = await client.apis["building"].building_create({org_uuid: orgID ? orgID : myOrgID});
      
              if (response.status >= 200 && response.status < 300) {
                if (building?.additions) await postBuildingAI(response.obj.id, building, obaiKeys);
                resolve();
              }
      
              client.http.requestInterceptor = originalRequestInterceptor;
            } catch (error) {
              addToast(t("building"), t("responseError"), "error");
              reject(error.message);
              client.http.requestInterceptor = originalRequestInterceptor;
            }
        });
    }

    const postBuildings = (building, orgID, obaiKeys) => {
      console.log('keys', obaiKeys)
        return new Promise(async (resolve, reject) => {
            try {
                for (let i = 0; i < building.length; i++) {
                    await postBuilding(building[i], orgID, obaiKeys);
                }
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    const checkDataValidation = useCallback((buildings) => {
        let validation = true;
        // Initialise Styling
        buildings.forEach((b, i) => {
            tableRef.current.rows[i].style.backgroundColor = null
            tableRef.current.rows[i].cells[2].getElementsByClassName("form-control")[0].style.border = null
            tableRef.current.rows[i].cells[3].getElementsByClassName("form-control")[0].style.border = null
            tableRef.current.rows[i].cells[4].getElementsByClassName("form-control")[0].style.border = null
            tableRef.current.rows[i].cells[5].getElementsByClassName("form-control")[0].style.border = null
            tableRef.current.rows[i].cells[6].getElementsByClassName("form-control")[0].style.border = null
        })
        // Check if all neccessary information are present
        buildings.forEach((b, i) => {
            if (b.street === "" || b.street === null || b.street === undefined) {
                tableRef.current.rows[i].style.backgroundColor = 'rgba(245, 5, 18, .15)'
                tableRef.current.rows[i].cells[2].getElementsByClassName("form-control")[0].style.border = '1px solid rgb(245, 5, 18)'
                validation = false;
            }
            if (b.no === "" || b.no === null || b.no === undefined) {
                tableRef.current.rows[i].style.backgroundColor = 'rgba(245, 5, 18, .15)'
                tableRef.current.rows[i].cells[3].getElementsByClassName("form-control")[0].style.border = '1px solid rgb(245, 5, 18)'
                validation = false;
            }
            if ((b.zip === "" || b.zip === null || b.zip === undefined) || (b.zip !== "" && (!/^\d+$/.test(b.zip) || b.zip.length !== 5))) {
                tableRef.current.rows[i].style.backgroundColor = 'rgba(245, 5, 18, .15)'
                tableRef.current.rows[i].cells[4].getElementsByClassName("form-control")[0].style.border = '1px solid rgb(245, 5, 18)'
                validation = false;
            }
            if ((b.city === "" || b.city === null || b.city === undefined) || (b.city !== "" && (/\d/.test(b.city)))) {
                tableRef.current.rows[i].style.backgroundColor = 'rgba(245, 5, 18, .15)'
                tableRef.current.rows[i].cells[5].getElementsByClassName("form-control")[0].style.border = '1px solid rgb(245, 5, 18)'
                validation = false;
            }
            if ((b.construction_year === "" || b.construction_year === null || b.construction_year === undefined) || (b.construction_year !== "" && (!/^\d+$/.test(b.construction_year) || b.construction_year.length !== 4 || parseInt(b.construction_year, 10) > new Date().getFullYear()))) {
                tableRef.current.rows[i].style.backgroundColor = 'rgba(245, 5, 18, .15)'
                tableRef.current.rows[i].cells[6].getElementsByClassName("form-control")[0].style.border = '1px solid rgb(245, 5, 18)'
                validation = false;
            }
        })

        return validation;
    }, [data])


    // Before Posting Get OBAI in order to avoid conflict 
    const postOBAI = useCallback(async(key) => {
        // await fetch(`https://tech.sigmaheat.de/org/${newOrgID ? newOrgID : myOrgID }/obai/`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         'Content-Type': 'application/json'
        //     },
        //     method: "POST",
        //     body: JSON.stringify({key: key})
        // })
        // .then(response => {
        //     if (response.status === 409) throw new Error(t("keyExists"))
        //     if (!response.ok) throw new Error(t("responseError"))
        //     return response.json()
        // })
        // .catch(error => {
        //     addToast(t("error"), error.message, "error");
        // });

      if (!client) return;

      const originalRequestInterceptor = client.http.requestInterceptor;

      try {
        client.requestInterceptor = (req) => {
          req.headers["Content-Type"] = "application/json";
          req.headers["Authorization"] = login.Authorization;
          req.body = JSON.stringify({ key: key, ressource_type: 1 });
          return req;
        };

        const response = await client.apis["org"].org_oai_create({
          org_uuid: newOrgID ? newOrgID : myOrgID,
        });

        console.log("resposnese: ", response)

        client.http.requestInterceptor = originalRequestInterceptor;
      } catch (error) {
        const statusCode = error.response?.status;
        addToast(t("error"), t(statusCode === 409 ? "keyExists" : "responseError"), "error");
        client.http.requestInterceptor = originalRequestInterceptor;
      }
    }, [client, newOrgID, login.Authorization, t, addToast])

    const submitBuildings = useCallback(async() => {
      console.log("dataBuilding: ", dataBuilding);
      if (!dataBuilding) {
        postBuildings([building], newOrgID, [])
        .then(() => {
          setWizard(wizard + 1);
        })
        .catch((error) => {
          addToast(t("building"), error.message, "error");
        });
        return
      }
  
      if (data.length === 0) return;
      let buildingData = transformArray(data);
      const validation = checkDataValidation(buildingData);
      if (!validation) {
        addToast(t("error"), t("validationError"), "error");
        return;
      }
  
      let obaiKeys = obaiArray(data);

      console.log("OBAIs KEYS: ", obaiKeys)
  
      if (Boolean(obaiKeys.length)) {
        for (let i = 0; i < obaiKeys.length; i++) {
          await postOBAI(obaiKeys[i]);
        }
  
        // fetch(`https://tech.sigmaheat.de/org/${newOrgID ? newOrgID : myOrgID }/obai/`, {
        //   headers: {
        //     Authorization: login.Authorization,
        //     "Content-Type": "application/json",
        //   },
        //   method: "GET",
        // })
        //   .then((response) => response.json())
        //   .then((data) => {
        //     postBuildings(buildingData, newOrgID, data)
        //       .then(() => {
        //         setWizard(wizard + 1);
        //       })
        //       .catch((error) => {
        //         addToast(t("building"), error.message, "error");
        //       });
        //   })
        //   .catch((error) => {
        //     console.log("Error:", error);
        //   });
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
          client.requestInterceptor = (req) => {
            req.headers["Content-Type"] = "application/json";
            req.headers["Authorization"] = login.Authorization;
            return req;
          };

          const response = await client.apis["org"].getAllObais({
            org_uuid: newOrgID ? newOrgID : myOrgID,
          });

          console.log('responsessss: ', newOrgID ? newOrgID : myOrgID)

          if (response.status >= 200 && response.status < 300) {
            console.log("OBAIs: ", response.obj)
            postBuildings(buildingData, newOrgID, response.obj)
              .then(() => {
                setWizard(wizard + 1);
              })
              .catch((error) => {
                addToast(t("building"), error.message, "error");
              });
          }

          client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
          addToast(t("building"), error.message, "error");
          client.http.requestInterceptor = originalRequestInterceptor;
        }
      } else {
        postBuildings(buildingData, newOrgID, undefined)
          .then(() => {
            setWizard(wizard + 1);
          })
          .catch((error) => {
            addToast(t("building"), error.message, "error");
          });
      }
    }, [addToast, t, wizard, setWizard, data, newOrgID, dataBuilding, building]);

    const subscription = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/org/${newOrgID ? newOrgID : myOrgID}/sw_products`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "GET"
        // })
        // .then((response) => {
        //     return response.json()
        // })
        // .then((data) => {
        //     setPlans(data.map(p => ({...p, quantity: 1})))
        // })
        // .catch(error => {
        //     console.log('Error:', error);
        // });
      
      if (!client) return;

      const originalRequestInterceptor = client.http.requestInterceptor;
  
      try {
        client.requestInterceptor = (req) => {
          req.headers["Content-Type"] = "application/json";
          req.headers["Authorization"] = login.Authorization;
          return req;
        };
  
        const response = await client.apis["org"].org_sw_products_retrieve({
          org_uuid: newOrgID ? newOrgID : myOrgID,
        });
  
        if (response.status >= 200 && response.status < 300) {
          setPlans(response.obj.map(p => ({...p, quantity: 1})))
        }
  
        client.http.requestInterceptor = originalRequestInterceptor;
      } catch (error) {
        client.http.requestInterceptor = originalRequestInterceptor;
      }
    }, [client, login.Authorization, newOrgID])

    const postOrgHasProduct = useCallback(async(planID) => {
        // fetch(`https://tech.sigmaheat.de/org/${newOrgID ? newOrgID : myOrgID}/software/post`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         'Content-Type': 'application/json'
        //     },
        //     method: "POST",
        //     body: JSON.stringify({ software: planID.software_id, count: planID.quantity })
        // })
        // .then(response => {
        //     if (!response.ok) throw new Error(t("reponseError"))
        //     setPlanID(planID)
        //     // setWizard(prev => prev + 1)
        // })
        // .catch(error => {
        //     addToast(t("error"), error.message, "error")
        // })
      
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
          client.requestInterceptor = (req) => {
            req.headers["Content-Type"] = "application/json";
            req.headers["Authorization"] = login.Authorization;
            req.body = JSON.stringify({
              software: planID.software_id,
              count: planID.quantity
            });
            return req;
          };

          const response = await client.apis["org"].org_software_post_create({
            org_uuid: newOrgID ? newOrgID : myOrgID,
          });

          if (response.status >= 200 && response.status < 300) {
            setPlanID(planID)
            // setWizard(prev => prev + 1)
          }

          client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
          addToast(t("error"), t("reponseError"), "error");
          client.http.requestInterceptor = originalRequestInterceptor;
      }
    }, [client, newOrgID, login.Authorization])

    function validateIBAN(value) {
      if (!value.startsWith('DE')) {
        return false;
      }
    
      const rearrangedIban = value.slice(4) + value.slice(0, 4);
    
      let numericIban = '';
      for (let char of rearrangedIban) {
        if (isNaN(char)) {
          numericIban += (char.charCodeAt(0) - 55).toString();
        } else {
          numericIban += char;
        }
      }
    
      let remainder = 0;
      for (let i = 0; i < numericIban.length; i += 9) {
        const part = numericIban.slice(i, i + 9);
        remainder = parseInt(remainder + part, 10) % 97;
      }
    
      return remainder === 1;
    }
    
    const paymentMethodHandler = useCallback((e) => {
        const { id, value } = e.target;

        setPaymentMethod(prevState => ({
          ...prevState,
          [id]: value
        }))

        if (id == "iban") {
          setIsValid(validateIBAN(value));
        }
        
    }, [paymentMethod])

    const postPaymentMethod = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/payment/org/${newOrgID ? newOrgID : myOrgID}/paymentmethods/`, {
        //     headers: {
        //         "Authorization": login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "POST",
        //     body: JSON.stringify({...paymentMethod, data: {iban: paymentMethod.data}})
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t('responseError'));
        //     return response.json()
        // })
        // .then(_ => {
        //     redirect()
        // })
        // .catch(error => {
        //     addToast(t("error"), error.message, "error")
        // });

      if(!isValid) {
        addToast(t("error"), t("verifyIBAN"), "error");
        return
      }
      
      if (!client) return;

      const originalRequestInterceptor = client.http.requestInterceptor;

      try {
        client.requestInterceptor = (req) => {
          req.headers["Content-Type"] = "application/json";
          req.headers["Authorization"] = login.Authorization;
          // req.body = JSON.stringify({...paymentMethod, data: {iban: paymentMethod.data}});
          req.body = JSON.stringify({...paymentMethod, data: {iban: paymentMethod.iban}});

          return req;
        };

        const response = await client.apis["payment"].payment_org_paymentmethods_create({
          org_uuid: newOrgID ? newOrgID : myOrgID,
        });

        if (response.status >= 200 && response.status < 300) {
          redirect()
        }

        client.http.requestInterceptor = originalRequestInterceptor;
      } catch (error) {
        addToast(t("error"), t("reponseError"), "error");

        client.http.requestInterceptor = originalRequestInterceptor;
      }
    }, [client, login.Authorization, t, addToast, paymentMethod, newOrgID])

    const submitNext = useCallback(() => {
        if (wizard === 0) submitOrganisation()
        if (wizard === 1) submitBuildings()
        if (wizard === 3) setWizard(prev => prev + 1)
        if (wizard === 4) postPaymentMethod()
    }, [wizard, submitOrganisation, submitBuildings, paymentMethod])

    const redirect = useCallback(() => {
        // let newLogin = JSON.parse(JSON.stringify(login));
        let orgFromParentOrganization = daughterOrganisation ? daughterOrganisation : (organization ? organization : false)
        let newLogin = login;

        if (orgFromParentOrganization === false) {
          let updatedOrganisation = newLogin.organisations.find(obj => newOrgID ? obj.id === newOrgID : obj.id === myOrgID)
          updatedOrganisation.in_onboarding = 3;
          newLogin.currentOrganisation = updatedOrganisation
          doSetLogin(newLogin);
        } else {
          orgFromParentOrganization.in_onboarding = 3
          newLogin.currentOrganisation = orgFromParentOrganization
          doSetLogin(newLogin);
        }

        // let updatedOrganisation = newLogin.organisations.find(obj => newOrgID ? obj.id === newOrgID : obj.id === myOrgID)
        // updatedOrganisation.in_onboarding = 3;
        // newLogin.currentOrganisation = updatedOrganisation
        // doSetLogin(newLogin);
        
        navigate(`/${newOrgID ? newOrgID : myOrgID}/dashboard`)
        addToast(t("success"), t("organisationSuccess"), "success");
    }, [login, addToast, t, navigate, newOrgID, myOrgID, doSetLogin, daughterOrganisation, organization])

    const buildingHandler = useCallback((e) => {
      const { id, value } = e.target;
      setBuilding((prev) => ({
        ...prev,
        [id]: value,
      }));
    }, []);

    useEffect(() => {
        if (newOrgID === undefined && myOrgID === undefined) return
        subscription();
    }, [subscription, newOrgID, myOrgID])

    return <div>
        {wizard === 0 && <OrganisationsDetails {...{ name, setName, street, setStreet, streetNo, setStreetNo, zip, setZip, city, setCity, terms, setTerms, pA, setPA, pp, setPP, rights, setRights, mother }} />}
        {wizard === 1 && <UploadEditExcel ref={tableRef} {...{ excelData, setExcelData, data, setData, setDataBuilding, dataInit, building, buildingHandler }} />}
        {wizard === 2 && <PricingTableWrapper {...{ plans, setPlans, setPlanID, postOrgHasProduct, setWizard, wizard, setSelectedLicense, redirect: () => redirect() }} />}
        {wizard === 3 && <OrderSummary {...{ planID, setWizard, wizard }} />}
        {wizard === 4 && <PaymentMethod {...{ paymentMethod, paymentMethodHandler, setPaymentAgreement, paymentAgreement, highlightCheck }} />}

        <div className={`d-flex ${(wizard === 0 || wizard === 1 || wizard === 3) ? 'justify-content-between' : 'justify-content-end'}`}>
            {/* {wizard === 1 && <Button variant="outline-primary" onClick={() => setWizard(wizard + 1)}>{t('skip')}</Button>} */}
            {(wizard === 0 || wizard === 3) && <Button variant="outline-primary" onClick={() => wizard === 0 ? setPage(1) : setWizard(wizard - 1)}>{t('previous')}</Button>}
            
            {/* <Button variant="outline-primary" style={{ display: wizard === 2 ? 'none' : 'block' }} onClick={() => wizard !== 3 ? submitNext() : (postOrgHasProduct(selectedLicense), submitNext())}>{t('submit')}</Button> */}
            <Button variant="outline-primary" style={{ display: wizard === 2 ? 'none' : 'block' }}  onClick={() => {
              if (wizard === 4 && !paymentAgreement) {
                setHighlightCheck(true);
                return addToast(t("error"), t("registrationPaymentConditions"), "error");
              }
              if (wizard === 3 && planID?.price == 0) {
                redirect()
              }

              if (wizard !== 3) {
                  submitNext();
              } else {
                  postOrgHasProduct(selectedLicense);
                  submitNext();
              }
            }}>{t('submit')}</Button>

        </div>
    </div>
}

export const Organisation = ({ motherID, level, myOrgID, createDaughterOrg, organization }) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [wizard, setWizard] = useState(level !== undefined ? level + 1 : 0);
    const showAllOrgs = level !== undefined ? false : true;

    const title = useCallback(() => {
        if (page === 1) return t('selectOrganisation')
        if (wizard === 0) return t('setupWizard.organisation')
        if (wizard === 1) return t('setupWizard.building')
        if (wizard === 2) return t('setupWizard.plan')
        if (wizard === 3) return t('setupWizard.summary')
        if (wizard === 4) return t('setupWizard.checkout')
    }, [page, wizard, t])

    return (
      <div style={{ width: '100%', maxWidth: '1200px' }}>
          {(page === 1 && showAllOrgs) ? (
              <Card heading={title()} className="w-100">
                  <Organisations {...{ setPage }}/>
              </Card>
          ) : (
              showAllOrgs ? (
                 // create org after click "+"
                  <Card heading={title()} className="w-100">
                      <CreateOrganisation {...{ setPage, wizard, setWizard, myOrgID }} />
                  </Card>
              ) : (
                // create org, but onboarding is not completed
                  <CreateOrganisation {...{ setPage, wizard, setWizard, myOrgID, createDaughterOrg, motherID, organization }} />
              )
          )}
      </div>
    );
}

export default Organisation;