import React from 'react';
import './style.css';

function ScatterPlotSVG(props) {
    return (
        <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1695.93 1673.74">
            <path className="cls-1" d="M433,72V1725.56" transform="translate(-287.39 -68.52)"/>
            <path className="cls-1" d="M1943.81,1585.56H287.39" transform="translate(-287.39 -68.52)"/>
            <text className="cls-2" transform="translate(86.35 952.76) rotate(-90)">{props.yAxis}</text>
            <text className="cls-2" transform="translate(604.02 1590.68)">{props.xAxis}</text>
            <path className="cls-1" d="M359.67,143.67" transform="translate(-287.39 -68.52)"/>
            <polyline className="cls-6" points="217.52 75.15 145.91 3.54 73.69 75.76"/>
            <polyline className="cls-6" points="1585.37 1588.95 1656.98 1517.34 1584.76 1445.12"/>
            <circle className="cls-7" cx="305.16" cy="264.04" r="11.11"/>
            <circle className="cls-7" cx="464.72" cy="430.26" r="11.11"/>
            <circle className="cls-7" cx="580.27" cy="359.43" r="11.11"/>
            <circle className="cls-7" cx="900.27" cy="359.43" r="11.11"/>
            <circle className="cls-7" cx="738.5" cy="469.37" r="11.11"/>
            <circle className="cls-7" cx="1042.5" cy="430.26" r="11.11"/>
            <circle className="cls-7" cx="1168.27" cy="528.04" r="11.11"/>
            <circle className="cls-7" cx="1328.72" cy="441.37" r="11.11"/>
            <circle className="cls-7" cx="1134.94" cy="430.26" r="11.11"/>
            <circle className="cls-7" cx="1236.27" cy="570.82" r="11.11"/>
            <circle className="cls-7" cx="1449.61" cy="528.04" r="11.11"/>
            <circle className="cls-7" cx="1364.27" cy="602.7" r="11.11"/>
            <circle className="cls-7" cx="1550.94" cy="591.59" r="11.11"/>
            <circle className="cls-7" cx="1638.05" cy="702.7" r="11.11"/>
            <line className="cls-8" x1="169.41" y1="276.04" x2="1694.64" y2="684.92"/>
        </svg>
    )
}

export default ScatterPlotSVG