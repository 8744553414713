import React from 'react';
import { Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

export const DjangoPagination = ({ total_pages, currentPage, setCurrentPage }) => {

    return <div className="d-flex justify-content-center w-100">
        <Pagination size="sm" className="mt-3 mb-0 flex-wrap">
            <Pagination.Prev onClick={() => setCurrentPage(1)} className={`${currentPage == 1 && 'disabled'}`}>
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </Pagination.Prev>
            <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} className={`${currentPage == 1 && 'disabled'}`}>
                <FontAwesomeIcon icon={faAngleLeft} />
            </Pagination.Prev>

            {Array.from({ length: total_pages }).map((_, index) => (
                <Pagination.Item key={index} onClick={() => setCurrentPage(index + 1)} active={index + 1 === currentPage}>{index + 1}</Pagination.Item>
            ))}

            <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} className={`${currentPage == total_pages && 'disabled'}`}>
                <FontAwesomeIcon icon={faAngleRight} />
            </Pagination.Next>
            <Pagination.Next onClick={() => setCurrentPage(total_pages)} className={`${currentPage == total_pages && 'disabled'}`}>
                <FontAwesomeIcon icon={faAngleDoubleRight} />
            </Pagination.Next>
        </Pagination>
    </div>
}

export default DjangoPagination;