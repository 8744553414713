import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useAuth } from '../Context';
import { useTranslation } from 'react-i18next';

const LICENCE = process.env.REACT_APP_AMCHART5;

const Bubblechart = ({heatparts}) => {
    // Bubblechart über Card mit x Raumtemperatur, y Rücklauftemperatur und dicke der Bubble als Heizleistung target_thermal_power
    const { login } = useAuth();
    const [data, setData] = useState(undefined)
    const [loading, setLoading] = useState(true)
    const seriesRef = useRef([]);
    const { t } = useTranslation();

    let heatpartsArray = [];
    let firstColor = login.settings.theme_code === 'dark' ? am5.color(0xf3f3f3) : am5.color(0x000000);
    let secondColor = login.settings.theme_code === 'dark' ? am5.color(0xffd981) : am5.color(0x072c50);
    let thirdColor = login.settings.theme_code === 'dark' ? am5.color(0xf1c40f) : am5.color(0x072c50);


    const transformDataStructure = (array) => {
        return array.map(item => ({
            y: Number((item?.target_return_temp).toFixed(2)),
            x: Number((item?.target_room_temp).toFixed(2)),
            value: Number((item?.target_thermal_power_to_ambient).toFixed(2)),
            unit: item?.unit_name,
            room: item?.room_name
        }));
    };

    useEffect(() => {
        // Data
        heatparts?.units?.map(unit => unit.unit_rooms?.map(room => room.heatparts?.map(hp => heatpartsArray.push({...hp, unit_name: unit?.name, room_name: room?.name }))))
        heatparts?.rooms_without_unit?.map(room => room.heatparts?.map(hp => heatpartsArray.push({...hp, room_name: room?.name })))

        if (heatparts) {
            const dataStructure = transformDataStructure(heatpartsArray);
            setData(dataStructure)
            setLoading(false)
        } else {
            setLoading(true)
            setData(undefined)
        }
    }, [heatparts])

    useEffect(() => {
        am5.addLicense(LICENCE)

        if (document.getElementById('bubbleChartdiv') === null) return
        
        let root = am5.Root.new("bubbleChartdiv");
        seriesRef.current = [];
        
        // Set themes
        root.setThemes([am5themes_Animated.new(root)]);

        // Change grid color
        root.interfaceColors.set("grid", firstColor);
        root.interfaceColors.set("text", firstColor);

        // Create chart
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelY: "zoomXY",
            pinchZoomX:true,
            pinchZoomY:true,
        }));

        // Hourglass
        let indicator = root.container.children.push(am5.Container.new(root, {
            width: am5.p100,
            height: am5.p100,
            layer: 1000,
            background: am5.Rectangle.new(root, {
                fillOpacity: 0.7
            })
        }));

        indicator.children.push(am5.Label.new(root, {
            text: "Loading...",
            fill: secondColor,
            fontSize: 25,
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50
        }));

        let hourglass = indicator.children.push(am5.Graphics.new(root, {
            width: 32,
            height: 32,
            fill: secondColor,
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            dy: -45,
            svgPath: "M12 5v10l9 9-9 9v10h24V33l-9-9 9-9V5H12zm20 29v5H16v-5l8-8 8 8zm-8-12-8-8V9h16v5l-8 8z"
        }));

        let hourglassanimation = hourglass.animate({
            key: "rotation",
            to: 180,
            loops: Infinity,
            duration: 2000,
            easing: am5.ease.inOut(am5.ease.cubic)
        });

        // Colors
        chart.get("colors").set("colors", [
            thirdColor,
        ]);

        // Create axes
        let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 50 }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        xAxis.children.moveValue(am5.Label.new(root, {
            text: `${t('room_temperature')} °C`,
            x: am5.p50,
            centerX: am5.p50,
            fill: firstColor,
        }), xAxis.children.length - 1);

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {}),
            tooltip: am5.Tooltip.new(root, {})
        }));

        yAxis.children.moveValue(am5.Label.new(root, {
            rotation: -90,
            text: `${t('return_temperature')} °C`,
            y: am5.p50,
            centerX: am5.p50,
            fill: firstColor,
        }), 0);

        // Create series
        let series0 = chart.series.push(am5xy.LineSeries.new(root, {
            calculateAggregates: true,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "y",
            valueXField: "x",
            valueField: "value",
            tooltip: am5.Tooltip.new(root, {
                labelText: `{unit} {room}: ${t('room_temperature')}: {valueX} °C, ${t('return_temperature')}: {valueY} °C, ${t('heatPower')}: {value} W`,
            })
        }));

        // Add bullet
        let circleTemplate = am5.Template.new({});

        series0.bullets.push(function() {
            let graphics = am5.Circle.new(root, {
                fill: series0.get("fill"),
                fillOpacity: 0.8
            }, circleTemplate);
            return am5.Bullet.new(root, {
                sprite: graphics
            });
        });

        // Add heat rule
        series0.set("heatRules", [{
            target: circleTemplate,
            min: 3,
            max: 35,
            dataField: "value",
            key: "radius",
        }]);

        series0.strokes.template.set("strokeOpacity", 0);

        // Add cursor
        chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            snapToSeries: [series0]
        }));

        // Add scrollbars
        // chart.set("scrollbarX", am5.Scrollbar.new(root, {
        //     orientation: "horizontal"
        // }));
          
        // chart.set("scrollbarY", am5.Scrollbar.new(root, {
        //     orientation: "vertical"
        // }));

        // Loading
        if (loading) {
            hourglassanimation.play();
            indicator.show();
        } else {
            hourglassanimation.pause();
            indicator.hide();  
            series0.data.setAll(data);
            seriesRef.current.push(series0);

            // Make stuff animate on load
            seriesRef.current[0]?.appear(2500, 100);
            chart.appear(1000, 100);
        }
        return () => root.dispose();

    }, [loading, setLoading])

    // Effect for updating chart data
    useEffect(() => {
        if (!loading && data && seriesRef.current?.length > 0) {
            seriesRef.current[0]?.data.setAll(data);
        }
    }, [data, loading]);

    return (
        <>
            {heatparts ? (
                <div id="bubbleChartdiv" style={{ width: "100%", height: "500px" }}></div>
            ) : (
                <p>{t('noHeatparts')}</p>
            )}
        </>
    )
}

export default Bubblechart