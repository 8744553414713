import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../Context";
import { useSwagger } from "../../context/SwaggerContext";
import { useToast } from "../../NotificationsContent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";

export const EnergyResourcesForm = ({ resource, types, onClose }) => {
    const { login } = useAuth();
    const client = useSwagger();
    const { addToast } = useToast();
    const { t } = useTranslation();
    const _orgGuid = useParams()['orgGuid'];
    const [disable, setDisable] = useState(false);
    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const [res, setRes] = useState(resource || {
        type: 0 || resource?.type,
        g_co2_kWh: "0" || resource?.g_co2_kWh,
        cent_kWh: "0" || resource?.cent_kWh,
        from_date: `${new Date().getFullYear()}-01-01` || resource?.from_date,
    })

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;

        setRes(prev => ({
            ...prev,
            [id]: value
        }))
    }, [res])

    const validateForm = useCallback(() => {
        let formErrors = {};
    
        if (!res.from_date || new Date(res.from_date).getMonth() !== 0 || new Date(res.from_date).getDate() !== 1) {
            formErrors.from_date = t('energyResourceInfo');
        }
    
        return formErrors;
    }, [t, res])

    const onSubmit = useCallback(async(e) => {
        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            setDisable(true);
            setValidated(true);
        } else {
            setErrors(formErrors);
            setValidated(false);
            return
        }
        
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(res)
                return req;
            };

            const response = resource ? await client.apis["energy_resource"].energy_resource_update({ org_uuid: _orgGuid, energy_resource_uuid: resource.id }) : await client.apis["energy_resource"].energy_resource_create({ org_uuid: _orgGuid });

            if (response.status >= 200 && response.status < 300) {
                console.log("response", response.obj)
                addToast(t('energyResource'), t('energyResourceSuccess'), "success");
                onClose()
            }
            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            setErrors({ from_date: t('energyResourceTypeYearWarning') });
            setValidated(false);
            setDisable(false);
            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            setDisable(false);
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [addToast, client, login.Authorization, login.currentOrganisation.id, t, res, validateForm]);

    return <Form noValidate validated={validated} onSubmit={(e) => onSubmit(e)}>
        <Form.Group className="mb-3" controlId="type">
            <Form.Label>{t("type")}</Form.Label>
            <Form.Select required type="number" value={res?.type || 1} onChange={(e) => changeHandler(e)}>
                {types?.map(type => <option value={type.value} key={`hp-type-${type.value}`}>{t(`${type.label}`)}</option>)}
            </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3' controlId='g_co2_kWh'>
            <Form.Label>{t('g_co2_kWh')}</Form.Label>
            <Form.Control type='number' required value={res?.g_co2_kWh} onChange={(e) => changeHandler(e)} />
        </Form.Group>

        <Form.Group className='mb-3' controlId='cent_kWh'>
            <Form.Label>{t('cent_kWh')}</Form.Label>
            <Form.Control type='number' required value={res?.cent_kWh} onChange={(e) => changeHandler(e)} />
        </Form.Group>

        <Form.Group className='mb-3' controlId='from_date'>
            <Form.Label>{t('from_date')}</Form.Label>
            <Form.Control type='date' required value={res?.from_date} isInvalid={!!errors.from_date} onChange={(e) => changeHandler(e)} />
            <Form.Control.Feedback type="invalid">{errors.from_date}</Form.Control.Feedback>
        </Form.Group>

        <div className="d-flex justify-content-end">
            <Button variant="outline-primary" className="p-10" type="submit" disabled={disable}>{t('submit')}</Button>
        </div>
    </Form>
}

export default EnergyResourcesForm;