import React, { useCallback, useEffect, useState } from 'react'
import { useAuth } from '../Context';
import { useToast } from '../NotificationsContent';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSwagger } from '../context/SwaggerContext';
import { Button, Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faCheck } from "@fortawesome/free-solid-svg-icons";

export const EditIndividualBuildingParts = ({individualBuildingParts, setShow, getIndividualBuildingParts}) => {
    const { login } = useAuth();
    const { addToast } = useToast();
    const { t } = useTranslation();
    const _buildingGuid = useParams()["buildingGuid"];
    const client = useSwagger();
    const [types, setType] = useState(undefined);
    const [settings, setSettings] = useState({type : 0});
    const [editAll, setEditAll] = useState(true);
    const [checkAll, setCheckAll] = useState(false);
    const [buildingparts, setBuildingparts] = useState(undefined)

    const onClose = () => {
        setShow("individual_bp")
        getIndividualBuildingParts()
    }

    const tableStructure = [
        {
            col: t('select'),
            type: 'checkbox'
        }, {
            col: t('name'),
            type: 'label'
        }, {
            col: t('unit'),
            type: 'label'
        }, {
            col: t('level'),
            type: 'label'
        }, {
            col: t('room'),
            type: 'label'
        }, {
            col: t('from_catalog'),
            type: 'label'
        }, {
            col: t('uValue'),
            type: 'label'
        }, {
            col: t('thermalBridgeSurcharge'),
            type: 'label'
        }
    ];

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;

        setSettings((prevState) => ({
            ...prevState,
            [id]: value,
        }));

    })

    const getTypes = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["buildingparttypes"].buildingparttypes_retrieve();

            if (response.status === 204) {
                setType([]);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
            
                const labelMap = {
                Floor: "floor",
                OuterWall: "outerwall",
                Ceiling: "ceiling",
                Window: "window",
                OuterDoor: "outerdoor",
                Roof: "roof",
                InnerWall: "innerwall",
                InnerDoor: "innerdoor",
                InnerCeiling: "innerceiling",
                Shutterbox: "rollershutterbox",
                "Virtual Group": "virtualGroup",
                "Ground Soil" : "groundSoil",
                "OuterWall Soil": "outerWallSoil",
                };

                const updatedItems = response.obj.map((item) => {
                    const newLabel = labelMap[item.label];
                    return newLabel ? { ...item, label: newLabel } : item;
                });

                setType(updatedItems);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization]);

    const editIndividualBuildingParts = useCallback(async() => {
        if (!client) return;

        const filteredChecked = individualBuildingParts?.filter((bp) => bp.checked)?.map((bp) => bp.id);

        if (editAll && buildingparts?.length === 0) {
            addToast(t("error"), t("noBuildingPartsWithThisType"), "error");
            return
        }

        if (!editAll && filteredChecked?.length === 0) {
            addToast(t("error"), t("selectBuildingPart"), "error");
            return
        }

        const data = {
            buildingparts: (editAll ? buildingparts : filteredChecked), 
            individual: {
                U_typical: settings?.U_typical, 
                U_thermal_bridge: settings?.U_thermal_bridge
            }
        };

        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
            req.headers["Content-Type"] = "application/json";
            req.headers["Authorization"] = login.Authorization;
            req.body = JSON.stringify(data);
            return req;
          };
    
            const response = await client.apis["building"].building_buildingpart_edit_partial_update({
                building_uuid: _buildingGuid,
            });
    
            if (response.status >= 200 && response.status < 300) {
                addToast(t("success"), t("buildingPartPatch"), "success");
                onClose()
            }
    
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, addToast, t. buildingparts, settings, editAll]);

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();
        editIndividualBuildingParts()
    }, [editIndividualBuildingParts])

    useEffect(() => {
        getTypes()
    }, [])

    useEffect(() => {
        const filteredBuildingParts = individualBuildingParts?.filter((bp) => bp?.type == settings?.type);
        const filteredIds = filteredBuildingParts?.map((bp) => bp.id);
        setBuildingparts(filteredIds);
    }, [individualBuildingParts, settings?.type]);

    const table = () => {
        return (
            <>
                {!editAll && (
                    <>
                      {individualBuildingParts?.some((bp) => bp?.type == settings?.type) ? (
                        <Table responsive>
                            <thead>
                                {/* <tr>
                                    {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                                        <span>{ts.col}</span>
                                    </th>)}
                                </tr> */}
                                <tr>
                                    {tableStructure.map((ts) => (
                                        <th
                                        key={`tableCol-${ts.col}`}
                                        style={ ts.col.toLowerCase() === 'select' ?  {cursor: 'pointer'} : {} }
                                        onClick={() => {
                                            if (ts.col.toLowerCase() === 'select') {
                                            setCheckAll((prevCheckAll) => !prevCheckAll);
                                            }
                                        }}
                                        >
                                        <span>{ts.col}</span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {individualBuildingParts?.filter((bp) => bp?.type == settings?.type)?.map((individualBP) => <IndividualBuildingPartRow key={`ref-key-${individualBP.id}`} {...{individualBP, checkAll}} />)}
                            </tbody>
                            {/* <Button variant="outline-primary" className="mt-3 w-100" onClick={() => setCheckAll(!checkAll)}>
                                {t("selectAll")}
                            </Button> */}
                        </Table>
                      ) : (
                        <p>{t('noIndividualBuildingPartsType')}</p>
                      )}
                    </>
                )}
            </>
        )   
    }

    return (
        <>
            <Form onSubmit={(e) => onSubmit(e)}>
                <Form.Group className="mb-3">
                <Form.Label>Type</Form.Label>
                    <Form.Select
                        id="type"
                        value={settings["type"] || ""}
                        onChange={(e) => changeHandler(e)}
                        >
                        {types?.map((b) => (
                            <option key={`type-key-${b.value}`} value={b.value}>
                                {t(b.label)}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>U {t("thermalBridge")}</Form.Label>
                    <Form.Control
                        id="U_thermal_bridge"
                        type="number"
                        step="any"
                        min="0"
                        placeholder=""
                        required
                        value={settings?.U_thermal_bridge || ""}
                        onChange={(e) => changeHandler(e)}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>{t("uTypical")} (W/m²K)</Form.Label>
                    <Form.Control
                        id="U_typical"
                        type="number"
                        placeholder=""
                        min="0"
                        step="0.01"
                        required
                        value={settings?.U_typical || ""}
                        onChange={(e) => changeHandler(e)}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Check
                    id="editAll"
                    type="checkbox"
                    checked={editAll}
                    onChange={(e) => setEditAll(e.target.checked)}
                    label={t('edit_all_individual_bp')}
                    />
                </Form.Group>

                {table()}

                <Button variant="outline-primary" className="w-100" type="submit">
                    {t("save")}
                </Button>
            </Form>
        </>
    )
}

const IndividualBuildingPartRow = ({ individualBP, checkAll }) => {
    const { t } = useTranslation();
    const [isChecked, setIsChecked] = useState(checkAll);
    
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    useEffect(() => {
        setIsChecked(checkAll);
    }, [checkAll]);

    useEffect(() => {
        individualBP.checked = isChecked;
    }, [isChecked]);

    return (
        <>
            <tr>
                <td>
                    <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange}/>
                </td>
                <td>
                    <span>{individualBP?.name}</span>
                </td>
                <td>
                    <span>{individualBP?.room_info?.utilization_unit || individualBP?.room?.utilization_unit?.name || t('belongsToNoAppartment')}</span>
                </td>
                <td>
                    <span>{individualBP?.floor_info?.name || individualBP?.room?.floor?.name || t('belongsToNoAppartment')}</span>
                </td>
                <td>
                    <span>{individualBP?.room_info?.name|| t('belongsToNoAppartment')}</span>
                </td>
                <td>
                    <span>{individualBP?.individual?.standard_reference ? <FontAwesomeIcon icon={faCheck} /> : t("individual")}</span>
                </td>
                <td>
                    <span>{individualBP?.individual?.U_typical || t('noValues')}</span>
                </td>
                <td>
                    <span>{individualBP?.individual?.U_thermal_bridge || t('noValues')}</span>
                </td>
                
            </tr>
        </>
    )
}