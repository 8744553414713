import React from 'react'
import { ToastContainer, Toast } from 'react-bootstrap'
import { faFaceFrown, faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ToastNotification({ toasts, handleCloseToast }) {
    const toastStatus = [
        {
            name: "success",
            icon: faCheck,
            color: 'green'
        }, {
            name: "error",
            icon: faFaceFrown,
            color: 'red'
        }, {
            name: "notification",
            icon: faExclamation,
            color: 'blue'
        }
    ]
    
    return (
        <ToastContainer className='position-fixed bottom__3 right__3' style={{zIndex: "9999"}}>
            {toasts?.map((toast) => (
                <Toast onClose={() => handleCloseToast(toast.id)} key={`toast-key-${toast.id}`} delay={10000} autohide>
                    <Toast.Header>
                        <div className='w-100 d-flex justify-content-between'>
                            <div className='d-flex align-items-center me-4'>
                                <FontAwesomeIcon className='me-2' icon={toastStatus.find((obj) => obj.name === toast.status).icon} color={toastStatus.find((obj) => obj.name === toast.status).color} />
                                <strong className='me-auto' style={{ color: toastStatus.find((obj) => obj.name === toast.status).color }}>{toast.title}</strong>
                            </div>
                            <small>{toast.time}</small>
                        </div>
                    </Toast.Header>
                    <Toast.Body>{toast.content}</Toast.Body>
                </Toast>
                )
            )}
        </ToastContainer>
    )
}

export default ToastNotification