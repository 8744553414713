import React, { useState } from "react";
import { Col, Card, Form, Alert, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import { SHButton, SHCheck } from "./SigmaHeatTheme";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

// export const CenterRow = (props) => <Row className="justify-content-md-center" > {props.children} </Row>;
export const CenterRow = (props) => <div className="justify-content-md-center"> {props.children} </div>;

export const LargeCol = (props) => <Col xs={12} className={`${!props.last ? ' mb-4' : '' }`}> {props.children} </Col>;

export const StyledCard = (props) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const handleDropdownItemClick = (callback) => {
        callback();
        setShowDropdown(false);
    };

    return (<Card border="light" className={`shadow-sm${!props.last ? ' mb-4' : '' }`}>
        <Card.Body>
            <div className="flex-content space-between">
                <h5 className='m-0'>{props.title || ""}</h5>
                {Boolean(props.buttons?.length) && <>
                    <div className='d-none d-sm-block'>
                        <div className={`actions__buttonbar ${props.buttons.length === 1 ? 'grid__one' : props.buttons.length === 2 ? 'grid__two' : props.buttons.length === 3 ? 'grid__three' : 'grid_four'}`}>
                            {props.buttons.map(b => {
                                return (
                                    <OverlayTrigger
                                        key={`overlaytrigger-${Math.random()}`}
                                        trigger={['hover', 'focus']}
                                        overlay={
                                            <Tooltip className='position-absolute'>{b.tooltip}</Tooltip>
                                        }>
                                        {(b.type === 'button') ? (
                                            <SHButton onClick={() => b.callback()} style={b?.style}>
                                                <FontAwesomeIcon icon={b.icon} />
                                            </SHButton>
                                        ) : (b.type === 'switch') ? (
                                            <SHCheck
                                                type="switch"
                                                checked={b.value}
                                                onChange={() => b.callback()}
                                            />
                                        ) : (b.type === 'select') ? (
                                                <Form.Select
                                                    value={b.value}
                                                    onChange={e => b.callback(e)}
                                                >
                                                    {b.options.map(o => <option key={`option-${o.value}`} value={o.value}>{o.label}</option>)}
                                                </Form.Select>
                                        ) : (<></>)}
                                    </OverlayTrigger>
                                )}
                            )}
                        </div>
                    </div>
                    <div className='d-sm-none'>
                        <Dropdown show={showDropdown} onToggle={() => setShowDropdown(!showDropdown)} align={{ lg: 'start' }}>
                            <SHButton id={`dropdown-${Math.random()}`} data-bs-toggle='dropdown' aria-expanded='false' onClick={() => setShowDropdown(!showDropdown)}>
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </SHButton>

                            <ul className='dropdown-menu'>
                                {props.buttons.map(b => {
                                    if (b.type === 'button') return <li key={`dropdown-${Math.random()}`}><button className="dropdown-item break-spaces" onClick={() => handleDropdownItemClick(b.callback)}>{b.tooltip}</button></li>
                                    if (b.type === 'switch') return <li key={`dropdown-${Math.random()}`}><button className="dropdown-item break-spaces"><SHCheck className='darkfont' type="switch" checked={b.value} label={b.tooltip} onChange={() => b.callback()} /></button></li>
                                    if (b.type === 'select') return <li key={`dropdown-${Math.random()}`}><button className="dropdown-item break-spaces"><Form.Select value={b.value} onChange={e => b.callback(e)}>{b.options.map(o => <option key={`select-option-${o.value}`} value={o.value}>{o.label}</option>)}</Form.Select></button></li>
                                    return <></>
                                })}
                            </ul>
                        </Dropdown>
                    </div>
                </>
                }
            </div>
            <div className="mt-3">{props.children}</div>
        </Card.Body>
    </Card>);
}

export const Wrapper = styled.div`
    display: flex;
    & .sidebar__wrapper {
        flex-shrink: 0;
    }
`;

export const Container = styled.div`
    width: 100%;
    // padding: 1rem;
`;

export const AbsoluteCenter = (props) => <div className="d-flex flex-column justify-content-center align-items-center pt-3" style={{minHeight: '100vh', background: 'linear-gradient(to top left, #f28533,  #e0b260)', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed', paddingBottom: 'calc(70px + 1rem)'}}> {props.children} </div>;

export const FixedForm = (props) => <Form id={props.id} className="p-3" style={{width: "100%", maxWidth: "384px"}}>{props.children}</Form>;

export const FormAlert = (props) => <Alert className="mb-3" style={{width: "100%", maxWidth: "384px"}} variant={props.variant}>{props.children}</Alert>;

export const LoginFooter = styled.div.attrs({
        className: 'd-flex flex-wrap justify-content-between align-items-center p-3',
    })`
    position: relative;
    width: 100%;
    bottom: 0;
    height: 70px;
    border-top: 1px solid #ffffff!important;
    margin-top: -70px;
`;

export const TextFooter = styled.p.attrs({
        className: 'col-md-4 mb-0',
    })`
    font-weight: 500;
    color: #ffffff;
`;

export const ULFooter = styled.ul.attrs({
    className: 'nav col-md-4 justify-content-end',
})``;

export const LIFooter = styled.li.attrs({
    className: 'nav-item',
})``;

export const LinkFooter = styled.a.attrs({
    className: 'nav-link px-2',
})`
font-weight: 500;
    color: #ffffff;
    &:hover,
    &:focus {
        color: #ffffff;
        text-decoration: underline;
    }
    &:focus-visible {
        outline: none;
    }
`;