import { useState, useCallback, useEffect } from "react";
import { useAuth } from "../../Context";
import { useSwagger } from "../../context/SwaggerContext";
import { useToast } from "../../NotificationsContent";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { Form } from "react-bootstrap";
import PitchedRoof from "./PitchedRoof";
import GableRoof from "./GableRoof";
import HipRoof from "./HipRoof";

export const RoofForm = ({ room, setRoof, roof }) => {
    const { login } = useAuth();
    const client = useSwagger();
    const { addToast } = useToast();
    const _buildingGuid = useParams()["buildingGuid"];
    const { t } = useTranslation();
    const [type, setType] = useState(roof?.type || 1);
    const [buildingParts, setBuildingParts] = useState(undefined)

    const roofTypes = [
        // {
        //     value: 0,
        //     name: t("pitchedRoof"),
        //     pitches: 1
        // }, 
        {
            value: 1,
            name: t("gableRoofOnePitch"),
            pitches: 1
        }, {
            value: 2,
            name: t("gableRoofTwoPitches"),
            pitches: 2
        }, {
            value: 3,
            name: t("hipRoofTwoPitches"),
            pitches: 2
        }, {
            value: 4,
            name: t("hipRoofThreePitches"),
            pitches: 3
        }, {
            value: 5,
            name: t("hipRoofFourPitches"),
            pitches: 4
        }, 
    ]

    const loadBuildingParts = useCallback(async() => {
        if (!client) return;
        console.log(room);
    
        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
        
            const response = await client.apis["building"].building_room_buildingpart_retrieve({
                building_uuid: _buildingGuid,
                room_uuid: room,
            });
        
            if (response.status >= 200 && response.status < 300) {
                setBuildingParts(response.status === 204 ? [] : response.obj);
                console.log("BP: ", response.obj)
            }
        
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, _buildingGuid, room, t]);

    useEffect(() => {
        loadBuildingParts();
    }, [loadBuildingParts, room])

    useEffect(() => {
        console.log("Roof: ", roof);
    }, [roof])

    return <>
        <Form.Group className='mb-3'>
            <Form.Label>{t("roofType")}</Form.Label>
            <Form.Select className="mb-3" value={type} onChange={(e) => setType(parseInt(e.target.value))}>
                {roofTypes.map(roof => <option key={`roof-name-${roof.name}`} value={parseInt(roof.value)}>{roof.name}</option>)}
            </Form.Select>
        </Form.Group>

        {(type === 0 && buildingParts !== undefined) && <PitchedRoof {...{ pitches: roofTypes.find(obj => obj.value === type)?.pitches, room, type, setFormData: setRoof, buildingParts }} />}
        {((type === 1 || type === 2) && buildingParts !== undefined) && <GableRoof {...{ pitches: roofTypes.find(obj => obj.value === type)?.pitches, room, type, setFormData: setRoof, buildingParts, roof }} />}
        {((type === 3 || type === 4 || type === 5 || type === 6) && buildingParts !== undefined) && <HipRoof {...{ pitches: roofTypes.find(obj => obj.value === type)?.pitches, room, type, setFormData: setRoof, buildingParts, roof }} />}
    </>
}

export default RoofForm;