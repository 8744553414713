import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';

const BuildingDocumentData = ({data, item}) => {
    const { t } = useTranslation();
    
    const foundItem = item?.toLowerCase()
    const elements = useMemo(() => {
        return Object.keys(data).length === 0 ? [] : data;
    }, [data]);

    const [elementSizes, setElementSizes] = useState([]);

    const uniqueArray = useMemo(() => {
        return [...new Set(elements)];
    }, [elements]);

    const getRandomSizeClass = () => {
        // const sizes = ['small', 'medium', 'large'];
        const sizes = ['small', 'medium'];
        return sizes[Math.floor(Math.random() * sizes.length)];
    };

    useEffect(() => {
        const lowerCaseArray = uniqueArray.map(item => item.toLowerCase());
        if (foundItem && lowerCaseArray.some(item => item.includes(foundItem))) {
            const originalIndex = uniqueArray.findIndex(item => item.toLowerCase().includes(foundItem));
            if (originalIndex !== -1) {
                const [removedItem] = uniqueArray.splice(originalIndex, 1);
                uniqueArray.unshift(removedItem);
            }
        }

        const initialSizes = elements.map(() => getRandomSizeClass());
        setElementSizes(initialSizes);
    }, [elements, foundItem, uniqueArray]);

    return (
        <div className={`elements_container`}>
            {uniqueArray.length !== 0 ? (
                uniqueArray.map((element, index) => (
                    <div key={index} className={`random-div ${elementSizes[index]} ${(foundItem && element?.toLowerCase().includes(foundItem.toLowerCase())) ? 'found_element' : ''}`}>
                        {element}
                    </div>
                ))
            ) : (
                <p>{t('noExtractedWordsFromFile')}</p>
            )}
        </div>
    )
}

export default BuildingDocumentData