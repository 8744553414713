import React, { useState, useEffect, useRef, useCallback } from "react";
import CanvasClass from "./CanvasClass";
import MainLayout from '../../components/MainLayout';
import { Button, Table } from "react-bootstrap";
import Card from '../../components/Card';
import "./style.css";

const useResizeObserver = (ref) => {
    const [boundaries, setBoundaries] = useState(null);

    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                setBoundaries(entry.contentRect);
            });
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);

    return boundaries;
};

export const Digimeta = () => {
    const canvasClassRef = useRef(null);
    const canvasRef = useRef(null);
    const boundaries = useResizeObserver(canvasRef);
    const [loaded, setLoaded] = useState(false);
    const [image, setImage] = useState(null);
    const [rotate, setRotate] = useState("0");
    const [data, setData] = useState([]);

    const loadImage = () => {
        const img = new Image();
        img.src = "https://images.unsplash.com/photo-1549190179-646f048c6108?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3087&q=80";
        img.onload = () => setImage(img);
    };

    const onSubmit = useCallback(() => {
        // submitData(data)
        setData([]);
        setRotate("0");
        // Load new image
        // Destory & Initialise Canvas
        canvasClassRef.current.cleanUp(canvasRef.current, boundaries, image);
    }, [data])

    useEffect(() => {
        loadImage();
        if (canvasRef.current === null || boundaries === null || loaded || image === null) return
        canvasClassRef.current = new CanvasClass(canvasRef.current, boundaries, image, setData);
        setLoaded(true)
    }, [canvasRef.current, boundaries, loaded])

    useEffect(() => {
        if (canvasClassRef.current) canvasClassRef.current.changeDegree(rotate);
    }, [rotate])

    return <MainLayout {...{ background: "https://images.unsplash.com/photo-1693509481240-60d81d010989?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3026&q=80" }}>
        <Card {...{ heading: 'Label Digimeta' }}>
            <main className="mb-3">
                <canvas ref={canvasRef} className="digimeta__canvas" />
            </main>

            <div className="d-flex justify-content-between gap-3 mb-3">
                <input className="form-control" type="range" id="rotate" name="rotate" min="0" max="360" value={rotate} onChange={(e) => setRotate(e.target.value === "" ? 0 : e.target.value)} />
                <input className="form-control" type="number" name="rotate" min="0" max="360" value={rotate} onChange={(e) => setRotate(e.target.value === "" ? 0 : e.target.value)} />
            </div>

            <Table responsive className="mb-3">
                <thead>
                    <tr>
                        {data.map((d, i) => <th key={`${i}-col`}>{i + 1}</th>)}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {data.map((d, i) => <td  key={`${i}-td`}>{d.value}</td>)}
                    </tr>
                </tbody>
            </Table>

            <div className="d-flex justify-content-between">
                <Button variant="outline-primary" onClick={() => onSubmit()}>Überspringen</Button>
                <Button variant="outline-primary" onClick={() => onSubmit()}>Submit</Button>
            </div>
        </Card>
    </MainLayout>
}

export default Digimeta;