import React, { useState, useEffect, useCallback } from 'react'
import { useAuth } from '../Context';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import './style.css'
import CookieBanner from '../components/CookieBanner';
import Background from "../assets/background_3_1.png"
import Navbar from '../widgets/Navbar';

function Footer() {
    const { login, doSetLogin } = useAuth();
    const year = new Date().getFullYear();
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(login?.settings?.language_code || localStorage.getItem('language') || "en");

    const languages = [
        {
            key: 'de',
            name: t('german')
        },
        {
            key: 'en',
            name: t('english')
        },
    ];

    useEffect(() => {
        i18n.changeLanguage(language)
    }, [])

    const handleChange = useCallback((e) => {
        const newLanguage = e.target.value;
        i18n.changeLanguage(newLanguage);
        localStorage.setItem("language", newLanguage)
        setLanguage(newLanguage);
    
        let loginTMP = JSON.parse(JSON.stringify(login));
        if (loginTMP) {
            loginTMP.settings.language_code = newLanguage; 
            doSetLogin(loginTMP);
        }
    }, [login, doSetLogin]); 

    return (
        <>
            <div className='footer'>
                <span className='col-md-4 mb-0'>© {year} Sigmaheat</span>
                <ul className='nav col-md-4 justify-content-end'>
                    <li className='nav-item'>
                        <a href='https://sigmaheat.de/legal/privacy-policy' className='nav-link px-2'>{t('privacy')}</a>
                    </li>
                    <li>
                        <a href='https://sigmaheat.de/imprint' className='nav-link px-2'>{t('imprint')}</a>
                    </li>
                    <li>
                        <Form>
                            <Form.Group>
                                <Form.Select onChange={(e) => handleChange(e)} value={language}>
                                    {languages.map((lng) => <option key={`key-${lng.key}`} value={lng.key}>{lng.name}</option>)}
                                </Form.Select>
                            </Form.Group>
                        </Form>
                    </li>
                </ul>
            </div>
            <CookieBanner />
        </>
    )
}

function CredentialWrapper({ children }) {
    const { login, defaultTheme } = useAuth();

    useEffect(() => {
        let htmlTag = document.getElementsByTagName('html')[0];
        defaultTheme === 'dark' ? htmlTag.classList.add('dark') : htmlTag.classList.remove('dark');
    }, [defaultTheme])

    return (
        <>
            {login !== null && <Navbar {...{ linksArray: []}} />}
            <div className='main__wrapper'>
                <div className="main__inner align-items-center">
                    {children}
                </div>
                <div className="main__background" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.4)), url(${Background})` }}></div>
            </div>
            <Footer />
        </>
    )
}

export default CredentialWrapper